import { useAppDispatch, useAppSelector } from 'hooks';
import { Typography } from 'interfaces/typography';
import React, { useState } from 'react';
import { getServicesManager } from 'services';
import {
  updateSessionStatus,  
  selectCount,
} from './SessionStoreSetter';
import './hover.css'
import { convertFromReduxSafeUserState, updatePendingReviews } from 'lib/redux/store';
import { RootState } from 'store';
import { connect } from 'react-redux';
import { UserDataResult } from 'interfaces/services';
import { CAPITAL_GENERATIONS_TEAM_ID } from 'cfg/const';

const unfilledFlag = 
<svg className='flag' viewBox="0 0 737 747" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className='flag' fillRule="evenodd" clipRule="evenodd" d="M63.2045 466.659V715.685C63.2045 732.795 49.3925 746.664 32.0898 746.664C14.9071 746.664 0.980469 732.695 0.980469 715.685V30.9787C0.980469 13.8693 14.9018 0 32.1111 0H716.511C733.705 0 741.126 12.2188 733 27.4533L637.839 205.88C629.751 221.041 629.714 245.552 637.839 260.787L733 439.213C741.084 454.375 733.719 466.667 716.511 466.667L63.2045 466.659ZM63.2045 62.2187V404.432H643.938L582.938 290.057C565.058 256.536 565.083 210.068 582.938 176.588L643.938 62.2133L63.2045 62.2187Z" fill="black"/>
</svg>

const filledFlag = 
<svg className='flag' viewBox="0 0 736 747" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className='flag' fillRule="evenodd" clipRule="evenodd" d="M62.224 466.659V715.685C62.224 732.795 48.412 746.664 31.1093 746.664C13.9267 746.664 0 732.695 0 715.685V30.9787C0 13.8693 13.9213 0 31.1307 0H715.531C732.724 0 740.145 12.2188 732.02 27.4533L636.859 205.88C628.77 221.041 628.734 245.552 636.859 260.787L732.02 439.213C740.103 454.375 732.739 466.667 715.531 466.667L62.224 466.659ZM62.224 62.2187V404.432H642.957L581.957 290.057C564.077 256.536 564.103 210.068 581.957 176.588L642.957 62.2133L62.224 62.2187Z" fill="#FF0000"/>
<path className='flag' d="M45 54H646.5L578.5 239.5L658.5 427.5L45 445V54Z" fill="#FF0000"/>
</svg>

const unselectedStarGraphic = 
<svg className='star' viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className='star' d="M10.0002 0.833374L12.8327 6.57171L19.1668 7.49754L14.5835 11.9617L15.6652 18.2684L10.0002 15.2892L4.33516 18.2684L5.41683 11.9617L0.833496 7.49754L7.16766 6.57171L10.0002 0.833374Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const selectedStarGraphic = 
<svg className='star' viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className='star' d="M9.99967 0.833984L12.8322 6.57232L19.1663 7.49815L14.583 11.9623L15.6647 18.269L9.99967 15.2898L4.33467 18.269L5.41634 11.9623L0.833008 7.49815L7.16717 6.57232L9.99967 0.833984Z" fill="#006116" stroke="#006116" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export type SessionStatusProps = {
  type: 'sessionData' | 'sessionList'
  sessionId: string
  sessionUserId: string
  viewerUserId: string
  reviewIsOpen?: boolean
  reviewOpenedByUser?: boolean 
  hasStar?: boolean
  isMainView?: boolean
  pendingReviews: number | null
  user: UserDataResult | null
}

function SessionStatusImpl(props: SessionStatusProps) {
  const count = useAppSelector(selectCount);
  const dispatch = useAppDispatch();

  function _handleOnStar(): void {
    const sessionInfo = count[props.sessionId]
    const hasSession = sessionInfo !== undefined
    const starState =  hasSession ? !sessionInfo.star : (!props.hasStar ?? true)
    getServicesManager().updateSessionStar(props.sessionId, starState).then((x) => {
      if (x !== null) {
        dispatch(updateSessionStatus({
        'sessionId': props.sessionId, 'reviewIsOpen': hasSession ? sessionInfo['reviewIsOpen'] : props.reviewIsOpen, 
        'reviewOpenedByUser': hasSession ? sessionInfo['reviewOpenedByUser'] : props.reviewOpenedByUser, 'star': starState }))
    }})
  }

  function _handleReview(): void {
    const sessionInfo = count[props.sessionId]
    const hasSession = sessionInfo !== undefined
    const reviewState =  hasSession ? !sessionInfo.reviewIsOpen : (!props.reviewIsOpen ?? true)
    getServicesManager().updateSessionReview(props.sessionId, reviewState).then((x) => {
      if (x !== null) {
        dispatch(updateSessionStatus({
        'sessionId': props.sessionId, 'reviewIsOpen': reviewState, 
        'reviewOpenedByUser': reviewState ? true : undefined, 'star': hasSession ? sessionInfo['star'] : props.hasStar }))
        if (props.pendingReviews !== null) {
          dispatch(updatePendingReviews({
            'value': props.pendingReviews + (reviewState ? 1 : -1)
          }))
        }
    }
  })
  }
  const reviewIsOpen = count[props.sessionId] ? count[props.sessionId].reviewIsOpen : props.reviewIsOpen
  const reviewOpenedByUser = count[props.sessionId] ? count[props.sessionId].reviewOpenedByUser : props.reviewOpenedByUser
  const starIsOpen = count[props.sessionId] ? count[props.sessionId].star : props.hasStar

  function _renderSessionData(): JSX.Element {
    return (<div className='flex flex-row gap-1'>
    {showReviewIcon() ?
    <div className='w-4 cursor-pointer' onClick={_handleReview}>
    {reviewIsOpen ? filledFlag : unfilledFlag}
    </div> : null}
    {showStarIcon() ? <div className='w-4 cursor-pointer' onClick={_handleOnStar}>
    {starIsOpen ? selectedStarGraphic : unselectedStarGraphic}
    </div> : null}
    </div>)
  }

  function showReviewIcon(): boolean {
    return props.reviewIsOpen !== undefined || props.sessionUserId !== props.viewerUserId || [CAPITAL_GENERATIONS_TEAM_ID].includes(props.user?.team_id ?? '')
  }

  function showStarIcon(): boolean {
    return props.sessionUserId === props.viewerUserId
  }

  return (
    props.type === 'sessionData' ? _renderSessionData() : 
    <div className='flex flex-row items-center justify-center gap-1'>
        {showReviewIcon() ? <DebouncerIcon isMainView={props.isMainView} reviewIsOpen={reviewIsOpen} reviewOpenedByUser={reviewOpenedByUser} starIsOpen={starIsOpen} type={'flag'} updateReview={_handleReview} updateStar={_handleOnStar}/> : null}
        {showStarIcon() ? <DebouncerIcon isMainView={props.isMainView} reviewIsOpen={reviewIsOpen} reviewOpenedByUser={reviewOpenedByUser} starIsOpen={starIsOpen} type={'star'} updateReview={_handleReview} updateStar={_handleOnStar}/> : null}
    </div>
  );
}

export type DebouncerIconProps = {
  type: 'flag' | 'star'
  reviewIsOpen: boolean | undefined, 
  reviewOpenedByUser: boolean | undefined, 
  starIsOpen?: boolean, 
  isMainView?: boolean
  updateReview: () => void
  updateStar: () => void
}

export type DebouncerIconState = {
  showSuggestion: boolean, 
  timeout: NodeJS.Timeout | null
}

class DebouncerIcon extends React.Component<DebouncerIconProps, DebouncerIconState> {
  constructor(props: DebouncerIconProps) {
    super(props)
    this.state = {
      'showSuggestion': false,
      'timeout': null
    }
  }

  renderFlagIcon(): JSX.Element {
    return (
    <div>
    {this.props.reviewIsOpen || this.props.reviewIsOpen === undefined ? <div onClick={this.props.updateReview} className={(((!this.props.reviewIsOpen) || (this.props.reviewIsOpen && this.props.reviewOpenedByUser))  ? 'cursor-pointer hover-opacity flag' : 'hover-opacity') + (!this.props.reviewIsOpen ? ' hover-only' : '')} style={{'width': '12px', 'height': '12px'}}>
         {this.props.reviewIsOpen ? filledFlag : unfilledFlag}
    </div> : 
    <div onClick={this.props.updateReview} className={'cursor-pointer hover-opacity flag items-center flex justify-center'} style={{'width': '12px', 'height': '12px'}}>
      &#x2713;
  </div> 
    }
    {this.state.showSuggestion ? 
      <div className='absolute' style={{'zIndex': '99999999', 'right': this.props.isMainView ? 'inherit' :  '30px', 'marginRight': this.props.isMainView ? 'inherit' : '30px'}}>
        <div className='relative' style={{'marginTop': '10px', }}>
        <div className='bg-white p-1' style={{'border': "1px solid black", 'borderRadius': '2px', zIndex: '9999999', 'boxShadow': '0px 0px 4px rgba(0, 0, 0, 0.35)'}}>
        <Typography variant='smallCaption'>
          {this.props.reviewIsOpen ? 'Close Review' : 'Flag for review' }
          </Typography>
        </div>
        </div>
      </div> : null}
    </div>
    )
  }

  renderStarIcon(): JSX.Element {
    return (
      <div>
      {this.props.starIsOpen ?
      <div onClick={this.props.updateStar} className='cursor-pointer star hover-opacity' style={{'width': '12px'}}>
        {selectedStarGraphic}
      </div> : 
      <div onClick={this.props.updateStar} className='hover-only cursor-pointer star' style={{'width': '12px'}}>
        {unselectedStarGraphic}
      </div>}
      {this.state.showSuggestion ? 
      <div className='absolute' style={{'zIndex': '9999',  'right': this.props.isMainView ?'inherit' :  '20px' , 'marginRight': this.props.isMainView ? 'inherit' : '20px'}}>
        <div className='relative' style={{'marginTop': '10px'}}>
        <div className='bg-white p-1' style={{'border': "1px solid black", 'borderRadius': '2px', 'boxShadow': '0px 0px 4px rgba(0, 0, 0, 0.35)'}}>
        <Typography variant='smallCaption'>
          {this.props.starIsOpen ? 'Unstar transcript' : 'Star transcript' }
          </Typography>
        </div>
        </div>
      </div> : null}
      </div>)
  }


  render(): React.ReactNode {
   return (
    <div className='flag' onMouseEnter={() => {
      const timeOut = setTimeout(() => this.setState({'showSuggestion': true}), 500)
      this.setState({'timeout': timeOut})
    }} onMouseLeave={() => {
      this.setState((prevState) => {
        if (prevState.timeout) clearTimeout(prevState.timeout)
        return {
          'timeout': null,
          showSuggestion: false
        }
      })
    }}>
      {this.props.type === 'flag' ? this.renderFlagIcon() : this.renderStarIcon()}
    </div>)
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    pendingReviews: state.pendingReviews.value,
    user: convertFromReduxSafeUserState(state.user)
  };
};

const ReduxWrapped = connect(mapStateToProps)(SessionStatusImpl)

export { ReduxWrapped as SessionStatus}