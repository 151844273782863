import { Typography, TypographyVariant } from 'interfaces/typography';
import React, { RefObject } from 'react'
import DatePicker from "react-datepicker";
import './datepicker.css'
import { xIcon } from 'icons';

export type FilterOption = {
  'label': string | JSX.Element;
  'selectedLabel'?: string | JSX.Element;
  'value': string;
  'selected': boolean;
  'id'?: any
};

export type FilterOption__STRICT_MULTI_VLAUE = {
  'label': string;
  'values': string[];
  'selected': boolean;
}

export type FilterOption__STRICT = {
  'label': string;
  'selectedLabel'?: string;
  'value': string;
  'selected': boolean;
  'id'?: string
};

// allows for either only one selection or multiple selection
export enum FilterType {
  SINGLE = 'single',
  MULTI = 'multi',
  DATE = 'date',
  TEXT = 'text',
  CUSTOM = 'custom'
}

type SelectableFilterProps = {
  allowsNonSelected?: boolean
  showAll?: boolean
  selectedColor?: string,
  selectedTextColor?: string
  selectedStyle?: React.CSSProperties,
  selectedClassName?: string,
  unselectedStyle?: React.CSSProperties
  unselectedClassName?: string
  removeOuterBoxForShowAll?: boolean
  vertical?: boolean
  headerElement?: JSX.Element
  filterOptions: FilterOption[];
  groupById?: boolean
  spreadBetween?: boolean
  onFilterUpdate: (updatedFilters: FilterOption[]) => void;
  noMaxHeight?: boolean,
  usesRelativeAtParent?: boolean
  hideInputText?: boolean
  openFilterDefault?: boolean
  clickoutIsEmptyUpdate?: boolean
}

type DatePickerFilterProps = {
  simpleView?: boolean,
  customWrapperClass?: string,
  onlyDateShownCustomView?: boolean
  label?: string,
  value: Date | null | undefined, 
  dateFormat: string,
  placeholderText: string,
  onChange: (date: Date | null) => void
}


export type FilterProps = {
  maxWidth?: number
  maxHeight?: string
  variant?: TypographyVariant,
  filterType: FilterType
  selectableFilterProps?: SelectableFilterProps
  dateFilterProps?: DatePickerFilterProps
  closeOnSelect?: boolean
  heightFull?: boolean
  widthFit?: boolean
  widthFull?: boolean
};

export type FilterState = {
  showFilter: boolean
  textSearch: string,
}

export class Filter extends React.Component<FilterProps, FilterState> {
  _wrapperRef : RefObject<HTMLDivElement> = React.createRef()
  _inputRef: RefObject<HTMLInputElement> = React.createRef()

    constructor(props: FilterProps) {
        super(props)
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
          showFilter: this.props.selectableFilterProps?.openFilterDefault ?? false,
          textSearch: '',
        }
    }

  _renderSelectedItem(filterOption: FilterOption): JSX.Element {
    return (
    <div key={filterOption.value} className='text-xs pr-1 py-0.5 px-1 rounded justify-center items-center flex text-left' style={{backgroundColor: 'rgb(77, 165, 107)'}}>
        <div className='mr-0.5'>
          {typeof(filterOption.label) == 'string' ?
          <Typography variant={this.props.variant ? this.props.variant : 'smallCaption'} color={'white'}>
            {filterOption.selectedLabel ? filterOption.selectedLabel : filterOption.label}
          </Typography> : 
            filterOption.selectedLabel ? filterOption.selectedLabel : filterOption.label
          }
        </div>
        {this.props.filterType == FilterType.MULTI || this.props.selectableFilterProps?.allowsNonSelected ?
        <div className='rounded justify-center items-center cursor-pointer flex' onClick={() => {this._onFilterUpdate.bind(this)(filterOption, false)}}>
          {React.cloneElement(xIcon, {className: "w-3.5 h-3.5"})}
        </div>
        : null }
    </div>
    )
  }

  _onFilterUpdate(filterOption: FilterOption, isSelect: boolean) {
    const updatedFilters: FilterOption[] =[{...filterOption, 'selected': !filterOption.selected}]
    if (this.props.filterType == FilterType.SINGLE && isSelect) {
      const selectedFilters = this.props.selectableFilterProps?.filterOptions.filter((o) => o.selected)
      if (selectedFilters && selectedFilters.length > 1) throw new Error('too many filters selected')
      if (selectedFilters && selectedFilters.length == 1) updatedFilters.push({...selectedFilters[0], 'selected': false})
    } 
    if (this.props.closeOnSelect) this.setState({'showFilter': false, 'textSearch': ''})
    this.props.selectableFilterProps?.onFilterUpdate(updatedFilters)
  }

  _renderNonSelectedOption(filterOption: FilterOption): JSX.Element {
      return (
        <div
          key={filterOption.value}
          tabIndex={0}
          className="cursor-pointer group flex py-1 pl-3 pr-2 focus-visible:bg-gray-200 focus-visible:outline-none focus-visible:ring-0 dark:focus-visible:bg-zinc-700 dark:focus-visible:ring-offset-zinc-700 hover:bg-gray-200 dark:hover:bg-zinc-700"
          onClick={() => {
            this.setState({'textSearch': ''})
            this._onFilterUpdate.bind(this)(filterOption, true)
          }}
        >
          <div className="min-h-5 flex select-none text-left items-center justify-center rounded py-0.5 pr-1 text-xs px-1" style={{color: 'rgb(0, 0, 0)', backgroundColor: 'rgb(194, 194, 194)'}}>
            <div className="mr-0.5">
                {typeof(filterOption.label) == 'string' ?
                 <Typography variant={this.props.variant ? this.props.variant : 'smallCaption'}>
                   {filterOption.label}
                </Typography> : 
                 filterOption.label
              }
            </div>
          </div>
      </div>)
  }

  _renderLineSplitHeaderRecallSection(x: string): JSX.Element {
    return (
    <div style={{'paddingLeft': '10px', 'paddingRight': '10px'}} className='w-full items-center flex flex-row justify-between'>
        <hr className='w-1/3' style={{'height': '1px', backgroundColor: '#e5e7eb'}} />
        <div className='w-full flex-grow text-center pl-2 pr-2 max-w-fit'>
          <Typography variant='smallParagraph'>
            {x}
          </Typography>
        </div>
        
        <hr className='w-1/3' style={{'height': '1px', backgroundColor: '#e5e7eb'}} />
  </div>)
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Readonly<FilterProps>, prevState: Readonly<FilterState>, snapshot?: any): void {
    if (this.state.showFilter && this._inputRef.current && 
      (!prevState.showFilter || 
        (prevProps.selectableFilterProps && this.props.selectableFilterProps && prevProps.selectableFilterProps.filterOptions.map((v) => v.selected ? v.label : '').join('') !== this.props.selectableFilterProps.filterOptions.map((v) => v.selected ? v.label : '').join('')))) {
      this._inputRef.current.focus()
    }
  }
  
  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event: MouseEvent) {
    if (this._wrapperRef && this._wrapperRef.current && event.target && !this._wrapperRef.current.contains(event.target as Node)) {
      this.setState({'showFilter': false, 'textSearch': ''})
      if (this.props.selectableFilterProps?.clickoutIsEmptyUpdate) {
        this.props.selectableFilterProps.onFilterUpdate([])
      }
    }
  }

  _getSelectableOptions(): FilterOption[] {
    if (!this.props.selectableFilterProps) return []
    return this.props.selectableFilterProps.filterOptions.filter((v) => !v.selected && (typeof v.label !== 'string' || this.state.textSearch === ''  || v.label.toLowerCase().includes(this.state.textSearch.toLowerCase())))
  }

  onKeyDown(x: React.KeyboardEvent<HTMLInputElement>) {
    if ((x.key === 'Enter' || x.keyCode === 13)) {
      x.preventDefault()
      const selectableOptions = this._getSelectableOptions()
      if (selectableOptions.length > 0) {
        this.setState({'textSearch': ''})
        this._onFilterUpdate(selectableOptions[0], true)
      }
   }

   if (this.props.filterType === FilterType.MULTI && this.state.textSearch.length === 0 && (x.key == "Backspace" || x.keyCode === 8 || x.keyCode === 46)) {
    const selectedOptions = (this.props.selectableFilterProps?.filterOptions ?? []).filter((v) => v.selected)
    if (selectedOptions.length > 0) {
      x.preventDefault()
      this.setState({'textSearch': ''})
      this._onFilterUpdate(selectedOptions[selectedOptions.length - 1], false)
    }
   }
  }

  _renderTextArea() {
    return <input
    onKeyDown={this.onKeyDown.bind(this)}
    autoFocus={this.props.selectableFilterProps?.openFilterDefault}
    value={this.state.textSearch}
    onChange={(e) => this.setState({'textSearch': e.target.value})}
    ref={this._inputRef} type={'text'} style={{
      'height': '20px',
      'boxSizing': 'border-box',
      'padding': '4px 6px',
      'width': '0px',
      'minWidth': '20px',
      'flexGrow': '1',
      'border': '0px',
      'margin': '0px',
      'outline': '0px',
      fontFamily: 'Calibri,Arial,Helvetica,sans-serif',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 'min(12px, max(11px, 1.8vw))',
      lineHeight: "18px",
      letterSpacing: "0.02em",
    }} />
  }

  _renderCategoricalFilter(): JSX.Element | null {
    if (!this.props.selectableFilterProps) return null
    if (this.props.selectableFilterProps.showAll) return this._renderShowAllFilter()
    const width = this.props.maxWidth ? this.props.maxWidth : '150px'
    const numSelected = this.props.selectableFilterProps.filterOptions.filter(option => option.selected).length
    
    function findPrevNonSelected(idx: number, options: FilterOption[]): number {
      let prev = idx - 1
      while (prev >= 0 && options[prev].selected) {
        prev -= 1
      }
      return prev
    }

    const numOptions = this.props.selectableFilterProps.filterOptions.length

    return (
        <div className={this.props.widthFit ? 'w-fit' : this.props.widthFull ? 'w-full' : ''} ref={this._wrapperRef} style={{'height': this.props.heightFull ? '100%' : 'inherit'}}>
        <div className={this.props.widthFit ? 'w-fit' : this.props.widthFull ? 'w-full ' : ''} style={{'height': this.props.heightFull ? '100%' : 'inherit'}}  onClick={(e) => {
          e.stopPropagation(); 
          this.setState((state) => {return {'showFilter': !state.showFilter, 'textSearch': !state.showFilter ? state.textSearch : ''}})}}>
          {this.props.selectableFilterProps.headerElement ? this.props.selectableFilterProps.headerElement : 
              <div className='p-1 cursor-pointer grid rounded-md' style={{'backgroundColor': "rgb(30 58 138)"}}>
              <div>
                <Typography variant='largeParagraph' color='white'>
                {numSelected > 1 ? numSelected + ' selected' : numSelected == 0 ? 'All' : this.props.selectableFilterProps.filterOptions.find((v) => v.selected)!.label}
                </Typography></div>
            </div>
          }
        </div>
        {this.state.showFilter ? <div onClick={(e) => e.stopPropagation()} className={this.props.selectableFilterProps.usesRelativeAtParent ? 'relative' : 'absolute'} style={{'zIndex': '999999999'}}>
            <div className='absolute cursor-default' style={{boxShadow: '0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a'}}>
              <div style={{width: width, maxWidth: width, backgroundColor: 'white', borderWidth: '1px', borderRadius: '0.25rem', overflowY: 'auto', maxHeight: this.props.selectableFilterProps.noMaxHeight ? 'fit-content' : '12rem'}}>
                    {<div className='gap-1' style={{'transition': 'padding-top 0.2s linear', alignItems: 'center', display: 'flex', flexWrap: 'wrap', 'padding': numOptions > 3 || (this.props.filterType === FilterType.MULTI && numSelected > 0) ? '4px' : '0px'}}>
                        {this.props.filterType !== FilterType.SINGLE ? this.props.selectableFilterProps.filterOptions.map(option => option.selected ? this._renderSelectedItem(option) : null) : null}
                        {!this.props.selectableFilterProps.hideInputText && numOptions > 3 ? this._renderTextArea() : null}
                    </div>}
                      {numOptions > 3 || (this.props.filterType === FilterType.MULTI && numSelected > 0)  ? <div className="full border-t-0 border-r-0 border-l-0 border-b border-gray-200"></div> : null}
                      <div className='my-1'>
                        {this.props.selectableFilterProps.groupById ? this._getSelectableOptions().map((value: FilterOption, idx: number, arr: FilterOption[]) => {
                          if (value.selected) return null
                          const prevNonSelectedIdx = findPrevNonSelected(idx, arr)
                          const renderId = idx == 0 || (prevNonSelectedIdx < 0 || arr[prevNonSelectedIdx].id !== value.id) 
                          if (renderId) {
                            return (
                              <div key={value.value}>
                                {this._renderLineSplitHeaderRecallSection(value.id)}
                                {this._renderNonSelectedOption(value)}
                              </div>)
                          } else {
                            return this._renderNonSelectedOption(value)
                          }
                        }):
                        (this._getSelectableOptions()).map(option => this._renderNonSelectedOption(option))}
                      </div>
                  </div>
              </div>
        </div>: null}
        </div>
    )
  }

  _renderShowAllOption(option: FilterOption): JSX.Element {
    const isString = typeof(option.label) == 'string'
    const defaultStyle =  {
      'color': option.selected ? this.props.selectableFilterProps?.selectedTextColor ?? 'white': 'black',
      'backgroundColor': option.selected ? this.props.selectableFilterProps?.selectedColor ?? 'rgb(30 58 138)' : 'white', 
      'cursor' : !option.selected || this.props.filterType == FilterType.MULTI ? 'pointer' : 'initial',
      'padding': '8px', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 20%)', "borderRadius": "10px", "border": "1px solid rgba(91, 101, 123, 0.25)"}
    return (
    <div
      key={option.value}
      className={ option.selected && this.props.selectableFilterProps?.selectedClassName ? this.props.selectableFilterProps.selectedClassName : !option.selected && this.props.selectableFilterProps?.unselectedClassName ? this.props.selectableFilterProps.unselectedClassName : undefined}
      onClick={() => {if(this.props.filterType === FilterType.MULTI || !option.selected || this.props.selectableFilterProps?.allowsNonSelected) this._onFilterUpdate.bind(this)(option, !option.selected)}}
      style={!isString ? {} : option.selected ? this.props.selectableFilterProps?.selectedStyle ?? defaultStyle : this.props.selectableFilterProps?.unselectedStyle ?? defaultStyle}
    >
      <div className='flex text-center justify-center items-center h-full w-full'>
        {typeof(option.label) == 'string' ?
          <Typography color={option.selected ? 'white' : 'black'} variant={this.props.variant ? this.props.variant : 'smallCaption'}>
            {option.selected && option.selectedLabel ? option.selectedLabel : option.label}
        </Typography> : 
            option.selected && option.selectedLabel ? option.selectedLabel : option.label
          }
      </div>
    </div>
    )
  }

  _renderShowAllFilter(): JSX.Element | null {
    if (!this.props.selectableFilterProps) return null
    const showElements = this.props.selectableFilterProps.filterOptions.map(option => this._renderShowAllOption(option))
    if (this.props.selectableFilterProps.removeOuterBoxForShowAll) {
      return <div className={'flex' + (this.props.selectableFilterProps.spreadBetween ? ' gap-1 justify-between' : ' gap-2  flex-wrap')} style={{'flexDirection': this.props.selectableFilterProps.vertical ? 'column' : 'row'}}>
        {showElements}
      </div>
    } else {
    return <div className='flex gap-2' style={{'flexDirection': this.props.selectableFilterProps.vertical ? 'column' : 'row', 'overflowX': this.props.selectableFilterProps.vertical ? 'inherit' : 'auto', 'overflowY': this.props.selectableFilterProps.vertical ? 'auto' : 'inherit', 'padding': '7px', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 20%)', "borderRadius": "10px", "border": "1px solid rgba(91, 101, 123, 0.25)", "width": this.props.maxWidth ? this.props.maxWidth : 'fit-content', 'height': this.props.maxHeight ? this.props.maxHeight : 'fit-content'}}>
          {showElements}
        </div>
    }
  }

  _renderDateFilter(): JSX.Element | null{
    if (!this.props.dateFilterProps) return null
    if (this.props.dateFilterProps.simpleView) {
      return (
        <div className='flex flex-row gap-2 items-center text-center justify-center' 
        style={
          this.props.dateFilterProps.onlyDateShownCustomView ? 
          {'maxWidth': this.props.maxWidth ?? 'initial', 'padding': this.props.dateFilterProps.customWrapperClass ? '0' : '8px'} :
          {
          'padding': this.props.dateFilterProps.customWrapperClass ? '0' : '8px',
          'backgroundColor': '#FFFFFF',  borderRadius: '10px', 
          border: '1px solid rgba(91,101,123,0.25)', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 20%)', 
          'maxWidth': this.props.maxWidth ? this.props.maxWidth : 'initial'}
          }>
          {this.props.dateFilterProps.onlyDateShownCustomView ? null : <Typography variant={this.props.variant ?? 'caption'} style={{'minWidth': '35%', 'width': '35%'}}>
            {this.props.dateFilterProps.label}
          </Typography>}
           <div className='flex flex-auto'>
           <DatePicker selected={this.props.dateFilterProps.value} wrapperClassName={this.props.dateFilterProps.customWrapperClass ?? 'react-datepicker-custom-wrapper'} dateFormat={this.props.dateFilterProps.dateFormat} placeholderText={this.props.dateFilterProps.placeholderText} onChange={this.props.dateFilterProps.onChange.bind(this)} />
           </div>
        </div>
      )
    }
    return (<DatePicker wrapperClassName={this.props.dateFilterProps.customWrapperClass ?? 'react-datepicker-custom-text'} selected={this.props.dateFilterProps.value} dateFormat={this.props.dateFilterProps.dateFormat} placeholderText={this.props.dateFilterProps.placeholderText} onChange={this.props.dateFilterProps.onChange.bind(this)} />)
  }

  render() {
    return this.props.filterType === FilterType.DATE ? this._renderDateFilter() : this._renderCategoricalFilter()
  }
}
