import { HOUR_TO_SEC, MS_TO_MICRO, SEC_TO_MS } from "cfg/const"
import { SERVICES_HOSTNAME, MESSAGE_TYPES,
  GET_SUMMARY_DATA_ENDPOINT, GET_USER_TRIGGERS_ENDPOINT, SAVE_USER_TRIGGERS_ENDPOINT, 
  GET_HEALTH_TABLE_ENDPOINT, GET_HEALTH_SERIES_ENDPOINT, SUBMIT_NOTES_ENDPOINT, 
  GET_USER_DISPOSITIONS, EXTENSION_ID,
  UPDATE_DISPLAY_ENDPOINT, REQUEST_TEXT_SUMMARY_ENDPOINT,
  SIGNIN_USER_ENDPOINT, SIGNUP_USER_ENDPOINT,
  PASSWORD_LOGIN, SUBMIT_TRACKER, GET_NAMED_PERSONS, STAR_SESSION, SETTINGS, 
  REQUEST_EMAIL_ENDPOINT, SESSION_REVIEW, COMMENTS, REQUEST_GEN_COACHING_ENDPOINT, GET_SESSION_SUMMARIES, REQUEST_AGENT_SUMMARY, AUGMENTATION_HOSTNAME,
  NUMBER_HEALTH, AREA_CODE_CONNECT_RATE,
  LEADERBOARD_AVATAR, LEADERBOARD_ROOM, LEADERBOARD_MEMBERSHIP, LEADERBOARD_DATA, GET_USER, PROSPECT_INFO_CHOICES, DISPOSITION_CHOICES, SUBTEAM_VIEW, SUBTEAM, SUBTEAM_MEMBER, KEYWORD, KEYWORD_PHRASE, KEYWORD_GROUP, KEYWORD_GROUP_MEMBERSHIP, CONDITIONS, PLAYS, METRIC_DETAILS_V3, SESSION_LIST_V3, REPORT_HOSTNAME, BACKTEST_CONDITION, GET_SESSION_DATA_V3, AUDIO_LINKS, PASSWORD_RESET_REQUEST, PASSWORD_RESET, OAUTHLOGIN_TYPES, LOGGING_HOSTNAME, FRONTEND_LOGS, AUTODIALER_SETTINGS, AUTODIALER_MAPPING_OPTIONS, AUTODIALER_MAPPINGS,
  SUBSCRIPTION_CREATION, CAN_UPGRADE,
  USER_GROUP, USER_GROUP_MEMBERSHIP, USER_ADMIN, SCOPE_ADMIN, TEAM_ADMIN, SEATS, SCOPES_V2, DOMAIN_ADMIN, GET_ADMIN_WRITE_TARGETS, GET_VISIBLE_ACCOUNTS, REQUEST_CUSTOM_SCORE,
  EXTERNAL_ACCOUNT, EXTERNAL_ACCOUNT_LINK, EMAIL_VALUE_PROP, REQUEST_PROSPECT_EMAIL_GEN, REQUEST_PROSPECT_EMAIL_GEN_REVISION, REQUEST_PROSPECT_LINKEDIN_REVISION, REQUEST_PROSPECT_LINKEDIN_GEN, MULTIDIALER_HOSTNAME, TWILIO_NUMBERS, METRIC_DETAILS_V4, SAVED_REPORTS,
  SHAKEN_STIR, REQUEST_SYNTH_PROSPECT, ADMIN_CHATBOTS, ADMIN_CHATBOTS_VARIANTS, ADMIN_CHATBOTS_TEMPLATES,
  AI_MANY_CALL_ANALYSIS,
  GET_TEAM,
  MULTIDIALER_ADMIN, RECORDED_GREETINGS,
  MULTIDIALER_SETTINGS} from "cfg/endpoints"
import { TRACKER_TYPE } from "cfg/sources"
import { AuthorizationInfo, getApiKeyInfo } from "components/Authentication/utils"
import { AdminSynthResult, AdminSynthTeamResult, CustomEditInfo, LinkedInGenRequest, MetricDetailParamsV4, MetricsDetailsResultV4, MultiDialerQuotaInfo, jsonSafeToAdminSynthResult, jsonSafeToAdminSynthResults, jsonSafeToAdminSynthTeamResult, jsonSafeToMetricsDetailsResultQueryV4, jsonSafeToQuotaInfo } from "interfaces/services"
 import { AvatarStyle } from "components/Widgets/Leaderboard"

import { logInfo, sleep } from "core"
import {
  AGENT_TYPE, SETTING,
  AutoDialerMapping, AutoDialerMappingOptions, AutoDialerSetting, AutomationType,
  jsonSafeToAutodialerMappingOptionsList, jsonSafeToAutodialerMappings, jsonSafeToAutodialerSettings,
  PartyRole, Platform, SubmitNoteParams, TrellusDisposition,
  Subteam, SubteamMember, SubteamView,
  jsonSafeToSubteam, jsonSafeToSubteamView,
  UserGroup, jsonSafeToUserGroup,
  ExternalAccount, jsonSafeToExternalAccount, ExternalAccountLink, jsonSafeToExternalAccountLink, CustomMetric, jsonSafeToCustomMetric, EmailValueProp, jsonSafeToEmailValueProps, jsonSafeToEmailValueProp, AIProspectEmail, jsonSafeToAIProspectEmail, EmailToneType, jsonSafeToLinkedInGeneratedEmail, LinkedInGeneratedMessage, RegisteredTwilioNumber, OptionalTwilioNumber, jsonSafeToRegisteredTwilioNumber, jsonSafeToRegisteredTwilioNumbers, jsonSafeToOptionalTwilioNumbers, jsonSafeToSavedReports, SavedReport, jsonSafeToSavedReport, ChatbotPromptVariant, jsonSafeToChatbotPromptVariants, jsonSafeToChatbotPromptTemplates, ChatbotPromptTemplate, jsonSafeToChatbotPromptTemplate, jsonSafeToChatbotPromptVariant,
  AIManyCallAnalysis, jsonSafeToAIManyCallAnalysis,
  RecordedGreeting, jsonSafeToRecordedGreeting
} from "interfaces/db"
import { jsonSafeToSummaryDataResult, jsonSafeToHealthTableResult, 
  jsonSafeToTriggerListDataResult, jsonSafeToUserSessionResult,
  SummaryDataResult, HealthTableResult, TriggerListDataResult, 
  UserSessionsResult, HealthSeriesResult, jsonSafeToHealthSeriesResult, DispositionsDataResult, jsonSafeToDispositionsDataResult,
  DisplayParams, jsonSafeToTextSummaryResult, TrackerPayloadParam, 
  jsonSafeToAuthResult, AuthResult, jsonSafeToNamedPersonResult, NamedPersonTable, 
  SettingsResult, jsonSafeToSettingsResult, SettingsUpdate, TriggerPostParams, ScopeResult, 
  TextSummaryResult, CommentDataResult, jsonSafeToComment, CommentParams, 
  UpdateType, jsonSafeToPreviousCallSummaryResult, PreviousCallSummaryResult, RoomMetricType, GetRoomMembershipsResult, LeaderboardDataResult, 
  GLOBAL_ROOM_ID, UserDataResult, jsonSafeToAuthorizedUserDataResult, RoomMetadata, jsonSafeToLeaderboardDataResult, jsonSafeToRoomMetadata,
  jsonSafeToGetRoomMembershipsResult, SummarizerResult, jsonSafeToSummarizerResult, ListUserSessionParamsV2, 
  jsonSafeToProspectInfoChoices, ProspectInfoChoices, jsonSafeToGetSubteamViewResult, jsonSafeToSubteamResult, jsonSafeToSubteamMemberResult, KeywordPhrase, KeywordGroupMembership, Keyword, jsonSafeToKeywordResult, jsonSafeToKeywordPhraseResult, KeywordGroup, jsonSafeToKeywordGroupResult, jsonSafeToKeywordGroupMembershipResult, jsonSafeToKeyword, DEFAULT_LANGUAGE_SETTING, jsonSafeToKeywordPhrase, jsonSafeToKeywordGroup, Condition, jsonSafeToConditionResult, TemporalFilterDisjunction, jsonSafeToCondition, Play, jsonSafeToPlayResult, jsonSafeToPlay, MetricsDetailsResult, MetricDetailParamsV3 as MetricDetailParams, jsonSafeToMetricsDetailResultQuery, ListUserSessionParamsV3, SessionDataResult, jsonSafeToSessionDataResult, AudioDataResult, jsonSafeToAudioDataResult, cleanTemporalFilterDisjunctions, FrontEndLogs,
  NumberHealthResult, jsonSafeToNumberHealthResultQuery, StaticFilterDisjunction,
  AreaCodeConnectRateResult, jsonSafeToAreaCodeConnectRateResultQuery,
  GetUserGroupResult, jsonSafeToGetUserGroupResult, jsonSafeToSubscriptionInfo, SubscriptionInfo, PaymentSubscriptionOption, UpgradeStatus, jsonSafeToUpgradeStatus,
  UserAndUserAuthResult, jsonSafeToUserAndUserAuthResults, jsonSafeToTeamResult, TeamResult, jsonSafeToTeamResults, AdminUserSettingsUpdate, AdminTeamSettingUpdate, ScopeType,
    Scope, jsonSafeToScopeV2Result, ScopeV2Result, ScopeV2,
    jsonSafeToAdminWriteTargetsResult, AdminWriteTargetsResult,
  VisibleAccountsResult, jsonSafeToScopesV2, jsonSafeToVisibleAccountsResult, EMAIL_INPUT, EmailValuePropUpdate, EmailGenRequest
 } from "interfaces/services"
import { corsDelete, corsGet, corsPut, responseToJson, simpleGet, simplePost } from "network"
import { CustomerProfileData, jsonSafeToCustomerProfileData } from "interfaces/twilio"
import { ca } from "date-fns/locale"

const _LOG_SCOPE = `[Trellus Web][Services]`

export class ServicesManager {
  apiKey: string | null = null
  hostname: string = SERVICES_HOSTNAME
  mock: boolean
  hasInitialized: boolean
  summaryDataServiceManager: SummaryDataServiceManager = new SummaryDataServiceManager()
  metricDetailServiceManager: MetricDetailServiceManager = new MetricDetailServiceManager()
  metricDetailServiceManagerV4: MetricDetailServiceManagerV4 = new MetricDetailServiceManagerV4()
  numberHealthServiceManager: NumberHealthServiceManager = new NumberHealthServiceManager()
  areaCodeConnectRateServiceManager: AreaCodeConnectRateServiceManager = new AreaCodeConnectRateServiceManager()
  healthTableDataServiceManager: HealthTableDataServiceManager = new HealthTableDataServiceManager()
  healthSeriesDataServiceManager: HealthSeriesDataServiceManager = new HealthSeriesDataServiceManager()
  listUserSessionsServiceManagerV3: ListUserSessionsServiceManagerV3 = new ListUserSessionsServiceManagerV3()
  sessionDataServiceManager: SessionDataServiceManager = new SessionDataServiceManager()
  audioDataServiceManager: AudioDataServiceManager = new AudioDataServiceManager()
  triggerServiceManager: TriggerServiceManager = new TriggerServiceManager()
  getDispositionServiceManager: GetUserDispositionsServiceManager = new GetUserDispositionsServiceManager()
  getUserServiceManager: UserServiceManager = new UserServiceManager()
  getTeamServiceManager: TeamServiceManager = new TeamServiceManager()
  displayServiceManager: updateDisplayServiceManager = new updateDisplayServiceManager()
  requestSummaryServiceManager: RequestTextSummaryServiceManager = new RequestTextSummaryServiceManager()
  requestEmailServiceManager: RequestEmailServiceManager = new RequestEmailServiceManager()
  requestCoachingSuggestionServiceManager: RequestCoachingSuggestionServiceManager = new RequestCoachingSuggestionServiceManager()
  requestCustomMetricServiceManager: RequestCustomMetricServiceManager = new RequestCustomMetricServiceManager()
  OAuthServiceManager: OAuthServiceManager = new OAuthServiceManager()
  passwordLoginServiceManager: PasswordLoginServiceManager = new PasswordLoginServiceManager()
  passwordSignupServiceManager: PasswordSignupServiceManager = new PasswordSignupServiceManager()
  trackerSubmitServiceManager: PostTrackerServiceManager = new PostTrackerServiceManager()
  getNamedPersonServiceManager: GetNamedPersonServiceManager = new GetNamedPersonServiceManager()
  putStarSessionManager: StarSessionServiceManager = new StarSessionServiceManager()
  putReviewSessionManager: ReviewSessionServiceManager = new ReviewSessionServiceManager()
  settingsServiceManager: SettingsServiceManager = new SettingsServiceManager()
  scopeV2ServiceManager: ScopeV2ServiceManager = new ScopeV2ServiceManager()
  readWriteAccessServiceManager: ReadWriteAccessServiceManager = new ReadWriteAccessServiceManager()
  listPreviousSessionSummariesManager: ListPreviousSessionSummariesServiceManager = new ListPreviousSessionSummariesServiceManager()
  gptSummarizerServiceManager: GPTSummarizerServiceManager = new GPTSummarizerServiceManager()
  profileLeaderboardManager: ProfileLeaderboardManager = new ProfileLeaderboardManager()
  groupLeaderboardManager: RoomLeaderboardManager = new RoomLeaderboardManager()
  prospectInfoManager: ProspectInfoManager = new ProspectInfoManager()
  dispositionInfo: DispositionInfoManager = new DispositionInfoManager()
  subteamViewManager: SubteamViewManager = new SubteamViewManager()
  subteamManager: SubteamManager = new SubteamManager()
  subteamMembershipManager: SubteamMembershipManager = new SubteamMembershipManager()
  userGroupManager: UserGroupManager = new UserGroupManager()
  userGroupMembershipManager: UserGroupMembershipManager = new UserGroupMembershipManager()
  externalAccountManager: ExternalAccountManager = new ExternalAccountManager()
  externalAccountLinkManager: ExternalAccountLinkManager = new ExternalAccountLinkManager()
  keywordManager: KeywordManager = new KeywordManager()
  conditionManager: ConditionManager = new ConditionManager()
  playManager: PlayManager = new PlayManager()
  backtestManager: BackTestManager = new BackTestManager()
  passwordManager: PasswordServiceManager = new PasswordServiceManager()
  frontEndLogManager: FrontEndLogManager = new FrontEndLogManager()
  autodialerSettingManager: AutodialerSettingManager = new AutodialerSettingManager()
  autodialerMapppings: AutodialerMappingsManager = new AutodialerMappingsManager()
  autodialerMappingOptions: AutodialerMappingOptionManager = new AutodialerMappingOptionManager()
  paymentManager: PaymentManager = new PaymentManager()
  adminManager: AdminManager = new AdminManager()
  seatsManager: SeatManager = new SeatManager()
  emailValuePropManager: EmailValuePropManager = new EmailValuePropManager()
  emailGenRequestManager: EmailGenerationManager = new EmailGenerationManager()
  linkedinGenRequestManager: LinkedinGenerationManager = new LinkedinGenerationManager()
  twilioNumberManager: TwilioNumberManager = new TwilioNumberManager()
    recordedGreetingsManager: RecordedGreetingsManager = new RecordedGreetingsManager()
  reportManager: SavedReportManager = new SavedReportManager()
  stirShakenManager: StirShakenManager = new StirShakenManager()
  synthVoiceManager: SynthVoiceManager = new SynthVoiceManager()
  aiManyCallAnalysisManager: AIManyCallAnalysisManager = new AIManyCallAnalysisManager()
  multiaDialerQuota: MultiDialerQuotaManager = new MultiDialerQuotaManager()

  updateApiInfo(info: AuthorizationInfo) {
    this.apiKey  = info.apiKey
    this.hostname = info.services ?? SERVICES_HOSTNAME
    this.hasInitialized = true
  }

  async fetchApiKey() {
    const info = await getApiKeyInfo()
    if (info) {
      this.apiKey = info.apiKey
      this.hostname = info.services ?? SERVICES_HOSTNAME
    }
    this.hasInitialized = true
  }

  constructor() {
    logInfo(`${_LOG_SCOPE} Set up service manager at start`)
    // initialize the api key from search params
    this.mock = (new URLSearchParams(window.location.search)).get('mock') === 'true'
    this.hasInitialized = false
    this.fetchApiKey()
  }

  async getApiKey(): Promise<string | null> {
    while (!this.hasInitialized) {
      if (this.apiKey != null)
        return this.apiKey
      await sleep(0.5 * SEC_TO_MS)
    }
    return this.apiKey ?? null
  }

  async getUser(forcedApiKey?: string): Promise<UserDataResult | null> {
    const apiKey = forcedApiKey ?? await this.getApiKey()
    if (apiKey == null) {
      logInfo(`${_LOG_SCOPE} Unable to find api key for user result`)
      return null
    }
    return this.getUserServiceManager.getSingleUser(apiKey, this.hostname)
  }

  async getTeam(forcedApiKey?: string): Promise<TeamResult | null> {
    const apiKey = forcedApiKey ?? await this.getApiKey()
    if (apiKey == null) {
      logInfo(`${_LOG_SCOPE} Unable to find api key for team result`)
      return null
    }
    return this.getTeamServiceManager.getTeam(apiKey, this.hostname)
  }

  async getSummaryData(): Promise<SummaryDataResult | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) {
      logInfo(`${_LOG_SCOPE} Unable to find api key for summary data`)
      return null
    }
    return this.summaryDataServiceManager.getResult(apiKey, this.hostname, this.mock)
  }

  async getPreviousCallSummaries(params: ListUserSessionParamsV2, apiKeyForced?: string, forceRefresh?: boolean): Promise<PreviousCallSummaryResult | null> {
    const apiKey = apiKeyForced ?? await this.getApiKey()
    if (apiKey == null) {
      logInfo(`${_LOG_SCOPE} Unable to find api key for metrics data`)
      return null
    }
    return this.listPreviousSessionSummariesManager.getResult(apiKey, this.hostname, params, forceRefresh)
  }

  async getMetricDetails(
    metricDetailsParams: MetricDetailParams,
    noCache?: boolean
  ): Promise<MetricsDetailsResult[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) {
      logInfo(`${_LOG_SCOPE} Unable to find api key for metrics data`)
      return null
    }
    return this.metricDetailServiceManager.getResult(apiKey, this.hostname, metricDetailsParams, noCache);
  }

  async getMetricDetailsV4(
    metricDetailsParams: MetricDetailParamsV4,
    noCache?: boolean
  ): Promise<MetricsDetailsResult[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) {
      logInfo(`${_LOG_SCOPE} Unable to find api key for metrics data`)
      return null
    }
    return this.metricDetailServiceManagerV4.getResult(apiKey, this.hostname, metricDetailsParams, noCache);
  }

  async getNumberHealth(
    start: Date,
    end: Date,
    cnf: StaticFilterDisjunction[],
  ): Promise<NumberHealthResult[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) {
      logInfo(`${_LOG_SCOPE} Unable to find api key for number health data`)
      return null
    }
    return this.numberHealthServiceManager.getResult(apiKey, this.hostname, start, end, cnf)
  }

  async getAreaCodeConnectRate(
    start: Date,
    end: Date,
    cnf: StaticFilterDisjunction[],
  ): Promise<AreaCodeConnectRateResult[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) {
      logInfo(`${_LOG_SCOPE} Unable to find api key for number health data`)
      return null
    }
    return this.areaCodeConnectRateServiceManager.getResult(apiKey, this.hostname, start, end, cnf)
  }

  async getHealthTableData(): Promise<HealthTableResult | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) {
      logInfo(`${_LOG_SCOPE} Unable to find api key for infrastructure health table data`)
      return null
    }
    return this.healthTableDataServiceManager.getResult(apiKey, this.hostname, this.mock)
  }

  async getHealthSeriesData(field: string): Promise<HealthSeriesResult | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) {
      logInfo(`${_LOG_SCOPE} Unable to find api key for infrastructure table plot data`)
      return null
    }
    return this.healthSeriesDataServiceManager.getResult(apiKey, this.hostname, this.mock, field)
  }

  async getListUserSessionsV3(
    listUserSessionParams: ListUserSessionParamsV3,
    forceRefresh?: boolean
  ): Promise<UserSessionsResult | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.listUserSessionsServiceManagerV3.getResult(apiKey, this.hostname, listUserSessionParams, forceRefresh)
  }

  async getSessionData(sessionId: string, temporalCnf?: TemporalFilterDisjunction[], forceCache?: boolean) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.sessionDataServiceManager.getResult(apiKey, this.hostname, sessionId, temporalCnf, forceCache)
  } 

  async getAudioData(sessionId: string) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.audioDataServiceManager.getResult(apiKey, this.hostname, sessionId)
  } 

  async updateComments(sessionId: string, commentParams: CommentParams[]) : Promise<(CommentDataResult | null)[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.sessionDataServiceManager.updateComments(apiKey, this.hostname, sessionId, commentParams)
  }


  async getTriggerData(): Promise<TriggerListDataResult | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.triggerServiceManager.getResult(apiKey, this.hostname)
  }

  async postTriggerData(triggerParams: TriggerPostParams): Promise<Record<string, any> | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.triggerServiceManager.postParams(apiKey, this.hostname, triggerParams)
  } 

  async postDisplayParams(displayParams: DisplayParams): Promise<Record<string, any> | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.displayServiceManager.postDisplayParams(apiKey, this.hostname, displayParams)
  }

  async getDispositions(
  ): Promise<ProspectInfoChoices | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.dispositionInfo.getResult(apiKey, this.hostname)
  }

  async requestCoachingSuggestion(
    sessionId: string
  ): Promise<string | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.requestCoachingSuggestionServiceManager.requestCoachingSuggestion(apiKey, this.hostname, sessionId)
  }

  async requestTextSummary(
    sessionId: string
  ): Promise<TextSummaryResult | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.requestSummaryServiceManager.requestTextSummary(apiKey, this.hostname, sessionId)
  }

  async requestEmail(
    sessionId: string
  ): Promise<string | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.requestEmailServiceManager.requestEmail(apiKey, this.hostname, sessionId)
  }

  async requestCustomMetric(
    sessionId: string,
  ): Promise<CustomMetric | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.requestCustomMetricServiceManager.requestCustomMetric(apiKey, this.hostname, sessionId)
  }

  async passwordLogin(
    email: string,
    password: string
  ): Promise<AuthResult | null> {
    return this.passwordLoginServiceManager.postParams(this.hostname, email, password)
  }

  async passwordSignup(
    name: string,
    email: string,
    password: string
  ): Promise<AuthResult | null> {
    return this.passwordSignupServiceManager.postParams(this.hostname, name, email, password)
  }

  async oauthLogin(
    credential: string,
    type: OAUTHLOGIN_TYPES
  ): Promise<AuthResult | null> {
    return this.OAuthServiceManager.postParams(this.hostname, credential, type)
  }

  async submitTrackers(
    baseUrl: string,
    trackerType: TRACKER_TYPE,
    trackerPayload?: TrackerPayloadParam,
    apiKey?: string,
  ): Promise<Record<string, any> | null> {
    // note that an api key may be passed in for this tracker since tracker submissions happen
    // sometimes right after an api key is retrieved by a user and so the extension cannot communicate it
    // to the react application in time
    if (apiKey)
      return this.trackerSubmitServiceManager.postParams(this.hostname, apiKey, baseUrl, trackerType, trackerPayload)
    else {
      const apiKey = await this.getApiKey()
      if (apiKey == null) return null
      return this.trackerSubmitServiceManager.postParams(this.hostname, apiKey, baseUrl, trackerType, trackerPayload)
      }
    }

  async getNamedPerson(query: string): Promise<NamedPersonTable | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.getNamedPersonServiceManager.getNames(apiKey, this.hostname, query)
  }

  async updateSessionStar(sessionId: string, hasStar: boolean): Promise<Record<string, any> | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.putStarSessionManager.updateSessionStar(apiKey, this.hostname, sessionId, hasStar)
  }

  async updateSessionReview(sessionId: string, isOpen: boolean): Promise<Record<string, any> | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.putReviewSessionManager.updateSessionReview(apiKey, this.hostname, sessionId, isOpen)
  }

  async getSettings(): Promise<SettingsResult | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.settingsServiceManager.getSettings(apiKey, this.hostname)
  } 

  async postSettings(update: SettingsUpdate): Promise<Record<string, any> | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.settingsServiceManager.setSettings(apiKey, this.hostname, update)
  }

  async getScopes(): Promise<ScopeV2Result | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.scopeV2ServiceManager.getScope(apiKey, this.hostname)
  }

  async getAdminWriteTargets(): Promise<AdminWriteTargetsResult | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.readWriteAccessServiceManager.getAdminWriteTargets(apiKey, this.hostname)
  }

  async getVisibleAccounts(): Promise<VisibleAccountsResult | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.readWriteAccessServiceManager.getVisibleAccounts(apiKey, this.hostname)
  }

  async updateScope(scope: ScopeV2, is_delete?: boolean): Promise<boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.scopeV2ServiceManager.putScope(apiKey, this.hostname, scope, is_delete)
  }

  async removeCachedSessionData(sessionId: string) {
    this.sessionDataServiceManager.removeCachedSessionDataResult(sessionId)
  }

  async getScrapedSummarizedData(url: string, text: string | null, cachedOnly: boolean, agentType: AGENT_TYPE, forcedApiKey?: string) {
    const apiKey = forcedApiKey ?? await this.getApiKey()
    if (apiKey == null) return null
    return this.gptSummarizerServiceManager.getResult(apiKey, AUGMENTATION_HOSTNAME, url, text, cachedOnly, agentType)
  }

  async putProfile(avatarName: string, avatarType: AvatarStyle): Promise<boolean | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.profileLeaderboardManager.putProfile(apiKey, avatarName, avatarType)
  }

  async getUserMemberships() {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.profileLeaderboardManager.getRoomMemberships(apiKey)
  }

  async putRoom(roomName: string, roomMetric: RoomMetricType, isTeamRoom?: boolean, isDomainRoom?: boolean, roomId?: string): Promise<RoomMetadata | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.groupLeaderboardManager.putRoom(apiKey, roomName, roomMetric, isTeamRoom, isDomainRoom, roomId)
  }

  async getRoomMetadata(roomCode: string | null, roomId: string | null) {
    let apiKey = null
    if (roomId !== null || (roomCode == null && roomId == null)) {
      apiKey = await this.getApiKey()
      if (apiKey == null && roomId) return null
    }
    return this.groupLeaderboardManager.getRoomMetdata(apiKey, roomCode, roomId)
  }

  async joinRoom(roomId: string, isMember: boolean, roomCode: string | null) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.groupLeaderboardManager.joinRoom(apiKey, roomId, isMember, roomCode)
  }

  async getRoomLeaderboardData(roomId: string, roomMetric: RoomMetricType) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.groupLeaderboardManager.getRoomLeaderboardData(apiKey, roomId, roomMetric)
  }

  async getProspectInfo() {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.prospectInfoManager.getResult(apiKey, this.hostname)
  }

  async getSubteamViews() {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.subteamViewManager.getSubteamViews(apiKey, this.hostname)
  }

  async putSubteamView(
    subteamViewName: string | null, 
    subteamViewId: string | null,
    isDisjoint: boolean | null,
    remove: boolean | null
    ) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.subteamViewManager.putSubteamView(apiKey, this.hostname, subteamViewName, subteamViewId, isDisjoint, remove)    
  }

  async getSubteams(subteamViewId: string | null ) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.subteamManager.getSubteam(apiKey, this.hostname, subteamViewId)    
  }

  async putSubteam(subteamName: string | null, subteamId: string | null, subteamViewId: string | null, remove: boolean | null) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.subteamManager.putSubteam(apiKey, this.hostname, subteamName, subteamId, subteamViewId, remove)    
  }

  async getSubteamMembership(subteamViewId: string | null) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.subteamMembershipManager.getSubteamMembership(apiKey, this.hostname, subteamViewId)
  }

  async putSubteamMembership(subteamId: string, user_id: string, remove: boolean | null) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.subteamMembershipManager.putSubteamMembership(apiKey, this.hostname, subteamId, user_id, remove)
  }

  async getUserGroups(noCache?: boolean) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.userGroupManager.getUserGroup(apiKey, this.hostname, noCache)
  }

  async putUserGroup(userGroupName: string | null, userGroupId: string | null, remove: boolean | null): Promise<UserGroup | boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.userGroupManager.putUserGroup(apiKey, this.hostname, userGroupName, userGroupId, remove)
  }

  async putUserGroupMembership(userGroupId: string, memberId: string, memberIsGroup: boolean, remove: boolean | null): Promise<boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.userGroupMembershipManager.putUserGroupMembership(apiKey, this.hostname, userGroupId, memberId, memberIsGroup, remove)
  }

  async getExternalAccounts(): Promise<ExternalAccount[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.externalAccountManager.getExternalAccount(apiKey, this.hostname)
  }

  async putExternalAccount(externalAccountName: string | null, externalAccountId: string | null, isActive: boolean | null): Promise<ExternalAccount | boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.externalAccountManager.putExternalAccount(apiKey, this.hostname, externalAccountName, externalAccountId, isActive)
  }

  async getExternalAccountLinks(): Promise<ExternalAccountLink[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.externalAccountLinkManager.getExternalAccountLink(apiKey, this.hostname)
  }

  async putExternalAccountLink(
    externalAccountLinkId: string | null,
    externalAccountId: string | null,
    platformLogin: string | null,
    remove: boolean | null,
  ): Promise<ExternalAccountLink | boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.externalAccountLinkManager.putExternalAccountLink(apiKey, this.hostname, externalAccountLinkId, externalAccountId, platformLogin, remove)
  }

  async getKeywords(): Promise<Keyword[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.keywordManager.getKeywords(apiKey, this.hostname)
  }

  async updateKeyword(
    keyword_id: string | null,
    keyword_name: string | null,
    party_role: PartyRole | null,
    remove: boolean | null): Promise<Keyword | boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.keywordManager.updateKeyword(apiKey, this.hostname, keyword_id, keyword_name, party_role, remove)    
  }

  async getKeywordPhrases(): Promise<KeywordPhrase[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.keywordManager.getKeywordPhrases(apiKey, this.hostname)
  }

  async updateKeywordPhrase(
    keyword_id: string | null,
    keyword_phrase_id: string | null,
    keyword_phrase: string | null, 
    boost: boolean | null,
    language: DEFAULT_LANGUAGE_SETTING | null,
    remove: boolean | null
  ): Promise<KeywordPhrase | boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.keywordManager.updateKeywordPhrase(apiKey, this.hostname, keyword_id, keyword_phrase_id, keyword_phrase, boost, language, remove)
  }

  async getKeywordGroups(): Promise<KeywordGroup[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.keywordManager.getKeywordGroups(apiKey, this.hostname)
  }

  async updateKeywordGroup(
    keyword_group_id: string | null,
    keyword_group_name: string | null,
    remove: boolean | null
  ): Promise<KeywordGroup | boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.keywordManager.updateKeywordGroup(apiKey, this.hostname, keyword_group_id, keyword_group_name, remove)
  }

  async getKeywordGroupMemberships(): Promise<KeywordGroupMembership[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.keywordManager.getKeywordMemberships(apiKey, this.hostname)
  }

  async updateKeywordGroupMemberships(
    keyword_id: string | null,
    keyword_group_id: string | null,
    remove: boolean | null
  ): Promise<boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.keywordManager.updateKeywordGroupMembership(apiKey, this.hostname, keyword_id, keyword_group_id, remove)
  }

  async getConditions(): Promise<Condition[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.conditionManager.getConditions(apiKey, this.hostname)
  }

  async putCondition(
    condition_id: string | null,
    filter_cnf: TemporalFilterDisjunction[] | null,
    active: boolean | null,
    condition_name: string | null,
    condition_group: string | null,
  ): Promise<Condition | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.conditionManager.putCondition(apiKey, this.hostname, condition_id, filter_cnf, active, condition_name, condition_group)
  }

  async getPlays(): Promise<Play[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.playManager.getPlays(apiKey, this.hostname)
  }

  async putPlay(
    play_id: string | null,
    condition_id: string | null,
    active: boolean | null,
    prompt_text: string | null,
    weight: number | null,
    play_name: string | null,
    min_duration: number | null,
  ): Promise<Play | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.playManager.putPlay(apiKey, this.hostname, play_id, condition_id, active, prompt_text, weight, play_name, min_duration)
  }

  async backtestCondition(
    start: Date | null,
    end: Date | null,
    cnf: TemporalFilterDisjunction[],
  ): Promise<UserSessionsResult | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.backtestManager.backtest(apiKey, start, end, cnf)
  }

  async requestPasswordResetEmail(email: string): Promise<boolean> {
    return this.passwordManager.requestPasswordResetEmail(this.hostname, email)
  }

  async resetPassword(email: string, recovery_code: string, password: string): Promise<AuthResult | null> {
    return this.passwordManager.resetPassword(this.hostname, email, recovery_code, password)
  }

  async submitFrontEndLogs(logs: FrontEndLogs[]): Promise<boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.frontEndLogManager.submitFrontEndLog(apiKey, logs)
  }

  async getAutodialerSettings(): Promise<AutoDialerSetting[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.autodialerSettingManager.getAutodialerSettings(this.hostname, apiKey)
  }

  async putAutodialerSettings(isTeam: boolean, user_group_id: string | null, autodialerSettings: AutoDialerSetting): Promise<boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.autodialerSettingManager.putAutodialerSettings(this.hostname, apiKey, isTeam,  user_group_id, autodialerSettings)
  }

  async getAutodialerMappingOptions(): Promise<AutoDialerMappingOptions[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.autodialerMappingOptions.getAutodialerMappingOptions(this.hostname, apiKey)
  }

  async getAutodialerMappings(): Promise<AutoDialerMapping[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.autodialerMapppings.getAutodialerMappings(this.hostname, apiKey)    
  }

  async putAutodialerMappings(
    isTeam: boolean, 
    user_group_id: string | null,
    isRemove: boolean, platform: Platform, 
    automationType: AutomationType, 
    trellusDisposition: TrellusDisposition, value: null | string): Promise<boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.autodialerMapppings.putAutodialerMappings(this.hostname, apiKey, isTeam, user_group_id, isRemove, platform, automationType, trellusDisposition, value)
  }

  async canUpgrade(): Promise<UpgradeStatus | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.paymentManager.canUpgrade(this.hostname, apiKey)
  }

  async createSubscription(subscriptionType: PaymentSubscriptionOption) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.paymentManager.createSubscription(this.hostname, apiKey, subscriptionType)
  }

  async admin_getAllUsers() {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.adminManager.getUsers(this.hostname, apiKey)
  }

  async admin_updateUser(user_id: string, settingUpdate: AdminUserSettingsUpdate) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.adminManager.updateUser(this.hostname, apiKey, user_id, settingUpdate)
  }

  async admin_getAllTeams() {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.adminManager.getTeams(this.hostname, apiKey)
  }

  async admin_updateTeam(team_id: string, settingUpdate: AdminTeamSettingUpdate) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.adminManager.updateTeam(this.hostname, apiKey, team_id, settingUpdate)
  }

  async admin_getAllScopes() {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.adminManager.getScopes(this.hostname, apiKey)
  }

  async admin_updateScope(scope: ScopeV2, is_remove: boolean) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.adminManager.updateScope(this.hostname, apiKey, scope, is_remove)
  }

  async admin_updateDomain(team_id: string, domain: string | null) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.adminManager.updateDomain(this.hostname, apiKey, team_id, domain)
  }

  async putSeats(target_user_id: string, team_is_active?: boolean, can_dial?: boolean) {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.seatsManager.putSeats(this.hostname, apiKey, target_user_id, team_is_active, can_dial)
  }

  async getEmailValueProp(): Promise<EmailValueProp[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.emailValuePropManager.getEmailValueProp(apiKey, this.hostname)
  }

  async updateEmailValueProp(emailValuePropUpdate: EmailValuePropUpdate): Promise<EmailValueProp | boolean | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.emailValuePropManager.updateEmailValueProp(apiKey, this.hostname, emailValuePropUpdate)
  }

  async generateProspectEmail(emailGenRequest: EmailGenRequest): Promise<AIProspectEmail | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.emailGenRequestManager.generateEmail(apiKey, this.hostname, emailGenRequest)
  }

  async generateRevisionEmail(emailGenRequest: EmailGenRequest, previous_email: string, custom_edit_info: CustomEditInfo): Promise<AIProspectEmail | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.emailGenRequestManager.generateRevisionEmail(apiKey, this.hostname, emailGenRequest, previous_email, custom_edit_info)
  }

  async generateLinkedInMessage(linkedinGenRequest: LinkedInGenRequest): Promise<LinkedInGeneratedMessage | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.linkedinGenRequestManager.generateMessage(apiKey, this.hostname, linkedinGenRequest)
  }

  async generateRevisionLinkedin(linkedinGenRequest: LinkedInGenRequest, previous_message: string, custom_edit_info: CustomEditInfo): Promise<LinkedInGeneratedMessage | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.linkedinGenRequestManager.generateRevisionEmail(apiKey, this.hostname, linkedinGenRequest, previous_message, custom_edit_info)
  }

  async getMyTwilioNumbers(): Promise<RegisteredTwilioNumber[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.twilioNumberManager.getRegisteredNumbers(apiKey)
  }

  async registerTwilioNumber(twilio_number: OptionalTwilioNumber, country_iso_code?: string): Promise<boolean | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.twilioNumberManager.registerNumber(apiKey, twilio_number, country_iso_code)
  }

  async unregisterTwilioNumber(rep_phone_value: string): Promise<string | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return "Not logged in"
    return this.twilioNumberManager.unregisterNumber(apiKey, rep_phone_value)
  }

  async getOptionsForTwilioNumber(k: {
    area_code?: string | null,
    iso_country_code?: string | null,
    state_or_province?: string | null,
    substring?: string | null}
  ): Promise<OptionalTwilioNumber[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.twilioNumberManager.getPotentialNumbers(apiKey, k.area_code, k.iso_country_code, k.state_or_province, k.substring)
  }

  async getCustomerProfileData(teamId: string): Promise<CustomerProfileData | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.stirShakenManager.getStirShakenStatus(apiKey, teamId)
  }

  async getSavedReports(): Promise<SavedReport[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.reportManager.getReports(apiKey, this.hostname)
  }

  async putSavedReports(report: SavedReport, is_new: boolean): Promise<SavedReport | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.reportManager.putReport(apiKey, this.hostname, report, is_new)
  }

  async deleteSavedReports(report_id: string): Promise<boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.reportManager.removeReport(apiKey, this.hostname, report_id)
  }

  async getSynthVoices(): Promise<ChatbotPromptVariant[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.synthVoiceManager.getSynthVoices(apiKey, this.hostname)
  }

  async getAdminVoices(): Promise<AdminSynthResult[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.synthVoiceManager.getAdminVoices(apiKey, this.hostname)
  }

  async getAdminTemplates(): Promise<ChatbotPromptTemplate[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.synthVoiceManager.getAdminChatbotPromptTemplates(apiKey, this.hostname)
  }

  async getAdminVariants(): Promise<ChatbotPromptVariant[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.synthVoiceManager.getAdminChatbotPromptVariants(apiKey, this.hostname)
  }

  async getAdminVoiceForTeam(team_id: string): Promise<AdminSynthTeamResult | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.synthVoiceManager.getAdminResultForTeam(apiKey, this.hostname, team_id)
  }

  async addChatPromptTemplate(chatbotPromptTemplate: Omit<ChatbotPromptTemplate, "chatbot_prompt_template_id">): Promise<string | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.synthVoiceManager.addChatPromptTemplate(apiKey, this.hostname, chatbotPromptTemplate)
  }

  async updateChatPromptTemplate(chatbotPromptTemplate: ChatbotPromptTemplate): Promise<string | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.synthVoiceManager.updateChatPromptTemplate(apiKey, this.hostname, chatbotPromptTemplate)
  }

  async deleteChatPromptTemplate(chatbotPromptTemplateId: string): Promise<boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.synthVoiceManager.deleteChatPromptTemplate(apiKey, this.hostname, chatbotPromptTemplateId)
  }

  async addChatbotPromptVariant(variant: Omit<ChatbotPromptVariant, "chatbot_prompt_variant_id">): Promise<string | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.synthVoiceManager.addChatbotPromptVariant(apiKey, this.hostname, variant)
  }

  async updateChatbotPromptVariant(variant: ChatbotPromptVariant): Promise<string | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.synthVoiceManager.updateChatbotPromptVariant(apiKey, this.hostname, variant)
  }

  async deleteChatbotPromptVariant(variantId: string): Promise<boolean> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return false
    return this.synthVoiceManager.deleteChatbotPromptVariant(apiKey, this.hostname, variantId)
  }

  async getAIManyCallAnalysis(userId: string, periodStart: Date): Promise<AIManyCallAnalysis[]> {
    // get all AIManyCallAnalysis objects for this user with a period_start at or after periodStart.
    const apiKey = await this.getApiKey()
    if (apiKey === null) return []
    return this.aiManyCallAnalysisManager.getAIManyCallAnalysis(apiKey, this.hostname, userId, periodStart)
  }

  async getMultidialerQuota(): Promise<MultiDialerQuotaInfo | null> {
    const apiKey = await this.getApiKey()
    if (apiKey === null) return null
    return this.multiaDialerQuota.getQuotaInfo(apiKey)
  }

  async uploadGreeting(name: string, file: File): Promise<RecordedGreeting | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.recordedGreetingsManager.uploadGreeting(apiKey, name, file, undefined)
  }

  async uploadGreetingFromURL(name: string, url: string): Promise<RecordedGreeting | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.recordedGreetingsManager.uploadGreeting(apiKey, name, undefined, url)
  }

  async getGreetings(): Promise<RecordedGreeting[] | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.recordedGreetingsManager.getRecordedGreetings(apiKey)
  }

  async getSpecificGreetingURL(recordedGreetingId: string): Promise<string | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.recordedGreetingsManager.getSpecificGreetingURL(apiKey, recordedGreetingId)
  }

  async renameGreeting(recordedGreetingId: string, name: string): Promise<RecordedGreeting | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.recordedGreetingsManager.updateGreeting(apiKey, recordedGreetingId, name)
  }

  async deleteGreeting(recordedGreetingId: string): Promise<boolean | null> {
    const apiKey = await this.getApiKey()
    if (apiKey == null) return null
    return this.recordedGreetingsManager.deleteGreeting(apiKey, recordedGreetingId)
  }
}

abstract class CachingMockingDataServiceManager<ResultType, Params extends Array<any> = []> {
  // TODO extend to the ListUserSessionsServiceManager and GetSessionDataServiceManager. Use a hash map?
  lastParams: Array<any> = [null, null]
  cachedPromise: Promise<ResultType | null> | null = null

  async getResult(
    apiKey: string,
    hostname: string,
    mock: boolean,
    ...rest: Params
  ): Promise<ResultType | null> {
    // return mock result if requested
    if (mock) return this._getResultFromMock(...rest)

    // check cache first
    if (this.cachedPromise != null && [apiKey, hostname, ...rest].every((x, i) => x === this.lastParams[i])) {
      return this.cachedPromise
    }

    // make a promise resolving to session data
    this.lastParams = [apiKey, hostname, ...rest]
    this.cachedPromise = this._getResultFromService(apiKey, hostname, ...rest)
    return this.cachedPromise
  }

  abstract _getResultFromMock(...rest: Params): ResultType
  abstract _getResultFromService(apiKey: string, hostname: string, ...rest: Params): Promise<ResultType | null>
}

class SummaryDataServiceManager extends CachingMockingDataServiceManager<SummaryDataResult> {
  /**
   * Get summary data from the service endpoint
   */
  async _getResultFromService(
    apiKey: string, 
    hostname: string
  ): Promise<SummaryDataResult | null> {
    const url = `https://${hostname}/${GET_SUMMARY_DATA_ENDPOINT}`
    const parameters: Record<string, string> = {'api_key': apiKey}
    try {
      const result = await simpleGet(url, parameters)
      return jsonSafeToSummaryDataResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error while getting summary data`, e)
      return null
    }
  }

  _getResultFromMock(): SummaryDataResult {
    return {
      scope_domain: 'GLOBAL',
      weekly_activity: [
        {'week_end': new Date('2022-08-22 00:00:00+00'), num_sessions: 722, duration: 37571.48966299999},
        {'week_end': new Date('2022-08-29 00:00:00+00'), num_sessions: 1166, duration: 51583.51605699996},
        {'week_end': new Date('2022-09-05 00:00:00+00'), num_sessions: 881, duration: 35856.363737000036},
        {'week_end': new Date('2022-09-12 00:00:00+00'), num_sessions: 846, duration: 23687.995383000005},
      ],
      user_activity: [
        {user_id: '8054828e339311ed913095cb8eb5a87e', user_name: 'Dom', num_sessions: 1063, duration: 15526.397787000005},
        {user_id: '498a87a8248411ed913095cb8eb5a87e', user_name: 'Whitney', num_sessions: 997, duration: 29208.825371999985},
        {user_id: 'c928cf7009e411ed913095cb8eb5a87e', user_name: 'Samuel', num_sessions: 862, duration: 30415.286520999987},
        {user_id: 'b75436d41ff311ed913095cb8eb5a87e', user_name: 'Oscar', num_sessions: 618, duration: 12463.155589000007},
      ],
      cohort_activity: [
        {cohort_week_end: new Date('2022-08-22 00:00:00+00'), week_end: new Date('2022-08-22 00:00:00+00'), num_sessions: 722, duration: 37571.5896},
        {cohort_week_end: new Date('2022-08-22 00:00:00+00'), week_end: new Date('2022-08-29 00:00:00+00'), num_sessions: 870, duration: 35524.026048},
        {cohort_week_end: new Date('2022-08-22 00:00:00+00'), week_end: new Date('2022-09-05 00:00:00+00'), num_sessions: 405, duration: 18505.116523},
        {cohort_week_end: new Date('2022-08-29 00:00:00+00'), week_end: new Date('2022-08-29 00:00:00+00'), num_sessions: 296, duration: 16059.490009000001},
        {cohort_week_end: new Date('2022-08-29 00:00:00+00'), week_end: new Date('2022-09-05 00:00:00+00'), num_sessions: 237, duration: 9523.033457999998},
      ],
    }
  }
}


class GPTSummarizerServiceManager {
  apiKey: string | null = null
  hostname: string | null = null

  async getResult(
    apiKey: string, 
    hostname: string, 
    url: string,
    text: string | null,
    cachedOnly: boolean,
    agentType: AGENT_TYPE
  ): Promise<SummarizerResult | null> {
    // make a promise resolving to session data
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.apiKey = apiKey
      this.hostname = hostname
    }


    this.apiKey = apiKey
    this.hostname = hostname
    const result = this._getResultFromService(apiKey, hostname, url, text, cachedOnly, agentType)
    return result
  } 

  async _getResultFromService(
    apiKey: string, 
    hostname: string, 
    scrapedUrl: string,
    text: string | null,
    cachedOnly: boolean,
    agentType: AGENT_TYPE
  ): Promise<SummarizerResult | null> {
    const url = `https://${hostname}/${REQUEST_AGENT_SUMMARY}`
    const headers: Record<string, any> = {
      'api_key': apiKey, 'url': scrapedUrl, 
      'cached_only': cachedOnly,
      'text': text, 'agent_type': agentType}
    try {
      const result = await simplePost(url, headers)
      return jsonSafeToSummarizerResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from request-agent-summary endpoint`, e)
      return null
    }
  
  }
}


class HealthTableDataServiceManager extends CachingMockingDataServiceManager<HealthTableResult> {
  async _getResultFromService(
    apiKey: string,
    hostname: string
  ): Promise<HealthTableResult | null> {
    const url = `https://${hostname}/${GET_HEALTH_TABLE_ENDPOINT}`
    const parameters: Record<string, string> = {'api_key': apiKey}
    try {
      const result = await simpleGet(url, parameters)
      return result === null ? null : jsonSafeToHealthTableResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error while getting infrastructure health table data`, e)
      return null
    }
  }

  _getResultFromMock(): HealthTableResult {
    return [
      {
        "hostname": "rt-1.trellus.ai",
        "period_end": new Date(1667336399999.999),
        "update_as_of": new Date(1667335837864.000),
        "latency": 0.16951552405953407,
        "response_code": 200,
        "reports_this_period": 5,
        "num_active_audio_connections": 6,
        "num_active_client_connections": 3,
        "num_active_sessions": 120,
        "num_coaching_sessions": 3,
        "num_async_tasks": 2586,
        "num_db_readonly_connections": 1,
        "num_db_readwrite_connections": 1,
        "cpu_utilization": 0.2208251953125,
        "memory_utilization": 0.11200289242390904,
        "disk_utilization": 0.3522945852305332,
        "num_global_processes": 286,
        "num_global_net_connections": 102,
        "num_global_open_files": 4416,
        "max_descendant_open_files": 652,
        "is_aggregate": false
      },
      {
        "hostname": "rt-1.trellus.ai",
        "period_end": new Date(1667336399999.999),
        "update_as_of": new Date(1667335837864.000),
        "latency": 0.5382838200312108,
        "response_code": 503,
        "reports_this_period": 11,
        "num_active_audio_connections": 12,
        "num_active_client_connections": 4,
        "num_active_sessions": 152,
        "num_coaching_sessions": 4,
        "num_async_tasks": 3341,
        "num_db_readonly_connections": 5,
        "num_db_readwrite_connections": 2,
        "cpu_utilization": 0.2513427734375,
        "memory_utilization": 0.11200289242390904,
        "disk_utilization": 0.3522945852305332,
        "num_global_processes": 288,
        "num_global_net_connections": 105,
        "num_global_open_files": 4480,
        "max_descendant_open_files": 745,
        "is_aggregate": true
      }
    ]
  }
}

class HealthSeriesDataServiceManager extends CachingMockingDataServiceManager<HealthSeriesResult, [string]> {
  async _getResultFromService(
    apiKey: string,
    hostname: string,
    field: string,
  ): Promise<HealthSeriesResult | null> {
    const url = `https://${hostname}/${GET_HEALTH_SERIES_ENDPOINT}`
    const parameters: Record<string, string> = {'api_key': apiKey, 'field': field}
    try {
      const result = await simpleGet(url, parameters)
      return result === null ? null : jsonSafeToHealthSeriesResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error while getting infrastructure health plot data`, e)
      return null
    }
  }

  _getResultFromMock(field: string): HealthSeriesResult {
    if (field.slice(0, 3) === "num" || field.slice(0, 3) === "max") {
      return new Map([
        ["rt-1.trellus.ai", [
          { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667350542179.290), "value": 158 },
          { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667271319496.531), "value": 122 },
          { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667199599116.657), "value": 30 },
          { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667113176259.217), "value": 30 },
          { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667037555725.539), "value": 32 },
          { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1666958332769.678), "value": 55 }
        ]]
      ])
    }
    return new Map([
      ["rt-1.trellus.ai", [
        { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667335237661.075), "value": 0.16951552405953407 },
        { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667332536872.198), "value": 0.09779415000230074 },
        { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667328935747.206), "value": 0.10767719405703247 },
        { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667325334710.534), "value": 0.08239419781602919 },
        { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667321733714.881), "value": 0.07232619496062398 },
        { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667300127874.682), "value": 0.0533081479370594 },
        { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667264117458.623), "value": 0.07138348813168705 },
        { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1667228107171.272), "value": 0.06892171991057694 },
        { "hostname": "rt-1.trellus.ai", "update_as_of": new Date(1666904317256.001), "value": 0.14039348694495857 }
      ]]
    ])
  }
}

class ListPreviousSessionSummariesServiceManager {
  apiKey: string | null = null
  hostname: string | null = null
  paramsToCachedPromise: Map<string, Promise<PreviousCallSummaryResult | null>> = new Map()

  async getResult(
    apiKey: string, 
    hostname: string, 
    listUserSessionParams: ListUserSessionParamsV2,
    forceRefresh?: boolean,
  ): Promise<PreviousCallSummaryResult | null> {
    // ensure the cache is consistent with the requested api and host
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.paramsToCachedPromise = new Map()
    }
    
    const mapKey = JSON.stringify(listUserSessionParams.has_star) + JSON.stringify(listUserSessionParams.start) + JSON.stringify(listUserSessionParams.end) + JSON.stringify(listUserSessionParams.min_duration)
    + JSON.stringify(listUserSessionParams.review_is_open) + 
    JSON.stringify([...(listUserSessionParams.user_ids ?? [])].sort()) + 
    JSON.stringify([...(listUserSessionParams.phone_numbers ?? [])].sort()) + 
    JSON.stringify([...(listUserSessionParams.dispositions ?? [])].sort()) + 
    JSON.stringify([...(listUserSessionParams.prompt_types ?? [])].sort()) + 
    JSON.stringify([...(listUserSessionParams.remarks ?? [])].sort()) + 
    JSON.stringify([...(listUserSessionParams.counterparts ?? [])].sort())
    if (this.paramsToCachedPromise.has(mapKey) && !forceRefresh) {
    return this.paramsToCachedPromise.get(mapKey) ?? null
  }

    // make a promise resolving to session data
    this.apiKey = apiKey
    this.hostname = hostname
    const cachedPromise = this._getResultFromService(apiKey, hostname, listUserSessionParams)
    this.paramsToCachedPromise.set(mapKey, cachedPromise)
    return cachedPromise
  } 

  async _getResultFromService(
    apiKey: string, 
    hostname: string, 
    listUserSessionParams: ListUserSessionParamsV2,
  ): Promise<PreviousCallSummaryResult | null> {
    const url = `https://${hostname}/${GET_SESSION_SUMMARIES}`
    const headers: Record<string, any> = {'api_key': apiKey}
    if (listUserSessionParams.start) headers['start'] = listUserSessionParams.start.getTime()*MS_TO_MICRO
    if (listUserSessionParams.end) headers['end'] = listUserSessionParams.end.getTime()*MS_TO_MICRO
    if (listUserSessionParams.phone_numbers) headers['phone_numbers'] = listUserSessionParams.phone_numbers
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToPreviousCallSummaryResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get-session-summaries endpoint`, e)
      return null
    }
  }
}

class ListUserSessionsServiceManagerV3 {
  apiKey: string | null = null
  hostname: string | null = null
  userIdToEndDateJsonSafeToCachedPromise: Map<string | undefined, Map<number | undefined, Promise<UserSessionsResult | null>>> = new Map()
  paramsToCachedPromise: Map<string, Promise<UserSessionsResult | null>> = new Map()

  async getResult(
    apiKey: string, 
    hostname: string, 
    listUserSessionParams: ListUserSessionParamsV3,
    forceRefresh?: boolean,
  ): Promise<UserSessionsResult | null> {
    // ensure the cache is consistent with the requested api and host
    if (this.apiKey !== apiKey || this.hostname !== hostname)
      this.userIdToEndDateJsonSafeToCachedPromise = new Map()
    
    const mapKey = Array.from(Object.entries(listUserSessionParams)).map(([key, value]) => JSON.stringify(value)).join('')
    
    if (this.paramsToCachedPromise.has(mapKey) && !forceRefresh) {
    return this.paramsToCachedPromise.get(mapKey) ?? null
  }

    // make a promise resolving to session data
    this.apiKey = apiKey
    this.hostname = hostname
    const cachedPromise = this._getResultFromService(apiKey, hostname, listUserSessionParams)
    this.paramsToCachedPromise.set(mapKey, cachedPromise)
    return cachedPromise
  } 

  _cleanCnf(cnf: StaticFilterDisjunction): StaticFilterDisjunction {
    let copy = {...cnf}
    delete copy['metadata_type_info']
    return copy
  }

  async _getResultFromService(
    apiKey: string, 
    hostname: string, 
    params: ListUserSessionParamsV3
  ): Promise<UserSessionsResult | null> {
    const url = `https://${hostname}/${SESSION_LIST_V3}`
    const headers: Record<string, any> = {'api_key': apiKey}
    if (params.start) headers['start'] = params.start.getTime() * MS_TO_MICRO
    if (params.end) headers['end'] = params.end.getTime() * MS_TO_MICRO
    if (params.cnf) headers['cnf'] = params.cnf.map((value: StaticFilterDisjunction) => this._cleanCnf(value))
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToUserSessionResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from list-user-sessions-v3 endpoint`, e)
      return null
    }
  }
}

class AudioDataServiceManager {
  async getResult(
    apiKey: string, 
    hostname: string, 
    sessionId: string,
  ): Promise<AudioDataResult | null> {
    // ensure the cache is consistent with the requested api and host
    return this._getResultFromService(apiKey, hostname, sessionId)
  } 

  async _getResultFromService(
    apiKey: string, 
    hostname: string, 
    sessionId: string,
  ): Promise<AudioDataResult | null> {
    const url = `https://${hostname}/${AUDIO_LINKS}`
    const parameters: Record<string, any> = {'api_key': apiKey, 'session_id': sessionId}
    try {
      const result = await corsGet(url, parameters)
      return jsonSafeToAudioDataResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get audio links endpoint`, e)
      return null
    }
  }
}


class SessionDataServiceManager {
  apiKey: string | null = null
  hostname: string | null = null
  sessionDataParamsToCachedPromise: Map<string, Map<string, Promise<SessionDataResult | number | null>> | null> = new Map()
  sessionDataToAsOf: Map<string, Map<string, Date>> = new Map()

  async getResult(
    apiKey: string, 
    hostname: string, 
    sessionId: string,
    temporalCnf?: TemporalFilterDisjunction[],
    forceCache?: boolean,
  ): Promise<SessionDataResult | number | null> {
    // ensure the cache is consistent with the requested api and host
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.sessionDataParamsToCachedPromise = new Map()
      this.sessionDataToAsOf = new Map()
      this.apiKey = apiKey
      this.hostname = hostname
    }

    const temporalCnfClean = temporalCnf ? cleanTemporalFilterDisjunctions(temporalCnf) : undefined
    // check if the cached promise includes the requested data
    const mapKey = temporalCnfClean ? Array.from(Object.entries(temporalCnfClean)).map(([key, value]) => JSON.stringify(value)).join('') : ''
    const primaryKey = sessionId
    
    const cachedMap = this.sessionDataParamsToCachedPromise.get(primaryKey)
    const timeMap = this.sessionDataToAsOf.get(primaryKey)

    if (!forceCache && cachedMap && timeMap) {
      const cachedMapEntry = cachedMap.get(mapKey)
      const time = timeMap.get(mapKey)
      if (cachedMapEntry && time && ((new Date().getTime() - time.getTime()) < HOUR_TO_SEC*SEC_TO_MS)) {
        return cachedMapEntry
    }
  }

    // make a promise resolving to session data
    const cachedPromise = this._getResultFromService(apiKey, hostname, sessionId, temporalCnfClean)
    
    if (!cachedMap) {
      const cachedMapStored: Map<string, Promise<SessionDataResult | number | null>> = new Map()
      cachedMapStored.set(mapKey, cachedPromise)
      this.sessionDataParamsToCachedPromise.set(primaryKey, cachedMapStored)
    } else {
      cachedMap.set(mapKey, cachedPromise)
      this.sessionDataParamsToCachedPromise.set(primaryKey, cachedMap)
    }

    if (!timeMap) {
      const timeMapStored: Map<string, Date> = new Map()
      timeMapStored.set(mapKey, new Date())
      this.sessionDataToAsOf.set(primaryKey, timeMapStored)
    } else {
      timeMap.set(mapKey, new Date())
      this.sessionDataToAsOf.set(primaryKey, timeMap)
    }

    return cachedPromise
  } 

  async _getResultFromService(
    apiKey: string, 
    hostname: string, 
    sessionId: string,
    temporalCnf?: TemporalFilterDisjunction[]
  ): Promise<SessionDataResult | number | null> {
    const url = `https://${hostname}/${GET_SESSION_DATA_V3}`
    const parameters: Record<string, any> = {'api_key': apiKey, 'session_id': sessionId}
    if (temporalCnf) parameters['temporal_cnf'] = temporalCnf
    try {
      const result = await corsGet(url, parameters)
      return jsonSafeToSessionDataResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get session data endpoint`, e)
      if (e instanceof Error && e.message === "429") return new Date().getTime() + 3.5*SEC_TO_MS
      else return null
    }
  }

  removeCachedSessionDataResult(sessionId: string): void {
    this.sessionDataParamsToCachedPromise.delete(sessionId)
    this.sessionDataToAsOf.delete(sessionId)
  }

  async updateComments(
    apiKey: string,
    hostname: string,
    sessionId: string,
    params: CommentParams[]
  ): Promise<(CommentDataResult | null)[] | null> {
    const url = `https://${hostname}/${COMMENTS}`
    const parameters: Record<string, any> = {
      'api_key': apiKey, 'session_id': sessionId,
      'comments': params.map((value: CommentParams) => {
        const ret: Record<string, any> = {}
        const comment = value.comment
        if (value.update === UpdateType.ADD || value.update === UpdateType.MODIFY) {
          if (value.update === UpdateType.MODIFY) ret['comment_id'] = comment.commentId
          ret['comment'] = comment.comment
          if (comment.start) ret['start'] = comment.start
          if (comment.end) ret['end'] = comment.end
        } else {
          // removal
          ret['comment_id'] = value.comment.commentId
        }
        return ret
      })
    }
    try {
      const result = await corsPut(url, parameters)
      const json: Record<string, any> = await responseToJson(result)
      this.removeCachedSessionDataResult(sessionId)
      const toReturn = json.map((value: Record<string, any>) => {
        return value == null ? null : jsonSafeToComment(value)
      })
      return toReturn
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from update comments`, e)
      return null
    }
  }

  async postSubmitNotes(
    apiKey: string, 
    hostname: string, 
    sessionId: string,
    notesParams: SubmitNoteParams,
  ): Promise<Record<string, any> | null> {
    this.apiKey = apiKey
    this.hostname = hostname
    const promiseResult = this._postSubmitNotesFromService(apiKey, hostname, sessionId, notesParams)
    return promiseResult
  } 

  async _postSubmitNotesFromService(
    apiKey: string, 
    hostname: string,
    sessionId: string,
    notesParams: SubmitNoteParams,
  ): Promise<Record<string, any> | null> {
    const url = `https://${hostname}/${SUBMIT_NOTES_ENDPOINT}`
    const parameters: Record<string, any> = {'session_id': sessionId, 'api_key': apiKey, 
      'dispositions': notesParams['dispositions'], 'notes': notesParams['notes'], 'note_source': notesParams['note_source']}
    try {
      const result = await simplePost(url, parameters)
      if (result['success']) this.removeCachedSessionDataResult(sessionId)
      return result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get user triggers endpoint`, e)
      return null
    }
  }
}


class TriggerServiceManager {
  apiKey: string | null = null
  hostname: string | null = null
  triggerToCachedPromise: Promise<TriggerListDataResult | null> | null = null
  async getResult(
    apiKey: string, 
    hostname: string, 
  ): Promise<TriggerListDataResult | null> {
    // ensure the cache is consistent with the requested api and host
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.triggerToCachedPromise = null
      this.apiKey = apiKey
      this.hostname = hostname
    }

    // check if the cached promise includes the requested data
    if (this.triggerToCachedPromise !== null) return this.triggerToCachedPromise

    // make a promise resolving to session data
    this.apiKey = apiKey
    this.hostname = hostname
    const cachedPromise = this._getResultFromService(apiKey, hostname)
    this.triggerToCachedPromise = cachedPromise
    return cachedPromise
  } 

  async _getResultFromService(
    apiKey: string, 
    hostname: string, 
  ): Promise<TriggerListDataResult | null> {
    const url = `https://${hostname}/${GET_USER_TRIGGERS_ENDPOINT}`
    const parameters: Record<string, string> = {'api_key': apiKey}
    try {
      const result = await simpleGet(url, parameters)
      return jsonSafeToTriggerListDataResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get user triggers endpoint`, e)
      return null
    }
  }

  removeCachedTrigger(): void {
    this.triggerToCachedPromise = null 
  }


  async postParams(
    apiKey: string, 
    hostname: string, 
    triggerParams: TriggerPostParams,
  ): Promise<Record<string, any> | null> {
    this.apiKey = apiKey
    this.hostname = hostname
    const promiseResult = this._postParamsFromService(apiKey, hostname, triggerParams)
    return promiseResult
  } 

  async _postParamsFromService(
    apiKey: string, 
    hostname: string,
    triggerParams: TriggerPostParams,
  ): Promise<Record<string, any> | null> {
    const url = `https://${hostname}/${SAVE_USER_TRIGGERS_ENDPOINT}`
    const parameters: Record<string, any> = {'triggers': triggerParams['triggers'], 'trigger_formulas': triggerParams['trigger_formula'], 'is_team': triggerParams['is_team'], 'api_key': apiKey}

    try {
      const result = await simplePost(url, parameters)
      if (result['success']) this.removeCachedTrigger()
      return result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get user triggers endpoint`, e)
      return null
    }
  }

}


class GetUserDispositionsServiceManager {
  apiKey: string | null = null
  hostname: string | null = null
  userIdToEndDateJsonSafeToCachedPromise: Map<string | undefined, Promise<DispositionsDataResult | null>> = new Map()

  async getResult(
    apiKey: string, 
    hostname: string, 
    userId?: string, 
  ): Promise<DispositionsDataResult | null> {
    // ensure the cache is consistent with the requested api and host
    if (this.apiKey !== apiKey || this.hostname !== hostname)
      this.userIdToEndDateJsonSafeToCachedPromise = new Map()

    // check if the cached promise includes the requested data
    if (this.userIdToEndDateJsonSafeToCachedPromise.has(userId)) 
      return this.userIdToEndDateJsonSafeToCachedPromise.get(userId)!

    // make a promise resolving to session data
    this.apiKey = apiKey
    this.hostname = hostname
    const resultCache = this._getResultFromService(apiKey, hostname, userId)
    this.userIdToEndDateJsonSafeToCachedPromise.set(userId, resultCache)
    return resultCache
  } 

  async _getResultFromService(
    apiKey: string, 
    hostname: string, 
    userId?: string, 
  ): Promise<DispositionsDataResult | null> {
    const url = `https://${hostname}/${GET_USER_DISPOSITIONS}`
    const parameters: Record<string, string> = {'api_key': apiKey}
    if (userId !== undefined) parameters['user_id'] = userId
    try {
      const result = await simpleGet(url, parameters)
      return jsonSafeToDispositionsDataResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get-user-dispositions endpoint`, e)
      return null
    }
  }
}

class TeamServiceManager {
  apiKey: string | null = null
  hostname: string | null = null

  async getTeam(
    apiKey: string,
    hostname: string
  ): Promise<TeamResult | null> {
    const url = `https://${hostname}/${GET_TEAM}`
    const parameters: Record<string, string> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, parameters)
      return jsonSafeToTeamResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get-team endpoint`, e)
      return null
    }
  }
}


class UserServiceManager {
  apiKey: string | null = null
  hostname: string | null = null

  async getSingleUser(
    apiKey: string,
    hostname: string
  ): Promise<UserDataResult | null> {
    const url = `https://${hostname}/${GET_USER}`
    const parameters: Record<string, string> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, parameters)
      return jsonSafeToAuthorizedUserDataResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get-authorized-users endpoint`, e)
      return null
    }
  }
}

class updateDisplayServiceManager {
  apiKey: string | null = null
  hostname: string | null = null

  async postDisplayParams(
    apiKey: string, 
    hostname: string, 
    displayParams: DisplayParams,
  ): Promise<Record<string, any> | null> {
    this.apiKey = apiKey
    this.hostname = hostname
    const promiseResult = this._postDisplayParamsFromService(apiKey, hostname, displayParams)
    return promiseResult
  } 

  async _postDisplayParamsFromService(
    apiKey: string, 
    hostname: string,
    displayParams: DisplayParams): Promise<Record<string, any> | null> {
    const url = `https://${hostname}/${UPDATE_DISPLAY_ENDPOINT}`
    const parameters: Record<string, any> = {'api_key': apiKey, 'display': {...displayParams}}

    try {
      const result = await simplePost(url, parameters)
      return result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from update display endpoint`, e)
      return null
    }
  }
}


class RequestTextSummaryServiceManager {
  apiKey: string | null = null
  hostname: string | null = null

  async requestTextSummary(
    apiKey: string, 
    hostname: string, 
    sessionId: string,
  ): Promise<TextSummaryResult | null> {
    this.apiKey = apiKey
    this.hostname = hostname
    const promiseResult = this._requestTextSummaryFromService(apiKey, hostname, sessionId)
    return promiseResult
  } 

  async _requestTextSummaryFromService(
    apiKey: string, 
    hostname: string,
    sessionId: string): Promise<TextSummaryResult | null> {
    const url = `https://${hostname}/${REQUEST_TEXT_SUMMARY_ENDPOINT}`
    const parameters: Record<string, any> = {'session_id': sessionId, 'api_key': apiKey}
    try {
      const result = await simplePost(url, parameters)
      return jsonSafeToTextSummaryResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get text summary endpoint`, e)
      return null
    }
  }
}


class RequestEmailServiceManager {
  apiKey: string | null = null
  hostname: string | null = null

  async requestEmail(
    apiKey: string, 
    hostname: string, 
    sessionId: string,
  ): Promise<string | null> {
    this.apiKey = apiKey
    this.hostname = hostname
    const promiseResult = this._requestEmailFromService(apiKey, hostname, sessionId)
    return promiseResult
  } 

  async _requestEmailFromService(
    apiKey: string, 
    hostname: string,
    sessionId: string): Promise<string | null> {

    const url = `https://${hostname}/${REQUEST_EMAIL_ENDPOINT}`
    const parameters: Record<string, any> = {'session_id': sessionId, 'api_key': apiKey}

    try {
      const result = await simplePost(url, parameters, true)
      return result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get email endpoint`, e)
      return null
    }
  }
}

class RequestCoachingSuggestionServiceManager {
  apiKey: string | null = null
  hostname: string | null = null

  async requestCoachingSuggestion(
    apiKey: string, 
    hostname: string, 
    sessionId: string,
  ): Promise<string | null> {
    this.apiKey = apiKey
    this.hostname = hostname
    const promiseResult = this._requestCoachingSuggestionFromService(apiKey, hostname, sessionId)
    return promiseResult
  } 

  async _requestCoachingSuggestionFromService(
    apiKey: string, 
    hostname: string,
    sessionId: string): Promise<string | null> {

    const url = `https://${hostname}/${REQUEST_GEN_COACHING_ENDPOINT}`
    const parameters: Record<string, any> = {'session_id': sessionId, 'api_key': apiKey}

    try {
      const result = await simplePost(url, parameters, true)
      return result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get coaching suggestion endpoint`, e)
      return null
    }
  }
}

class RequestCustomMetricServiceManager {
  apiKey: string | null = null
  hostname: string | null = null

  async requestCustomMetric(
    apiKey: string,
    hostname: string,
    sessionId: string,
  ): Promise<CustomMetric | null> {
    this.apiKey = apiKey
    this.hostname = hostname
    const promiseResult = this._requestCustomMetricFromService(apiKey, hostname, sessionId)
    return promiseResult
  }

  async _requestCustomMetricFromService(
    apiKey: string,
    hostname: string,
    sessionId: string): Promise<CustomMetric | null> {
    const url = `https://${hostname}/${REQUEST_CUSTOM_SCORE}`
    const parameters: Record<string, any> = {'session_id': sessionId, 'api_key': apiKey}
    try {
      const result = await corsGet(url, parameters)
      return result ? jsonSafeToCustomMetric(result) : null
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get custom metric endpoint`, e)
      return null
    }
  }
}

class PostTrackerServiceManager {
  hostname: string | null = null

  async postParams(
    hostname: string, 
    apiKey: string,
    baseUrl: string,
    trackerType: TRACKER_TYPE, 
    trackerPayload?: TrackerPayloadParam,
  ): Promise<Record<string, any> | null> {
    this.hostname = hostname
    const promiseResult = this._postParamsFromService(hostname, apiKey, baseUrl, trackerType, trackerPayload)
    return promiseResult
  } 

  async _postParamsFromService(
    hostname: string,
    apiKey: string,
    baseUrl: string,
    trackerType: TRACKER_TYPE, 
    trackerPayload?: TrackerPayloadParam
  ): Promise<Record<string, any> | null> {
    const url = `https://${hostname}/${SUBMIT_TRACKER}`
    const parameters: Record<string, any> = {'api_key': apiKey, 'base_url': baseUrl, 'tracker_type': trackerType}
    if (trackerPayload) parameters['tracker_payloads'] = trackerPayload 
    try {
      const result = await simplePost(url, parameters)
      return result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from tracker submission`, e)
      return null
    }
  }
}

class PasswordSignupServiceManager {
  hostname: string | null = null

  async postParams(
    hostname: string, 
    name: string,
    email: string,
    password: string,
  ): Promise<AuthResult | null> {
    this.hostname = hostname
    const promiseResult = this._postParamsFromService(hostname, name, email, password)
    return promiseResult
  } 

  async _postParamsFromService(
    hostname: string,
    name: string,
    email: string,
    password: string
  ): Promise<AuthResult | null> {
    const url = `https://${hostname}/${SIGNUP_USER_ENDPOINT}`
    const parameters = {'email': email, 'name': name, 'password': password, 'type': PASSWORD_LOGIN}
    try {
      const result = await simplePost(url, parameters)
      return jsonSafeToAuthResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from password signup`, e)
      return null
    }
  }
}

class PasswordLoginServiceManager {
  hostname: string | null = null

  async postParams(
    hostname: string, 
    email: string,
    password: string,
  ): Promise<AuthResult | null> {
    this.hostname = hostname
    const promiseResult = this._postParamsFromService(hostname, email, password)
    return promiseResult
  } 

  async _postParamsFromService(
    hostname: string,
    email: string,
    password: string
  ): Promise<AuthResult | null> {
    const url = `https://${hostname}/${SIGNIN_USER_ENDPOINT}`
    const parameters = {'email': email, 'password': password}

    try {
      const result = await simplePost(url, parameters)
      return jsonSafeToAuthResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from password login`, e)
      return null
    }
  }
}


class OAuthServiceManager {
  hostname: string | null = null

  async postParams(
    hostname: string, 
    credential: string,
    type: OAUTHLOGIN_TYPES,
  ): Promise<AuthResult| null> {
    this.hostname = hostname
    const promiseResult = this._postParamsFromService(hostname, credential, type)
    return promiseResult
  } 

  async _postParamsFromService(
    hostname: string,
    credential: string,
    type: OAUTHLOGIN_TYPES
  ): Promise<AuthResult | null> {
    const url = `https://${hostname}/${SIGNUP_USER_ENDPOINT}`
    const parameters: Record<string, any> = {'type': type}
    if (type === OAUTHLOGIN_TYPES.GOOGLE_LOGIN) parameters['google_credential'] = credential
    if (type === OAUTHLOGIN_TYPES.MICROSOFT_LOGIN) parameters['microsoft_credential'] = credential

    try {
      const result = await simplePost(url, parameters)
      return jsonSafeToAuthResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from oauth login`, e)
      return null
    }
  }
}


class GetNamedPersonServiceManager {
  apiKey: string | null = null
  hostname: string | null = null
  nameToCachedPromise: Map<string, NamedPersonTable> = new Map()

  async getNames(
    apiKey: string,
    hostname: string, 
    query: string,
  ): Promise<NamedPersonTable| null> {
    // make a promise resolving to session data
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.nameToCachedPromise = new Map()
      this.apiKey = apiKey
      this.hostname = hostname
    }

    if (this.nameToCachedPromise.has(query)) {
      return this.nameToCachedPromise.get(query)!
    }

    const promiseResult = this._postParamsFromService(hostname, query)
    return promiseResult
  } 

  async _postParamsFromService(
    hostname: string,
    query: string,
  ): Promise<NamedPersonTable | null> {
    const url = `https://${hostname}/${GET_NAMED_PERSONS}`
    const parameters: Record<string, any> = {'api_key': this.apiKey, 'query': query}
    try {
      const result = await corsGet(url, parameters)
      const val = jsonSafeToNamedPersonResult(result)
      this.nameToCachedPromise.set(query, val)
      return val
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get named persons`, e)
      return null
    }
  }
}


class ReviewSessionServiceManager {
  apiKey: string | null = null
  hostname: string | null = null

  async updateSessionReview(
    apiKey: string,
    hostname: string, 
    sessionId: string,
    isOpen: boolean,
  ): Promise<Record<string, any> | null> {
    // make a promise resolving to session data
    this.apiKey = apiKey
    this.hostname = hostname

    const promiseResult = this._putParamsFromService(hostname, sessionId, isOpen)
    return promiseResult
  } 

  async _putParamsFromService(
    hostname: string,
    sessionId: string,
    isOpen: boolean,
  ): Promise<Record<string, any> | null> {
    const url = `https://${hostname}/${SESSION_REVIEW}`
    const parameters: Record<string, any> = {'api_key': this.apiKey, 'session_id': sessionId, 'is_open': isOpen}
    try {
      const result = await corsPut(url, parameters)
      if (result.status === 200) return result
      return null
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from session review`, e)
      return null
    }
  }
}

class StarSessionServiceManager {
  apiKey: string | null = null
  hostname: string | null = null

  async updateSessionStar(
    apiKey: string,
    hostname: string, 
    sessionId: string,
    hasStar: boolean,
  ): Promise<Record<string, any> | null> {
    // make a promise resolving to session data
    this.apiKey = apiKey
    this.hostname = hostname

    const promiseResult = this._putParamsFromService(hostname, sessionId, hasStar)
    return promiseResult
  } 

  async _putParamsFromService(
    hostname: string,
    sessionId: string,
    hasStar: boolean,
  ): Promise<Record<string, any> | null> {
    const url = `https://${hostname}/${STAR_SESSION}`
    const parameters: Record<string, any> = {'api_key': this.apiKey, 'session_id': sessionId, 'has_star': hasStar}
    try {
      const result = await corsPut(url, parameters)
      if (result.status === 200) return result
      return null
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from star session`, e)
      return null
    }
  }
}


class SettingsServiceManager {
  apiKey: string | null = null
  hostname: string | null = null
  settingsCachedPromise: Promise<SettingsResult | null> | null = null

  async getSettings(
    apiKey: string,
    hostname: string, 
  ): Promise<SettingsResult| null> {
    // make a promise resolving to session data
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.settingsCachedPromise = null
      this.apiKey = apiKey
      this.hostname = hostname
    }

    if (this.settingsCachedPromise) return this.settingsCachedPromise

    this.settingsCachedPromise = this._getSettingsFromSerivce()
    return this.settingsCachedPromise
  } 

  async setSettings(
    apiKey: string,
    hostname: string,
    update: SettingsUpdate
  ): Promise<Record<string, any> | null> {
      // make a promise resolving to session data
      if (this.apiKey !== apiKey || this.hostname !== hostname) {
        this.apiKey = apiKey
        this.hostname = hostname
      }

      this.settingsCachedPromise = null
      return this._postSettingsFromService(update)
  }

  async _getSettingsFromSerivce(): Promise<SettingsResult | null> {
    const url = `https://${this.hostname}/${SETTINGS}`
    const parameters: Record<string, any> = {'api_key': this.apiKey}
    try {
      const result = await corsGet(url, parameters)
      return jsonSafeToSettingsResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get settings`, e)
      return null
    }
  }

  async _postSettingsFromService(update: SettingsUpdate) {
    const url = `https://${this.hostname}/${SETTINGS}`
    const parameters: Record<string, any> = {'api_key': this.apiKey, 'update': update}
    try {
      const result = await corsPut(url, parameters)
      return result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from post settings`, e)
      return null
    }
  }
}

class ReadWriteAccessServiceManager {
  apiKey: string | null = null
  hostname: string | null = null
  adminWriteTargetsCachedPromise: Promise<AdminWriteTargetsResult | null> | null = null
  visibleAccountsCachedPromise: Promise<VisibleAccountsResult | null> | null = null

  async getAdminWriteTargets(
    apiKey: string,
    hostname: string,
  ): Promise<AdminWriteTargetsResult | null> {
    // make a promise resolving to session data
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.adminWriteTargetsCachedPromise = null
      this.apiKey = apiKey
      this.hostname = hostname
    }

    if (this.adminWriteTargetsCachedPromise) return this.adminWriteTargetsCachedPromise

    this.adminWriteTargetsCachedPromise = this._getAdminWriteTargetsFromSerivce()
    return this.adminWriteTargetsCachedPromise
  }

  async _getAdminWriteTargetsFromSerivce(): Promise<AdminWriteTargetsResult | null> {
    const url = `https://${this.hostname}/${GET_ADMIN_WRITE_TARGETS}`
    const parameters: Record<string, any> = {'api_key': this.apiKey}
    try {
      const result = await corsGet(url, parameters)
      return jsonSafeToAdminWriteTargetsResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get admin write targets`, e)
      return null
    }
  }

  async getVisibleAccounts(
    apiKey: string,
    hostname: string,
  ): Promise<VisibleAccountsResult | null> {
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.visibleAccountsCachedPromise = null
      this.apiKey = apiKey
      this.hostname = hostname
    }

    if (this.visibleAccountsCachedPromise) return this.visibleAccountsCachedPromise
    this.visibleAccountsCachedPromise = this._getVisibleAccountsFromSerivce()
    return this.visibleAccountsCachedPromise
  }

  async _getVisibleAccountsFromSerivce(): Promise<VisibleAccountsResult | null> {
    const url = `https://${this.hostname}/${GET_VISIBLE_ACCOUNTS}`
    const parameters: Record<string, any> = {'api_key': this.apiKey}
    try {
      const result = await corsGet(url, parameters)
      return jsonSafeToVisibleAccountsResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get visible accounts`, e)
      return null
    }
  }

}

class ScopeV2ServiceManager {
  apiKey: string | null = null
  hostname: string | null = null
  scopeCachedPromise: Promise<ScopeV2Result | null> | null = null

  async getScope(
    apiKey: string,
    hostname: string, 
  ): Promise<ScopeV2Result| null> {
    // make a promise resolving to session data
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.scopeCachedPromise = null
      this.apiKey = apiKey
      this.hostname = hostname
    }

    if (this.scopeCachedPromise) return this.scopeCachedPromise

    this.scopeCachedPromise = this._getScopeFromSerivce()
    return this.scopeCachedPromise
  }

  async _getScopeFromSerivce(): Promise<ScopeV2Result | null> {
    const url = `https://${this.hostname}/${SCOPES_V2}`
    const parameters: Record<string, any> = {'api_key': this.apiKey}
    try {
      const result = await corsGet(url, parameters)
      return jsonSafeToScopeV2Result(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get scopes v2`, e)
      return null
    }
  }

  async putScope(
    apiKey: string,
    hostname: string,
    scope_v2: ScopeV2,
    is_delete?: boolean
  ): Promise<boolean> {
    const url = `https://${hostname}/${SCOPES_V2}`
    const parameters: Record<string, any> = {
      'api_key': apiKey, 
      'scope': scope_v2
    }
    if (is_delete) parameters['delete'] = is_delete
    try {
      const result = await corsPut(url, parameters)
      return result.status === 200
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from put scopes v2`, e)
      return false
    }
  }
  
}


class ProfileLeaderboardManager {
  async putProfile(    
    apiKey: string,
    avatarName: string,
    avatarStyle: AvatarStyle): Promise<boolean> {
    const url = `https://${AUGMENTATION_HOSTNAME}/${LEADERBOARD_AVATAR}`
    const parameters: Record<string, any> = {
      'api_key': apiKey, 
      'avatar_name': avatarName,
      'avatar_style': avatarStyle
    }
    try {
      const result = await corsPut(url, parameters)
      return result.status === 200
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from put avatar`, e)
      return false
    }
  }

  async getRoomMemberships(
    apiKey: string
  ): Promise<GetRoomMembershipsResult | null> {
    const url = `https://${AUGMENTATION_HOSTNAME}/${LEADERBOARD_MEMBERSHIP}`
    const parameters: Record<string, any> = {
      'api_key': apiKey, 
    }
    try {
      const result = await corsGet(url, parameters)
      const memberships = jsonSafeToGetRoomMembershipsResult(result)
      return [...memberships, {'room_id': GLOBAL_ROOM_ID, 'room_name': 'Global Room', 'metric': RoomMetricType.TOTAL_CALLS, 'team_id': null, 'email_domain': null, 'room_code': null}]
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get memberships`, e)
      return null
    }
  }
}

class RoomLeaderboardManager {
  async putRoom(    
    apiKey: string,
    roomName: string, 
    roomMetric: RoomMetricType, 
    isTeamRoom?: boolean, 
    isDomainRoom?: boolean,
    roomId?: string
    ): Promise<RoomMetadata | null> {
    const url = `https://${AUGMENTATION_HOSTNAME}/${LEADERBOARD_ROOM}`
    const parameters: Record<string, any> = {
      'api_key': apiKey, 
      'room_name': roomName,
      'room_metric': roomMetric,
    }
    if (roomId) parameters['room_id'] = roomId
    if (isTeamRoom !== undefined) parameters['is_team_room'] = isTeamRoom
    if (isDomainRoom !== undefined) parameters['is_domain_room'] = isDomainRoom
    try {
      const result = await corsPut(url, parameters)
      if (result.status !== 200) return null
      const json: Record<string, any> = await responseToJson(result)
      return jsonSafeToRoomMetadata(json)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from put group`, e)
      return null
    }
  }

  async joinRoom(
    apiKey: string,
    roomId: string,
    isMember: boolean,
    roomCode: string | null,
    ): Promise<boolean> {
      const url = `https://${AUGMENTATION_HOSTNAME}/${LEADERBOARD_MEMBERSHIP}`
      const parameters: Record<string, any> = {
        'api_key': apiKey,
        'room_id': roomId,
        'is_member': isMember
      }
      if (roomCode) parameters['room_code'] = roomCode
      try {
        const result = await corsPut(url, parameters)
        return result.status === 200
      } catch (e) {
        logInfo(`${_LOG_SCOPE} Unexpected error from join group`, e)
        return false
      }
    }

    async getRoomMetdata(
      apiKey: string | null,
      roomCode: string | null,
      roomId: string | null
    ): Promise <RoomMetadata | null> {
      const url = `https://${AUGMENTATION_HOSTNAME}/${LEADERBOARD_ROOM}`
      const parameters: Record<string, any> = { }
      if (apiKey) parameters['api_key'] = apiKey
      if (roomCode) parameters['room_code'] = roomCode
      if (roomId) parameters['room_id'] = roomId
      try {
        const result = await corsGet(url, parameters)
        return jsonSafeToRoomMetadata(result)
      } catch(e) {
        logInfo(`${_LOG_SCOPE} Unexpected error from get room metadata`, e)
        return null
      }
    }

    async getRoomLeaderboardData(
      apiKey: string,
      roomId: string,
      roomMetric: RoomMetricType
    ): Promise<LeaderboardDataResult | null> {
      const url = `https://${AUGMENTATION_HOSTNAME}/${LEADERBOARD_DATA}`
      const parameters: Record<string, any> = {
        'api_key': apiKey,
        'room_id': roomId,
        'room_metric': roomMetric
      }
      try {
        const result = await corsGet(url, parameters)
        return jsonSafeToLeaderboardDataResult(result)
      } catch (e) {
        logInfo(`${_LOG_SCOPE} Unexpected error from room data`, e)
        return null
      }
    }
}

class MetricDetailServiceManager {
  apiKey: string | null = null
  hostname: string | null = null
  paramsToCachedPromise: Map<string, Promise<MetricsDetailsResult[] | null>> = new Map()

  async getResult(
    apiKey: string, 
    hostname: string, 
    metricDetailParams: MetricDetailParams,
    noCache?: boolean
  ): Promise<MetricsDetailsResult[] | null> {
    // make a promise resolving to session data
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.paramsToCachedPromise = new Map()
      this.apiKey = apiKey
      this.hostname = hostname
    }

    const mapKey = Array.from(Object.entries(metricDetailParams)).map(([key, value]) => JSON.stringify(value)).join('')

    if (this.paramsToCachedPromise.get(mapKey) && !noCache) {
      return this.paramsToCachedPromise.get(mapKey)!
    }

    this.apiKey = apiKey
    this.hostname = hostname
    const result = this._getResultFromService(apiKey, hostname, metricDetailParams)
    this.paramsToCachedPromise.set(mapKey, result)
    return result
  } 

  async _getResultFromService(
    apiKey: string, 
    hostname: string, 
    params: MetricDetailParams
  ): Promise<MetricsDetailsResult[] | null> {
    const url = `https://${hostname}/${METRIC_DETAILS_V3}`
    const headers: Record<string, any> = {'api_key': apiKey, ...params, 'start': params.start.getTime() * MS_TO_MICRO, 'end': params.end.getTime() * MS_TO_MICRO}
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToMetricsDetailResultQuery(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get-metric-summary-v3 endpoint with params ${params}`, e)
      return null
    }
  }
}

class MetricDetailServiceManagerV4 {
  apiKey: string | null = null
  hostname: string | null = null
  paramsToCachedPromise: Map<string, Promise<MetricsDetailsResultV4[] | null>> = new Map()

  async getResult(
    apiKey: string,
    hostname: string,
    metricDetailParams: MetricDetailParamsV4,
    noCache?: boolean
  ): Promise<MetricsDetailsResultV4[] | null> {
    // make a promise resolving to session data
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.paramsToCachedPromise = new Map()
      this.apiKey = apiKey
      this.hostname = hostname
    }

    const mapKey = Array.from(Object.entries(metricDetailParams)).map(([key, value]) => JSON.stringify(value)).join('')

    if (this.paramsToCachedPromise.get(mapKey) && !noCache) {
      return this.paramsToCachedPromise.get(mapKey)!
    }

    this.apiKey = apiKey
    this.hostname = hostname
    const result = this._getResultFromService(apiKey, hostname, metricDetailParams)
    this.paramsToCachedPromise.set(mapKey, result)
    return result
  }

  async _getResultFromService(
    apiKey: string,
    hostname: string,
    params: MetricDetailParamsV4
  ): Promise<MetricsDetailsResultV4[] | null> {
    const url = `https://${hostname}/${METRIC_DETAILS_V4}`
    const headers: Record<string, any> = {'api_key': apiKey, ...params, 'start': params.start.getTime() * MS_TO_MICRO, 'end': params.end.getTime() * MS_TO_MICRO}
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToMetricsDetailsResultQueryV4(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get-metric-summary-v4 endpoint with params ${params}`, e)
      return null
    }
  }
}


class NumberHealthServiceManager {
  apiKey: string | null = null
  hostname: string | null = null
  paramsToCachedPromise: Map<string, Promise<NumberHealthResult[] | null>> = new Map()

  async getResult(
    apiKey: string,
    hostname: string,
    start: Date,
    end: Date,
    cnf: StaticFilterDisjunction[],
  ): Promise<NumberHealthResult[] | null> {
    // make a promise resolving to number health data
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.paramsToCachedPromise = new Map()
      this.apiKey = apiKey
      this.hostname = hostname
    }

    const mapKey = [start, end, cnf].map(value => JSON.stringify(value)).join('')

    if (this.paramsToCachedPromise.get(mapKey)) {
      return this.paramsToCachedPromise.get(mapKey)!
    }

    this.apiKey = apiKey
    this.hostname = hostname
    const result = this._getResultFromService(apiKey, hostname, start, end, cnf)
    this.paramsToCachedPromise.set(mapKey, result)
    return result
  }

  async _getResultFromService(
    apiKey: string,
    hostname: string,
    start: Date,
    end: Date,
    cnf: StaticFilterDisjunction[],
  ): Promise<NumberHealthResult[] | null> {
    const url = `https://${hostname}/${NUMBER_HEALTH}`
    const headers: Record<string, any> = {
      'api_key': apiKey,
      'start': start.getTime() * MS_TO_MICRO,
      'end': end.getTime() * MS_TO_MICRO,
      'cnf': cnf,
    }
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToNumberHealthResultQuery(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from ${NUMBER_HEALTH} endpoint with start=${start} end=${end}`, e)
      return null
    }
  }
}

class AreaCodeConnectRateServiceManager {
  apiKey: string | null = null
  hostname: string | null = null
  paramsToCachedPromise: Map<string, Promise<AreaCodeConnectRateResult[] | null>> = new Map()

  async getResult(
    apiKey: string,
    hostname: string,
    start: Date,
    end: Date,
    cnf: StaticFilterDisjunction[],
  ): Promise<AreaCodeConnectRateResult[] | null> {
    // make a promise resolving to area code connect rate data
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this.paramsToCachedPromise = new Map()
      this.apiKey = apiKey
      this.hostname = hostname
    }

    const mapKey = [start, end, cnf].map(value => JSON.stringify(value)).join('')

    if (this.paramsToCachedPromise.get(mapKey)) {
      return this.paramsToCachedPromise.get(mapKey)!
    }

    this.apiKey = apiKey
    this.hostname = hostname
    const result = this._getResultFromService(apiKey, hostname, start, end, cnf)
    this.paramsToCachedPromise.set(mapKey, result)
    return result
  }

  async _getResultFromService(
    apiKey: string,
    hostname: string,
    start: Date,
    end: Date,
    cnf: StaticFilterDisjunction[],
  ): Promise<AreaCodeConnectRateResult[] | null> {
    const url = `https://${hostname}/${AREA_CODE_CONNECT_RATE}`
    const headers: Record<string, any> = {
      'api_key': apiKey,
      'start': start.getTime() * MS_TO_MICRO,
      'end': end.getTime() * MS_TO_MICRO,
      'cnf': cnf,
    }
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToAreaCodeConnectRateResultQuery(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from ${AREA_CODE_CONNECT_RATE} endpoint with start=${start} end=${end}`, e)
      return null
    }
  }
}

class ProspectInfoManager {
  async getResult(
    apiKey: string, 
    hostname: string,
  ): Promise<ProspectInfoChoices | null> {
    const url = `https://${hostname}/${PROSPECT_INFO_CHOICES}`
    const headers: Record<string, any> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToProspectInfoChoices(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from prospect-info endpoint`, e)
      return null
    }
}
}

class DispositionInfoManager {
  async getResult(
    apiKey: string, 
    hostname: string
  ): Promise<ProspectInfoChoices | null> {
    const url = `https://${hostname}/${DISPOSITION_CHOICES}`
    const headers: Record<string, any> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToProspectInfoChoices(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from prospect-info endpoint`, e)
      return null
    }
}
}


class SubteamViewManager {
  async getSubteamViews(
    apiKey: string, 
    hostname: string,
  ): Promise<SubteamView[] | null> {
    const url = `https://${hostname}/${SUBTEAM_VIEW}`
    const headers: Record<string, any> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToGetSubteamViewResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from subteam-view endpoint`, e)
      return null
    }
  }

  async putSubteamView(
    apiKey: string,
    hostname: string,
    subteamViewName: string | null,
    subteamViewId: string | null,
    isDisjoint: boolean | null,
    remove: boolean | null
  ): Promise<SubteamView | boolean> {
    const url = `https://${hostname}/${SUBTEAM_VIEW}`
    const headers: Record<string, any> = {'api_key': apiKey}
    if (subteamViewName !== null) headers['subteam_view_name'] = subteamViewName
    if (subteamViewId !== null) headers['subteam_view_id'] = subteamViewId
    if (isDisjoint !== null) headers['is_disjoint'] = isDisjoint
    if (remove !== null) headers['delete'] = remove
    try {
      const result = await corsPut(url, headers)
      if (result.status !== 200) return false
      if (subteamViewId !== null) return true
      const json: Record<string, any> = await responseToJson(result)
      return jsonSafeToSubteamView(json)
    } catch (e) {
      return false
    }
  }
}



class SubteamManager {
  async getSubteam(
    apiKey: string, 
    hostname: string,
    subteamViewId: string | null,
  ): Promise<Subteam[] | null> {
    const url = `https://${hostname}/${SUBTEAM}`
    const headers: Record<string, any> = {'api_key': apiKey}
    if (subteamViewId !== null) headers['subteam_view_id'] = subteamViewId
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToSubteamResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from subteam endpoint`, e)
      return null
    }
  }

  async putSubteam(
    apiKey: string,
    hostname: string,
    subteamName: string | null,
    subteamId: string | null,
    subteamViewId: string | null,
    remove: boolean | null
  ): Promise<Subteam | boolean> {
    const url = `https://${hostname}/${SUBTEAM}`
    const headers: Record<string, any> = {'api_key': apiKey}
    if (subteamName !== null) headers['subteam_name'] = subteamName
    if (subteamId !== null) headers['subteam_id'] = subteamId
    if (subteamViewId !== null) headers['subteam_view_id'] = subteamViewId 
    if (remove !== null) headers['delete'] = remove
    try {
      const result = await corsPut(url, headers)
      if (result.status !== 200) return false
      if (subteamId !== null) return true
      const json: Record<string, any> = await responseToJson(result)
      return jsonSafeToSubteam(json)
    } catch (e) {
      return false
    }
  }
}

class SubteamMembershipManager {
  async getSubteamMembership(
    apiKey: string, 
    hostname: string,
    subteamViewId: string | null,
  ): Promise<SubteamMember[] | null> {
    const url = `https://${hostname}/${SUBTEAM_MEMBER}`
    const headers: Record<string, any> = {'api_key': apiKey}
    if (subteamViewId !== null) headers['subteam_view_id'] = subteamViewId
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToSubteamMemberResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from subteam members endpoint`, e)
      return null
    }
  }

  async putSubteamMembership(
    apiKey: string,
    hostname: string,
    subteamId: string,
    user_id: string,
    remove: boolean | null
  ): Promise<boolean> {
    const url = `https://${hostname}/${SUBTEAM_MEMBER}`
    const headers: Record<string, any> = {'api_key': apiKey, 'subteam_id': subteamId, 'user_id': user_id}
    if (remove !== null) headers['delete'] = remove
    try {
      const result = await corsPut(url, headers)
      return result.status === 200
    } catch (e) {
      return false
    }
  }
}

class UserGroupManager {
  apiKey: string | null = null
  hostname: string | null = null
  _cachedUserGroup: GetUserGroupResult | null = null

  async getUserGroup(
    apiKey: string,
    hostname: string,
    noCache?: boolean
  ): Promise<GetUserGroupResult | null> {
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this._cachedUserGroup = null
      this.apiKey = apiKey
      this.hostname = hostname
    }

    if (this._cachedUserGroup && !noCache) return Promise.resolve(this._cachedUserGroup)
    this._cachedUserGroup = await this._getUserGroup(apiKey, hostname)
    return Promise.resolve(this._cachedUserGroup)
  }

  async _getUserGroup(
    apiKey: string,
    hostname: string): Promise<GetUserGroupResult | null> {
      const url = `https://${hostname}/${USER_GROUP}`
      const headers: Record<string, any> = {'api_key': apiKey}
      try {
        const result = await corsGet(url, headers)
        return jsonSafeToGetUserGroupResult(result)
      } catch (e) {
        logInfo(`${_LOG_SCOPE} Unexpected error from ${USER_GROUP} endpoint`, e)
        return null
      }
  }
    

  async putUserGroup(
    apiKey: string,
    hostname: string,
    userGroupName: string | null,
    userGroupId: string | null,
    remove: boolean | null
  ): Promise<UserGroup | boolean> {
    const url = `https://${hostname}/${USER_GROUP}`
    const headers: Record<string, any> = {'api_key': apiKey}
    if (userGroupName !== null) headers['user_group_name'] = userGroupName
    if (userGroupId !== null) headers['user_group_id'] = userGroupId
    if (remove !== null) headers['delete'] = remove
    try {
      const result = await corsPut(url, headers)
      if (result.status !== 200) return false
      if (userGroupId !== null) return true
      const json: Record<string, any> = await responseToJson(result)
      return jsonSafeToUserGroup(json)
    } catch (e) {
      return false
    }
  }
}

class UserGroupMembershipManager {
  async putUserGroupMembership(
    apiKey: string,
    hostname: string,
    userGroupId: string,
    memberId: string,
    memberIsGroup: boolean,
    remove: boolean | null
  ): Promise<boolean> {
    const url = `https://${hostname}/${USER_GROUP_MEMBERSHIP}`
    const headers: Record<string, any> = {'api_key': apiKey, 'user_group_id': userGroupId, 'member_id': memberId, 'member_is_group': memberIsGroup}
    if (remove !== null) headers['delete'] = remove
    try {
      const result = await corsPut(url, headers)
      return result.status === 200
    } catch (e) {
      return false
    }
  }
}

class ExternalAccountManager {
  apiKey: string | null = null
  hostname: string | null = null
  _cachedExternalAccount: ExternalAccount[] | null = null

  async getExternalAccount(
    apiKey: string,
    hostname: string
  ): Promise<ExternalAccount[] | null> {
    if (this.apiKey !== apiKey || this.hostname !== hostname) {
      this._cachedExternalAccount = null
      this.apiKey = apiKey
      this.hostname = hostname
    }

    if (this._cachedExternalAccount) return Promise.resolve(this._cachedExternalAccount)
    this._cachedExternalAccount = await this._getExternalAccount(apiKey, hostname)
    return Promise.resolve(this._cachedExternalAccount)
  }

  async _getExternalAccount(
    apiKey: string,
    hostname: string,
  ): Promise<ExternalAccount[] | null> {
    const url = `https://${hostname}/${EXTERNAL_ACCOUNT}`
    const headers: Record<string, any> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, headers)
      if (!result) return null
      return result.map(jsonSafeToExternalAccount)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from ${EXTERNAL_ACCOUNT} endpoint`, e)
      return null
    }
  }

  async putExternalAccount(
    apiKey: string,
    hostname: string,
    externalAccountName: string | null,
    externalAccountId: string | null,
    isActive: boolean | null
  ): Promise<ExternalAccount | boolean> {
    const url = `https://${hostname}/${EXTERNAL_ACCOUNT}`
    const headers: Record<string, any> = {'api_key': apiKey}
    if (externalAccountName !== null) headers['external_account_name'] = externalAccountName
    if (externalAccountId !== null) headers['external_account_id'] = externalAccountId
    if (isActive !== null) headers['is_active'] = isActive
    try {
      const result = await corsPut(url, headers)
      if (result.status !== 200) return false
      if (externalAccountId !== null) return true
      const json: Record<string, any> = await responseToJson(result)
      return jsonSafeToExternalAccount(json)
    } catch (e) {
      return false
    }
  }
}

class ExternalAccountLinkManager {
  async getExternalAccountLink(
    apiKey: string,
    hostname: string,
  ): Promise<ExternalAccountLink[] | null> {
    const url = `https://${hostname}/${EXTERNAL_ACCOUNT_LINK}`
    const headers: Record<string, any> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, headers)
      if (!result) return null
      return result.map(jsonSafeToExternalAccountLink)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from ${EXTERNAL_ACCOUNT_LINK} endpoint`, e)
      return null
    }
  }

  async putExternalAccountLink(
    apiKey: string,
    hostname: string,
    externalAccountLinkId: string | null,
    externalAccountId: string | null,
    platformLogin: string | null,
    remove: boolean | null
  ): Promise<ExternalAccountLink | boolean> {
    const url = `https://${hostname}/${EXTERNAL_ACCOUNT_LINK}`
    const headers: Record<string, any> = {'api_key': apiKey}
    if (externalAccountLinkId !== null) headers['external_account_link_id'] = externalAccountLinkId
    if (externalAccountId !== null) headers['external_account_id'] = externalAccountId
    if (platformLogin !== null) headers['platform_login'] = platformLogin
    if (remove !== null) headers['delete'] = remove
    try {
      const result = await corsPut(url, headers)
      if (result.status !== 200) return false
      if (remove) return true
      return jsonSafeToExternalAccountLink(await result.json())
    } catch (e) {
      return false
    }
  }
}

class KeywordManager {
  async getKeywords(
    apiKey: string, 
    hostname: string,
  ): Promise<Keyword[] | null> {
    const url = `https://${hostname}/${KEYWORD}`
    const headers: Record<string, any> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToKeywordResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get keyword endpoint`, e)
      return null
    }
  }

  async updateKeyword(
    apiKey: string,
    hostname: string,
    keyword_id: string | null,
    keyword_name: string | null,
    party_role: PartyRole | null,
    remove: boolean | null
  ): Promise<Keyword | boolean> {
    const url = `https://${hostname}/${KEYWORD}`
    const headers: Record<string, any> = {'api_key': apiKey, 'party_role': party_role}
    if (keyword_id !== null) headers['keyword_id'] = keyword_id
    if (keyword_name !== null) headers['keyword_name'] = keyword_name
    if (remove !== null) headers['delete'] = remove 
    try {
      const result = await corsPut(url, headers)
      if (result.status !== 200) return false
      if (keyword_id !== null) return true
      const json: Record<string, any> = await responseToJson(result)
      return jsonSafeToKeyword(json)
    } catch (e) {
      return false
    }
  }

  async getKeywordPhrases(
    apiKey: string, 
    hostname: string,
  ): Promise<KeywordPhrase[] | null> {
    const url = `https://${hostname}/${KEYWORD_PHRASE}`
    const headers: Record<string, any> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToKeywordPhraseResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get keyword phrase endpoint`, e)
      return null
    }
  }

  async updateKeywordPhrase(
    apiKey: string,
    hostname: string,
    keyword_id: string | null,
    keyword_phrase_id: string | null,
    keyword_phrase: string | null, 
    boost: boolean | null,
    language: DEFAULT_LANGUAGE_SETTING | null,
    remove: boolean | null
  ): Promise<KeywordPhrase | boolean> {
    const url = `https://${hostname}/${KEYWORD_PHRASE}`
    const headers: Record<string, any> = {'api_key': apiKey}
    if (keyword_id !== null) headers['keyword_id'] = keyword_id
    if (keyword_phrase_id !== null) headers['keyword_phrase_id'] = keyword_phrase_id
    if (keyword_phrase !== null) headers['keyword_phrase'] = keyword_phrase
    if (boost !== null) headers['boost'] = boost
    if (language !== null) headers['language'] = language
    if (remove !== null) headers['delete'] = remove 
    try {
      const result = await corsPut(url, headers)
      if (result.status !== 200) return false
      if (keyword_phrase_id !== null) return true
      const json: Record<string, any> = await responseToJson(result)
      return jsonSafeToKeywordPhrase(json)
    } catch (e) {
      return false
    }
  }

  async getKeywordGroups(
    apiKey: string, 
    hostname: string,
  ): Promise<KeywordGroup[] | null> {
    const url = `https://${hostname}/${KEYWORD_GROUP}`
    const headers: Record<string, any> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToKeywordGroupResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get keyword group endpoint`, e)
      return null
    }
  }

  async updateKeywordGroup(
    apiKey: string,
    hostname: string,
    keyword_group_id: string | null,
    keyword_group_name: string | null,
    remove: boolean | null
  ): Promise<KeywordGroup | boolean> {
    const url = `https://${hostname}/${KEYWORD_GROUP}`
    const headers: Record<string, any> = {'api_key': apiKey}
    if (keyword_group_id !== null) headers['keyword_group_id'] = keyword_group_id
    if (keyword_group_name !== null) headers['keyword_group_name'] = keyword_group_name
    if (remove !== null) headers['delete'] = remove 
    try {
      const result = await corsPut(url, headers)
      if (result.status !== 200) return false
      if (keyword_group_id !== null) return true
      const json: Record<string, any> = await responseToJson(result)
      return jsonSafeToKeywordGroup(json)
    } catch (e) {
      return false
    }
  }

  async getKeywordMemberships(
    apiKey: string, 
    hostname: string,
  ): Promise<KeywordGroupMembership[] | null> {
    const url = `https://${hostname}/${KEYWORD_GROUP_MEMBERSHIP}`
    const headers: Record<string, any> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToKeywordGroupMembershipResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get keyword group membership endpoint`, e)
      return null
    }
  }


  async updateKeywordGroupMembership(
    apiKey: string,
    hostname: string,
    keyword_id: string | null,
    keyword_group_id: string | null,
    remove: boolean | null
  ): Promise<boolean> {
    const url = `https://${hostname}/${KEYWORD_GROUP_MEMBERSHIP}`
    const headers: Record<string, any> = {'api_key': apiKey}
    if (keyword_id !== null) headers['keyword_id'] = keyword_id
    if (keyword_group_id !== null) headers['keyword_group_id'] = keyword_group_id
    if (remove !== null) headers['delete'] = remove 
    try {
      const result = await corsPut(url, headers)
      return result.status === 200
    } catch (e) {
      return false
    }
  }
}

class ConditionManager {
  async getConditions(
    apiKey: string, 
    hostname: string,
  ): Promise<Condition[] | null> {
    const url = `https://${hostname}/${CONDITIONS}`
    const headers: Record<string, any> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToConditionResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get condition endpoint`, e)
      return null
    }
  }

  jsonifyFilterCnfStr(filterCnf: TemporalFilterDisjunction[]): string {
    const cleanedTemporal: TemporalFilterDisjunction[] = cleanTemporalFilterDisjunctions(filterCnf)
    return JSON.stringify(cleanedTemporal)
  }

  async putCondition(
    apiKey: string, 
    hostname: string,
    condition_id: string | null,
    filter_cnf: TemporalFilterDisjunction[] | null,
    active: boolean | null,
    condition_name: string | null,
    condition_group: string | null,
  ): Promise<Condition | null> {
    const headers: Record<string, any> = {'api_key': apiKey}
    if (condition_id !== null) headers['condition_id'] = condition_id
    if (filter_cnf !== null) headers['filter_cnf_str'] = this.jsonifyFilterCnfStr(filter_cnf)
    if (active !== null) headers['active'] = active
    if (condition_name !== null) headers['condition_name'] = condition_name
    if (condition_group !== null) headers['condition_group'] = condition_group
    const url = `https://${hostname}/${CONDITIONS}`
    try {
      const result = await corsPut(url, headers)
      if (result.status !== 200) {
        return null
      }
      const json: Record<string, any> = await responseToJson(result)
      return jsonSafeToCondition(json)
    } catch (e) {
      return null
    }
  }
}

class PlayManager {
  async getPlays(
    apiKey: string,
    hostname: string
  ): Promise<Play[] | null> {
    const url = `https://${hostname}/${PLAYS}`
    const headers: Record<string, any> = {'api_key': apiKey}
    try {
      const result = await corsGet(url, headers)
      return jsonSafeToPlayResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get plays endpoint`, e)
      return null
    }
  }

  async putPlay(
    apiKey: string, 
    hostname: string,
    play_id: string | null,
    condition_id: string | null,
    active: boolean | null,
    prompt_text: string | null,
    weight: number | null,
    play_name: string | null,
    min_duration: number | null,
  ): Promise<Play | null> {
    const headers: Record<string, any> = {'api_key': apiKey}
    if (play_id !== null) headers['play_id'] = play_id
    if (condition_id !== null) headers['condition_id'] = condition_id
    if (active !== null) headers['active'] = active
    if (prompt_text !== null) headers['prompt_text'] = prompt_text
    if (weight !== null) headers['weight'] = weight
    if (play_name !== null) headers['play_name'] = play_name
    if (min_duration !== null) headers['min_duration'] = min_duration
    
    const url = `https://${hostname}/${PLAYS}`
    try {
      const result = await corsPut(url, headers)
      if (result.status !== 200) return null
      const json: Record<string, any> = await responseToJson(result)
      return jsonSafeToPlay(json)
    } catch (e) {
      return null
    }
  }
}

class BackTestManager {
  apiKey: string | null = null
  paramsToCachedPromise: Map<string, UserSessionsResult | null> = new Map()

  async backtest(
    apiKey: string,
    start: Date | null,
    end: Date | null,
    cnf: TemporalFilterDisjunction[] 
  ): Promise<UserSessionsResult | null> {

    if (this.apiKey !== apiKey) {
      this.paramsToCachedPromise = new Map()
    }
    
    const headers: Record<string, any> = {'api_key': apiKey, 'cnf': cleanTemporalFilterDisjunctions(cnf)}
    if (start) headers['start'] = start.getTime()*MS_TO_MICRO
    if (end) headers['end'] = end.getTime()*MS_TO_MICRO

    const mapKey = Array.from(Object.entries(headers)).map(([key, value]) => JSON.stringify(value)).join('')
    const cached = this.paramsToCachedPromise.get(mapKey)
    if (cached) return cached

    try {
      const url = `https://${REPORT_HOSTNAME}/${BACKTEST_CONDITION}`
      const result = await corsGet(url, headers)
      const resultJson = jsonSafeToUserSessionResult(result)
      this.paramsToCachedPromise.set(mapKey, resultJson)
      return resultJson
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from backtest-condition endpoint`, e)
      return null
    }
  }  
}

class PasswordServiceManager {
  async requestPasswordResetEmail(
    hostname: string,
    email: string,
  ): Promise<boolean> {
    const parameters: Record<string, any> = {'email': email}
    try {
      const url = `https://${hostname}/${PASSWORD_RESET_REQUEST}`
      const result = await simplePost(url, parameters, false, true)
      if (result.status === 200) return result
      return false
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from request password reset endpoint`, e)
      return false
    }
  }

  async resetPassword(
    hostname: string,
    email: string,
    recovery_code: string,
    password: string
  ): Promise<AuthResult | null> {
    const url = `https://${hostname}/${PASSWORD_RESET}`
    const parameters = {'email': email, 'password': password, recovery_code: recovery_code}
    try {
      const result = await simplePost(url, parameters)
      return jsonSafeToAuthResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from password reset`, e)
      return null
    }
  }
}


class FrontEndLogManager {
  async submitFrontEndLog(
    apiKey: string,
    frontEndLogs: FrontEndLogs[]
  ): Promise<boolean> {
    const parameters: Record<string, any> = {'logs': frontEndLogs, 'api_key': apiKey}
    try {
      const url = `https://${LOGGING_HOSTNAME}/${FRONTEND_LOGS}`
      const result = await simplePost(url, parameters, false, true)
      if (result.status === 200) return true
      return false
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from frontend log manager endpoint`, e)
      return false
    }
  }
}


class AutodialerSettingManager {
  async getAutodialerSettings(
    hostname: string,
    apiKey: string,
  ): Promise<AutoDialerSetting[] | null> {
    const parameters: Record<string, any> = {'api_key': apiKey}
    try {
      const url = `https://${hostname}/${AUTODIALER_SETTINGS}`
      const result = await corsGet(url, parameters)
      return jsonSafeToAutodialerSettings(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get autodialer setting endpoint`, e)
      return null
    }
  }

  async putAutodialerSettings(
    hostname: string,
    apiKey: string,
    isTeam: boolean,
    userGroupId: string | null,
    autodialerSetings: AutoDialerSetting
  ): Promise<boolean> {
    const parameters: Record<string, any> = {'api_key': apiKey, 'is_team': isTeam, ...autodialerSetings}
    for (const key of Object.keys(parameters)) {
      if (parameters[key] === null) delete parameters[key]
      if (parameters[key] && key === SETTING.NUMBER_ROTATION_PREFERENCE) parameters[key] = JSON.stringify(parameters[key])
      if (parameters[key] && key === SETTING.PHONE_PREFERENCE) parameters[key] = JSON.stringify(Object.fromEntries(parameters[key]));
    }
    if (userGroupId && userGroupId !== '') parameters['user_group_id'] = userGroupId
    try {
      const url = `https://${hostname}/${AUTODIALER_SETTINGS}`
      const result = await corsPut(url, parameters)
      return result.status === 200
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from put autodialer setting endpoint`, e)
      return false
    }
  }
}


class AutodialerMappingOptionManager {
  async getAutodialerMappingOptions(
    hostname: string,
    apiKey: string,
  ): Promise<AutoDialerMappingOptions[] | null> {
    const parameters: Record<string, any> = {'api_key': apiKey}
    try {
      const url = `https://${hostname}/${AUTODIALER_MAPPING_OPTIONS}`
      const result = await corsGet(url, parameters)
      return jsonSafeToAutodialerMappingOptionsList(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from autodialer mapping options endpoint`, e)
      return null
    }
  }
}


class AutodialerMappingsManager {
  async getAutodialerMappings(
    hostname: string,
    apiKey: string,
  ): Promise<AutoDialerMapping[] | null> {
    const parameters: Record<string, any> = {'api_key': apiKey}
    try {
      const url = `https://${hostname}/${AUTODIALER_MAPPINGS}`
      const result = await corsGet(url, parameters)
      return jsonSafeToAutodialerMappings(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from autodialer mapping endpoint`, e)
      return null
    }


  }

  async putAutodialerMappings(
    hostname: string,
    apiKey: string,
    isTeam: boolean,
    userGroupId: string | null,
    isRemove: boolean,
    platform: Platform,
    automationType: AutomationType,
    trellusDisposition: TrellusDisposition,
    value: string | null
  ): Promise<boolean> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'is_team': isTeam,
      'is_remove': isRemove,
      'platform': platform,
      'automation_type': automationType,
      'trellus_disposition': trellusDisposition
    }
    if (value) parameters['value'] = value
    if (userGroupId && userGroupId !== '') parameters['user_group_id'] = userGroupId
    try {
      const url = `https://${hostname}/${AUTODIALER_MAPPINGS}`
      const result = await corsPut(url, parameters)
      return result.status === 200
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from autodialer mapping endpoint`, e)
      return false
    }
  }
}

class PaymentManager {
  async canUpgrade(
    hostname: string,
    apiKey: string,
  ): Promise<UpgradeStatus | null> {
    try {
      const parameters: Record<string, any> = {
        'api_key': apiKey,
      }
      const url = `https://${hostname}/${CAN_UPGRADE}`
      const json = await corsGet(url, parameters)
      return jsonSafeToUpgradeStatus(json)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from can upgrade endpoint`, e)
      return null
    }
  }

  async createSubscription(
    hostname: string,
    apiKey: string,
    subscriptionType: PaymentSubscriptionOption,
  ): Promise<SubscriptionInfo | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'subscription_type': subscriptionType
    }
    try {
      const url = `https://${hostname}/${SUBSCRIPTION_CREATION}`
      const result = await corsPut(url, parameters)
      if (result.status !== 200) return null
      const json: Record<string, any> = await responseToJson(result)
      return jsonSafeToSubscriptionInfo(json)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from subscription endpoint`, e)
      return null
    }
  }
}

class AdminManager {
  async getUsers(
    hostname: string,
    apiKey: string
  ): Promise<UserAndUserAuthResult[] | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    try {
      const url = `https://${hostname}/${USER_ADMIN}`
      const json = await corsGet(url, parameters)
      return jsonSafeToUserAndUserAuthResults(json)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from admin users endpoint`, e)
      return null
    }
  }

  async updateUser(
    hostname: string,
    apiKey: string,
    user_id: string,
    settingUpdate: AdminUserSettingsUpdate
  ): Promise<boolean | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'user_id': user_id,
    }
    if (settingUpdate.autodialer_paid !== undefined) parameters['autodialer_paid'] = settingUpdate.autodialer_paid
    if (settingUpdate.can_dial !== undefined) parameters['can_dial'] = settingUpdate.can_dial
    if (settingUpdate.team_is_active !== undefined) parameters['team_is_active'] = settingUpdate.team_is_active
    if (settingUpdate.autodialer_weekly_limit !== undefined) parameters['autodialer_weekly_limit'] = settingUpdate.autodialer_weekly_limit
    if (settingUpdate.parallel_enabled !== undefined) parameters['parallel_enabled'] = settingUpdate.parallel_enabled
    if (settingUpdate.defaults_to_native_engine !== undefined) parameters['defaults_to_native_engine'] = settingUpdate.defaults_to_native_engine

    try {
      const url = `https://${hostname}/${USER_ADMIN}`
      const result = await corsPut(url, parameters)
      return result.status == 200
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from admin users endpoint`, e)
      return null
    }
  }

  async getTeams(
    hostname: string,
    apiKey: string
  ): Promise<TeamResult[] | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    try {
      const url = `https://${hostname}/${TEAM_ADMIN}`
      const json = await corsGet(url, parameters)
      return jsonSafeToTeamResults(json)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from admin team endpoint`, e)
      return null
    }
  }

  async updateTeam(
    hostname: string,
    apiKey: string,
    team_id: string,
    settingUpdate: AdminTeamSettingUpdate
  ): Promise<boolean | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'team_id': team_id,
      'update': settingUpdate
    }
    try {
      const url = `https://${hostname}/${TEAM_ADMIN}`
      const result = await corsPut(url, parameters)
      return result.status == 200
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from admin team endpoint`, e)
      return null
    }
  }

  async updateScope(
    hostname: string,
    apiKey: string,
    scope: ScopeV2,
    is_remove: boolean
  ) {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'scope': scope,
      'is_remove': is_remove,
    }
    try {
      const url = `https://${hostname}/${SCOPE_ADMIN}`
      const result = await corsPut(url, parameters)
      return result.status === 200
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from admin scope update endpoint`, e)
      return null
    }
  }

  async getScopes(
    hostname: string,
    apiKey: string
  ): Promise<ScopeV2[] | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    try {
      const url = `https://${hostname}/${SCOPE_ADMIN}`
      const json = await corsGet(url, parameters)
      return jsonSafeToScopesV2(json)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from admin scope endpoint`, e)
      return null
    }
  }

  async updateDomain(
    hostname: string,
    apiKey: string,
    team_id: string,
    domain: string | null,
  ) {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'team_id': team_id,
      'domain': domain,
    }
    try {
      const url = `https://${hostname}/${DOMAIN_ADMIN}`
      const result = await corsPut(url, parameters)
      return result.status === 200
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from admin domain update endpoint`, e)
      return null
    }
  }
}

class SeatManager {
  async putSeats(
    hostname: string,
    apiKey: string,
    target_user_id: string,
    team_is_active?: boolean,
    can_dial?: boolean
  ): Promise<boolean> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'user_id': target_user_id,
    }
    if (team_is_active !== undefined) parameters['team_is_active'] = team_is_active
    if (can_dial !== undefined) parameters['can_dial'] = can_dial
    try {
      const url = `https://${hostname}/${SEATS}`
      const result = await corsPut(url, parameters)
      return result.status === 200
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from seat endpoint`, e)
      return false
    }
  }
}

class EmailValuePropManager {
  async getEmailValueProp(
    apiKey: string,
    hostname: string,
  ): Promise<EmailValueProp[] | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    try {
      const url = `https://${hostname}/${EMAIL_VALUE_PROP}`
      const json = await corsGet(url, parameters)
      return jsonSafeToEmailValueProps(json)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get email value prop endpoint`, e)
      return null
    }
  }

  async updateEmailValueProp(
    apiKey: string,
    hostname: string,
    update: EmailValuePropUpdate
  ): Promise<EmailValueProp | boolean> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    if (update.email_value_prop_id !== null) parameters['email_value_prop_id'] = update.email_value_prop_id
    if (update.is_remove !== null) parameters['is_remove'] = update.is_remove
    if (update.is_team !== null) parameters['is_team'] = update.is_team
    if (update.pain_point !== null) parameters['pain_point'] = update.pain_point
    if (update.prospect_benefits !== null) parameters['prospect_benefits'] = update.prospect_benefits
    if (update.solution !== null) parameters['solution'] = update.solution
    try {
      const url = `https://${hostname}/${EMAIL_VALUE_PROP}`
      const result = await corsPut(url, parameters)
      if (result.status !== 200) return false
      if (update.is_remove) return true
      const json = await responseToJson(result)
      return jsonSafeToEmailValueProp(json)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from update email value prop endpoint`, e)
      return false
    }
  }
}

class EmailGenerationManager {
  async generateEmail(
    apiKey: string,
    hostname: string,
    input: EmailGenRequest
  ): Promise<AIProspectEmail | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      ...input,
    }
    try {
      const url = `https://${hostname}/${REQUEST_PROSPECT_EMAIL_GEN}`
      const result = await corsGet(url, parameters)
      return jsonSafeToAIProspectEmail(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from email generation endpoint`, e)
      return null
    }
  }

  async generateRevisionEmail(
    apiKey: string,
    hostname: string,
    input: EmailGenRequest,
    previous_email: string, 
    custom_edit_info: CustomEditInfo,
  ): Promise<AIProspectEmail | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'previous_email': encodeURIComponent(previous_email),
      ...input,
    }
    if (custom_edit_info.custom_tone) parameters['custom_tone'] = custom_edit_info.custom_tone
    if (custom_edit_info.custom_instructions) parameters['custom_instruction'] = custom_edit_info.custom_instructions
    try {
      const url = `https://${hostname}/${REQUEST_PROSPECT_EMAIL_GEN_REVISION}`
      const result = await corsGet(url, parameters)
      return jsonSafeToAIProspectEmail(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from email generation endpoint`, e)
      return null
    }
  }
}

class LinkedinGenerationManager {
  async generateMessage(
    apiKey: string,
    hostname: string,
    input: LinkedInGenRequest
  ): Promise<LinkedInGeneratedMessage | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      ...input
    }
    try {
      const url = `https://${hostname}/${REQUEST_PROSPECT_LINKEDIN_GEN}`
      const result = await simplePost(url, parameters)
      return jsonSafeToLinkedInGeneratedEmail(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from linkedin generation endpoint`, e)
      return null
    }
  }

  async generateRevisionEmail(
    apiKey: string,
    hostname: string,
    input: LinkedInGenRequest,
    previous_message: string, 
    custom_edit_info: CustomEditInfo,
  ): Promise<LinkedInGeneratedMessage | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'previous_message': previous_message,
      ...input
    }
    if (custom_edit_info.custom_tone) parameters['custom_tone'] = custom_edit_info.custom_tone
    if (custom_edit_info.custom_instructions) parameters['custom_instruction'] = custom_edit_info.custom_instructions
    try {
      const url = `https://${hostname}/${REQUEST_PROSPECT_LINKEDIN_REVISION}`
      const result = await simplePost(url, parameters)
      return jsonSafeToLinkedInGeneratedEmail(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from linkedin revision generation endpoint`, e)
      return null
    }
  }
}

class MultiDialerQuotaManager {
  async getQuotaInfo(
    apiKey: string
  ): Promise<MultiDialerQuotaInfo | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    try {
      const url = `https://${MULTIDIALER_HOSTNAME}/${MULTIDIALER_SETTINGS}`
      const result = await corsGet(url, parameters)
      return jsonSafeToQuotaInfo(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get multidialer settings endpoint`, e)
      return null
    }
  }
}

class TwilioNumberManager {
  async getRegisteredNumbers(
    apiKey: string
  ): Promise<RegisteredTwilioNumber[] | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'rented': true
    }
    try {
      const url = `https://${MULTIDIALER_HOSTNAME}/${TWILIO_NUMBERS}`
      const result = await corsGet(url, parameters)
      return jsonSafeToRegisteredTwilioNumbers(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get twilio numbers endpoint`, e)
      return null
    }
  }

  async registerNumber(
    apiKey: string,
    twilio_number: OptionalTwilioNumber,
    country_iso_code?: string
  ): Promise<boolean | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'phone_number': twilio_number.phone_number
    }
    if (country_iso_code) parameters['country'] = country_iso_code
    try {
      const url = `https://${MULTIDIALER_HOSTNAME}/${TWILIO_NUMBERS}`
      const result = await simplePost(url, parameters)
      return result === twilio_number.phone_number
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from register twilio number endpoint`, e)
      return null
    }
  }

  async unregisterNumber(
    apiKey: string,
    rep_phone_value: string,
  ): Promise<string | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'phone_number': rep_phone_value,
      'delete': true,
    }
    try {
      const url = `https://${MULTIDIALER_HOSTNAME}/${TWILIO_NUMBERS}`
      const response = await simplePost(url, parameters, /* textResponse = */ false, /* noJsonification = */ true)
      if (response === null) {
        return "Not logged in"
      } else if (response.status === 404) {
        return "Registration not found"
      } else if (response.status === 409) {
        return "Too many deregistrations this month"
      } else if (response.status >= 400) {
        return "Server error"
      }
      return null
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from register twilio number endpoint`, e)
      return "Server error"
    }
  }

  async getPotentialNumbers(
    apiKey: string,
    area_code?: string | null,
    iso_country_code?: string | null,
    state_or_province?: string | null,
    substring?: string | null
  ): Promise<OptionalTwilioNumber[] | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    if (area_code) parameters['area_code'] = area_code
    if (iso_country_code) parameters['country'] = iso_country_code
    if (state_or_province) parameters['state_or_province'] = state_or_province
    if (substring) parameters['substring'] = substring
    try {
      const url = `https://${MULTIDIALER_HOSTNAME}/${TWILIO_NUMBERS}`
      const result = await corsGet(url, parameters)
      return jsonSafeToOptionalTwilioNumbers(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get twilio numbers endpoint`, e)
      return null
    }
  } 
}

class StirShakenManager {
  async getStirShakenStatus(
    apiKey: string,
    teamId: string
  ): Promise<CustomerProfileData | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'team_id': teamId
    }
    try {
      const url = `https://${MULTIDIALER_HOSTNAME}/${SHAKEN_STIR}`
      const result = await corsGet(url, parameters)
      return jsonSafeToCustomerProfileData(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get stir shaken status endpoint`, e)
      return null
    }
  }
}

class SavedReportManager {
  async getReports(
    apiKey: string,
    hostname: string
  ): Promise<SavedReport[] | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    try {
      const url = `https://${hostname}/${SAVED_REPORTS}`
      const result = await corsGet(url, parameters)
      return jsonSafeToSavedReports(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get reports endpoint`, e)
      return null
    }
  }

  async putReport(
    api_key: string,
    hostname: string,
    report: SavedReport,
    is_new: boolean
  ): Promise<SavedReport | null> {
    const parameters: Record<string, any> = {
      'api_key': api_key,
      ...report
    }
    if (is_new) delete parameters['report_id']
    try {
      const url = `https://${hostname}/${SAVED_REPORTS}`
      const result = await corsPut(url, parameters)
      // get the report back
      const json = await responseToJson(result)
      return jsonSafeToSavedReport(json)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from put report endpoint`, e)
      return null
    }
  }

  async removeReport(
    apiKey: string,
    hostname: string,
    report_id: string
  ): Promise<boolean> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'report_id': report_id
    }
    try {
      const url = `https://${hostname}/${SAVED_REPORTS}`
      const result = await corsDelete(url, parameters)
      return result.status === 200
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from remove report endpoint`, e)
      return false
    }
  }
}

class SynthVoiceManager {
  async getSynthVoices(
    apiKey: string,
    hostname: string
  ): Promise<ChatbotPromptVariant[] | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    try {
      const url = `https://${hostname}/${REQUEST_SYNTH_PROSPECT}`
      const result = await corsGet(url, parameters)
      return jsonSafeToChatbotPromptVariants(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get synth voices endpoint`, e)
      return null
    }
  }

  async getAdminVoices(
    apiKey: string,
    hostname: string
  ): Promise<AdminSynthResult[] | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    try {
      const url = `https://${hostname}/${ADMIN_CHATBOTS}`
      const result = await corsGet(url, parameters)
      return jsonSafeToAdminSynthResults(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get synth voices endpoint`, e)
      return null
    }
  }

  async getAdminChatbotPromptTemplates(
    apiKey: string,
    hostname: string
  ): Promise<ChatbotPromptTemplate[] | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    try {
      const url = `https://${hostname}/${ADMIN_CHATBOTS_TEMPLATES}`
      const result = await corsGet(url, parameters)
      return jsonSafeToChatbotPromptTemplates(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get synth voices endpoint`, e)
      return null
    }
  }

  async getAdminChatbotPromptVariants(
    apiKey: string,
    hostname: string
  ): Promise<ChatbotPromptVariant[] | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    try {
      const url = `https://${hostname}/${ADMIN_CHATBOTS_VARIANTS}`
      const result = await corsGet(url, parameters)
      return jsonSafeToChatbotPromptVariants(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get synth voices endpoint`, e)
      return null
    }
  }

  async getAdminResultForTeam(
    apiKey: string,
    hostname: string,
    team_id: string
  ): Promise<AdminSynthTeamResult | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'team_id': team_id
    }
    try {
      const url = `https://${hostname}/${ADMIN_CHATBOTS}`
      const result = await corsGet(url, parameters)
      return jsonSafeToAdminSynthTeamResult(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get synth voices endpoint`, e)
      return null
    }
  }

  async addChatPromptTemplate(
    apiKey: string,
    hostname: string,
    chatbotPromptTemplate: Omit<ChatbotPromptTemplate, "chatbot_prompt_template_id">
  ): Promise<string | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      ...chatbotPromptTemplate
    }
    try {
      const url = `https://${hostname}/${ADMIN_CHATBOTS}`
      const result = await simplePost(url, parameters)
      return result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from add chatbot prompt template endpoint`, e)
      return null
    }
  }

  async updateChatPromptTemplate(
    apiKey: string,
    hostname: string,
    chatbotPromptTemplate: ChatbotPromptTemplate
  ): Promise<string | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      ...chatbotPromptTemplate
    }
    try {
      const url = `https://${hostname}/${ADMIN_CHATBOTS}`
      const result = await simplePost(url, parameters)
      // need to return a string result
      return result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from update chatbot prompt template endpoint`, e)
      return null
    }
  }

  async deleteChatPromptTemplate(
    apiKey: string,
    hostname: string,
    chatbotPromptTemplateId: string
  ): Promise<boolean> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'chatbot_prompt_template_id': chatbotPromptTemplateId,
      'delete': true
    }
    try {
      const url = `https://${hostname}/${ADMIN_CHATBOTS}`
      const result = await simplePost(url, parameters)
      return result.status === 200
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from delete chatbot prompt template endpoint`, e)
      return false
    }
  }

  async addChatbotPromptVariant(
    apiKey: string,
    hostname: string,
    variant: Omit<ChatbotPromptVariant, "chatbot_prompt_variant_id">
  ): Promise<string | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      ...variant,
    }
    try {
      const url = `https://${hostname}/${ADMIN_CHATBOTS}`
      const result = await simplePost(url, parameters)
      return result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from add chatbot prompt variant endpoint`, e)
      return null
    }
  }

  async updateChatbotPromptVariant(
    apiKey: string,
    hostname: string,
    variant: ChatbotPromptVariant
  ): Promise<string | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      ...variant,
    }
    try {
      const url = `https://${hostname}/${ADMIN_CHATBOTS}`
      const result = await simplePost(url, parameters)
      return result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from update chatbot prompt variant endpoint`, e)
      return null
    }
  }

  async deleteChatbotPromptVariant(
    apiKey: string,
    hostname: string,
    variantId: string
  ): Promise<boolean> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'chatbot_prompt_variant_id': variantId,
      'delete': true
    }
    try {
      const url = `https://${hostname}/${ADMIN_CHATBOTS}`
      const result = await simplePost(url, parameters)
      return result.status === 200
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from delete chatbot prompt variant endpoint`, e)
      return false
    }
  }
}

class AIManyCallAnalysisManager {
  async getAIManyCallAnalysis(
    apiKey: string,
    hostname: string,
    userId: string,
    periodStart: Date,
  ): Promise<AIManyCallAnalysis[]> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'user_id': userId,
      'period_start': periodStart.getTime() * MS_TO_MICRO,
    }
    try {
      const url = `https://${hostname}/${AI_MANY_CALL_ANALYSIS}`
      const result = await corsGet(url, parameters)
      if (!(result instanceof Array)) { return [] }
      return result.map(jsonSafeToAIManyCallAnalysis)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from ${AI_MANY_CALL_ANALYSIS} endpoint`, e)
      return []
    }
  }
}

class RecordedGreetingsManager {
  async getRecordedGreetings(
    apiKey: string,
  ): Promise<RecordedGreeting[] | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
    }
    try {
      const url = `https://${MULTIDIALER_HOSTNAME}/${RECORDED_GREETINGS}`
      const result = await corsGet(url, parameters)
      if (!(result instanceof Array)) { return [] }
      return result.map(jsonSafeToRecordedGreeting)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get recorded greetings endpoint`, e)
      return null
    }
  }

  async getSpecificGreetingURL(
    apiKey: string,
    recordedGreetingId: string,
  ): Promise<string | null> {
    const parameters: Record<string, any> = {
      'api_key': apiKey,
      'recorded_greeting_id': recordedGreetingId,
    }
    try {
      const url = `https://${MULTIDIALER_HOSTNAME}/${RECORDED_GREETINGS}`
      const result = await corsGet(url, parameters)
      return result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from get recorded greetings endpoint`, e)
      return null
    }
  }

  async uploadGreeting(
    apiKey: string,
    name: string,
    file?: File,
    url?: string,
  ): Promise<RecordedGreeting | null> {
    const formData = new FormData()
    formData.append('api_key', apiKey)
    formData.append('name', name)
    if (file) {
      formData.append('file', file)
    } else if (url) {
      formData.append('url', url)
    } else {
      logInfo(`${_LOG_SCOPE} uploadGreeting requires file or url`)
      return null
    }

    try {
      const url = `https://${MULTIDIALER_HOSTNAME}/${RECORDED_GREETINGS}`
      const result = await simplePost(url, formData)
      return jsonSafeToRecordedGreeting(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from upload recorded greetings endpoint`, e)
      return null
    }
  }

  async updateGreeting(
    apiKey: string,
    recordedGreetingId: string,
    name?: string,
  ): Promise<RecordedGreeting | null> {
    const formData = new FormData()
    formData.append('api_key', apiKey)
    formData.append('recorded_greeting_id', recordedGreetingId)
    if (name) { formData.append('name', name) }
    try {
      const url = `https://${MULTIDIALER_HOSTNAME}/${RECORDED_GREETINGS}`
      const result = await simplePost(url, formData)
      return jsonSafeToRecordedGreeting(result)
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from update recorded greetings endpoint`, e)
      return null
    }
  }

  async deleteGreeting(
    apiKey: string,
    recordedGreetingId: string,
  ): Promise<boolean | null> {
    const formData = new FormData()
    formData.append('api_key', apiKey)
    formData.append('recorded_greeting_id', recordedGreetingId)
    formData.append('delete', 'delete')
    try {
      const url = `https://${MULTIDIALER_HOSTNAME}/${RECORDED_GREETINGS}`
      const result = await simplePost(url, formData)
      return !!result
    } catch (e) {
      logInfo(`${_LOG_SCOPE} Unexpected error from delete recorded greetings endpoint`, e)
      return null
    }
  }
}


const _SERVICES_MANAGER = new ServicesManager()
export function getServicesManager(): ServicesManager {
  return _SERVICES_MANAGER
}
