import { History, Transition } from 'history';
import { useCallback, useContext, useEffect } from "react";
import { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

export interface PromptOnLeaveInterface {
    message: string;
    when?: boolean;
  }
  
  function useConfirmExit(confirmExit: () => boolean, when = true) {
    const { navigator } = useContext(NavigationContext);
  
    useEffect(() => {
      if (!when) {
        return;
      }
  
      const push = navigator.push;
  
      navigator.push = (...args: Parameters<typeof push>) => {
        const result = confirmExit();
        if (result !== false) {
          push(...args);
        }
      };
  
      return () => {
        navigator.push = push;
      };
    }, [navigator, confirmExit, when]);
  }
  
  export function usePrompt(message: string, when = true) {
    useEffect(() => {
      if (when) {
        window.onbeforeunload = function () {
          return message;
        };
      }
  
      return () => {
        window.onbeforeunload = null;
      };
    }, [message, when]);
  
    const confirmExit = useCallback(() => {
      const confirm = window.confirm(message);
      return confirm;
    }, [message]);
    useConfirmExit(confirmExit, when);
  }
  
  
  export function PromptOnLeave({ message, when }: PromptOnLeaveInterface) {
    usePrompt(message, when);
    return null;
  }