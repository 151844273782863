import React from "react"

const plus = <svg width="" height="" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_2404_7975)">
<path d="M800 367.677H432.323V0H367.677V367.677H0V432.323H367.677V800H432.323V432.323H800V367.677Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_2404_7975">
<rect width="800" height="800" fill="white"/>
</clipPath>
</defs>
</svg>

export type PlusProps = {
    onClick: () => void
}

export class PlusButton extends React.Component<PlusProps, {}> {
    constructor(props: PlusProps) {
        super(props)
    }
    render() {
        return <button tabIndex={-1} onClick={this.props.onClick.bind(this)} type="button" className="h-5 w-5 items-center justify-center hover:bg-green-200 bg-white" style={{
            'padding': '5px',
            'borderRadius': '50%',
            'border': '0.5px solid lightgrey'
        }}>
        <div className="flex max-w-full grow items-center justify-center text-center ">
          {plus}
        </div> 
        </button>
    }
}