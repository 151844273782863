import { MS_TO_SEC, SEC_TO_MS } from 'cfg/const';
import { SpeechTurn, VisibleAccountsResult } from 'interfaces/services';
import { Typography } from 'interfaces/typography';
import { convertFromReduxSafeVisibleAccounts } from 'lib/redux/store';
import React, { useState, useRef, MouseEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';

export type Interval = {
  startTime: number,
  endTime: number
}

type ProgressBarProps = {
    currentTime: number
    updateCurrentTime: (percentageComplete: number) => void, 
    endTime?: number
    turns?: SpeechTurn[]
    onMouseUp: () => void
    onMouseDown: () => void
    prospectNotRecorded?: boolean,
    play: boolean
    toggleState: () => void
    copyTranscriptOnClick?: () => void
    userId?: string
    speedIdx: number,
    updateSpeedIdx: (idx: number) => void
    downloadAudio: () => void
  }


const copySvg = <svg width="100%`" height="100%" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_2837_7104)">
<path d="M199.101 142.2V170.6H426.701C442.401 170.6 455.101 183.4 455.101 199V426.6C455.101 442.3 442.301 455 426.701 455H199.101C183.401 455 170.701 442.2 170.701 426.6V199C170.701 183.3 183.501 170.6 199.101 170.6V142.2V113.8C152.001 113.8 113.801 152 113.801 199.1V426.7C113.801 473.8 152.001 512 199.101 512H426.701C473.801 512 512.001 473.8 512.001 426.7V199.1C512.001 152 473.801 113.8 426.701 113.8H199.101V142.2Z" fill="black"/>
<path d="M398.2 142.2V85.3C398.2 38.2 360 0 312.9 0H85.3C38.2 0 0 38.2 0 85.3V312.9C0 360 38.2 398.2 85.3 398.2H142.2C157.9 398.2 170.6 385.5 170.6 369.8C170.6 354.1 157.9 341.4 142.2 341.4H85.3C69.6 341.4 56.9 328.6 56.9 313V85.4C56.9 69.7 69.7 57 85.3 57H312.9C328.6 57 341.3 69.7 341.3 85.4V142.3C341.3 158 354 170.7 369.7 170.7C385.4 170.7 398.2 157.9 398.2 142.2Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_2837_7104">
<rect width="512" height="512" fill="white"/>
</clipPath>
</defs>
</svg>


const downloadButton = <svg width="" height="" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_2863_7104)">
<path d="M679.484 509.84V692.587H120.488V509.84H13.0488V746.307C13.0488 775.955 37.0664 799.999 66.8483 799.999H733.124C762.879 799.999 786.923 775.982 786.923 746.307V509.84H679.484Z" fill="black"/>
<path d="M390.152 491.721L236.333 305.851C236.333 305.851 212.929 283.755 238.307 283.755C263.686 283.755 324.984 283.755 324.984 283.755C324.984 283.755 324.984 268.891 324.984 245.967C324.984 180.612 324.984 61.6719 324.984 13.183C324.984 13.183 321.542 0 341.396 0C361.411 0 449.075 0 463.406 0C477.71 0 477.389 11.1015 477.389 11.1015C477.389 58.1226 477.389 181.173 477.389 244.393C477.389 264.888 477.389 278.177 477.389 278.177C477.389 278.177 526.545 278.177 557.395 278.177C588.191 278.177 565 301.314 565 301.314C565 301.314 434.131 475.042 415.878 493.268C402.748 506.478 390.152 491.721 390.152 491.721Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_2863_7104">
<rect width="800" height="800" fill="white"/>
</clipPath>
</defs>
</svg>


export const SPEEDS: number[] = [1, 1.25, 1.5, 2]


export const AudioPlayerBar = (props: ProgressBarProps) => {
  const rangeRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    props.onMouseDown()
    updateRange(event.clientX);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (event: any) => {
    updateRange(event.clientX);
  };

  const handleMouseUp = () => {
    props.onMouseUp()
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const updateRange = (clientX: number) => {
    if (!rangeRef.current) return;
    const rangeRect = rangeRef.current.getBoundingClientRect();
    const offsetX = clientX - rangeRect.left;
    let percentage = (offsetX / rangeRect.width);
    percentage = Math.min(Math.max(percentage, 0), 1); // Clamp between 0 and 100
    props.updateCurrentTime(percentage);
  };

  const talkTimeHeight = 4
  const bgColor = 'rgb(3, 33, 78)'
  // gap*2 + typography + adjust upwards = extra for downwards
  const lineHeight = 2*8 + 1*14 + 2 + 4

  const element =( 
  <div>
  <div className='flex flex-col gap-0' style={{
    'position': 'absolute',
    'top': '-12px',
  }}>
  <div style={{
    'width': '8px',
    'height': '8px',
    'borderRadius': '50%',
    'backgroundColor': `${bgColor}`,
    'cursor': 'grab',
    'zIndex': '9999999',
    'pointerEvents': 'initial'
  }}
  onMouseDown={handleMouseDown} 
  />
<div style={{
  'position': 'relative',
  'left': '3.5px',
  'top': '-2px',
  'width': '1px',
  'height': `${lineHeight}px`,
  'backgroundColor': `${"black"}`,
  'pointerEvents': 'initial',
  'zIndex': '9999999',
  'cursor': 'grab',
  'opacity': '0.6'
  }}
  onMouseDown={handleMouseDown} 
  />
  <div style={{
    'position': 'relative',
    'top': '-10px',
    'width': '8px',
    'height': '8px',
    'borderRadius': '50%',
    'backgroundColor': `${bgColor}`,
    'cursor': 'grab',
    'zIndex': '9999999',
    'pointerEvents': 'initial'
  }}
  onMouseDown={handleMouseDown} 
  />
  </div>
  </div>
  )
  const position = `calc(${props.endTime ? (100*props.currentTime / props.endTime) : 0}%)`

  const Pause = <svg className="w-5/6 h-5/6" viewBox="0 0 40 60">
  <polygon points="0,0 15,0 15,60 0,60" />
  <polygon points="25,0 40,0 40,60 25,60" />
  </svg>
  
  const Play = <svg className="w-5/6 h-5/6" viewBox="0 0 50 60">
  <polygon points="0,0 50,30 0,60" />
  </svg>

  function _formatMsToString(time: number) {
    const timeInSecond = (time * MS_TO_SEC)
    const minutes = Math.floor((timeInSecond / 60)).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
    const seconds = Math.round((timeInSecond % 60)).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
    return minutes + ":" + seconds
  }

  const renderControls = () => {
      return (
      <div className='h-full flex flex-col gap-2 justify-between items-center p-1.5'>
        <div className="w-full flex flex-row gap-2 items-center justify-center">
          <div style={{'width': '32px', 'minWidth': '32px', 'height': '32px', 'minHeight': '32px', 'userSelect': 'none'}}>
              <div onClick={() => props.toggleState()} className={"hover:bg-slate-300 cursor-pointer"} style={{
                'userSelect': 'none',
                'width': '100%', 'height': '100%', 'padding': '8px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': "1px solid rgba(91, 101, 124, 0.25)", 'borderRadius': '50%', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 10%)'}}>
                {props.play ? Pause : Play}
              </div>
          </div>
  
          <div onClick={() => props.updateSpeedIdx((props.speedIdx + 1)%SPEEDS.length)} className={"hover:bg-slate-300 cursor-pointer"} style={{'userSelect': 'none', 'width': '32px', 'minWidth': '32px', 'height': '32px', 'minHeight': '32px', 'padding': '8px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': "1px solid rgba(91, 101, 124, 0.25)", 'borderRadius': '50%', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 10%)'}}>
            <Typography variant="caption">
              {SPEEDS[props.speedIdx] + "x"} 
            </Typography>
          </div>
  
          {props.copyTranscriptOnClick ? 
          <div onClick={() => {if (props.copyTranscriptOnClick) props.copyTranscriptOnClick()}} className={"hover:bg-slate-300 cursor-pointer"} style={{'width': '32px', 'minWidth': '32px', 'height': '32px', 'minHeight': '32px', 'padding': '8px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': "1px solid rgba(91, 101, 124, 0.25)", 'borderRadius': '50%', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 10%)'}}>
            <div className="w-full h-full">
              {copySvg}
            </div>
          </div> : null }
          <div 
          onClick={props.downloadAudio.bind(this)}
          className={"hover:bg-slate-300 cursor-pointer"} style={{'width': '32px', 'minWidth': '32px', 'height': '32px', 'minHeight': '32px', 'padding': '8px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': "1px solid rgba(91, 101, 124, 0.25)", 'borderRadius': '50%', 'boxShadow': '0px 3px 3px rgb(0 0 0 / 10%)'}}>
              {downloadButton}
          </div>
      </div>
  
      <div className="flex flex-row gap-1" style={{'userSelect': 'none'}}>
      <Typography variant="caption">{_formatMsToString(props.currentTime)}</Typography>
      <Typography variant="caption">/</Typography>
      <Typography variant="caption">{_formatMsToString(props.endTime ?? 0)}</Typography>
    </div>
    </div>)
  }


  return (
    <div className='w-full flex flex-row gap-1'>
    <div className='pt-1.5 pb-1.5 pl-2 pr-2 flex flex-col gap-1' style={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 'backgroundColor': 'white', 'borderRadius': '2px'}}> 
    {renderControls()}
    </div>
    <div className='pt-1.5 pb-1.5 pl-2 pr-2 w-full flex flex-col gap-1.5 justify-center' style={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 'backgroundColor': 'white', 'borderRadius': '2px'}}> 

      <SequenceTimeline 
      bgColor={bgColor} 
      endTime={props.endTime} 
      lineHeight={talkTimeHeight} 
      userId={props.userId} 
      party={'REP'} 
      callbackfn={handleMouseDown} 
      turns={props.turns}/>
  
      <div className='w-full flex flex-col'>
        <div
          ref={rangeRef}
          style={{
            width: '100%',
          }}
         >
          
          <div className='relative w-full' style={{'userSelect': 'none'}}>
          <div
            style={{
              width: `${props.endTime ? (100*props.currentTime / props.endTime) : 0}%`,
              height: '100%',
              backgroundColor: `${bgColor}`,
              'overflowX': 'hidden'
            }}
            />
          <MovingVerticalLine element={element}  propsPosition={position}/>
          </div>
          </div>
      </div>
      
      <SequenceTimeline prospectNotRecorded={props.prospectNotRecorded} bgColor={bgColor} endTime={props.endTime} lineHeight={talkTimeHeight} party={'PROSPECT'} callbackfn={handleMouseDown} turns={props.turns}/>

      </div>
      </div>
  );
};




type MovingElementProps = {
  propsPosition: string
  element: JSX.Element
}

const MovingVerticalLine = (props: MovingElementProps) => {
  const [position, setPosition] = useState(props.propsPosition);
  const lineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animate = () => {
      // Update the position
      setPosition(props.propsPosition);
      // Call animate again on the next frame
      requestAnimationFrame(animate);
    };

    // Start the animation
    animate();
  }, [props.propsPosition]);


  useEffect(() => {
    const lineElement = lineRef.current;

    if (lineElement) {
      lineElement.style.transform = `translateX(${position})`;
    }
  }, [position]);

  return (
    <div className='absolute w-full' style={{'zIndex': '999999',   'pointerEvents': 'none'}} ref={lineRef}>
      {props.element}
    </div>
  );
};


type SequenceTimelineProps = {
  visibleAccounts: VisibleAccountsResult | null
  userId?: string
  party: string
  lineHeight: number
  endTime?: number
  turns?: SpeechTurn[]
  callbackfn?: (event: MouseEvent<HTMLDivElement>) => void
  bgColor: string,
  prospectNotRecorded?: boolean
}

class SequenceTimelineImpl extends React.Component<SequenceTimelineProps, {}> {
  constructor(props: SequenceTimelineProps) {
    super(props)
  }

  render() {
    let name = this.props.party === 'REP' ? 'Rep' : 'Prospect'
    if (this.props.party === 'REP' && this.props.visibleAccounts?.users && this.props.userId) {
      const potentialMatch = this.props.visibleAccounts.users.find((v) => v.user_id === this.props.userId)
      if (potentialMatch) name = `Rep - ${potentialMatch.user_name}`
    }
    return (
    <div className='flex flex-col'>
    <div className='flex flex-row items-center gap-1'>
      <Typography variant='mediumParagraph'>
          {name}
      </Typography>
      {this.props.prospectNotRecorded ? 
      <Typography variant='smallParagraph'>
        (Not recorded for compliance)
      </Typography> : null}
    </div>
  <div className='w-full' style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        height: `${this.props.lineHeight}px`,
        backgroundColor: '#ccc',
        cursor: this.props.callbackfn ? 'pointer' : 'initial',
        borderRadius: '2px',

    }}
    onMouseDown={this.props.callbackfn ? this.props.callbackfn : undefined}
    >
      {(this.props.turns ?? []).map((value: SpeechTurn) => {
        if (value.role === this.props.party && this.props.endTime) {
          return (
            <div
            style={{
              'position': 'absolute',
              'left': `${100*value.start*SEC_TO_MS / this.props.endTime}%`,
              'width': `${100*SEC_TO_MS*(value.end - value.start) / this.props.endTime}%`,
              'height': `${this.props.lineHeight}px`,
              'backgroundColor': `${this.props.bgColor}`,
              'borderRadius': '2px'
            }}
            >
            </div>
          )
        } 
      })}
  </div>
  </div>)
  }
}

export const SequenceTimeline = connect((state: RootState) => {
  return {
      visibleAccounts: convertFromReduxSafeVisibleAccounts(state.visibleAccounts),
  }
})(SequenceTimelineImpl as React.ComponentType<any>)
