import { Typography } from 'interfaces/typography'
import React from 'react'

export enum AlertType {
    STANDARD='standard',
    YES_OR_NO='YES_OR_NO'
}  

export type AlertProps = {
    stringToShow: string
    secondaryText?: string,
    bgColorTailwind: string
    alertType: AlertType
    onCloseOrNo: () => void
    onYes?: () => void
}


export class Alert extends React.Component<AlertProps> {
    
    constructor(props: AlertProps) {
        super(props)
    }
    
    _renderText(): JSX.Element {
        if (this.props.secondaryText) {
            return <div className='flex flex-col'>
                <Typography variant='caption' color='white'>
                    {this.props.stringToShow}
                </Typography>
                <Typography variant='smallCaption' color='white'>
                    {this.props.secondaryText}
                </Typography>
            </div>
        } else {
            return (
            <Typography variant='caption' color='white'>
                {this.props.stringToShow}
            </Typography>)
        }
    }




    _renderYesOrNoButtons(): JSX.Element {
        return (
            <div className='flex flex-row gap-2'>
                {this._renderNoButton()}
                {this._renderYesButton()}
            </div>
            )
    }

    _renderYesButton(): JSX.Element {
        return (
            <button onClick={this.props.onYes?.bind(this)} className="bg-slate-300 hover:bg-slate-500 outline-none focus:outline-none p-3 rounded-full w-4 h-4 justify-center flex items-center text-center">
                <Typography variant='caption' color='black'>
                &#10003;
                </Typography>
            </button>)
    }

    _renderNoButton(): JSX.Element {
        return (
        <button onClick={this.props.onCloseOrNo.bind(this)} className="bg-slate-300 hover:bg-slate-500 outline-none focus:outline-none p-3 rounded-full w-4 h-4 justify-center flex items-center text-center">
            <Typography variant='caption' color='black'>
            x
            </Typography>
        </button>)
    }

    render(): JSX.Element | null {
        return (
        <div 
            className=
                {"gap-2 w-full flex flex-row justify-between opacity-95 px-6 py-4 border-0 rounded-br-l " + this.props.bgColorTailwind + " sticky z-50 top-0"}
            >
            {this._renderText()}
            {this.props.alertType === AlertType.STANDARD ? this._renderNoButton() : this._renderYesOrNoButtons()}
            </div>
        )
    }
}
  