import { SessionDetails } from "components/SessionDetails";
import React from "react";
import { GoToHeader } from "./GoToHeader";
import { PreviousCallSummary } from "interfaces/services";
import { Typography } from "interfaces/typography";
import { durationToString, minimalDateToString } from "time";
import { Table, TableProps } from "components/Table";
import { MS_TO_MICRO } from "cfg/const";

export type SessionSelectorProps = {
    selectedSessionId: string | null
    sessionOptions: PreviousCallSummary[] | null
    onSessionSelect: (sessionId: string) => void
}

export type SessionSelectorState = {
    hovered: boolean
}

export function getMinimalSummary(callInfo: PreviousCallSummary): {content: string, bold: boolean} {
    if (!callInfo.sessionMetric) return {content: 'No response', bold: false}
    else if (callInfo.sessionMetric.voicemailDuration > 0) {
        if (callInfo.sessionMetric.repSpeechDuringVoicemailDuration > 10) return {content: 'Left VM', bold: false}
        else return {content: 'Reached VM', bold: false}
    } else if (callInfo.sessionMetric.targetDuration > 0) return {content: 'Prospect', bold: true}
    else if (callInfo.sessionMetric.gatekeeperDuration > 0) return {content: 'Gatekeeper', bold: true} 
    return {content: 'No response', bold: false}
}


class SessionSelector extends React.Component<SessionSelectorProps, SessionSelectorState> {
    constructor(props: SessionSelectorProps) { 
        super(props) 
        this.state = { hovered: false }
    }

    _getSummary(callInfo: PreviousCallSummary): JSX.Element {
        const summary = getMinimalSummary(callInfo)
        return <Typography style={{'whiteSpace': 'nowrap'}} variant="largeParagraph" fontWeight={summary.bold ? 550 : 350}>
                {summary.content}
        </Typography>
    }

    _getTableProps(): TableProps<PreviousCallSummary> {
        return {
            items: this.props.sessionOptions ?? [],
            highlightedItemIdx: this.props.sessionOptions?.findIndex(session => session.sessionId === this.props.selectedSessionId) ?? -1,
            tableWidthFull: true,
            trClassAdditionalStyleRowFn(item) {
                const isBold = getMinimalSummary(item).bold
                return isBold ? {'opacity': '100%'} : {'opacity': '50%'}
            },
            trClassStyleHighlighted: {'backgroundColor': 'lightgrey'},
            trClassStyleNonHighlighted: {},
            group_by: (item: PreviousCallSummary) => minimalDateToString(new Date(item.scheduledStart)),
            group_by_value: (item: PreviousCallSummary) => <Typography variant="largeParagraph" fontWeight={550} style={{'whiteSpace': 'nowrap'}}>{minimalDateToString(new Date(item.scheduledStart))}</Typography>,
            columnFuncs: [
                (item) => this._getSummary(item),
                (item) => <Typography variant="largeParagraph" fontWeight={350}>
                        {durationToString(item.duration*MS_TO_MICRO)}
                    </Typography>,
            ],
            onRowClick: (item) => this.props.onSessionSelect(item.sessionId),
            keyFunc: (item) => item.sessionId,
        }
    }

    render() {
        let selectedIdx = (this.props.sessionOptions ?? []).findIndex(session => session.sessionId === this.props.selectedSessionId)
        const numberOptions = (this.props.sessionOptions ?? []).length
        return <div 
        onMouseEnter={() => this.setState({hovered: true})}
        onMouseLeave={() => this.setState({hovered: false})} 
        className="flex flex-col w-full items-end">
        <div className="p-1 cursor-pointer w-fit" style={{
            'borderRadius': '8px', 
            'boxShadow': 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            'userSelect': 'none'
            }}>
            <Typography variant="largeParagraph" fontWeight={650}>{`${(selectedIdx === -1 ?  '-' : (selectedIdx + 1))} of ${numberOptions === 0 ? '-' : numberOptions}`}</Typography>
        </div>
        {numberOptions <= 1 ? null : 
            <div style={{display: this.state.hovered ? undefined: 'none'}} className="relative">
                <div className="absolute right-0" style={{
                    zIndex: 9999999,
                    maxHeight: '60vh',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    maxWidth: "80vw",
                    width: 'fit-content',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    paddingLeft: '6.5px',
                    paddingRight: '6.5px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                }}>
                    <Table {...this._getTableProps()} />
                </div>
            </div>
        }
        </div>
    }
}

export type EmbeddedTranscriptViewerProps = SessionSelectorProps & {
    onGoBack: () => void
}

export class EmbeddedTranscriptViewer extends React.Component<EmbeddedTranscriptViewerProps, {}> {
    constructor(props: EmbeddedTranscriptViewerProps) { super(props) }  
    
    constructTimeHeader(scheduled_start: number): JSX.Element {
        const date = new Date(scheduled_start)
        return <div className="w-full text-center flex justify-center items-center">
            <Typography variant="h1">
                {minimalDateToString(date)}
            </Typography>
        </div>
    }

    constructHeader(): JSX.Element[] | null {
        if (!this.props.selectedSessionId || !this.props.sessionOptions) return null
        const selectedSessionIdx = this.props.sessionOptions.findIndex(session => session.sessionId === this.props.selectedSessionId)
        if (selectedSessionIdx === -1) return null
        const selectedSession = this.props.sessionOptions[selectedSessionIdx]
        return [this.constructTimeHeader(selectedSession.scheduledStart), <SessionSelector {...this.props}/>]
    }

    render() {
        return <div className="w-full h-full flex flex-col gap-0.5">
            <GoToHeader backMessage="Back" onGoBack={this.props.onGoBack.bind(this)} additionalContent={this.constructHeader()} />
            <div className="w-full h-full pl-2 pr-2 mt-1.5 overflow-hidden">
            <SessionDetails sessionId={this.props.selectedSessionId ?? undefined} isInView={true} embeddedView={true} />     
            </div>
        </div>    
    }
}
