import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export type Stored = {
 'reviewIsOpen': boolean | undefined, 
 'reviewOpenedByUser': boolean | undefined, 
 'star': boolean| undefined
} 

export interface CounterState {
  value: {[k: string]: Stored};
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CounterState = {
  value: {},
  status: 'idle',
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateSessionStatus: (state, action: PayloadAction<{'sessionId': string, 'reviewIsOpen': boolean | undefined, 'reviewOpenedByUser': boolean | undefined, 'star': boolean | undefined}>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value[action.payload.sessionId] = {'reviewIsOpen': action.payload.reviewIsOpen, 'reviewOpenedByUser': action.payload.reviewOpenedByUser , 'star': action.payload.star}
    }
  },
});

export const { updateSessionStatus } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state: RootState) => state.counter.value;


export default counterSlice.reducer;