import { Typography } from "interfaces/typography"
import React from "react"

const backArrow = <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
<path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
</svg>

export type GoToHeaderProps = {
    backMessage?: string
    onGoBack: () => void
    additionalContent?: JSX.Element[] | null
}

export class GoToHeader extends React.Component<GoToHeaderProps, {}> {
    renderBackToInputs() {
        return <div className="w-full"><button 
        onClick={this.props.onGoBack.bind(this)}
        className="flex items-center justify-center hover:bg-slate-100" style={{
            userSelect: 'none',
            verticalAlign:  'middle',
            outline: 'transparent solid 2px',
            outlineOffset: '2px',
            lineHeight: '1.2',
            borderRadius: '8px',
            cursor: 'pointer',
        }}>
            {backArrow}
            <Typography variant="h1">
                {this.props.backMessage ?? "Back to data"}
            </Typography>
            </button>
        </div>
    }

    renderGoToSection() {
        return <div className="pt-1.5 pb-1.5 w-full pl-2 pr-2 flex flex-row items-center justify-between">
            {this.renderBackToInputs()}
            {this.props.additionalContent ? this.props.additionalContent : null}
        </div>
    }

    renderBreaker() { return <div className="w-full bg-gray-300" style={{height: '1px'}}></div> }
    render() {
        return <div className="flex flex-col w-full gap-0.5">
            {this.renderGoToSection()}
            {this.renderBreaker()}
        </div>    
    }
}