import { EXT_MANAGER_KEY, MESSAGE_TYPES, TRANSIT_MESSAGE_KEYS } from "cfg/endpoints";
import { RequestState } from "components/Widgets/Automation";
import { AGENT_TYPE, AIProspectEmail, EmailValueProp } from "interfaces/db";
import { EMAIL_INPUT, EmailGenRequest } from "interfaces/services";
import { Typography } from "interfaces/typography";
import React from "react";
import { getServicesManager } from "services";
import { EMAIL_INPUT_DATA, jsonSafeToAgentData, jsonSafeToNumberInfo } from "./interfaces";
import { ProspectOverviewPage } from "./ProspectDataPage";
import { EmbeddedTranscriptViewer } from "./TranscriptViewer";
import { sendMessageToTop } from "lib/user-agent";
import { ChatGPTGenerationPage } from "../ChatGPTGenerationView/ChatGPTGenerationPage";
import { CustomEditInfo } from "interfaces/services";
import { RootState } from "store";
import { connect } from "react-redux";
import { reloadEmailValueProps } from "lib/redux/store";
import { Dispatch, AnyAction } from "@reduxjs/toolkit";
import { SEC_TO_MS } from "cfg/const";

export enum PageView {   
    TRANSCRIPT_VIEW = 'TRANSCRIPT_VIEW',
    EMAIL_GENERATION_VIEW = 'EMAIL_GENERATION_VIEW',
    PROSPECT_DATA_VIEW = 'PROSPECT_DATA_VIEW',
}

export type EmailState = {
    input_data: EMAIL_INPUT_DATA
    latest_prospect_id: string | null
    email_requested_for_prospect_id: string | null
    generated_email: {[k: string]: AIProspectEmail | null}
    selected_transcript: string | null
    selected_section: EMAIL_INPUT,
    current_view: PageView
    apiKey?: string
    conversational_histories: {[k: string]: CustomEditInfo[]},
    conversational_generated_emails: {[k: string]: {[uuid in string]: AIProspectEmail | null}}
}

export type EmbeddedAutomatorProps = {
    email_value_props: EmailValueProp[] | null
    dispatch: Dispatch<AnyAction>
 } 

class EmbeddedAutomatorImpl extends React.Component<EmbeddedAutomatorProps, EmailState> {
    constructor(props: EmbeddedAutomatorProps) {
        super(props)
        this.state = {
            input_data: this.getEmptyProspectData(),
            latest_prospect_id: null,
            generated_email: {},
            selected_section: EMAIL_INPUT.TRANSCRIPTS,
            selected_transcript: null,
            current_view: PageView.PROSPECT_DATA_VIEW,
            email_requested_for_prospect_id: null,
            conversational_histories: {},
            conversational_generated_emails: {},
        }
    }

    componentDidMount(): void { 
        window.addEventListener('message', this.onWindowMessageBinded) 
        if (this.props.email_value_props === null) reloadEmailValueProps(this.props.dispatch)
    }
    componentWillUnmount(): void { window.removeEventListener('message', this.onWindowMessageBinded) }
    onWindowMessageBinded = this.onWindowMessage.bind(this)
    _sendMessageToTop(message: any) {
        if (window.top && document.location.ancestorOrigins 
            && document.location.ancestorOrigins.length > 0) {
              const ancestorOrigin = document.location.ancestorOrigins[0]
            try { window.top.postMessage(message, ancestorOrigin) } 
            catch (e) {}
        }
    }
    compareNumbersArray(a: string[], b: string[]) { return a.length === b.length && JSON.stringify(a.sort()) === JSON.stringify(b.sort()) } 

    passMessageToWeb(target_manager: string, message_info: any) { this._sendMessageToTop({type: MESSAGE_TYPES.TRELLUS_ROUTER_PASSING, [TRANSIT_MESSAGE_KEYS.SOURCE_INFO]: { [EXT_MANAGER_KEY.MANAGER_TYPE]: 'EXTERNAL'}, [TRANSIT_MESSAGE_KEYS.TARGET_INFO]: { [EXT_MANAGER_KEY.MANAGER_TYPE]: target_manager}, [TRANSIT_MESSAGE_KEYS.MESSAGE_INFO]: message_info}) }

    onWindowMessage(event: MessageEvent) {
        const data = event.data
        if (!data['type']) return
        switch (data['type']) {
            case MESSAGE_TYPES.APP_TO_EXTERNAL_SET_EXTENSION_INFO:
            const apiKey = data['apiKey']
                if (apiKey) this.setState({'apiKey': apiKey})
                break
            case MESSAGE_TYPES.APP_TO_EXTERNAL_CHECK_IS_LOADED:
                this._sendMessageToTop({'type': MESSAGE_TYPES.EXTERNAL_TO_APP_IS_LOADED})
                break
            case MESSAGE_TYPES.PRE_CALL_TO_EXTERNAL_IS_LOADED:
                this._sendMessageToTop({'type': MESSAGE_TYPES.EXTERNAL_TO_PRE_CALL_IS_LOADED})
                this.passMessageToWeb('DATA_IFRAME', {'type': MESSAGE_TYPES.EXTERNAL_TO_PRE_CALL_IS_LOADED})
                break
            case MESSAGE_TYPES.PRE_CALL_TO_EXTERNAL_UPDATE_EMAIL_INPUT:
                this.onInputReceived(data['input'], data['data'], data['id'])
                break
            case MESSAGE_TYPES.PRE_CALL_TO_EXTERNAL_SCRAPED_DATA:
                const agent_type = data['agent_type'] as AGENT_TYPE
                this.setState((state) => {
                    if (state.input_data.agent_data[agent_type].url !== data['url']) return null
                    return { input_data: {...state.input_data, agent_data: {...state.input_data.agent_data, 
                        [agent_type]: {
                            ...state.input_data.agent_data[agent_type], 
                            scraped_data: data['scraped_data'],
                            status: data['scraped_data'] ? RequestState.REQUESTED_BY_USER : RequestState.ERROR,
                            error_reason: data['error_reason']
                        }}}}
                })
                break
            case MESSAGE_TYPES.PRE_CALL_TO_EXTERNAL_REQUEST_EMAIL:
                if (!this.state.latest_prospect_id) return
                this.setState((state) => {
                    return {
                        current_view: PageView.EMAIL_GENERATION_VIEW,
                        email_requested_for_prospect_id: state.latest_prospect_id
                    }
                })
                break
            case MESSAGE_TYPES.PRE_CALL_TO_EXTERNAL_REFRESH_TRANSCRIPTS:
                const number = data['number']
                const immediately = data['immediately']
                if (!this.state.input_data.number_info.numbers.includes(number)) return
                // need to fetch the transcripts again
                if (!immediately) {
                    setTimeout(() => {
                        if (!this.state.input_data.number_info.numbers.includes(number)) return
                        this.fetchNumbers(this.state.input_data.number_info.numbers, true)
                    }, 10*SEC_TO_MS)
                } else {
                    this.fetchNumbers(this.state.input_data.number_info.numbers, true)
                }

        }
    }

    getEmptyProspectData(): EMAIL_INPUT_DATA {
        return {
            prospect_name: null,
            prospect_company: null,
            prospect_title: null,
            agent_data: {
                [AGENT_TYPE.LINKEDIN]: {status: RequestState.DEFAULT, url: null, info: undefined, has_data: false, },
                [AGENT_TYPE.COMPANY_WEBSITE]: {status: RequestState.DEFAULT, url: null, info: undefined, has_data: false, },
            },
            number_info: {numbers: [], summaries: null, transcripts_found: null},
        }
    }

    onProspectIdentifierReceived(prospect_identifier: string | null) {  this.setState({latest_prospect_id: prospect_identifier}) }
    onAgentInfoUpdate(agent_type: AGENT_TYPE, data: any) {
        const agent_data = jsonSafeToAgentData(data)
        this.setState((state) => {
            if (state.input_data.agent_data[agent_type].url === agent_data.url && state.input_data.agent_data[agent_type].has_data) return null
            return { input_data: {...state.input_data, agent_data: {...state.input_data.agent_data, [agent_type]: agent_data}}}
        })
    }
    onNumberInfoUpdate(data: any) {
        const agent_data = jsonSafeToNumberInfo(data)
        this.setState((state) => {
            if (this.compareNumbersArray(agent_data.numbers, state.input_data.number_info.numbers)) return null
            return { input_data: {...state.input_data, number_info: agent_data}}
        })
    }
    onProspectMetadataUpdate(prospect_value: string, key: 'prospect_name' | 'prospect_company' | 'prospect_title') {
        this.setState((state) => {
            return { input_data: {...state.input_data, [key]: prospect_value}}
        })
    }

    onInputReceived(input: EMAIL_INPUT, data: any, prospect_identifier: string) {
        this.onProspectIdentifierReceived(prospect_identifier)
        switch(input) {
            case EMAIL_INPUT.LINKEDIN: this.onAgentInfoUpdate(AGENT_TYPE.LINKEDIN, data); break
            case EMAIL_INPUT.WEBSITE: this.onAgentInfoUpdate(AGENT_TYPE.COMPANY_WEBSITE, data); break
            case EMAIL_INPUT.TRANSCRIPTS: this.onNumberInfoUpdate(data); break
            case EMAIL_INPUT.PROSPECT_NAME: this.onProspectMetadataUpdate(data, 'prospect_name'); break
            case EMAIL_INPUT.PROSPECT_COMPANY: this.onProspectMetadataUpdate(data, 'prospect_company'); break
            case EMAIL_INPUT.PROSPECT_TITLE: this.onProspectMetadataUpdate(data, 'prospect_title'); break
        }
    }

    componentDidUpdate(prevProps: Readonly<EmbeddedAutomatorProps>, prevState: Readonly<EmailState>, snapshot?: any): void {
        if (this.state.input_data.number_info.numbers.length > 0 &&  
            !this.compareNumbersArray((prevState.input_data.number_info.numbers ?? []), this.state.input_data.number_info.numbers)) {
            this.fetchNumbers(this.state.input_data.number_info.numbers)
        }

        for (const agent_type of Object.values(AGENT_TYPE)) {
            if (this.state.input_data.agent_data[agent_type].url &&
                ((
                    this.state.input_data.agent_data[agent_type].url !== prevState.input_data.agent_data[agent_type].url && this.state.input_data.agent_data[agent_type].url
                ) || 
                (
                    this.state.input_data.agent_data[agent_type].url === prevState.input_data.agent_data[agent_type].url && 
                    this.state.input_data.agent_data[agent_type].status === RequestState.REQUESTED_BY_USER && 
                    (agent_type !== AGENT_TYPE.LINKEDIN || this.state.input_data.agent_data[agent_type].scraped_data) &&
                    this.state.input_data.agent_data[agent_type].status !== prevState.input_data.agent_data[agent_type].status
                ) || 
                (
                    this.state.input_data.agent_data[agent_type].url === prevState.input_data.agent_data[agent_type].url &&
                    !this.state.input_data.agent_data[agent_type].info && this.state.input_data.agent_data[agent_type].scraped_data &&
                    !prevState.input_data.agent_data[agent_type].scraped_data 
                )
                )
                )  {
                    const targetUrl = this.state.input_data.agent_data[agent_type].url
                    const scrapedData = this.state.input_data.agent_data[agent_type].scraped_data ?? null
                    const checkCache = agent_type === AGENT_TYPE.LINKEDIN ? scrapedData ? false : true : false
                    if (targetUrl) this.getScrapedData(targetUrl, scrapedData, checkCache, agent_type)
                }
        }

        if (this.state.email_requested_for_prospect_id && this.state.email_requested_for_prospect_id !== prevState.email_requested_for_prospect_id && this.state.email_requested_for_prospect_id === this.state.latest_prospect_id) {
            this.generateEmail()
        }

        if (this.state.latest_prospect_id && 
            this.state.conversational_histories[this.state.latest_prospect_id] &&
            !this._compareConversationHistories(this.state.conversational_histories[this.state.latest_prospect_id] ?? [], prevState.conversational_histories[this.state.latest_prospect_id] ?? []) && 
            this.state.generated_email[this.state.latest_prospect_id]?.gen_email
            ) {
            // check if all prior conversations have either been generated or returned...
            const previous_conversations = prevState.conversational_histories[this.state.latest_prospect_id] ?? []
            const latest_prospect_id = this.state.latest_prospect_id
            if (previous_conversations.some((v) => !(v.uuid in this.state.conversational_generated_emails[latest_prospect_id] ?? {}))) return  // check all previous conversations have been generated
            const emails_generated = [this.state.generated_email[this.state.latest_prospect_id]?.gen_email, ...previous_conversations.map((x) => this.state.conversational_generated_emails[latest_prospect_id][x.uuid]?.gen_email)].filter((v) => v)
            const last_email = emails_generated[emails_generated.length - 1] as string
            const current_conversation = this.state.conversational_histories[this.state.latest_prospect_id]
            this.generateRevisionEmail(last_email, current_conversation[current_conversation.length - 1])
        }
    }

    _compareConversationHistories(historyA: CustomEditInfo[], historyB: CustomEditInfo[]): boolean {
        if (historyA.length !== historyB.length) return false
        if (historyA.map((v) => v.uuid).sort().join('') !== historyB.map((v) => v.uuid).sort().join('')) return false
        return true
    }

    async generateEmail() {
        if (!this.props.email_value_props || !this.state.latest_prospect_id) return
        if (this.state.generated_email[this.state.latest_prospect_id]) return

        const generated_for_prospect_id = this.state.latest_prospect_id
        const response = await getServicesManager().generateProspectEmail({
            prospect_id: this.state.latest_prospect_id,
            previous_session_ids: this.state.input_data.number_info.summaries ? this.state.input_data.number_info.summaries.map((summary) => summary.sessionId) : [],
            selected_session_ids: this.state.input_data.number_info.summaries ? this.state.input_data.number_info.summaries.filter((x) => x.sessionMetric?.targetDuration || x.sessionMetric?.gatekeeperDuration).map((v) => v.sessionId) : [],
            value_prop: this.props.email_value_props[0],
            prospect_info: {
                'prospect_name': this.state.input_data.prospect_name,
                'prospect_company': this.state.input_data.prospect_company,
                'prospect_title': this.state.input_data.prospect_title,
                'website_summary': this.state.input_data.agent_data.COMPANY_WEBSITE.info?.summary ?? null,
                'linkedin_summary': this.state.input_data.agent_data.LINKEDIN.info?.summary ?? null,
            }
        })
        const email = response?.gen_email
        if (!email) return
        this.setState((state) => {
            return {generated_email: {...state.generated_email, [generated_for_prospect_id]: response}}
        })
    }

    async generateRevisionEmail(previous_email: string, custom_edit_info: CustomEditInfo) {
        if (!this.props.email_value_props || !this.state.latest_prospect_id) return
        const generated_for_prospect_id = this.state.latest_prospect_id
        const emailRequest: EmailGenRequest = {
            prospect_id: this.state.latest_prospect_id,
            value_prop: this.props.email_value_props[0],
            prospect_info: {
                'prospect_name': this.state.input_data.prospect_name,
                'prospect_company': this.state.input_data.prospect_company,
                'prospect_title': this.state.input_data.prospect_title,
                'website_summary': this.state.input_data.agent_data.COMPANY_WEBSITE.info?.summary ?? null,
                'linkedin_summary': this.state.input_data.agent_data.LINKEDIN.info?.summary ?? null,
            },
            previous_session_ids: this.state.input_data.number_info.summaries ? this.state.input_data.number_info.summaries.map((summary) => summary.sessionId) : [],
            selected_session_ids: this.state.input_data.number_info.summaries ? this.state.input_data.number_info.summaries.filter((x) => x.sessionMetric?.targetDuration || x.sessionMetric?.gatekeeperDuration).map((v) => v.sessionId) : [],
        }
        const response = await getServicesManager().generateRevisionEmail(emailRequest, previous_email, custom_edit_info)
        this.setState((state) => {
            const generated_emails = state.conversational_generated_emails[generated_for_prospect_id] ?? {}
            return {
                conversational_generated_emails: {
                    ...state.conversational_generated_emails,
                    [generated_for_prospect_id]: {
                        ...generated_emails,
                        [custom_edit_info.uuid]: response
                    }
                }
            }
        })
    }


    async getScrapedData(url: string, scraped_data: string | null, checkCache: boolean, agent_type: AGENT_TYPE) {
        const info = await getServicesManager().getScrapedSummarizedData(url, scraped_data, checkCache, agent_type, this.state.apiKey)
        const status = info?.summary || info?.custom_json ? RequestState.RECEIVED : agent_type === AGENT_TYPE.COMPANY_WEBSITE ? RequestState.ERROR : RequestState.TO_BE_REQUESTED_BY_USER
        const has_data = info?.summary != null || info?.custom_json != null
        sendMessageToTop({
            'type': MESSAGE_TYPES.EXTERNAL_TO_PRE_CALL_HAS_DATA_ALREADY, 
            'agent_type': agent_type, 
            'has_data': has_data,
            'url': url,
            'status': status
        })
        this.passMessageToWeb('RESEARCH_LOOKUP', {
            type: MESSAGE_TYPES.EXTERNAL_TO_PRE_CALL_HAS_DATA_ALREADY,
            agent_type: agent_type,
            has_data: has_data,
            url: url,
            status: status
        })
        this.setState((state) => {
            if (state.input_data.agent_data[agent_type].url !== url) return null
            return { input_data: {...state.input_data, agent_data: {...state.input_data.agent_data, [agent_type]: {url: url, status: status, info: info, has_data: has_data}}}}
        })
    }

    async fetchNumbers(numbers: string[], forceRefresh?: boolean) {
        const data = await getServicesManager().getPreviousCallSummaries({
            phone_numbers: numbers,
            remarks: null,
            start: null,
            end: null,
            has_star: null,
            user_ids: null,
            min_duration: null,
            dispositions: null,
            review_is_open: null,
            prompt_types: null,
            counterparts: null,
            cadence_steps: null,
            cadences: null,
            industries: null,
            remark_cnf: null,
            seniorities: null,
            text_cnf: null,
            stages: null,
            titles: null,
        }, this.state.apiKey, forceRefresh)
        const numberTranscriptsFound = data == null ? 0: data.length
        sendMessageToTop({
                'type': MESSAGE_TYPES.EXTERNAL_TO_PRE_CALL_HAS_TRANSCRIPT, 
                'numbers': numbers,
                'numberFound': numberTranscriptsFound,
                'summaries': data,
        })
        this.passMessageToWeb('TRANSCRIPT_LOOKUP', {
            type: MESSAGE_TYPES.EXTERNAL_TO_PRE_CALL_HAS_TRANSCRIPT,
            numbers: numbers,
            numberFound: numberTranscriptsFound,
            summaries: data
        })
        this.setState((state) => {
            if (!this.compareNumbersArray(state.input_data.number_info.numbers, numbers)) return null
            return { input_data: {...state.input_data, number_info: {...state.input_data.number_info, transcripts_found: numberTranscriptsFound, summaries: data}} }
        })
    }

    renderNoDataDetected() {
        return <div className="p-4 flex-grow flex gap-3 justify-center items-center text-center">
            <Typography className="" variant="largeParagraph">
                {this.state.latest_prospect_id ? 'Fetching information': 'No prospect detected'}
            </Typography>
        </div>
    }

    _getCurrentView() {
        if (this.state.current_view === PageView.TRANSCRIPT_VIEW && this.state.selected_transcript !== null && this.state.input_data.number_info.summaries?.find((v) => v.sessionId === this.state.selected_transcript)) return PageView.TRANSCRIPT_VIEW
        else if (this.state.current_view === PageView.EMAIL_GENERATION_VIEW && this.state.latest_prospect_id === this.state.email_requested_for_prospect_id) return PageView.EMAIL_GENERATION_VIEW
        return PageView.PROSPECT_DATA_VIEW
    }

    _hasAnyData() {
        return this.state.input_data.number_info.numbers.length > 0 || this.state.input_data.agent_data.COMPANY_WEBSITE.url || this.state.input_data.agent_data.LINKEDIN.url
    }


    _canRequestConversationalHistory(original_email: AIProspectEmail | null, 
        conversational_history: CustomEditInfo[], 
        generated_emails: {[uuid in string]: AIProspectEmail | null}) {
        if (!original_email) return false
        if (conversational_history.some((v) => !(v.uuid in generated_emails))) return false
        if (conversational_history.length >= 3) return false // cannot request more than 3 revisions
        return true
    }

    _onRequestConversationHistoryUpdate(conversational_edit: CustomEditInfo) {
        if (!this.state.latest_prospect_id) return
        if (!this.state.generated_email[this.state.latest_prospect_id]) return
        this.setState((state) => {
            if (!state.latest_prospect_id) return null
            const original_email = state.generated_email[state.latest_prospect_id]
            const conversational_histories = state.conversational_histories[state.latest_prospect_id] ?? []
            const generated_emails = state.conversational_generated_emails[state.latest_prospect_id] ?? {}
            if (!this._canRequestConversationalHistory(original_email, conversational_histories, generated_emails)) return null
            return {
                conversational_histories: {
                    ...state.conversational_histories,
                    [state.latest_prospect_id]: [...conversational_histories, conversational_edit]
                }
            }
        })
    }

    _renderInnerBody() {
        if (!this._hasAnyData()) return this.renderNoDataDetected()
        const conversational_history = this.state.latest_prospect_id ? this.state.conversational_generated_emails[this.state.latest_prospect_id] ?? {} : {}
        const formatted_conversational_history: {[uuid: string]: string | null} = conversational_history ? Object.fromEntries(Object.entries(conversational_history).map(((v) => { return [v[0], v[1] ? v[1].gen_email : null]}))) : {}
        switch(this._getCurrentView()) {
            case PageView.PROSPECT_DATA_VIEW: return <ProspectOverviewPage 
            onEmailRequest={() => {
                this.setState((state) => { return {
                    'email_requested_for_prospect_id': state.latest_prospect_id,
                    'current_view': PageView.EMAIL_GENERATION_VIEW
                }
                })}
            }
            onSectionUpdate={(section) => this.setState({selected_section: section})}
            selected_section={this.state.selected_section}
            onTranscriptSelect={(transcript_id: string) => this.setState({selected_transcript: transcript_id, current_view: PageView.TRANSCRIPT_VIEW})}
            data={this.state.input_data}
             />
            case PageView.TRANSCRIPT_VIEW: return <EmbeddedTranscriptViewer
            onSessionSelect={(session_id) => this.setState({selected_transcript: session_id})} 
            onGoBack={() => this.setState({current_view: PageView.PROSPECT_DATA_VIEW})}
            sessionOptions={this.state.input_data.number_info.summaries}
            selectedSessionId={this.state.selected_transcript} />
            case PageView.EMAIL_GENERATION_VIEW: return <ChatGPTGenerationPage
            conversational_history={this.state.latest_prospect_id ? this.state.conversational_histories[this.state.latest_prospect_id] ?? [] : []}
            conversational_history_emails={formatted_conversational_history}
            request_conversational_history_update={(conversational_edit: CustomEditInfo) => this._onRequestConversationHistoryUpdate(conversational_edit)}
            can_request_conversational_history={this.state.latest_prospect_id ? this._canRequestConversationalHistory(this.state.generated_email[this.state.latest_prospect_id], this.state.conversational_histories[this.state.latest_prospect_id] ?? [], this.state.conversational_generated_emails[this.state.latest_prospect_id] ?? {}) : false}
            original_email={this.state.latest_prospect_id ? this.state.generated_email[this.state.latest_prospect_id]?.gen_email ?? null : null}
            onGoBack={() => this.setState({current_view: PageView.PROSPECT_DATA_VIEW})}
        />
        }
    }
    render() {
        return <div className="w-screen h-screen">
            {this._renderInnerBody()}
        </div>
    }
}

function getPriority(value_prop: EmailValueProp) {
    if (value_prop.user_id) return -1
    if (value_prop.team_id) return 0
    else return 1
}

const mapStateToProps = (state: RootState) => {
    let value_props = state.emailValueProps.value
    if (value_props) value_props = Array.from(value_props).sort((a, b) => getPriority(a) - getPriority(b))
    return {
      email_value_props: value_props
    };
  };
  
  const ReduxWrapped = connect(mapStateToProps)(EmbeddedAutomatorImpl)
  
  export { ReduxWrapped as EmbeddedAutomator}