import { Typography, TypographyVariant } from 'interfaces/typography';
import React, { useState, useRef, useEffect } from 'react';

interface Props {
  text: string;
  maxLines: number;
  variant: TypographyVariant,
  color: string
  noCursor?: boolean
}

export const TextBlock: React.FC<Props> = ({ text, maxLines, variant, color, noCursor }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <div onClick={toggleText} style={{}}>
    <Typography variant={variant} color={color} style={{
            'cursor': noCursor ? 'initial' : 'pointer',
            'display': '-webkit-box',
            'WebkitLineClamp': isExpanded ? 'initial' : maxLines,
            'WebkitBoxOrient': 'vertical',
            'overflow': 'hidden'}}>
        {text.split(/\r?\n/).map((value: string, idx: number) => {
          return <React.Fragment key={idx}>{value}<br style={{
            display: 'block',
            content: '',
            marginTop: '4px',
          }}/></React.Fragment>;
        })}
      </Typography>

      </div>
    </div>
  );
};

