
import { ATTYX_TEAM_ID, MS_TO_SEC, SEC_TO_MS } from 'cfg/const';
import { prospectPartyCode, repPartyCode } from 'cfg/endpoints';
import { AudioPlayer, AudioPlayerDataFetcher } from 'components/AudioPlayer';
import { Loader } from 'components/Loader';
import { PromptType } from 'interfaces/consts';
import { Counterpart, COUNTERPART_TO_HUMAN_READABLE, PartyRole, Platform, PLATFORM_TO_HUMAN_READABLE, Remark, REMARK_TO_HUMAN_READABLE, ExternalAccount, CustomMetric } from 'interfaces/db';
import { CommentDataResult, ProspectInfo, SessionDataResult, SpeechTurn, UserDataResult } from 'interfaces/services';
import { Typography } from 'interfaces/typography';
import { connect } from "react-redux";

import React, { Dispatch, RefObject } from 'react'
import { getServicesManager } from 'services';
import { durationToString } from 'time';
import { WidgetType, withWidget } from '../Widget/Widget';
import './height.css'
import { Debouncer } from './Debouncer';
import { ReviewModeToIcon, TranscriptReviewMode, ViewSelectorBar } from './TranscriptSideBar';
import { Comment, CommentFunctionProps } from 'components/Comment';
import { SessionStatus } from 'components/SessionStatus/SessionStatus'
import { Action, AnyAction } from "@reduxjs/toolkit";
import { convertFromReduxSafeUserState, updateForceRefresh, updateSessionListParams } from 'lib/redux/store';
import { Automation, RequestState } from '../Automation';
import { TextBlock } from 'components/TextBlock';
import './switch.css'
import { Option } from 'components/FilterSelector';
import salesloft from './Salesloft.png'
import freshsales from './freshsales.png'
import bloobirds from './Bloobirds.jpg'
import { RootState } from 'store';
import { TeamIdToMetricType, TeamIdToTeamView } from 'cfg/score';
import { Score } from '../Score';
import { PlusButton } from 'lib/elements';

const transcriptDefault =
<svg width="184" height="136" viewBox="0 0 184 136" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.5342 10C22.5342 4.47715 27.0113 0 32.5342 0H173.956C179.479 0 183.956 4.47715 183.956 10V58.8108H32.5342C27.0113 58.8108 22.5342 54.3337 22.5342 48.8108V10Z" fill="#B7DEC0"/>
<line x1="31.8926" y1="10.8652" x2="174.598" y2="10.8652" stroke="#929292" strokeWidth="4" strokeLinecap="round"/>
<line x1="31.8926" y1="21.8926" x2="174.598" y2="21.8926" stroke="#929292" strokeWidth="4" strokeLinecap="round"/>
<line x1="31.8926" y1="32.918" x2="174.598" y2="32.918" stroke="#929292" strokeWidth="4" strokeLinecap="round"/>
<line x1="31.8926" y1="43.9453" x2="119.871" y2="43.9453" stroke="#929292" strokeWidth="4" strokeLinecap="round"/>
<line x1="168.48" y1="64.1621" x2="181.037" y2="64.1621" stroke="#535353" strokeWidth="4" strokeLinecap="round"/>
<path d="M0 85.8106C0 80.2877 4.47715 75.8105 10 75.8105H151.422C156.945 75.8105 161.422 80.2877 161.422 85.8105V110.838C161.422 116.36 156.945 120.838 151.422 120.838H0V85.8106Z" fill="#D8DBE1"/>
<line x1="9.3584" y1="88.5137" x2="152.064" y2="88.5137" stroke="#929292" strokeWidth="4" strokeLinecap="round"/>
<line x1="9.3584" y1="99.541" x2="152.064" y2="99.541" stroke="#929292" strokeWidth="4" strokeLinecap="round"/>
<line x1="9.3584" y1="110.566" x2="97.3366" y2="110.566" stroke="#929292" strokeWidth="4" strokeLinecap="round"/>
<line x1="2" y1="126.189" x2="14.5561" y2="126.189" stroke="#535353" strokeWidth="4" strokeLinecap="round"/>
</svg>

const popoutSvg = 
<svg width="" height="" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M81.7 3.5V2.8L81.5 2.4L81.3 2.1L80.8 1.5L80 0.9L79.7 0.7L79.3 0.5H56.1C55.5747 0.5 55.0546 0.603463 54.5693 0.804482C54.084 1.0055 53.643 1.30014 53.2716 1.67157C52.9002 2.04301 52.6055 2.48396 52.4045 2.96927C52.2035 3.45457 52.1 3.97471 52.1 4.5C52.1 5.02529 52.2035 5.54543 52.4045 6.03073C52.6055 6.51604 52.9002 6.95699 53.2716 7.32843C53.643 7.69986 54.084 7.9945 54.5693 8.19552C55.0546 8.39654 55.5747 8.5 56.1 8.5H68.1L32.2 44.1C31.8258 44.4743 31.5289 44.9186 31.3263 45.4076C31.1238 45.8966 31.0195 46.4207 31.0195 46.95C31.0195 47.4793 31.1238 48.0034 31.3263 48.4924C31.5289 48.9814 31.8258 49.4257 32.2 49.8C32.5743 50.1743 33.0186 50.4712 33.5076 50.6737C33.9966 50.8763 34.5207 50.9805 35.05 50.9805C35.5793 50.9805 36.1034 50.8763 36.5924 50.6737C37.0814 50.4712 37.5258 50.1743 37.9 49.8L73.8 13.9V25.9C73.8 26.9609 74.2215 27.9783 74.9716 28.7284C75.7218 29.4786 76.7392 29.9 77.8 29.9C78.8609 29.9 79.8783 29.4786 80.6285 28.7284C81.3786 27.9783 81.8 26.9609 81.8 25.9V4.2C81.7874 3.96417 81.754 3.72992 81.7 3.5Z" fill="black"/>
<path d="M12.1992 81.8004H59.5992C62.7818 81.8004 65.8341 80.5361 68.0845 78.2857C70.3349 76.0352 71.5992 72.983 71.5992 69.8004V41.5004C71.5992 40.4395 71.1778 39.4221 70.4277 38.672C69.6775 37.9218 68.6601 37.5004 67.5992 37.5004C66.5384 37.5004 65.5209 37.9218 64.7708 38.672C64.0207 39.4221 63.5992 40.4395 63.5992 41.5004V69.8004C63.5992 70.8613 63.1778 71.8787 62.4276 72.6288C61.6775 73.379 60.6601 73.8004 59.5992 73.8004H12.1992C11.1384 73.8004 10.1209 73.379 9.37079 72.6288C8.62065 71.8787 8.19922 70.8613 8.19922 69.8004V22.4004C8.19922 21.3395 8.62065 20.3221 9.37079 19.572C10.1209 18.8218 11.1384 18.4004 12.1992 18.4004H40.4992C41.5601 18.4004 42.5775 17.979 43.3276 17.2288C44.0778 16.4787 44.4992 15.4613 44.4992 14.4004C44.4992 13.3395 44.0778 12.3221 43.3276 11.572C42.5775 10.8218 41.5601 10.4004 40.4992 10.4004H12.1992C9.01662 10.4004 5.96437 11.6647 3.71394 13.9151C1.4635 16.1655 0.199219 19.2178 0.199219 22.4004V69.8004C0.199219 72.983 1.4635 76.0352 3.71394 78.2857C5.96437 80.5361 9.01662 81.8004 12.1992 81.8004Z" fill="black"/>
</svg>

const outreachSvg = <svg width="" height="" viewBox="0 0 300 282" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M133.47 0.109375C179.26 0.109375 248.47 14.2094 280.66 46.5494C288.82 54.7494 299.5 70.8094 299.5 94.3294C299.5 136.149 265.94 200.329 228.89 237.519C209.73 256.749 179.26 281.889 138.27 281.889C98.21 281.889 67.6 254.569 58.75 245.669C24.81 211.589 0.5 159.869 0.5 111.639C0.5 81.2094 9.14 54.6394 31.18 32.4694C51.6 11.9994 86.77 0.109375 133.47 0.109375ZM190.15 166.879C205.61 151.349 209.77 132.219 209.77 121.689C209.838 116.435 208.855 111.219 206.88 106.349C204.906 101.479 201.978 97.0529 198.27 93.3294C183.69 78.6894 167.54 74.5494 150 74.5494C127.75 74.5494 112.8 81.6194 102.84 91.6194C95.03 99.4594 88.28 111.519 88.28 128.619C88.28 145.719 97.37 162.269 107.28 172.209C116.43 181.399 130.81 188.209 145.28 188.209C159.75 188.209 174.69 182.409 190.15 166.879Z" fill="#5952FF"/>
</svg>

const hubspotSvg = <svg width="" height="" viewBox="0 0 528 553" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M405.512 182.564V116.799C414.203 112.737 421.561 106.29 426.731 98.2088C431.9 90.1276 434.668 80.7443 434.712 71.1511V69.6422C434.712 41.6719 412.038 18.9975 384.07 18.9975H382.559C354.589 18.9975 331.914 41.6719 331.914 69.6422V71.1511C331.958 80.7443 334.726 90.1276 339.896 98.2088C345.065 106.29 352.424 112.737 361.115 116.799V182.564C336.136 186.389 312.612 196.747 292.924 212.587L112.559 72.1006C113.845 67.4579 114.516 62.6836 114.591 57.8915C114.613 46.6047 111.288 35.5648 105.034 26.1684C98.7813 16.772 89.8819 9.44115 79.4619 5.10322C69.0419 0.765288 57.5695 -0.384873 46.4957 1.79822C35.422 3.98131 25.2444 9.39958 17.2505 17.3677C9.2565 25.3357 3.80526 35.4957 1.58626 46.5623C-0.632737 57.6289 0.480211 69.105 4.78433 79.539C9.08844 89.973 16.3904 98.8961 25.7664 105.18C35.1425 111.463 46.1715 114.825 57.4584 114.839C67.3324 114.793 77.0214 112.157 85.5579 107.194L263.166 245.401C230.513 294.728 231.387 359.016 265.366 407.44L211.347 461.475C206.979 460.08 202.432 459.338 197.847 459.275C171.976 459.298 151.018 480.278 151.025 506.149C151.031 532.017 172.003 552.986 197.871 552.993C223.742 553 244.722 532.042 244.745 506.171C244.684 501.587 243.942 497.038 242.543 492.673L295.982 439.213C343.732 475.972 409.312 479.139 460.382 447.152C511.451 415.164 537.223 354.774 524.987 295.771C512.75 236.766 465.09 191.609 405.512 182.564ZM383.361 398.861C373.488 399.127 363.663 397.412 354.464 393.817C345.265 390.223 336.879 384.821 329.803 377.932C322.726 371.043 317.101 362.806 313.26 353.707C309.419 344.609 307.441 334.832 307.441 324.956C307.441 315.08 309.419 305.304 313.26 296.205C317.101 287.106 322.726 278.869 329.803 271.98C336.879 265.091 345.265 259.69 354.464 256.095C363.663 252.5 373.488 250.785 383.361 251.051C423.147 252.444 454.685 285.09 454.708 324.903C454.725 364.711 423.22 397.39 383.435 398.824" fill="#FF7A59"/>
</svg>

const linkedinSvg = <svg width="" height="" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_3011_7200)">
<path fillRule="evenodd" clipRule="evenodd" d="M8 72H64C68.4183 72 72 68.4183 72 64V8C72 3.58172 68.4183 0 64 0H8C3.58172 0 0 3.58172 0 8V64C0 68.4183 3.58172 72 8 72Z" fill="#007EBB"/>
<path fillRule="evenodd" clipRule="evenodd" d="M62 62H51.3156V43.8021C51.3156 38.8128 49.4198 36.0245 45.4707 36.0245C41.1746 36.0245 38.9301 38.9261 38.9301 43.8021V62H28.6333V27.3333H38.9301V32.0029C38.9301 32.0029 42.026 26.2742 49.3826 26.2742C56.7357 26.2742 62 30.7645 62 40.0512V62ZM16.3493 22.794C12.8421 22.794 10 19.9297 10 16.397C10 12.8644 12.8421 10 16.3493 10C19.8566 10 22.697 12.8644 22.697 16.397C22.697 19.9297 19.8566 22.794 16.3493 22.794ZM11.0326 62H21.7694V27.3333H11.0326V62Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_3011_7200">
<rect width="72" height="72" fill="white"/>
</clipPath>
</defs>
</svg>

const emailSvg = <svg width="" height="" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M702.282 128.445H97.7183C43.8388 128.445 0 172.284 0 226.164V573.835C0 627.715 43.8388 671.554 97.7183 671.554H702.282C756.161 671.554 800 627.715 800 573.835V226.174C800.011 172.284 756.172 128.445 702.282 128.445ZM654.533 192.195L400 356.182L145.467 192.195H654.533ZM702.282 607.814H97.7183C78.9864 607.814 63.7501 592.567 63.7501 573.846V227.98L380.333 431.556C380.758 431.821 381.215 432.002 381.651 432.246C382.107 432.501 382.575 432.746 383.042 432.98C385.497 434.244 388.026 435.264 390.607 435.933C390.873 436.008 391.139 436.04 391.404 436.103C394.241 436.773 397.11 437.187 399.979 437.187H400C400.021 437.187 400.042 437.187 400.042 437.187C402.911 437.187 405.78 436.783 408.617 436.103C408.883 436.04 409.148 436.008 409.414 435.933C411.996 435.264 414.514 434.244 416.979 432.98C417.446 432.746 417.914 432.501 418.371 432.246C418.806 432.002 419.263 431.821 419.688 431.556L736.271 227.98V573.835C736.26 592.567 721.014 607.814 702.282 607.814Z" fill="black"/>
</svg>

type EmailProps = {
  emailText: string | null,
  emailRequestState: RequestState,
  requestEmailText: () => void
  isCoaching?: boolean
}

type EmailPopupState = {
  showPopup: boolean
}

class EmailPopup extends React.Component<EmailProps, EmailPopupState> {
  
  constructor(props: EmailProps) {
    super(props)
    this.state = {
      showPopup: false,
    }
  }

  renderRequestEmail() {
    const requestedState = [RequestState.PENDING, RequestState.REQUESTED_BY_USER, RequestState.RECEIVED].includes(this.props.emailRequestState)
    return <div className='w-full flex justify-center items-center text-center'>
    <div onClick={this.props.requestEmailText.bind(this)} 
    className='w-full p-2' style={{
      'cursor': requestedState ? 'initial' : 'pointer',
      'borderRadius': '10px',
      'boxShadow': 'rgba(0, 0, 0, 0.1) 0px 3px 3px',
      'backgroundColor': requestedState ? 'darkgrey' : 'rgb(3, 33, 78)',
    }}>
        <Typography variant='largeParagraph' color='white'>
            {requestedState ? 'Requesting...' : 'Request email'}
        </Typography> 
    </div>
    </div>
  }

  renderEmailText(emailText: string): JSX.Element[] {
    return emailText.split(/\r?\n/).filter((v) => v !== '').map((value, idx: number, arr: string[]) => {
      return ( 
        <Typography variant='mediumParagraph'>
          {value}
        </Typography>)
        })
      }

  renderEmail(emailText: string): JSX.Element {
    return <div className='flex flex-col gap-2'>
      {this.renderEmailText(emailText)}
    </div>
  }

  hideEmailPopup() {
    this.setState({showPopup: false})
  }
  
  render() {
    return (<div className='relative'>
      <div onClick={(e) => {
        e.stopPropagation()
        this.setState((state) => {return {'showPopup': !state.showPopup}})
      }} className='cursor-pointer flex w-6 h-6 p-1.5 bg-white hover:bg-slate-100' style={{
        'backgroundColor': this.state.showPopup ? 'rgb(241 245 249)' : 'initial',
        'borderRadius': '10px',
        'boxShadow': 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        'userSelect': 'none',
        }}>
        {emailSvg}
      </div>
      {this.state.showPopup ? <div onClick={(e) => {e.stopPropagation()}} 
      className='absolute right-0' style={{'zIndex': '999999999999999999'}}>
      <div className='bg-white p-4 overflow-y-auto' style={{
            'width': this.props.isCoaching ? '70vw' : 'min(50vw, calc(100vw - 350px))',
            'height': 'fit-content',
            'maxHeight': '40vh',
            "boxShadow": "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            "borderRadius": "10px",
            "marginTop": "8px"
      }}>
        {this.props.emailText ? this.renderEmail(this.props.emailText) : this.renderRequestEmail()}
      </div>
      </div> : null}
    </div>)
  }
}


export type Transcript = {
    'personName': string
    'partyCode': number
    'start': number // in milliseconds
    'end'?: number // in milliseconds, only specified sometimes
    'content': string // transcript content 
    'behavioralDisplay'?: string
    'remarkDisplay'?: string
    'triggerDisplay'?: string
  }


export type TranscriptProps<A extends Action = AnyAction> = {
  additionalActionElement?: JSX.Element,
  sessionActive: boolean
  overviewAutoplay?: boolean,
  autoplay?: boolean,
  updateAutoplayState?: () => void
  showLoaderAsDefault: boolean,
  noLoaderEvenOnActive: boolean
  reviewMode: boolean,
  transcripts?: Transcript[]
  audioDetails?: {
    dataFetcher?: boolean
    data: SessionDataResult | null
    sessionId?: string,
    isInView?: boolean,
  }
  isMainView?: boolean,
  isPureTranscriptView?: boolean,
  counterpartyFilters?: CounterpartInterval[],
  remarkFilters?: RemarkOption[],
  behavioralFilters?: PromptTypeOption[],
  triggerFilters?: ReviewOption[],
  commentFilters?: CommentDataResult[],
  selectedTranscriptIdx?: number,
  updateSelectedTranscript?: (selectedIdx: number | undefined, callback?: () => void) => void
  viewMode?: TranscriptReviewMode
  updateViewMode?: (selectedViewMode: TranscriptReviewMode) => void
  commentFns?: CommentFunctionProps
  copyTranscriptOnClick?: () => void
  viewerUserId?: string
  dispatch?: Dispatch<A>;
  minimalView?: boolean
  pageClosed?: boolean
  prospectInfo?: ProspectInfo
  hideLabels?: boolean,
  externalAccountsById?: Map<string, ExternalAccount>
  customMetric?: CustomMetric,
  user: UserDataResult | null
  isAdmin: boolean | null
}

export type PromptTypeOption = {
  'uniqueIdentifier'?: string,
  'transcriptIdx': number,
  'displayText': string
  'prompts': PromptType[]
}

export type ReviewOption = {
  'uniqueIdentifier'?: string,
  'transcriptIdx': number,
  'displayText': string
}

export type RemarkOption = {
  'transcriptIdx': number
  'remarks': Remark[]
}

export type CounterpartInterval = {
  start: number
  end: number 
  counterpart: Counterpart
}

type TranscriptState = {
  play: boolean, 
  previousPlayState: boolean,
  currentTimeInMs: number,
  
  aiSummaryText?: string,
  aiSummaryRequestState: RequestState,
  aiSummarySessionId?: string,

  aiCoachingText?: string,
  aiCoachingRequestState: RequestState,
  aiCoachingVisible?: boolean
  aiCoachingSessionId?: string,

  aiEmailText?: string,
  aiEmailRequestState: RequestState,
  aiEmailSessionId?: string,

  customMetric?: CustomMetric,
  customMetricRequestState: RequestState,
  customMetricSessionId?: string,

  isOverflow: boolean,
  showPreviousCalls?: boolean,
  prevSelectedTranscriptIdx?: number,
}

const aiDefault = <svg width="100%" height="100%" viewBox="0 0 80 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.7389 51.3681V50.8681H15.2389H9.35628C7.48486 50.8681 5.93457 49.2902 5.93457 47.3162V23.005C5.93457 21.031 7.48486 19.4531 9.35628 19.4531H40.73C42.6014 19.4531 44.1517 21.031 44.1517 23.005V47.3162C44.1517 49.2902 42.6014 50.8681 40.73 50.8681H27.5472H27.4047L27.2837 50.9432L15.7389 58.1015V51.3681ZM40.73 47.8162H41.23V47.3162V23.005V22.505H40.73H9.35628H8.85628V23.005V47.3162V47.8162H9.35628H18.6606V51.8401V52.7382L19.4239 52.2652L26.6032 47.8162H40.73Z" fill="#5B657C" stroke="white"/>
<path d="M15.4883 29.3359H34.5969V32.8878H15.4883V29.3359ZM15.4883 37.4397H28.7143V40.9916H15.4883V37.4397Z" fill="#5B657C" stroke="white" strokeWidth="0.5"/>
<rect x="2.39111" y="16.9766" width="10.5217" height="11.8591" fill="white"/>
<path d="M7.17391 28.3415C10.3304 28.3415 12.913 25.6732 12.913 22.412C12.913 19.1507 10.3304 16.4824 7.17391 16.4824C4.01741 16.4824 1.43481 19.1507 1.43481 22.412C1.43481 25.6732 4.01741 28.3415 7.17391 28.3415ZM7.17391 15C11.1195 15 14.3478 18.3354 14.3478 22.412C14.3478 26.4885 11.1195 29.8239 7.17391 29.8239C3.22828 29.8239 4.1008e-05 26.4885 4.1008e-05 22.412C4.1008e-05 18.3354 3.22828 15 7.17391 15ZM10.7608 23.8202L10.2587 24.7838L6.45652 22.6343V18.706H7.5326V21.9672L10.7608 23.8202Z" fill="#5B657C"/>
<path d="M64.913 36.3681V35.8681H65.413H71.2956C73.167 35.8681 74.7173 34.2902 74.7173 32.3162V8.005C74.7173 6.03102 73.167 4.45312 71.2956 4.45312H39.9219C38.0504 4.45312 36.5001 6.03102 36.5001 8.005V32.3162C36.5001 34.2902 38.0504 35.8681 39.9219 35.8681H53.1047H53.2471L53.3682 35.9432L64.913 43.1015V36.3681ZM39.9219 32.8162H39.4219V32.3162V8.005V7.505H39.9219H71.2956H71.7956V8.005V32.3162V32.8162H71.2956H61.9913V36.8401V37.7382L61.2279 37.2652L54.0487 32.8162H39.9219Z" fill="#5B657C" stroke="white"/>
<path d="M46.0537 14.3359H65.1623V17.8878H46.0537V14.3359ZM46.0537 22.4397H59.2797V25.9916H46.0537V22.4397Z" fill="#5B657C" stroke="white" strokeWidth="0.5"/>
<rect x="66.6089" y="1.97656" width="10.5217" height="11.8591" fill="white"/>
<path d="M72.8262 13.3415C75.9827 13.3415 78.5653 10.6732 78.5653 7.41196C78.5653 4.1507 75.9827 1.48239 72.8262 1.48239C69.6697 1.48239 67.0871 4.1507 67.0871 7.41196C67.0871 10.6732 69.6697 13.3415 72.8262 13.3415ZM72.8262 0C76.7718 0 80.0001 3.33538 80.0001 7.41196C80.0001 11.4885 76.7718 14.8239 72.8262 14.8239C68.8806 14.8239 65.6523 11.4885 65.6523 7.41196C65.6523 3.33538 68.8806 0 72.8262 0ZM76.4131 8.82023L75.911 9.78379L72.1088 7.63432V3.70598H73.1849V6.96724L76.4131 8.82023Z" fill="#5B657C"/>
</svg>

const commentPlusIcon = <svg width="100%" viewBox="0 0 650 650" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M374.687 253.267H483.155C486.152 253.267 488.582 250.477 488.582 247.031C488.582 243.584 486.152 240.785 483.155 240.785H374.687C371.69 240.785 369.264 243.584 369.264 247.031C369.264 250.477 371.69 253.267 374.687 253.267Z" fill="black"/>
<path d="M498.901 283.164H397.251C394.254 283.164 391.824 285.954 391.824 289.401C391.824 292.847 394.254 295.646 397.251 295.646H498.901C501.897 295.646 504.324 292.847 504.324 289.401C504.324 285.954 501.897 283.164 498.901 283.164Z" fill="black"/>
<path d="M469.953 325.543H374.687C371.69 325.543 369.264 328.333 369.264 331.78C369.264 335.225 371.69 338.016 374.687 338.016H469.953C472.945 338.016 475.375 335.225 475.375 331.78C475.375 328.333 472.945 325.543 469.953 325.543Z" fill="black"/>
<path d="M532.953 189H336.047C315.072 189 298 207.147 298 229.463V353.993C298 376.301 315.072 394.447 336.047 394.447H452.165L535.957 462V394.33C555.536 392.691 571 375.225 571 353.993V229.463C571 207.147 553.928 189 532.953 189ZM554.724 353.993C554.724 365.979 544.952 375.73 532.952 375.73H519.68V426.017L457.302 375.73H336.047C324.043 375.73 314.275 365.979 314.275 353.993V229.463C314.275 217.468 324.043 207.719 336.047 207.719H532.952C544.952 207.719 554.724 217.469 554.724 229.463V353.993Z" fill="black"/>
<path d="M247.15 316.65H176.35V245.85C176.35 240.983 172.368 237 167.5 23render7C162.633 237 158.65 240.983 158.65 245.85V316.65H87.85C82.9825 316.65 79 320.633 79 325.5C79 330.368 82.9825 334.35 87.85 334.35H158.65V405.15C158.65 410.018 162.633 414 167.5 414C172.368 414 176.35 410.018 176.35 405.15V334.35H247.15C252.018 334.35 256 330.368 256 325.5C256 320.633 252.018 316.65 247.15 316.65Z" fill="black"/>
<circle cx="325" cy="325" r="320" stroke="black" strokeWidth="10"/>
</svg>

type TranscriptElementProps = {
  transcript: Transcript
  idx: number
  selectedTranscriptIdx?: number
  play: boolean
  currentTimeInMs: number
  refElement: React.RefObject<HTMLDivElement>
  reviewMode?: boolean
  isLast?: boolean
  sessionActive?: boolean
  updateSelectedTranscript?: (selectedIdx: number | undefined, callback?: () => void) => void
  viewMode?: TranscriptReviewMode
  updateViewMode?: (selectedViewMode: TranscriptReviewMode) => void
  isMainView?: boolean,
  commentFns?: CommentFunctionProps
  commentOptions?: CommentDataResult[],
  hideLabels?: boolean,
}

type TranscriptElementState = {
  showCommentAdd: boolean
}

class TranscriptElement extends React.Component<TranscriptElementProps, TranscriptElementState> {

  constructor(props: TranscriptElementProps) {
    super(props)
    this.state = {
      showCommentAdd: false
    }
  }

  _constructIcon(reviewMode: TranscriptReviewMode, transcriptIdx: number, first?: boolean, last?: boolean, onClick?: () => void): JSX.Element {
    return <div onClick={() => {
      if (onClick) onClick()
      
      if (!this.props.updateSelectedTranscript) return
      else if (this.props.selectedTranscriptIdx === transcriptIdx) this.props.updateSelectedTranscript(undefined)
      else this.props.updateSelectedTranscript(transcriptIdx, () => {
        if (this.props.updateViewMode && this.props.viewMode !== reviewMode) this.props.updateViewMode(reviewMode)
      })}} 
    style={{'color': reviewMode === TranscriptReviewMode.COMMENTS ? 'white' : 'black', 
    'borderTopLeftRadius': first? '10px' : 'initial', 'borderBottomLeftRadius': first ? '10px' : 'initial', 
    'borderTopRightRadius': last? '10px' : 'initial', 'borderBottomRightRadius': last ? '10px' : 'initial', 
    'backgroundColor': reviewMode === TranscriptReviewMode.COMMENTS ?  "rgb(3, 33, 78)" : '#F5F5F5', 'width': '30px', 'boxShadow': 'rgb(0 0 0 / 10%) 0px 2px 1px, rgb(0 0 0 / 10%) 0px -2px 1px'}} 
    className={'cursor-pointer p-2 opacity-80 hover:opacity-100'}>
      {ReviewModeToIcon[reviewMode]}
    </div>
  }

  _renderIcons(transcript: Transcript, idx: number): JSX.Element | null{
    if (!this.props.reviewMode || !this.props.isMainView) return null
    const comments = this.props.commentOptions?.filter((value: CommentDataResult) => value.transcriptIdx == idx) ?? []
    if (this.props.sessionActive || (!transcript.remarkDisplay && !transcript.behavioralDisplay && !transcript.triggerDisplay && !comments && !this.state.showCommentAdd)) return null
    const isProspect = transcript.partyCode === prospectPartyCode
    const showComment = (comments.length > 0 || this.state.showCommentAdd)
    return (
    <div className='relative'>
      <div className='absolute flex flex-row h-fit opacity-90 hover:opacity-100' style={{'top': '-5px', 'right': transcript.partyCode === prospectPartyCode ? '0': 'initial'}}>
      {isProspect && showComment ? <Debouncer 
      elementToShow={this._constructIcon(TranscriptReviewMode.COMMENTS, idx, true, !transcript.behavioralDisplay && !transcript.triggerDisplay && !transcript.remarkDisplay, this.state.showCommentAdd && this.props.commentFns && comments.length == 0 ?  () => {
        if (this.props.commentFns && comments.length === 0 && this.state.showCommentAdd) {
          this.props.commentFns.addEntry(idx)
      }} : undefined)}
      stringToShow={comments.length > 0 ? comments.map((value: CommentDataResult) => value.comment).join(', ') : 'Add comment'} /> : null}
      {transcript.remarkDisplay ? <Debouncer 
      elementToShow={this._constructIcon(TranscriptReviewMode.EVENTS, idx, !isProspect ? true : !this.state.showCommentAdd && comments.length === 0, !isProspect ? !transcript.triggerDisplay && !this.state.showCommentAdd && comments.length === 0 && !transcript.behavioralDisplay : !transcript.triggerDisplay && !transcript.behavioralDisplay)}
      stringToShow={transcript.remarkDisplay} /> : null}
      {transcript.triggerDisplay ? <Debouncer 
      elementToShow={this._constructIcon(TranscriptReviewMode.TRIGGERS, idx, !isProspect ? !transcript.remarkDisplay : !transcript.remarkDisplay && !this.state.showCommentAdd && comments.length === 0, !isProspect ? !this.state.showCommentAdd &&  comments.length === 0 && !transcript.behavioralDisplay : !transcript.behavioralDisplay)}
      stringToShow={transcript.triggerDisplay} /> : null}
      {transcript.behavioralDisplay ? <Debouncer 
      elementToShow={this._constructIcon(TranscriptReviewMode.BEHAVIORAL, idx, !isProspect ? !transcript.triggerDisplay && !transcript.remarkDisplay : !this.state.showCommentAdd && comments.length === 0 && !transcript.triggerDisplay && !transcript.remarkDisplay, !isProspect ? !this.state.showCommentAdd && comments.length === 0 : true)}
      stringToShow={transcript.behavioralDisplay} /> : null}
      {!isProspect && showComment ? <Debouncer 
      elementToShow={this._constructIcon(TranscriptReviewMode.COMMENTS, idx, !transcript.remarkDisplay && !transcript.behavioralDisplay && !transcript.triggerDisplay, true, this.state.showCommentAdd && this.props.commentFns && comments.length == 0 ?  () => {
        if (this.props.commentFns && comments.length === 0 && this.state.showCommentAdd) {
          this.props.commentFns.addEntry(idx)
      }} : undefined)}
      stringToShow={comments.length > 0 ? comments.map((value: CommentDataResult) => value.comment).join(', ') : 'Add comment'} /> : null}
      </div>
    </div>)
  }

  _renderAddCommentIcon(isProspect: boolean): JSX.Element { 
    return <div className='h-full flex justify-center items-center'>
      <div className='w-10 cursor-pointer opacity-80 hover:opacity-100' style={{'paddingRight': !isProspect ? '8px' : '0px', 'paddingLeft': isProspect ? '8px': '0px'}}>
      {commentPlusIcon}
      </div>
    </div>
  }

  render() {
    const transcript = this.props.transcript
    const isProspect = transcript.partyCode === prospectPartyCode
    const justifyDirection = isProspect ? 'flex-start' : 'flex-end'
    const nameText = isProspect ? 'Them' : 'You'
    const prospectNonSelectedColor = "#f2f7fe"
    const prospectSelectedColor = "##d9e9fd"
    const repNonSelectedColor = '#e9f5ec'
    const repSelectedColor = '#e9f5ec'
    const filterSelected = this.props.idx === this.props.selectedTranscriptIdx 
    const selected = !this.props.play ? filterSelected : ((transcript.start - (1*SEC_TO_MS)) <= this.props.currentTimeInMs) 
      && transcript.end && ((transcript.end + (1*SEC_TO_MS)) >= this.props.currentTimeInMs)
    const isRefElement = (transcript.start - 3*SEC_TO_MS) < this.props.currentTimeInMs && transcript.end && (transcript.end + 3*SEC_TO_MS) > this.props.currentTimeInMs 
    const activeStyle: React.CSSProperties = {
      'backgroundColor':selected ? isProspect ? prospectSelectedColor: repSelectedColor : isProspect ? prospectNonSelectedColor : repNonSelectedColor, 
      'boxShadow': '0px 2px 6px #d6dbe0', 'borderRadius': isProspect ? '20px 20px 20px 0px' :'20px 20px 0px 20px', 'padding': '10px',
      'cursor': this.props.isMainView ? 'pointer' : 'inherit',
    }
    return (
    <div className='w-full flex flex-col gap-2' 
    onMouseOver={() => {this.setState({'showCommentAdd': true})}} onMouseOut={() => {this.setState({'showCommentAdd': false})}}
    style={{
    'justifyContent': justifyDirection, 
    'backgroundColor': selected ? '#F5F5F5' : 'initial', 
    'boxShadow': selected ? 'rgb(0 0 0 / 10%) 0px 4px 2px, rgb(0 0 0 / 10%) 0px -4px 2px' : 'initial',
    paddingTop: '4px', paddingBottom: '4px', 
    paddingLeft: '10px', 'paddingRight': '10px'}}>
      <div ref={((isRefElement && this.props.reviewMode && (this.props.play || !this.props.selectedTranscriptIdx)) || !this.props.play && filterSelected && this.props.reviewMode) ? this.props.refElement : undefined} 
        className='w-full flex items-end justify-between flex-row' style={{'justifyContent': justifyDirection}}>
        {isProspect ? null : this._renderIcons(transcript, this.props.idx)}
          <div onClick={() => {
            if (this.props.updateSelectedTranscript && this.props.isMainView) this.props.updateSelectedTranscript(this.props.idx)
          }} className='w-5/6 flex flex-col' style={activeStyle}>
            <Typography variant='mediumParagraph' color="#131414">
              {transcript.content}
            </Typography>
            {this.props.isLast && this.props.sessionActive ? 
            <div className='flex justify-center items-center pt-3 pb-3'>
              <div className='dot-flashing'></div>
            </div> : null }
          </div>
        {isProspect ? this._renderIcons(transcript, this.props.idx) : null}
        </div>
        {this.props.hideLabels && !this.props.isLast ? null : <div className='flex' style={{'justifyContent': justifyDirection}}>
          <Typography variant='caption'>
            {transcript.end ? nameText + ', ' + durationToString(transcript.start) + '-' + durationToString(transcript.end) : 
            nameText + ', ' + durationToString(transcript.start)}
          </Typography>
        </div>}
    </div>
    )
    }
}

class TranscriptBodyComponent extends React.Component<TranscriptProps, TranscriptState> {
  messagesEndRef: RefObject<HTMLDivElement> = React.createRef()
  transcriptBodyRef: RefObject<HTMLDivElement> = React.createRef()
  selectedTrackerRef: RefObject<HTMLDivElement> = React.createRef()
  trackerBodyRef: RefObject<HTMLDivElement> = React.createRef()
  audioRef: RefObject<HTMLDivElement> = React.createRef()

  constructor(props: TranscriptProps) {
    super(props)
    this.state = {
      play: false,
      previousPlayState: false,
      currentTimeInMs: 0,
      aiSummaryRequestState: RequestState.TO_BE_REQUESTED_BY_USER,
      aiCoachingRequestState: RequestState.TO_BE_REQUESTED_BY_USER,
      aiEmailRequestState: RequestState.TO_BE_REQUESTED_BY_USER,
      customMetricRequestState: RequestState.TO_BE_REQUESTED_BY_USER,
      isOverflow: true
    }
  }

  _scrollToBottom() {
    if (this.props.overviewAutoplay) this.transcriptBodyRef.current?.scrollTo({'top': this.transcriptBodyRef.current.scrollHeight, 'behavior': 'smooth'})
    else this.messagesEndRef.current?.scrollIntoView({ 'behavior': "smooth" })
  }

  componentDidMount () {
    if (!this.props.autoplay) window.scrollTo({top : 0, behavior: 'smooth'});
    else this._scrollToBottom()
  }

  _isInViewport(element: RefObject<HTMLDivElement>, body: RefObject<HTMLDivElement>): boolean {
    if (!element.current || !body.current) return true
    const rect = element.current.getBoundingClientRect();
    return (
        rect.top >= (body.current?.offsetTop ?? 0) &&
        rect.bottom <= (body.current?.clientHeight)
    )
  }

  _scrollToMatchSelectedTrigger() {
      if (!this.selectedTrackerRef.current || !this.audioRef.current || !this.transcriptBodyRef.current) return
      const yScrollPosition = this.selectedTrackerRef.current?.getBoundingClientRect().top
      const yDiff = this.audioRef.current.getBoundingClientRect().top + this.transcriptBodyRef.current!.scrollTop
      const scrollToPosition = yDiff - yScrollPosition 
      this.transcriptBodyRef.current?.scrollTo({top: scrollToPosition, behavior: 'smooth'})
  }


  _scrollToMatchHighlightedItem() {
    if (!this.selectedTrackerRef.current || !this.audioRef.current || !this.trackerBodyRef.current) return
    const yScrollPosition = this.audioRef.current?.getBoundingClientRect().top
    const yDiff = this.selectedTrackerRef.current.getBoundingClientRect().top + this.trackerBodyRef.current.scrollTop
    const scrollToPosition = yDiff - yScrollPosition 
    this.trackerBodyRef.current?.scrollTo({top: scrollToPosition, behavior: 'smooth'})
  }

  _scrollTrackerBodyIntoViewAndMatchHighlightedItem() {
    if (!this.selectedTrackerRef.current || !this.trackerBodyRef.current || !this.audioRef.current || !this.transcriptBodyRef.current) return
    this.trackerBodyRef.current?.scrollTo({top: this.selectedTrackerRef.current.offsetTop - 30, behavior: 'smooth'})
    this.transcriptBodyRef.current?.scrollTo({top: this.audioRef.current.offsetTop - 30, behavior: 'smooth'})
  }

  _scrollTranscriptBodyIntoView() {
    if (!this.transcriptBodyRef.current || !this.audioRef.current || this._isInViewport(this.audioRef, this.transcriptBodyRef)) return
    this.audioRef.current.scrollIntoView({'behavior': 'smooth', 'block': 'center', 'inline': 'center'})
  }

  _requestSummaryUpdate() {
    if (!this.props.audioDetails?.sessionId) return
    const sessionId = this.props.audioDetails.sessionId
    getServicesManager().requestTextSummary(this.props.audioDetails?.sessionId).then((value) => {
      if (sessionId !== this.props.audioDetails?.sessionId) return 
      getServicesManager().removeCachedSessionData(sessionId)
      this.setState({'aiSummaryText': value?.summary, 'aiSummaryRequestState': value != null && value?.summary !== null ? RequestState.RECEIVED : RequestState.ERROR, 'aiSummarySessionId': sessionId})
    })
  }

  _requestEmailUpdate() {
    if (!this.props.audioDetails?.sessionId) return
    const sessionId = this.props.audioDetails.sessionId
    getServicesManager().requestEmail(this.props.audioDetails?.sessionId).then((value) => {
      if (sessionId !== this.props.audioDetails?.sessionId) return 
      getServicesManager().removeCachedSessionData(sessionId)
      this.setState({'aiEmailText': value ?? undefined, 'aiEmailRequestState': value != null ? RequestState.RECEIVED : RequestState.ERROR, 'aiEmailSessionId': sessionId})
    })
  }

  _requestCoachingUpdate() {
    if (!this.props.audioDetails?.sessionId) return
    const sessionId = this.props.audioDetails.sessionId
    getServicesManager().requestCoachingSuggestion(this.props.audioDetails?.sessionId).then((value) => {
      if (sessionId !== this.props.audioDetails?.sessionId) return 
      getServicesManager().removeCachedSessionData(sessionId)
      this.setState({'aiCoachingText': value ?? undefined, 'aiCoachingRequestState': value != null ? RequestState.RECEIVED : RequestState.ERROR, 'aiCoachingSessionId': sessionId})
    })
  }

  _requestCustomMetricUpdate() {
    if (!this.props.audioDetails?.sessionId) return
    const sessionId = this.props.audioDetails.sessionId
    getServicesManager().requestCustomMetric(this.props.audioDetails?.sessionId).then((value) => {
      if (sessionId !== this.props.audioDetails?.sessionId) return 
      getServicesManager().removeCachedSessionData(sessionId)
      this.setState({'customMetric': value ?? undefined, 'customMetricRequestState': value ? RequestState.RECEIVED : RequestState.ERROR, 'customMetricSessionId': sessionId})
    })
  }

  componentDidUpdate(prevProps: Readonly<TranscriptProps>, prevState: Readonly<TranscriptState>) {
    if (this.props.autoplay) this._scrollToBottom()
    if (this.state.play || (this.props.audioDetails?.sessionId && prevProps.audioDetails?.sessionId === this.props.audioDetails?.sessionId && !this.props.selectedTranscriptIdx  && this.state.currentTimeInMs !== prevState.currentTimeInMs)) {
      if (!this.props.isPureTranscriptView && this.audioRef.current && !this._isInViewport(this.audioRef, this.transcriptBodyRef)) {
        this.audioRef.current.scrollIntoView({'behavior': Math.abs(prevState.currentTimeInMs - this.state.currentTimeInMs) == 0 ? 'auto' : 'smooth', 'block': 'center', 'inline': 'center'})
      }

      if (this.selectedTrackerRef.current && !this._isInViewport(this.selectedTrackerRef, this.trackerBodyRef)) {
        this._scrollToMatchHighlightedItem()
      }
    }

    if (!prevProps.pageClosed && this.props.pageClosed && this.state.play) {
      this.setState({
        'previousPlayState': true,
        'play': false
      })
    }

    if (prevProps.audioDetails?.sessionId == this.props.audioDetails?.sessionId && prevProps.selectedTranscriptIdx !== this.props.selectedTranscriptIdx) {
      this.setState({'prevSelectedTranscriptIdx': prevProps.selectedTranscriptIdx})
    }

    if (this.props.audioDetails?.sessionId && prevProps.audioDetails?.sessionId === this.props.audioDetails?.sessionId && prevState.aiSummaryRequestState !== this.state.aiSummaryRequestState && this.state.aiSummaryRequestState === RequestState.REQUESTED_BY_USER) {
      this._requestSummaryUpdate()
    }

    if (this.props.audioDetails?.sessionId && prevProps.audioDetails?.sessionId === this.props.audioDetails?.sessionId && prevState.aiEmailRequestState !== this.state.aiEmailRequestState && this.state.aiEmailRequestState === RequestState.REQUESTED_BY_USER) {
      this._requestEmailUpdate()
    }
    if (this.props.audioDetails?.sessionId && prevProps.audioDetails?.sessionId === this.props.audioDetails?.sessionId && prevState.aiCoachingRequestState !== this.state.aiCoachingRequestState && this.state.aiCoachingRequestState === RequestState.REQUESTED_BY_USER) {
      this._requestCoachingUpdate()
    }

    if (this.props.audioDetails?.sessionId && prevProps.audioDetails?.sessionId === this.props.audioDetails?.sessionId && prevState.customMetricRequestState !== this.state.customMetricRequestState && this.state.customMetricRequestState === RequestState.REQUESTED_BY_USER) {
      this._requestCustomMetricUpdate()
    }

    if (prevProps.audioDetails?.sessionId && !this.props.audioDetails?.sessionId || !prevProps.audioDetails?.sessionId && this.props.audioDetails?.sessionId || prevProps.audioDetails?.sessionId !== this.props.audioDetails?.sessionId) {
        this.setState({
          'currentTimeInMs': 0,
          'previousPlayState': false,
          'play': false,
          'prevSelectedTranscriptIdx': undefined, 
          'aiSummaryText': undefined,
          'aiSummaryRequestState': RequestState.TO_BE_REQUESTED_BY_USER,
          'aiSummarySessionId': undefined,
          'aiEmailText': undefined,
          'aiEmailRequestState': RequestState.TO_BE_REQUESTED_BY_USER,
          'aiEmailSessionId': undefined,
          'aiCoachingText': undefined,
          'aiCoachingRequestState': RequestState.TO_BE_REQUESTED_BY_USER,
          'aiCoachingSessionId': undefined,
          'customMetric': undefined,
          'customMetricRequestState': RequestState.TO_BE_REQUESTED_BY_USER,
          'customMetricSessionId': undefined
        })
      }


    if (prevProps.audioDetails?.sessionId == this.props.audioDetails?.sessionId && prevProps.selectedTranscriptIdx !== this.props.selectedTranscriptIdx && this.props.selectedTranscriptIdx !== undefined && this.props.selectedTranscriptIdx !== -1) {
      if (this.selectedTrackerRef.current && !this._isInViewport(this.selectedTrackerRef, this.trackerBodyRef)) {
        this._scrollTrackerBodyIntoViewAndMatchHighlightedItem()
      }
      else if (!this.state.play && this.selectedTrackerRef.current) {
        this._scrollToMatchSelectedTrigger()
      }
      else {
        this._scrollTranscriptBodyIntoView() 
      }
      this.setState((state) => {
        if (!this.props.transcripts) return null
        return {
          'currentTimeInMs': this.props.transcripts.length > 0 && this.props.selectedTranscriptIdx !== undefined && this.props.selectedTranscriptIdx < this.props.transcripts.length ? this.props.transcripts[this.props.selectedTranscriptIdx!].start : 0,
          'play': false,
          'previousPlayState': state.play
      }
      }, () =>{ if (this.state.previousPlayState) this.setState({'play': true, 'previousPlayState': false})})
      }
  }

  _constructIcon(reviewMode: TranscriptReviewMode, transcriptIdx: number, first?: boolean, last?: boolean): JSX.Element {
    return <div onClick={() => {
      if (!this.props.updateSelectedTranscript) return
      if (this.props.selectedTranscriptIdx === transcriptIdx) this.props.updateSelectedTranscript(undefined)
      else this.props.updateSelectedTranscript(transcriptIdx, () => {
        if (this.props.updateViewMode && this.props.viewMode !== reviewMode) this.props.updateViewMode(reviewMode)
      })}} 
    style={{'color': 'black', 
    'borderTopLeftRadius': first? '10px' : 'initial', 'borderBottomLeftRadius': first ? '10px' : 'inital', 
    'borderTopRightRadius': last? '10px' : 'initial', 'borderBottomRightRadius': last ? '10px' : 'inital', 
    'backgroundColor': 'white', 'width': '30px', 'boxShadow': 'rgb(0 0 0 / 5%) 0px 2px 1px, rgb(0 0 0 / 5%) 0px -2px 1px'}} 
    className={'cursor-pointer p-2 opacity-80 hover:opacity-100'}>
      {ReviewModeToIcon[reviewMode]}
    </div>
  }

  _renderTranscriptElement(transcript: Transcript,  idx: number, isEnd?: boolean): JSX.Element 
  {
    return <TranscriptElement hideLabels={this.props.hideLabels || this.props.isPureTranscriptView} commentOptions={this.props.commentFilters} commentFns={this.props.commentFns} isMainView={this.props.isMainView} currentTimeInMs={this.state.currentTimeInMs} idx={idx} refElement={this.audioRef} play={this.state.play} transcript={transcript} isLast={isEnd} reviewMode={this.props.reviewMode} selectedTranscriptIdx={this.props.selectedTranscriptIdx}
    sessionActive={this.props.sessionActive} updateSelectedTranscript={this.props.updateSelectedTranscript} updateViewMode={this.props.updateViewMode} viewMode={this.props.viewMode}/>
  }

  _renderTranscriptBodyDefault(): JSX.Element {
    return (
      <div className='w-full h-full flex flex-col items-center'>
      {transcriptDefault}
      <div className='mt-2'>
      <div className='text-center font-bold mb-2'>
      <Typography variant='h3'>
        Real-time AI Transcription / Summarization / Coaching
      </Typography>
      </div>
      <ul style={{'listStyleType': 'circle', marginLeft: '30px'}}>
        <li>
        <Typography variant='mediumParagraph'>
              Here you'll see a live transcription of your call
          </Typography>
        </li>
        <li>
          <Typography variant='mediumParagraph'>
              After your call you will receive an automatic call summary + disposition (if available)
          </Typography>
        </li>
        <li>
          <Typography variant='mediumParagraph'>
            Hit star at the bottom to favorite a transcript to easily search for it in the transcripts tab above
          </Typography>
        </li>
      </ul>
      </div>
    </div>)
  }

  _renderEmptyBody(): JSX.Element {
    return <div className='w-full h-full flex justify-center items-center text-center'>
      <Typography variant='caption'>
        No transcripts available for this call. 
      </Typography>
    </div>
  }

  _renderLineSplitHeaderRecallSection(c: Counterpart): JSX.Element {
    return (
    <div style={{'paddingLeft': '10px', 'paddingRight': '10px'}} className='w-full items-center flex flex-row justify-between'>
        <hr className='w-1/3' style={{'height': '1px', backgroundColor: '#e5e7eb'}} />
        <div className='w-full flex-grow text-center pl-2 pr-2 max-w-fit'>
          <Typography variant='smallParagraph'>
            {COUNTERPART_TO_HUMAN_READABLE[c]}
          </Typography>
        </div>
        
        <hr className='w-1/3' style={{'height': '1px', backgroundColor: '#e5e7eb'}} />
  </div>)
  }

  _renderTranscripts(): JSX.Element[] | null {
    let currentCounterpartIdx = 0
    if (!this.props.transcripts || this.props.transcripts.length == 0) return null
    const transcripts = this.props.transcripts.map((value: Transcript, idx: number) => {
      const transcriptDiv = this._renderTranscriptElement(value, idx, idx + 1 == this.props.transcripts?.length)
      const counterpartyDivs = this.props.counterpartyFilters && this.props.counterpartyFilters.map((c: CounterpartInterval, idx: number) => {
        if (idx < currentCounterpartIdx) return

        if (c.start <= value.start) {
          currentCounterpartIdx += 1
          if (c.counterpart === Counterpart.UNKNOWN) return
          return this._renderLineSplitHeaderRecallSection(c.counterpart)
        } 
      }).filter((x: JSX.Element | undefined) => x !== undefined)

      if (this.props.reviewMode && this.props.counterpartyFilters) {
        return <div className='w-full flex flex-col gap-1'>
          {counterpartyDivs}
          {transcriptDiv}
        </div>
      } else {
        return transcriptDiv
      }
    })
    return transcripts
  }

  _renderTranscriptBody(): JSX.Element {
    return (
      <div onClick={() => {if (this.props.sessionActive && this.props.autoplay && this.props.updateAutoplayState) this.props.updateAutoplayState()}} ref={this.transcriptBodyRef} className='relative min-h-0 w-full overflow-y-auto flex-1 flex flex-col items-center' style={{'paddingTop': '8px', 'paddingBottom': '16px'}}>
          {this.props.transcripts ? (this.props.transcripts.length == 0 ? this._renderEmptyBody() : this._renderTranscripts()) :
            (this.props.sessionActive || this.props.showLoaderAsDefault) && (!this.props.noLoaderEvenOnActive) ? <Loader /> : this._renderTranscriptBodyDefault()
          }
          <div ref={this.messagesEndRef}></div>
    </div>)
}

  _getCurrentTime(currentTimeInMS?: number, audioActive?: boolean, prevAudioPlay?: boolean): void {
    this.setState((state) => {
      return {
        'currentTimeInMs': currentTimeInMS === undefined ? state.currentTimeInMs : currentTimeInMS,
        'play': audioActive === undefined ? state.play : audioActive,
        'previousPlayState': prevAudioPlay === undefined ? state.previousPlayState : prevAudioPlay,
      }}, () => {
        if (!this.state.play && this.props.updateSelectedTranscript && this.props.selectedTranscriptIdx && this.props.transcripts && 
        ((this.state.currentTimeInMs > (this.props.transcripts[this.props.selectedTranscriptIdx].end ?? 0)) || (this.state.currentTimeInMs < this.props.transcripts[this.props.selectedTranscriptIdx].start))) {
          this.props.updateSelectedTranscript(undefined)
        } }) 
  }

   _computeTalkTimeRatio(): string | null {
    if (!this.props.audioDetails?.data?.turns) return null
    const repTalkTime = this.props.audioDetails.data.turns.filter((value: SpeechTurn) => value.role === 'REP').map((value: SpeechTurn) => value.start - value.end).reduce((a, b) => a + b, 0)
    const prospectTalkTime = this.props.audioDetails.data.turns.filter((value: SpeechTurn) => value.role === 'PROSPECT').map((value: SpeechTurn) => value.start - value.end).reduce((a, b) => a + b, 0)
    const totalTalkTime = repTalkTime + prospectTalkTime
    if (totalTalkTime == 0) return null
    const repPercentage = Math.round(100*repTalkTime / (repTalkTime + prospectTalkTime)).toString() + '%'
    const prospectPercentage = Math.round(100*prospectTalkTime / (repTalkTime + prospectTalkTime)).toString() + '%'
    return 'Rep (' + repPercentage + ') : Prospect (' + prospectPercentage + ')'
   }

   _getAISummaryFromSessionData() {
    if (!this.props.audioDetails || !this.props.audioDetails?.data) return null
    let notes = null
    if (this.props.audioDetails.data.notes && this.props.audioDetails.data.notes.length > 0) {
      notes = this.props.audioDetails.data.notes.map(note => note['note']).join(' | ')
    } 
    if ((notes == null || notes == '') && this.props.audioDetails.data.sessionMetric && this.props.audioDetails.data.sessionMetric.genSummaryText) {
      notes = this.props.audioDetails.data.sessionMetric.genSummaryText
    } 
    
    if ((notes == null || notes == '') && this.state.aiSummaryText && this.state.aiSummarySessionId === this.props.audioDetails.sessionId) {
      notes = this.state.aiSummaryText
    }
    return notes
   }

   _getAIEmailFromSessionData() {
    if (!this.props.audioDetails || !this.props.audioDetails?.data) return null
    if (this.props.audioDetails.data.sessionMetric?.genEmailText) return this.props.audioDetails.data.sessionMetric.genEmailText
    else if (this.state.aiEmailText && this.state.aiEmailSessionId === this.props.audioDetails.sessionId) {
      return this.state.aiEmailText
    }
    return null
   }

   _getAICoachingFromSessionData() {
    if (!this.props.audioDetails || !this.props.audioDetails?.data) return null
    if (this.props.audioDetails.data.sessionMetric?.genCoachingText) return this.props.audioDetails.data.sessionMetric.genCoachingText
    else if (this.state.aiCoachingText && this.state.aiCoachingSessionId === this.props.audioDetails.sessionId) {
      return this.state.aiCoachingText
    }
    return null
   }

   _getCustomMetricFromSessionData() {
    if (!this.props.audioDetails || !this.props.audioDetails?.data) return null
    if (this.props.customMetric && this.props.audioDetails?.data?.session.session_id === this.props.customMetric.session_id) return this.props.customMetric
    else if (this.state.customMetric && this.state.customMetricSessionId === this.props.audioDetails.sessionId) return this.state.customMetric
    return null
   }


  _renderSummaryCoaching(): JSX.Element | null {
    if (!this.props.audioDetails?.data || !this.props.audioDetails.sessionId || !this.props.audioDetails.data.sessionMetric) return null
    const notes = this._getAISummaryFromSessionData()

      return (
      <div className='flex flex-row gap-2 items-center' style={{'padding': this.props.isMainView ? '8px': '0px', boxShadow: this.props.isMainView ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none'}}> 
        <Typography variant='smallCaption' style={{'whiteSpace': 'nowrap'}}>
          Summary
        </Typography>
        <div className='h-full' style={{'borderLeft': '1px solid #e5e7eb'}}>
        </div>
        {notes ?
        <TextBlock text={notes} maxLines={this.props.minimalView || !this.props.isMainView ? 3 : 4} color='black' variant='mediumParagraph' /> :
          this.state.aiSummaryRequestState === RequestState.REQUESTED_BY_USER ? <Loader /> :
          <div onClick={() => this.setState({'aiSummaryRequestState': RequestState.REQUESTED_BY_USER})} className='w-full h-full flex items-center justify-center' style={{'padding': '5px'}}>
            <div className='cursor-pointer hover:bg-slate-200 w-full flex flex-row gap-2 items-center justify-center' style={{paddingTop: '5px', 'paddingBottom': '5px', 'border': '1px solid #e5e7eb', borderRadius: '10px', 'height': '40px'}}>
              <div className='h-full'>
                {aiDefault}
                </div>
                <div className=' flex text-center'>
                  <Typography variant='mediumParagraph'>
                    {this.state.aiSummaryRequestState === RequestState.TO_BE_REQUESTED_BY_USER ? "Request AI automation" : "Request failed."}
                    </Typography>
                </div>
            </div>
          </div>
          }

      </div>)
    }

    _renderAITextMain(value: string, onClick?: () => void) {
      return (
        <Typography className={onClick ? 'cursor-pointer': ''}
        onClick={onClick} 
        variant='mediumParagraph' color={onClick ? 'blue' : 'black'} style={{'wordWrap':'break-word'}}>
          {value}
      </Typography>
      )
    }

    _parseTimestampToSeconds(timestamp: string): number | undefined{
      const parts = timestamp.split(":").map(Number).reverse();
    
      let totalSeconds = 0;
      let multiplier = 1;
    
      for (const part of parts) {
        if (isNaN(part)) {
          return undefined
        }
    
        totalSeconds += part * multiplier;
    
        if (multiplier === 60) {
          // Handle hours if applicable
          multiplier *= 60;
        } else {
          multiplier *= 60;
        }
      }
    
      return totalSeconds;
    }

    _getIdxCorrespondingToSingleTimestamp(singleTimestamp: string) {
      if (!this.props.transcripts) return undefined
      // first check against starts
      let idx = this.props.transcripts?.findIndex((v) => {
        const start = durationToString(v.start)
        return (singleTimestamp == start || singleTimestamp === `0${start}`)
      })
      if (idx !== -1) return idx
      // check against ends
      idx = this.props.transcripts?.findIndex((v) => {
        if (!v.end) return false
        const end = durationToString(v.end)
        return (singleTimestamp == end || singleTimestamp === `0${end}`)
      })
      if (idx !== -1) return idx
      // otherwise check against median time stamp
      const timestampInSeconds = this._parseTimestampToSeconds(singleTimestamp)
      if (timestampInSeconds === undefined) return 0
      const closestMedian = this.props.transcripts.map((v, idx: number) => {
        if (v.end) return {'idx': idx, 'value': Math.abs((v.end - v.start)*MS_TO_SEC/2 - timestampInSeconds!)}
        else return {'idx': idx, 'value': Math.abs(v.start*MS_TO_SEC - timestampInSeconds!)}
      })
      closestMedian.sort((a, b) => {return a.value - b.value})
      return closestMedian[0].idx
    }

    _getIdxCorrespondongToCoachingTimestamps(startTimestamp: string, endTimestamp: string): number | undefined {
      if (!this.props.transcripts) return undefined
      if (startTimestamp === endTimestamp) return this._getIdxCorrespondingToSingleTimestamp(startTimestamp) // do the first thing.... 
      const idx = this.props.transcripts?.findIndex((v) => {
        if (!v.end) return false
        const start = durationToString(v.start)
        const end = durationToString(v.end)
        return (startTimestamp == start || startTimestamp === `0${start}`) && (endTimestamp == end || endTimestamp == `0${end}`)
      })
      if (idx !== -1) return idx
      
      // if you could not find anything --- proactively use the timestamp to find the section that covers the most of it
      // if none are found, return first element if start otherwise return last element
      const startTimestampSeconds = this._parseTimestampToSeconds(startTimestamp)
      const endTimestampSeconds = this._parseTimestampToSeconds(endTimestamp)
      if (startTimestampSeconds === undefined || endTimestampSeconds === undefined) return 0 

      const length = Math.abs(endTimestampSeconds - startTimestampSeconds)
      if (length === 0) return this._getIdxCorrespondingToSingleTimestamp(startTimestamp) // do the single click thing
      const areaCovered = this.props.transcripts?.map((v, idx: number) => {
        if (!v.end) return {'idx': idx, 'value': 0}
        const start: number = v.start * MS_TO_SEC
        const end: number = v.end * MS_TO_SEC
        const overlap = Math.max(Math.min(end, endTimestampSeconds!), startTimestampSeconds!) -  Math.min(Math.max(start, startTimestampSeconds!), endTimestampSeconds!);
        const union = Math.max(endTimestampSeconds!, end) - Math.min(startTimestampSeconds!, start)
        return {'idx': idx, 'value': overlap <= 0 || union <= 0 ? 0 : overlap  / union}
      })

      areaCovered.sort((a, b) => {return b.value - a.value})
      return areaCovered[0].idx
    }

    _handleClickCoachingSuggestionDoubleTimestamp(startTimestamp: string, endTimestamp: string) {
      if (!this.props.updateSelectedTranscript) return
      const idx = this._getIdxCorrespondongToCoachingTimestamps(startTimestamp, endTimestamp)
      if (idx !== undefined) this.props.updateSelectedTranscript(this.props.selectedTranscriptIdx !== undefined && this.props.selectedTranscriptIdx === idx ? undefined : idx)
    }

    _handleClickCoachingSuggestionSingleTimestamp(singleTimestamp: string) {
      if (!this.props.updateSelectedTranscript) return
      const idx = this._getIdxCorrespondingToSingleTimestamp(singleTimestamp)
      if (idx !== undefined) this.props.updateSelectedTranscript(this.props.selectedTranscriptIdx !== undefined && this.props.selectedTranscriptIdx === idx ? undefined : idx)
    }

    _renderDoubleTimestamp(value: string, matchResult: string[]) {
      const startTimestamp = matchResult[1];
      const endTimestamp = matchResult[3];
    
      const idx = value.indexOf(matchResult[0])
      const restOfText = value.slice(idx + matchResult[0].length).trim().replace(/^[!-\/:-@[-`{-~]*/, '');
      const selectedIdx = this.props.selectedTranscriptIdx
      let selected = false
      if (selectedIdx !== undefined) {
        const comparedIdx = this._getIdxCorrespondongToCoachingTimestamps(startTimestamp, endTimestamp)
        selected = selectedIdx == comparedIdx
      }
      return (
      <div className='w-full flex flex-row px-2 py-1' style={{
      'backgroundColor': selected ? '#F5F5F5' : 'initial', 
      'boxShadow': selected ? 'rgb(0 0 0 / 10%) 0px 4px 2px, rgb(0 0 0 / 10%) 0px -4px 2px' : 'initial'}} >
      <div onClick={() => this._handleClickCoachingSuggestionDoubleTimestamp(startTimestamp, endTimestamp)} 
      className='flex flex-col gap-1 cursor-pointer p-2 opacity-100 bg-white hover:bg-slate-50' 
      style={{
         'width': '100%',
        'borderRadius': '10px', 'boxShadow': '0px 4px 12px rgba(0, 0, 0, 0.1)'
        }}>
          <Typography variant='mediumParagraph' color={'black'} style={{'wordWrap':'break-word'}}>
            <span style={{'color': 'blue', 'fontWeight': '300'}}>
              {startTimestamp + " - " + endTimestamp + ": "}
            </span>
              {restOfText}
          </Typography>
      </div>
      </div>)
    }

    _renderSingleTimestamp(value: string, matchResult: string[]) {
      const startTimestamp = matchResult[2];
      const idx = value.indexOf(matchResult[0])
      const restOfText = value.slice(idx + matchResult[0].length).trim().replace(/^[!-\/:-@[-`{-~]*/, '');
      let selected = false;
      const selectedIdx = this.props.selectedTranscriptIdx
      if (selectedIdx !== undefined) {
        const comparedIdx = this._getIdxCorrespondingToSingleTimestamp(startTimestamp)
        selected = selectedIdx == comparedIdx
      }
      return (
        <div className='w-full flex flex-row px-2 py-1' style={{
        'backgroundColor': selected ? '#F5F5F5' : 'initial', 
        'boxShadow': selected ? 'rgb(0 0 0 / 10%) 0px 4px 2px, rgb(0 0 0 / 10%) 0px -4px 2px' : 'initial'}} >
        <div onClick={() => this._handleClickCoachingSuggestionSingleTimestamp(startTimestamp)} 
        className='flex flex-row flex-wrap gap-1 cursor-pointer p-2 opacity-80 hover:opacity-100' 
          style={{
           'width': '100%',
          'borderRadius': '10px', 'backgroundColor': 'white', 'boxShadow': '0px 4px 12px rgba(0, 0, 0, 0.1)'
          }}>
          <Typography variant='mediumParagraph' color={'black'} style={{'wordWrap':'break-word'}}>
            <span style={{'color': 'blue', 'fontWeight': '300'}}>
              {startTimestamp + ": "}
            </span>
              {restOfText}
          </Typography>
        </div>
  
        </div>)
    }

    _renderCoachingText(value: string) {
      const regexDoubleTimestamp: RegExp = /(\d{2}:\d{2})\s*([:-])\s*(\d{2}:\d{2})/;
      const matchDouble = value.match(regexDoubleTimestamp);
      if (matchDouble !== null) return this._renderDoubleTimestamp(value, matchDouble)
      const regexSingleTimestamp: RegExp = /(\d+\.\s+)(\d{2}:\d{2})\s+([:-])/;
      const matchSingle = value.match(regexSingleTimestamp)
      if (matchSingle !== null) return this._renderSingleTimestamp(value, matchSingle)
      return this._renderAITextMain(value)
    }

    _renderWidgetContent(widgetType: WidgetType) {
        const coachingStyle: React.CSSProperties = widgetType === WidgetType.AI_AUTOMATION_SUMMARY ? {} : {'overflowY': 'auto', 'minHeight': '50px', 'maxHeight': '12vh'}
        switch(widgetType) {
          case WidgetType.AI_AUTOMATION_SUMMARY:
          case WidgetType.AI_AUTOMATION_COACHING:
            const text = widgetType === WidgetType.AI_AUTOMATION_SUMMARY ? this._getAISummaryFromSessionData() : this._getAICoachingFromSessionData()
            return (
              <div className='flex flex-row items-center'>
              <div className='flex flex-row items-center gap-2'>
              <Typography variant='smallCaption' style={{'whiteSpace': 'nowrap', 'maxWidth': '55px'}}>
                {widgetType === WidgetType.AI_AUTOMATION_SUMMARY ? "Summary" : "Coaching"} 
              </Typography>
              <div className='h-full' style={{'borderLeft': '1px solid #e5e7eb'}}>
              </div>
              </div>
              <div className='flex flex-col gap-1 w-full flex-grow' style={{'marginLeft': widgetType === WidgetType.AI_AUTOMATION_SUMMARY ? '8px' : '0px', ...coachingStyle}}>
              <TextBlock noCursor={true} color='black' maxLines={this.props.minimalView ? 3 : 3} text={text ?? ''} variant={'mediumParagraph'}/>
              </div>
              </div>
            )
          case WidgetType.CUSTOM_METRIC:
            return this._renderScoreButton()
          default:
            return null
      }
    }

    _renderRequestWidget(widgetType: WidgetType): JSX.Element | null {
      if (!this.props.audioDetails?.data || !this.props.audioDetails.sessionId || !this.props.audioDetails.data.sessionMetric) return null
      const automation = widgetType === WidgetType.AI_AUTOMATION_SUMMARY ? this._getAISummaryFromSessionData() : widgetType === WidgetType.AI_AUTOMATION_COACHING ? this._getAICoachingFromSessionData() : this._getCustomMetricFromSessionData()
      const requestState = this._getCurrentState(widgetType)
      if (automation) { 
      return (
        <div 
        className='flex flex-row items-center' 
        style={{'padding': this.props.isMainView ? '8px': '0px', boxShadow: this.props.isMainView ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none'}}>  
          {this._renderWidgetContent(widgetType)} 
        </div>)
      } else {
      return (
        <Automation 
        mainView={true} 
        noShowButtons={true} 
        widgetType={widgetType} 
        updateState={(state: RequestState) => {
          if (widgetType === WidgetType.AI_AUTOMATION_SUMMARY) this.setState({'aiSummaryRequestState': state})
          else if (widgetType === WidgetType.AI_AUTOMATION_COACHING) this.setState({'aiCoachingRequestState': state})
          else if (widgetType === WidgetType.CUSTOM_METRIC) this.setState({'customMetricRequestState': state})
        }} 
        triggerAlert={() => {}} onRequest={
          () => {
            if (widgetType === WidgetType.AI_AUTOMATION_SUMMARY) this._requestSummaryUpdate.bind(this)
            else if (widgetType === WidgetType.AI_AUTOMATION_COACHING) this._requestCoachingUpdate.bind(this)
            else if (widgetType === WidgetType.CUSTOM_METRIC) this._requestCustomMetricUpdate.bind(this)
        }}  
        copyToPlatform={false} 
        content={undefined} 
        requestState={requestState}/>
      )
    }
    }

  _formatPhoneNumber(phoneNumber: string) {
    // Remove all non-digit characters
    // Check if the phone number has an international code
    if (phoneNumber.charAt(0) === '1') {
      // Format for North American numbering plan (NANP)
      return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
    } else {
      // Format for international numbers
      return phoneNumber.replace(/(\d{1,4})(\d{2,4})(\d{4})/, '+$1 $2 $3');
    }
  }

  _constructPlatformLink(platform: Platform, person_id: string | null): string | null {
    if (!person_id) return null

    if (platform === Platform.SALESLOFT) {
      return `https://app.salesloft.com/app/people/${person_id}`
    } else if (platform === Platform.OUTREACH) {
      return `https://web.outreach.io/prospects/${person_id}/overview`
    } 
    return null
  } 

  _renderImageForPlatform(platform: Platform): JSX.Element | null {
    if (platform === Platform.SALESLOFT) {
      return <img src={salesloft} />
    } else if (platform === Platform.OUTREACH) {
      return outreachSvg
    } else if (platform === Platform.HUBSPOT) {
      return hubspotSvg
    } else if (platform === Platform.FRESHWORKS) {
      return <img src={freshsales} />
    } else if (platform === Platform.BLOOBIRDS) {
      return <img src={bloobirds} />
    }
    return null
  }
  
  _getPlatformFromLink(link: string): Platform | null {
    if (link.includes('salesloft')) return Platform.SALESLOFT
    else if (link.includes('outreach')) return Platform.OUTREACH
    else if (link.includes('hubspot')) return Platform.HUBSPOT
    else if (link.includes('freshsales')) return Platform.FRESHWORKS
    else if (link.includes('bloobirds')) return Platform.BLOOBIRDS
    return null
  }

  _renderPlatformLink(): JSX.Element | null {
    if (!this.props.prospectInfo) return null
    const platform = this.props.prospectInfo.platform ?? this.props.prospectInfo.xref_platform
    const personId = this.props.prospectInfo.platform_person_id ?? this.props.prospectInfo.xref_person_id
    const platformLink = this.props.prospectInfo.platform_person_url ?? this.props.prospectInfo.xref_person_url

    if (!platform || (!personId && !platformLink)) return null
    const constructUrl = platformLink ?? this._constructPlatformLink(platform, personId)
    if (!constructUrl) return null
    const truePlatform = this._getPlatformFromLink(constructUrl) ?? platform 
    return (<div onClick={() => window.open(constructUrl, '_blank')} className={"cursor-pointer w-3.5 h-3.5"}
    style={{'borderRadius': '10px'}} >
      {this._renderImageForPlatform(truePlatform)}
    </div>)
  }

  _renderLinkedInLink(): JSX.Element | null {
    if (!this.props.prospectInfo || !this.props.prospectInfo.prospect_linkedin) return null
    const linkedinUrl = this.props.prospectInfo.prospect_linkedin
    return (<div onClick={() => window.open(`https://www.linkedin.com/in/${linkedinUrl}`, '_blank')} className={"cursor-pointer w-3.5 h-3.5"}
    style={{'borderRadius': '10px'}} >
      {linkedinSvg}
    </div>)
  }

  _constructTitle(): string | null {
    if (!this.props.prospectInfo) return null
    const platform = this.props.prospectInfo.platform ?? this.props.prospectInfo.xref_platform
    let title = this.props.prospectInfo.prospect_title
    if (platform && platform === Platform.SALESLOFT) {
        // seniorirty + occupation
        title = `${this.props.prospectInfo.prospect_seniority ?? ''} ${this.props.prospectInfo.prospect_title ?? ''}`.trim()
    } else if (platform && platform === Platform.OUTREACH) {
      // occupation + title 
        title = this.props.prospectInfo.prospect_title ? this.props.prospectInfo.prospect_title : this.props.prospectInfo.prospect_occupation 
    } 

    title = title ? title.trim() !== '' ? title : null : null 
    if (this.props.prospectInfo.company_name) {
      title = title ? `${title}, ${this.props.prospectInfo.company_name}` : this.props.prospectInfo.company_name
    }
    return title 
  }

  _renderDot(): JSX.Element {
    return <div className='w-1 h-1' style={{'borderRadius': '50%', 'backgroundColor': 'rgb(3, 33, 78)'}} />
  }

  _renderMainTranscriptHeader(): JSX.Element | null{
    if (!this.props.audioDetails?.data) return null
    const ttr = this._computeTalkTimeRatio()
    const title = this._constructTitle()
    const platform = this._renderPlatformLink()
    const linkedin = this._renderLinkedInLink()
    const aiSummary = this._getAISummaryFromSessionData()
    const customMetric = this._getCustomMetricFromSessionData()
    const showSummaryButton = aiSummary || ![RequestState.DEFAULT, RequestState.TO_BE_REQUESTED_BY_USER].includes(this.state.aiSummaryRequestState)
    const showCustomMetricButton = this._canShowScore() && (customMetric || ![RequestState.DEFAULT, RequestState.TO_BE_REQUESTED_BY_USER].includes(this.state.customMetricRequestState))
    const aiCoaching = this._getAICoachingFromSessionData()
    const showCoachingButton = aiCoaching || ![RequestState.DEFAULT, RequestState.TO_BE_REQUESTED_BY_USER].includes(this.state.aiCoachingRequestState)
    const hasEnoughTranscriptsForAi = this.props.transcripts && this.props.transcripts.length > 0
    return (
      <div className='flex flex-col gap-2'>
          <div className='p-2 flex flex-col gap-1' style={{boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'}}> 
            <div className='flex flex-row justify-between flex-wrap items-center'>
            <div className='flex flex-row gap-1 items-center flex-wrap'>
            <Typography variant='h2'>
              {this.props.audioDetails?.data?.prospect_info?.prospect_name ?? 'Unknown Prospect'},
              </Typography>
              <Typography variant='h2'>
            {this.props.audioDetails?.data?.session.requested_time.toLocaleString('en-US', {'year': 'numeric',
          'month': 'long', 'day': 'numeric', 'hour': 'numeric', 'minute': 'numeric', 'hour12': true})}
            </Typography>
            {title == null ? platform : null}
            {title == null ? linkedin : null}
            {title && platform == null && linkedin == null ? title : null}
            </div>
          {this.props.minimalView && this.props.audioDetails ? 
          <div onClick={() => window.open(`https://${window.location.hostname}/transcripts?id=${this.props.audioDetails?.data?.session.session_id}`)} className='cursor-pointer opacity-80 hover:opacity-100 w-4 h-4'>
            {popoutSvg}
          </div>
          : null}
          {this.props.minimalView || !this.props.audioDetails.sessionId ? null : 
          <div className='flex flex-row gap-1.5 items-center'>
            {this.props.minimalView ? null : 
            this.props.audioDetails.sessionId && this.props.viewerUserId ?
            <SessionStatus isMainView={this.props.isMainView} sessionUserId={this.props.audioDetails.data.session.user_id}
            viewerUserId={this.props.viewerUserId} 
            type={'sessionData'}
            hasStar={this.props.audioDetails.data.has_star} 
            sessionId={this.props.audioDetails.sessionId} 
            reviewIsOpen={this.props.audioDetails.data.reviewStatus?.isOpen} 
            reviewOpenedByUser={this.props.audioDetails.data.reviewStatus?.openingUserId === this.props.viewerUserId} /> 
            : null}  
            {this.props.minimalView || !this.props.audioDetails.sessionId ? null : <EmailPopup isCoaching={!this.props.isMainView} emailRequestState={this.state.aiEmailRequestState} requestEmailText={() => this.setState({'aiEmailRequestState': RequestState.REQUESTED_BY_USER})} emailText={this._getAIEmailFromSessionData()}/>}
          </div>}
            </div>
            {title && (platform || linkedin) ? 
            <div className='flex w-full flex-row gap-1 items-center'>
              <Typography variant='smallParagraph'>
              {title}
              </Typography>
              {platform ? this._renderDot() : null}
              {platform}
              {linkedin && !platform ? this._renderDot() : null}
              {linkedin}
            </div> : null} 
          {<div className='flex flex-row gap-1 items-center flex-wrap'>
              {!this.props.minimalView && this.props.audioDetails.data.session.prospect_phone_value ?
              <div className='flex flex-row gap-1 items-center'>
                <Typography variant='smallParagraph'>
                  {this._formatPhoneNumber(this.props.audioDetails.data.session.prospect_phone_value)}
              </Typography>
              {this._renderDot()}
              </div>
               : null}
              {!this.props.minimalView && this.props.audioDetails?.data?.sessionMetric?.externalAccountId ? <div className='flex flex-row gap-1 items-center'>
                <Typography variant='smallParagraph'>
                  Client Account:
                </Typography>
                <Typography variant='smallParagraph'>
                {this.props.externalAccountsById?.get(this.props.audioDetails?.data?.sessionMetric?.externalAccountId)?.external_account_name ?? "(other)"}
                </Typography>
                {this._renderDot()}
                </div> : null}
              {!this.props.minimalView ? <div className='flex flex-row gap-1 items-center'>
              <Typography variant='smallParagraph'>
                Platform:
              </Typography>
              <Typography variant='smallParagraph'>
              {PLATFORM_TO_HUMAN_READABLE[this.props.audioDetails.data.session.platform as Platform]}
              </Typography>  
              </div> : null}
              {ttr ? <div className='flex flex-row gap-1 items-center'>
              {this.props.minimalView ? null : this._renderDot()}
              <Typography variant='smallParagraph'>
                Talk Time:
              </Typography>
              <Typography variant='smallParagraph'>
              {ttr}
              </Typography>  
              </div> : null}
          </div>}

              <div className='w-full flex flex-row justify-between items-center'>
                <div className='w-full flex flex-row gap-1 items-center'>
              {this.props.audioDetails.data.prospectSessionCount && this.props.audioDetails.data.prospectSessionCount > 1 ? 
              <div
                onClick={() => {
                  if (this.props.dispatch && this.props.audioDetails?.data?.session.prospect_phone_value) {
                    this.props.dispatch(updateForceRefresh({'forceRefresh': true}))
                    this.props.dispatch(updateSessionListParams({'updatedParams': [
                      {'option': Option.PHONE_NUMBERS, 
                      'valueFilterOption': [
                        {'label': this.props.audioDetails.data.session.prospect_phone_value, 
                        'value': this.props.audioDetails.data.session.prospect_phone_value, 
                        'selected': true
                      }]}]}))
                  }
                }}
                className='w-fit p-1.5 cursor-pointer opacity-80 hover:opacity-100' style={{
                  userSelect: "none",
                  boxShadow: "rgb(0 0 0 / 8%) 0px 2px 2px",
                  borderRadius: "10px",
                  border: "1px solid rgb(229, 231, 235)",
                }}>
            <Typography>
                  {(this.props.audioDetails.data.prospectSessionCount - 1) + (this.props.audioDetails.data.prospectSessionCount > 2 ? " other calls" : " other call")}  
          </Typography>
            </div> : null}
            {this._canShowScore() && this.props.transcripts?.find((v) => v.partyCode === prospectPartyCode && v.content !== '') && this.props.audioDetails.data.counterparts.find((v) => [Counterpart.GATEKEEPER, Counterpart.NOT_GATEKEEPER].includes(v.counterpart)) ? this._renderAnalysisRequestButton(WidgetType.CUSTOM_METRIC, customMetric !== null) : null}
            {hasEnoughTranscriptsForAi ? this._renderAnalysisRequestButton(WidgetType.AI_AUTOMATION_SUMMARY, aiSummary !== null && aiSummary !== '') : null}
            {hasEnoughTranscriptsForAi && !this.props.minimalView ? this._renderAnalysisRequestButton(WidgetType.AI_AUTOMATION_COACHING, aiCoaching !== null && aiCoaching !== '') : null}
            </div>
          </div>
           </div>
            {showCustomMetricButton ? this._renderRequestWidget(WidgetType.CUSTOM_METRIC) : null}
            {showSummaryButton ? this._renderRequestWidget(WidgetType.AI_AUTOMATION_SUMMARY) : null}
            {this.state.aiCoachingVisible && showCoachingButton && !this.props.minimalView ? this._renderRequestWidget(WidgetType.AI_AUTOMATION_COACHING) : null}
          </div>
    )
  }

  _canShowScore() {
    if (!this.props.user) return false
    const team_id = this.props.audioDetails?.data?.session.team_id ?? this.props.user.team_id
    if (!TeamIdToMetricType[team_id]) return false
    if (team_id === ATTYX_TEAM_ID && this._getCustomMetricFromSessionData()?.metric_version === 'attyx_0') return false
    return TeamIdToTeamView[team_id] || this.props.isAdmin
  }

  _renderScoreButton() {
    if (!this.props.user) return null
    const custom_metric = this._getCustomMetricFromSessionData()
    if (!custom_metric) return null
    const team_id = this.props.audioDetails?.data?.session.team_id ?? this.props.user.team_id
    const scoreFn = TeamIdToMetricType[team_id]
    if (!scoreFn) return null
    if (TeamIdToTeamView[team_id] || this.props.isAdmin) return <Score score={scoreFn(custom_metric)}/>
    return null
  }

  _renderRequestTextButton(widgetType: WidgetType, hasContent: boolean) {
    switch (widgetType) {
      case WidgetType.AI_AUTOMATION_SUMMARY:
        return 'Request AI Summary'
      case WidgetType.AI_AUTOMATION_COACHING:
        return hasContent ? this.state.aiCoachingVisible ? "Hide AI Coaching" : "See AI Coaching" : "Request AI Coaching"
      case WidgetType.CUSTOM_METRIC:
        return 'Request Custom Metric'
      default:
        return null
    }    
  }

  _getCurrentState(widgetType: WidgetType) {
    switch (widgetType) {
      case WidgetType.AI_AUTOMATION_SUMMARY:
        return this.state.aiSummaryRequestState
      case WidgetType.AI_AUTOMATION_COACHING:
        return this.state.aiCoachingRequestState
      case WidgetType.CUSTOM_METRIC:
        return this.state.customMetricRequestState
      default:
        return RequestState.DEFAULT
    }
  }

  _renderAnalysisRequestButton(widgetType: WidgetType, hasContent: boolean): JSX.Element | null {
    const currentState = this._getCurrentState(widgetType)
    const canBeClicked = !hasContent && [RequestState.DEFAULT, RequestState.TO_BE_REQUESTED_BY_USER, RequestState.ERROR].includes(currentState) || (widgetType === WidgetType.AI_AUTOMATION_COACHING)
    return <div onClick={() => {
        if (!canBeClicked) return
        switch (widgetType) {
          case WidgetType.AI_AUTOMATION_SUMMARY:
            this.setState({'aiSummaryRequestState': RequestState.REQUESTED_BY_USER})
            break
          case WidgetType.AI_AUTOMATION_COACHING:
            if (hasContent) this.setState((state) => {return {'aiCoachingVisible': !state.aiCoachingVisible}})
            else this.setState({'aiCoachingRequestState': RequestState.REQUESTED_BY_USER, 'aiCoachingVisible': true})
            break
          case WidgetType.CUSTOM_METRIC:
            this.setState({'customMetricRequestState': RequestState.REQUESTED_BY_USER})
            break
        }
    }} className={('w-fit p-1 ') + (canBeClicked ? 'opacity-80 hover:opacity-100' : 'opacity-60')} style={{
      userSelect: "none",
      boxShadow: "rgb(0 0 0 / 8%) 0px 2px 2px",
      borderRadius: "10px",
      border: "1px solid rgb(229, 231, 235)",
      color: 'black',
      backgroundColor: canBeClicked ? 'white' : 'lightgrey',
      cursor: canBeClicked ? 'pointer' : 'not-allowed'
    }}>
      <Typography>
        {this._renderRequestTextButton(widgetType, hasContent)}
      </Typography>
      </div>
  }

  _renderAICoachingRequestButton() {
    return <div className='w-fit p-2 cursor-pointer opacity-80 hover:opacity-100' style={{
      userSelect: "none",
      boxShadow: "rgb(0 0 0 / 8%) 0px 2px 2px",
      borderRadius: "10px",
      border: "1px solid rgb(229, 231, 235)",
      backgroundColor: 'rgb(60, 71, 88)',
      color: 'white',
    }}>
      <Typography>
        Request AI Coaching
      </Typography>
      </div>
  }

  _updateSelected(selectedIdx: number) {
    if (!this.props.updateSelectedTranscript) return
    this.props.updateSelectedTranscript(this.props.selectedTranscriptIdx === selectedIdx ? undefined : selectedIdx)
  }

  _renderCommentSection(value: CommentDataResult[]): JSX.Element | null {
    if (!this.props.transcripts || !this.props.commentFns || value.length === 0) return null
    const transcriptIdx = value[0].transcriptIdx ?? -1
    const selected = transcriptIdx == -1 ? false : this.state.play ? 
    (this.state.currentTimeInMs >= this.props.transcripts[transcriptIdx].start && (!this.props.transcripts[transcriptIdx].end || this.state.currentTimeInMs <= this.props.transcripts[transcriptIdx].end!)) :
    transcriptIdx === this.props.selectedTranscriptIdx
    return (
    <div ref={selected ? this.selectedTrackerRef : undefined} onClick={() => {if (transcriptIdx !== -1) {this._updateSelected.bind(this)(transcriptIdx)}}} className={('p-1.5 flex flex-col gap-1 items-center ') + (selected ?  'opacity-100 bg-gray-50' : 'opacity-80 hover:opacity-100 bg-white')} 
      style={{'cursor': transcriptIdx !== -1 ? 'pointer' : 'initial',  'borderRadius': '5px', border: selected ? '0.5px solid black' : '0.5px solid lightgrey', 'width': this.props.viewMode ? "100%" : '0%'}}> 
          {transcriptIdx !== -1 ? 
        <div className='flex flex-row gap-1 flex-start w-full items-center'>
            <Typography variant='largeParagraph' className='cursor-pointer' color='blue' style={{'textDecoration': 'underline'}}>
            {transcriptIdx == -1 ? 'no time' : durationToString(this.props.transcripts[transcriptIdx].start)}
          </Typography>
          <Typography variant='largeParagraph'>
            -
          </Typography>
          <Typography variant='largeParagraph' className='cursor-pointer' color='blue' style={{'textDecoration': 'underline'}}>
            {durationToString(this.props.transcripts[transcriptIdx].end!)}
          </Typography>
          </div>
           : null}
        {value.map((v: CommentDataResult) => <Comment viewerUserId={this.props.viewerUserId} 
        updateSelectedTranscript={this.props.updateSelectedTranscript} editFunctions={this.props.commentFns!} comment={v}/> )}
        <PlusButton onClick={() => this.props.commentFns?.addEntry(value[0].transcriptIdx)} />
    </div>)
  }

  _renderSection(value: ReviewOption): JSX.Element | null {
    if (!this.props.transcripts) return null
    const selected = this.state.play ? 
    (this.state.currentTimeInMs >= this.props.transcripts[value.transcriptIdx].start && (!this.props.transcripts[value.transcriptIdx].end || this.state.currentTimeInMs <= this.props.transcripts[value.transcriptIdx].end!)) :
    value.transcriptIdx === this.props.selectedTranscriptIdx
    const transcript = this.props.transcripts[value.transcriptIdx]
    return (
    <div ref={selected ? this.selectedTrackerRef : undefined} onClick={() => this._updateSelected.bind(this)(value.transcriptIdx)} className='p-2 cursor-pointer flex flex-col gap-1 items-center opacity-80 hover:opacity-100 hover:bg-slate-200' 
      style={{'borderRadius': '5px', boxShadow: selected ? '0px 8px 12px rgba(0, 0, 0, 0.4)' : '0px 4px 12px rgba(0, 0, 0, 0.1)', 'width': this.props.viewMode ? "100%" : '0%',
      'backgroundColor': selected ?  'rgb(245, 245, 245)' : 'initial'}}> 
        <div className='flex flex-row gap-1 flex-start w-full items-center'>
        <Typography variant='smallCaption' className='cursor-pointer' color='blue' style={{'textDecoration': 'underline'}}>
          {durationToString(transcript.start)}
        </Typography>
        <Typography variant='smallParagraph'>
          -
        </Typography>
        <Typography variant='smallParagraph' style={{'fontWeight': '800'}}>
          {value.displayText}
        </Typography>
        </div>
        <hr style={{'backgroundColor': '#e5e7eb'}}></hr>
        <div className='w-full'>
          <Typography className={'expandable cursor-pointer'} variant='smallParagraph' color='black' style={{'textOverflow': 'ellipsis'}}>
            {(transcript.partyCode === 1 ? 'Rep': 'Prospect')  + ": " + transcript.content}
          </Typography>
        </div>
    </div>)
  }

  _renderRemarkSection(value: RemarkOption): JSX.Element | null {
    if (!this.props.transcripts) return null
    const isProspect = this.props.transcripts[value.transcriptIdx].partyCode === prospectPartyCode
    const bgColor = isProspect ? 'rgb(242, 247, 254)' : 'rgb(233, 245, 236)'
    const selected = this.state.play ? 
    (this.state.currentTimeInMs >= this.props.transcripts[value.transcriptIdx].start && (!this.props.transcripts[value.transcriptIdx].end || this.state.currentTimeInMs <= this.props.transcripts[value.transcriptIdx].end!)) :
    value.transcriptIdx === this.props.selectedTranscriptIdx
    return (
    <div ref={selected ? this.selectedTrackerRef : undefined} className='w-full flex flex-row px-2 py-1' style={{
      'justifyContent': isProspect ? 'flex-start' : 'flex-end',
    'backgroundColor': selected ? '#F5F5F5' : 'initial', 
    'boxShadow': selected ? 'rgb(0 0 0 / 10%) 0px 4px 2px, rgb(0 0 0 / 10%) 0px -4px 2px' : 'initial',}} >
    <div onClick={() => {
      this._updateSelected.bind(this)(value.transcriptIdx)
    }} className='p-2 cursor-pointer flex flex-col gap-1 items-center opacity-80 hover:opacity-100 hover:bg-slate-200' 
      style={{'borderRadius': '5px', boxShadow: selected ? '0px 8px 12px rgba(0, 0, 0, 0.4)' : '0px 4px 12px rgba(0, 0, 0, 0.1)', 'width': this.props.viewMode ? "90%" : '0%',
      'backgroundColor': bgColor}}> 
        <div className='w-full flex flex-col gap-1'>
        {value.remarks.map((value: Remark) => {
          return (
            <Typography variant='smallParagraph'>
              {"> " + REMARK_TO_HUMAN_READABLE[value]}
            </Typography>
          )
        })}
        </div>
    </div>
    </div>
    )
  }

  _renderAnnotations(): JSX.Element {
    const filtersToUse = this.props.viewMode === TranscriptReviewMode.BEHAVIORAL ? this.props.behavioralFilters : this.props.triggerFilters 
    return (
    <div className='flex flex-col gap-2 px-2'>
      {filtersToUse?.map((value: ReviewOption) => this._renderSection(value))}
    </div>
    )
  }

  _renderRemarks(): JSX.Element {
    return (
      <div className='flex flex-col gap-3'>
        {this.props.remarkFilters?.map((value: RemarkOption) => this._renderRemarkSection(value))}
      </div>
      )
  }

  _renderComments(): JSX.Element | null {
    if (!this.props.commentFilters || this.props.commentFilters.length == 0) return null
    const elements: (JSX.Element | null)[] = []
    let lastIdx = 0
    for (let i = 0; i < this.props.commentFilters.length; i += 1) {
      if (i > 0 && this.props.commentFilters[i - 1].transcriptIdx !== this.props.commentFilters[i].transcriptIdx) {
        elements.push(this._renderCommentSection(this.props.commentFilters.slice(lastIdx, i)))
        lastIdx = i
      }
    }
    elements.push(this._renderCommentSection(this.props.commentFilters.slice(lastIdx, this.props.commentFilters.length)))
    return (
    <div className='flex flex-col gap-2 p-2'>

      {elements}
    </div>
    )
  }

  _renderTranscriptSelector(): JSX.Element | null { 
    if (this.props.viewMode === undefined) return null
    return (
    <div className='flex flex-col' style={{'width': this.props.viewMode ? "100%" : '0%'}}>
        <div className='p-2 flex flex-row gap-2 items-center'>
            <Typography variant='largeParagraph' style={{'color': this.props.viewMode ? 'black' : 'white', 'transition': 'color 1s linear'}}>
              {this.props.viewMode}
            </Typography>
            {this.props.viewMode === TranscriptReviewMode.COMMENTS ? 
            <PlusButton onClick={() => this.props.commentFns?.addEntry()} /> : null}
        </div>
        <div className='flex flex-col gap-2'>
            {this.props.viewMode === TranscriptReviewMode.COMMENTS ? this._renderComments() : this.props.viewMode === TranscriptReviewMode.EVENTS ? this._renderRemarks() : this._renderAnnotations()}
        </div>
    </div>)
  }

  _getViewModes(): {k: TranscriptReviewMode, active: boolean}[] {
    const viewModes: {k: TranscriptReviewMode, active: boolean}[] = []
    viewModes.push({k: TranscriptReviewMode.EVENTS, active: this.props.remarkFilters && this.props.remarkFilters.length > 0 ? true : false})
    viewModes.push({k: TranscriptReviewMode.TRIGGERS, active: this.props.triggerFilters && this.props.triggerFilters.length > 0 ? true : false})
    viewModes.push({k: TranscriptReviewMode.BEHAVIORAL, active: this.props.behavioralFilters && this.props.behavioralFilters.length > 0 ? true : false})
    viewModes.push({k: TranscriptReviewMode.COMMENTS, active: true}) 
    return viewModes
  }

  _renderMainReviewBody(): JSX.Element {
    return (
      <div className='gap-2 overflow-x-hidden min-h-0 w-full flex flex-col h-full' style={{'backgroundColor': 'white', 'paddingLeft': '2px', 'paddingRight': '2px', 'paddingBottom': '2px'}}>
        {this._renderMainTranscriptHeader()}
        <div className='overflow-y-hidden flex flex-row flex-grow justify-between gap-1 overflow-x-hidden'  style={{boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)', 'backgroundColor': 'white', 'borderRadius': '2px'}}> 
        <div className='overflow-y-hidden flex w-fit flex-grow' style={{boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 'backgroundColor': 'white', 'borderRadius': '2px'}}>
        {this._renderTranscriptBody()}
        </div>
        {this.props.minimalView || this.props.transcripts?.length === 0 ? null : <div ref={this.trackerBodyRef} className='relative overflow-x-hidden overflow-y-auto flex' style={{'width': this.props.viewMode ? '33%' : '0%', 'minWidth': this.props.viewMode ? '100px': '0px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 'backgroundColor': 'white', 'borderRadius': '2px'}}>
        {this._renderTranscriptSelector()}
        </div>}
        {this.props.transcripts && this.props.transcripts.length > 0 && !this.props.minimalView ? <div className='relative' style={{'width': '45px', 'height': '100%'}}>
          <div className='absolute' style={{'width': '45px', 'height': '100%'}}>
          <ViewSelectorBar viewModes={this._getViewModes()} updateReviewMode={(reviewMode: TranscriptReviewMode) => {if (this.props.updateViewMode) this.props.updateViewMode(reviewMode)}} 
          width={'35px'} selectedViewOption={this.props.viewMode}/>
          </div>
          </div> : null }
        </div>
        {this.props.audioDetails ? this.props.audioDetails.dataFetcher ? 
          <AudioPlayerDataFetcher 
          isPureTranscriptView={this.props.isPureTranscriptView}
          copyTranscriptOnClick={this.props.copyTranscriptOnClick} 
          currentTimeInMS={this.state.currentTimeInMs} play={this.state.play} 
          previousPlayState={this.state.previousPlayState} 
          isMainView={this.props.isMainView} 
          updateTime={this._getCurrentTime.bind(this)} 
          turns={(this.props.transcripts ?? []).map((v) => {return {'start': v.start, 'end': v.end ?? v.start, 'role': v.partyCode === repPartyCode ? PartyRole.REP : PartyRole.PROSPECT, 'text': v.content}})}
          details={this.props.audioDetails}

          /> : 
          <AudioPlayer
          isPureTranscriptView={this.props.isPureTranscriptView}
          copyTranscriptOnClick={this.props.copyTranscriptOnClick} 
          currentTimeInMS={this.state.currentTimeInMs} 
          play={this.state.play} 
          previousPlayState={this.state.previousPlayState} 
          isMainView={this.props.isMainView} 
          updateTime={this._getCurrentTime.bind(this)} 
          turns={this.props.audioDetails.data?.turns ?? []}
          details={this.props.audioDetails} 
          /> : null}
      </div>
    )
  }

  _renderCoachingReviewHeader(): JSX.Element | null{
    if (!this.props.audioDetails?.data) return null
    return (
      <div className='top-0 sticky flex flex-col gap-2' style={{zIndex: '99999'}}>
          <div className='p-2 flex flex-col gap-1' style={{zIndex: '999999', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'}}> 
            <div className='flex flex-row justify-between items-top'>
              <div className='flex flex-row gap-1 flex-wrap items-center'>
              <Typography variant='h2'>
                {this.props.audioDetails?.data?.prospect_info?.prospect_name ?? 'Unknown Prospect'},
                </Typography>
                <Typography variant='h2'>
              {this.props.audioDetails?.data?.session.requested_time.toLocaleString('en-US', {'month': 'long', 'day': 'numeric'})}
              </Typography>
              </div>
              {this.props.isPureTranscriptView ? null : <EmailPopup isCoaching={!this.props.isMainView} emailRequestState={this.state.aiEmailRequestState} emailText={this._getAIEmailFromSessionData()} requestEmailText={() => this.setState({'aiEmailRequestState': RequestState.REQUESTED_BY_USER})} />}
            </div>
          <hr />
          {this._renderSummaryCoaching()}
          </div>
      </div>
    )
  }

  _coachingMainReviewBody(): JSX.Element {
    return (
      <div className='gap-2 overflow-x-hidden min-h-0 w-full flex flex-col h-full' style={{'backgroundColor': 'white', 'borderRadius': this.props.isPureTranscriptView ? undefined : '10px'}}>
        {this.props.isPureTranscriptView ? null : this._renderCoachingReviewHeader()}
        {this._renderTranscriptBody()}
        {this.props.audioDetails ?
        <div className='flex flex-col gap-1' style={{zIndex: '999999', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 'backgroundColor': 'white', 'borderRadius': '2px'}}> 
          {this.props.audioDetails.dataFetcher ? 
          <AudioPlayerDataFetcher 
          isPureTranscriptView={this.props.isPureTranscriptView}
          copyTranscriptOnClick={this.props.copyTranscriptOnClick} 
          currentTimeInMS={this.state.currentTimeInMs} 
          play={this.state.play} 
          previousPlayState={this.state.previousPlayState} 
          isMainView={this.props.isMainView} 
          updateTime={this._getCurrentTime.bind(this)} 
          details={this.props.audioDetails}
          turns={(this.props.transcripts ?? []).map((v) => {return {'start': v.start, 'end': v.end ?? v.start, 'role': v.partyCode === repPartyCode ? PartyRole.REP : PartyRole.PROSPECT, 'text': v.content}})}
          /> : 
          <AudioPlayer 
          isPureTranscriptView={this.props.isPureTranscriptView}
          copyTranscriptOnClick={this.props.copyTranscriptOnClick} 
          currentTimeInMS={this.state.currentTimeInMs} 
          play={this.state.play} 
          previousPlayState={this.state.previousPlayState} 
          isMainView={this.props.isMainView} 
          updateTime={this._getCurrentTime.bind(this)} 
          details={this.props.audioDetails}
          turns={this.props.audioDetails.data?.turns ?? []}
          />}
        </div> : null}
      </div>
    )
  }


  _renderReviewBody(): JSX.Element {
    if (this.props.isMainView) return this._renderMainReviewBody()
    else return this._coachingMainReviewBody()
  }

  _renderLiveBody(): JSX.Element {
    return (<div className='gap-2 overflow-x-hidden min-h-0 w-full flex flex-col h-full' style={{'padding': '8px'}}>
        {this._renderTranscriptBody()}
        {this.props.sessionActive && this.props.updateAutoplayState ?  
        <div className='flex flex-col gap-1' style={{ zIndex: '999999','backgroundColor': 'white', 'borderRadius': '2px'}}> 
          {this.props.additionalActionElement ?? null}
          <div className='flex flex-row items-center gap-2 w-fit p-1' style={{
            'boxShadow': "rgb(0 0 0 / 50%) 0.25px 3px 10px",
            'borderRadius': '10px',
          }}>
          <Typography variant='largeParagraph'>
            Autoscroll
          </Typography>
          <label className="switch opacity-75 hover:opacity-100 " id="switch">
              <input onChange={() => {if (this.props.updateAutoplayState) this.props.updateAutoplayState()}} 
              type="checkbox" checked={this.props.autoplay}/>
              <span className='slider round'/>
          </label>
        </div>
        </div>
         : null}
      </div>)
  }


  render(): JSX.Element  {
    if (this.props.reviewMode && this.props.audioDetails) return this._renderReviewBody()
    else return this._renderLiveBody()
  }
}

export const TranscriptWidget = withWidget(TranscriptBodyComponent, WidgetType.TRANSCRIPT)

const TranscriptBodyExport = connect((state: RootState) => {
  return {
    externalAccountsById: new Map(state.externalAccounts.accounts?.map(a => [a.external_account_id, a])),
    user: convertFromReduxSafeUserState(state.user),
    isAdmin: state.adminWrite.value ? state.adminWrite.value.team_ids.length > 0 : null,
  }
})(TranscriptBodyComponent)
export {TranscriptBodyExport as TranscriptBody}