import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Foldy } from "components/Foldy";
import { groupByKeys } from "core";
import { UserGroup, UserGroupMembership } from "interfaces/db";
import { UserDataResult, VisibleAccountsResult } from "interfaces/services";
import { UserGroupInfo, convertFromReduxSafeUserResult, convertFromReduxSafeUserState, convertFromReduxSafeVisibleAccounts } from "lib/redux/store";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "store";

import './userpicker.css';
import { xIcon } from "icons";

export enum UserPickerSelectionType {
    USER = 'USER',
    USER_GROUP = 'USER_GROUP',
    ALL_SUBTEAMS = 'ALL_SUBTEAMS',
    ALL_USERS = 'ALL_USERS',
    ALL = 'ALL',
    ALL_EXTERNAL_ACCOUNT = 'ALL_EXTERNAL_ACCOUNT',
    EXTERNAL_ACCOUNT = 'EXTERNAL_ACCOUNT',
    EXTERNAL_ACCOUNT_MY_TEAM = 'EXTERNAL_ACCOUNT_MY_TEAM',
    EXTERNAL_ACCOUNT_OTHER_TEAM = 'EXTERNAL_ACCOUNT_OTHER_TEAM',
}

export type ExternalAccountSelection = {
    label: string, 
    account_ids: string[]
}

export type UserSelectionUpdate = {
    type: UserPickerSelectionType.USER
    selected: boolean
    item: UserDataResult
}

export type GroupSelectionUpdate = {
    type: UserPickerSelectionType.USER_GROUP
    selected: boolean
    item: UserGroup
}

export type ExternalAccountSelectionUpdate = {
    type: UserPickerSelectionType.EXTERNAL_ACCOUNT
    selected: boolean
    item: ExternalAccountSelection
}

export type AllSelectionUpdate = {
    type: UserPickerSelectionType.ALL_SUBTEAMS | UserPickerSelectionType.ALL_USERS | UserPickerSelectionType.ALL_EXTERNAL_ACCOUNT
    selected: boolean
}

export type UserPickerUpdate = UserSelectionUpdate | GroupSelectionUpdate | ExternalAccountSelectionUpdate | AllSelectionUpdate

export type UserPickerSelectionWithId = {
    id: string
    type: UserPickerSelectionType
}

export type UserPickerSelectionWithIds = {
    label: string,
    ids: string[]
    type: UserPickerSelectionType
}

export type UserPickerSelectionWithoutId = {
    type: UserPickerSelectionType.ALL_SUBTEAMS | UserPickerSelectionType.ALL_USERS | UserPickerSelectionType.ALL_EXTERNAL_ACCOUNT
}

export type UserPickerSelection = UserPickerSelectionWithId | UserPickerSelectionWithIds | UserPickerSelectionWithoutId

export type UserPickerBucket = {
    label: string
    allUserIds: string[]
    userGroupId: string | null
    externalAccountIds: string[] | null
}

interface Props {
    // stuff filled in by redux connect
    visibleAccounts: VisibleAccountsResult | null;
    user: UserDataResult | null
    userIdToUser: Map<string, UserDataResult>
    teamIdToTeamName: Map<string, string>
    userIdToGroups: Map<string, UserGroup[]>
    sortedGroupInfo: UserGroupInfo[]
    groupIdToSortedIdx: Record<string, number>
    rootGroups: UserGroup[]
    unassignedUsers: UserDataResult[]
    memberships: UserGroupMembership[]
    groupsLoaded: boolean
    dispatch: Dispatch<AnyAction>

    // stuff you should provide
    headerElement?: React.ReactNode  // render the whole header element yourself
    renderHeaderElementContainer?: (text: string) => React.ReactNode  // let UserPicker provide a string that you insert into a container/button/whatever
    selected?: UserPickerSelection[]
    onUpdate: (updates: UserPickerUpdate[]) => void
    showEmptyGroups?: boolean
    startOpen?: boolean
    allSubteamsLabel?: string
    allUsersLabel?: string
    prefilterUsers?: (user: UserDataResult) => boolean
    allSubteamsExcludesUnassigned?: boolean
    allUsersTogglesAllSubteams?: boolean
    showTopLevelTeamGrouping?: boolean
}

// TODO once we have the get-team endpoint, add it to the redux state and use `${team.team} by Subteams` etc.
const DEFAULT_ALL_SUBTEAMS_LABEL = "By Subteams"
const DEFAULT_ALL_USERS_LABEL = "By Users"
const DEFAULT_PREFILTER_USERS = (user: UserDataResult): boolean => user.team_is_active

type State = {
    textSearch: string
    open: boolean
    selected: UserPickerSelection[]
    queuedUpdates: UserPickerUpdate[]
    numUpdatesQueued: number  // monotonic counter to ensure each update gets sent exactly once
}

export class UserPickerImpl extends React.Component<Props, State> {
    /**
     * Pick subsets of users with awareness of groups. If you want a single user, just use the Filter.
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            textSearch: '',
            open: !!props.startOpen,
            selected: props.selected ?? [],
            queuedUpdates: [],
            numUpdatesQueued: 0,
        }
    }

    _wrapperRef: React.RefObject<HTMLDivElement> = React.createRef()
    handleClickOutside = (event: MouseEvent) => {
        if (this._wrapperRef && this._wrapperRef.current && event.target && !this._wrapperRef.current.contains(event.target as Node)) {
            this.setState({open: false, textSearch: ''})
        }
    }

    componentDidMount(): void {
        document.addEventListener("mousedown", this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (typeof this.props.startOpen !== 'undefined' && this.props.startOpen !== prevProps.startOpen) {
            this.setState({open: this.props.startOpen})
        }
        if (
            this.props.selected
            && this.props.selected?.map(selectionToString).join(",") !== prevProps.selected?.map(selectionToString).join(",")
        ) {
            this.setState({selected: this.props.selected})
        }
        // Only send and delete exactly as many updates as are new between prevState and this.state.
        // This level of indirection is intended to avoid assumptions about React batching state updates:
        // 1. If there are more componentDidUpdate() calls with stale data before the below setState() sticks, or
        // 2. if more queued updates stick after the below setState() sticks but before the next componentDidUpdate(),
        // then queuedUpdates.length cannot be trusted to tell us what's newly queued.
        if (this.state.numUpdatesQueued > prevState.numUpdatesQueued) {
            const numUpdatesSentThisTime = this.state.numUpdatesQueued - prevState.numUpdatesQueued
            this.props?.onUpdate(this.state.queuedUpdates.slice(-numUpdatesSentThisTime))
            this.setState(state => {return {queuedUpdates: state.queuedUpdates.slice(numUpdatesSentThisTime)}})
        }
    }

    getSelectedPresets(): UserPickerSelectionType[] {
        return (this.state.selected ?? []).map(s => s.type).filter(t => t !== UserPickerSelectionType.USER && t !== UserPickerSelectionType.USER_GROUP)
    }

    getDirectlySelectedUsers(): UserDataResult[] {
        return sortUsers((this.state.selected ?? [])
            .filter((s: UserPickerSelection): s is UserPickerSelectionWithId => s.type === UserPickerSelectionType.USER)
            .map(s => this.props.userIdToUser.get(s.id))
            .filter((u): u is UserDataResult => !!u))
            .filter((u) => u.team_is_active)
    }

    getDirectlySelectedGroups(): UserGroup[] {
        return sortGroups((this.state.selected ?? [])
            .filter((s: UserPickerSelection): s is UserPickerSelectionWithId => s.type === UserPickerSelectionType.USER_GROUP)
            .map(s => this.props.sortedGroupInfo[this.props.groupIdToSortedIdx[s.id]])
            .filter((g): g is UserGroupInfo => !!g)
            .map(g => g.group))
    }

    getDirectySelectedExternalAccounts(): ExternalAccountSelection[] {
        return ((this.state.selected ?? [])
            .filter((s: UserPickerSelection): s is UserPickerSelectionWithIds => s.type === UserPickerSelectionType.EXTERNAL_ACCOUNT)
            .map(s => {return {label: s.label, account_ids: s.ids}}))
    }

    getFlatSelectedUsers(): UserDataResult[] {
        const userIds: Set<string> = new Set()
        for (const selection of this.state.selected ?? []) {
            switch (selection.type) {
                case UserPickerSelectionType.USER:
                    if (!('id' in selection)) break 
                    userIds.add(selection.id)
                    break
                case UserPickerSelectionType.USER_GROUP:
                    if (!('id' in selection)) break 
                    const groupInfo = this.props.sortedGroupInfo[this.props.groupIdToSortedIdx[selection.id]]
                    for (const userId of groupInfo?.allUserIds ?? []) {
                        userIds.add(userId)
                    }
                    break
                case UserPickerSelectionType.ALL_SUBTEAMS:
                    const unassignedUserIds: Set<string> = new Set(this.props.unassignedUsers.map(u => u.user_id))
                    for (const user of this.props.visibleAccounts?.users ?? []) {
                        if (!unassignedUserIds.has(user.user_id)) {
                            userIds.add(user.user_id)
                        }
                    }
                    break
                case UserPickerSelectionType.ALL_USERS:
                    for (const user of this.props.visibleAccounts?.users ?? []) { userIds.add(user.user_id) }
                    break
            }
        }
        return sortUsers([...userIds].map(userId => this.props.userIdToUser.get(userId)).filter((u): u is UserDataResult => !!u))
    }

    getSelectionBuckets(): UserPickerBucket[] {
        const result: UserPickerBucket[] = []
        const userSelections = this.getDirectlySelectedUsers()
        const groupSelections = this.getDirectlySelectedGroups()
        const externalSelections = this.getDirectySelectedExternalAccounts()
        const selectedAllSubteams = this.getSelectedPresets().some(v => v === UserPickerSelectionType.ALL_SUBTEAMS)
        const selectedAllUsers = this.getSelectedPresets().some(v => v === UserPickerSelectionType.ALL_USERS)
        const selectedAllExternalAccounts = this.getSelectedPresets().some(v => v === UserPickerSelectionType.ALL_EXTERNAL_ACCOUNT)
        if (selectedAllUsers) {  // "All Users" -> one bucket per user
            return this.props.visibleAccounts?.users?.map(user => {return {
                'label': user.user_name,
                'allUserIds': [user.user_id],
                'userGroupId': null,
                'externalAccountIds': null,
            }}).slice(0, 100) 
            ?? []  // limit to 100 to try to avoid crashing the browser
        }
        if (selectedAllExternalAccounts) {
            const visibleExternalAccounts = this.getVisibleExternalAccounts()
            const visibleAgencyAccounts = this.getVisibleAgencyAccounts()
            if (visibleExternalAccounts.length + visibleAgencyAccounts.length === 0) { return [] }
            else if (visibleExternalAccounts.length === 0) { return visibleAgencyAccounts.map((v) => {return {
                'label': v.label,
                'allUserIds': [],
                'userGroupId': null,
                'externalAccountIds': v.account_ids,
                }}) 
            } else if (visibleAgencyAccounts.length === 0) { return visibleExternalAccounts.map((v) => {return {
                'label': v.label,
                'allUserIds': [],
                'userGroupId': null,
                'externalAccountIds': v.account_ids,
            }})}
            else return []
        }

        if (userSelections.length + groupSelections.length + externalSelections.length === 0) {  // if no selection, then show everybody (includes selectedAllSubteams)
            return getDefaultUserBuckets(
                this.props.sortedGroupInfo,
                this.props.userIdToUser,
                selectedAllSubteams && this.props.allSubteamsExcludesUnassigned ? [] : this.props.unassignedUsers,
            )
        }
        if (!selectedAllSubteams && !selectedAllExternalAccounts && userSelections.length === 0 && groupSelections.length === 0 && externalSelections.length === 1) {
            // get all the relevant users....
            const ext_account = externalSelections[0].account_ids
            const assignable_users = Array.from(new Set([
                ...(this.props.visibleAccounts?.users ?? []).filter((v) => ext_account.includes(v.team_id)).map((v) => v.user_id),
                ...(this.props.visibleAccounts?.ext_account_members ?? []).filter((v) => ext_account.includes(v.external_account_id)).map((v) => v.user_id)]
            ))
            return assignable_users.map((user_id) => {
                const user = this.props.userIdToUser.get(user_id)
                return {
                    'label': user?.user_name ?? "Unknown User",
                    'allUserIds': [user_id],
                    'userGroupId': null,
                    'externalAccountIds': ext_account,
                }
            })
        }
        else if (!selectedAllSubteams && userSelections.length === 0 && externalSelections.length === 0 && groupSelections.length === 1) {  // all direct members of a group
            const groupInfo = this.props.sortedGroupInfo[this.props.groupIdToSortedIdx[groupSelections[0].user_group_id]]
            if (!groupInfo) { return [] }  // requested nonexistent group
            for (const subgroupIdx of groupInfo.subgroupIdxs) {
                const subgroupInfo = this.props.sortedGroupInfo[subgroupIdx]
                result.push({
                    'label': subgroupInfo.group.user_group_name,
                    'allUserIds': subgroupInfo.allUserIds.slice(),
                    'userGroupId': subgroupInfo.group.user_group_id,
                    'externalAccountIds': null,
                })
            }
            if (result.length > 0) {  // if there were subgroups, add any remaining users in one bucket
                if (groupInfo.directUserIds.length > 0) {
                    result.push({
                        'label': _nameBucket(groupInfo.directUserIds, this.props.userIdToUser, (result.length > 0 ? "rest of " : "") + groupInfo.group.user_group_name),
                        'allUserIds': groupInfo.directUserIds.slice(),
                        'userGroupId': null,
                        'externalAccountIds': null,
                    })
                }
            } else {  // if there were no subgroups, show member users individually
                for (const userId of groupInfo.directUserIds) {
                    const user = this.props.userIdToUser.get(userId)
                    if (!user) continue
                    result.push({
                        'label': user?.user_name ?? "Unknown User",
                        'allUserIds': [userId],
                        'userGroupId': null,
                        'externalAccountIds': null,
                    })
                }
            }
        } else {  // each selection is its own line/bar in the plots
            for (const group of groupSelections) {
                const groupInfo = this.props.sortedGroupInfo[this.props.groupIdToSortedIdx[group.user_group_id]]
                if (!groupInfo) { continue }
                result.push({
                    'label': group.user_group_name,
                    'allUserIds': groupInfo.allUserIds.slice(),
                    'userGroupId': group.user_group_id,
                    'externalAccountIds': null,
                })
            }
            for (const user of userSelections) {
                result.push({
                    'label': user.user_name,
                    'allUserIds': [user.user_id],
                    'userGroupId': null,
                    'externalAccountIds': null,
                })
            }
            for (const externalAccount of externalSelections) {
                result.push({
                    'label': externalAccount.label,
                    'allUserIds': [],
                    'userGroupId': null,
                    'externalAccountIds': externalAccount.account_ids,
                })
            }
            if (selectedAllSubteams) {
                const unassignedUserIds = new Set(this.props.unassignedUsers.map(u => u.user_id))
                result.push({
                    'label': 'All Subteams',
                    'allUserIds': this.props.visibleAccounts?.users?.map(u => u.user_id).filter(user_id => !this.props.allSubteamsExcludesUnassigned || !unassignedUserIds.has(user_id)) ?? [],
                    'userGroupId': null,
                    'externalAccountIds': null,
                })
            }
        }
        return result
    }

    getVisibleExternalAccounts(): {label: string, account_ids: string[]}[] {
        if (!this.props.visibleAccounts || this.props.visibleAccounts.ext_accounts.length === 0) return []
        const ext_acounts = this.props.visibleAccounts?.ext_accounts ?? []
        const client_team_ids = ext_acounts.filter((v) => v.client_team_id && v.team_id === this.props.user?.team_id).map((v) => v.client_team_id as string)
        const accounts = client_team_ids.map((v) => {
            const correspondingAccounts = ext_acounts.filter((e) => e.client_team_id === v)
            const accountNames = correspondingAccounts[0].external_account_name
            return {
                'label': accountNames,
                'account_ids': [...correspondingAccounts.map((v) => v.external_account_id), v],
            }
        })
        const non_associated_accounts = ext_acounts.filter((v) => (!v.client_team_id || !client_team_ids.includes(v.client_team_id)) && ((v.team_id === this.props.user?.team_id))).map((v) => {
            return {
                'label': v.external_account_name,
                'account_ids': [v.external_account_id],
            }
        })
        return [...accounts, ...non_associated_accounts]
    }

    getVisibleAgencyAccounts(): {label: string, account_ids: string[]}[] {
        if (!this.props.visibleAccounts || this.props.visibleAccounts.ext_accounts.length === 0) return []
        const ext_acounts = (this.props.visibleAccounts?.ext_accounts ?? [])
        return ext_acounts.filter((v) => v.client_team_id === this.props.user?.team_id).map((v) => {
            return {
                'label': v.agency_name ?? '',
                'account_ids': [v.external_account_id],
            }
        })
    }
    
    render(): JSX.Element | null {
        
        // unless showEmptyGroups, filter out groups with no descendant users
        const hiddenGroupIds: Set<string> = (
            this.props.showEmptyGroups
            ? new Set()
            : new Set(this.props.sortedGroupInfo.filter(g => g.allUserIds.length === 0).map(g => g.group.user_group_id)))

        // track selection
        const selectedAllSubteams = this.state.selected.some(s => s.type === UserPickerSelectionType.ALL_SUBTEAMS)
        const selectedAllUsers = this.state.selected.some(s => s.type === UserPickerSelectionType.ALL_USERS)
        const selectedAllExternalAccounts = this.state.selected.some(s => s.type === UserPickerSelectionType.ALL_EXTERNAL_ACCOUNT)
        
        const renderAsIfSelectedAllSubteams = selectedAllSubteams || (selectedAllUsers && this.props.allUsersTogglesAllSubteams)
        const renderAsIfSelectedAllUsers = selectedAllUsers || (selectedAllSubteams && !this.props.allSubteamsExcludesUnassigned)
        const renderAsIfSelectedAllExternalAccounts = selectedAllExternalAccounts
        
        const selectedGroupIds: Set<string> = new Set(this.state.selected
            ?.filter((s: UserPickerSelection): s is UserPickerSelectionWithId => s.type === UserPickerSelectionType.USER_GROUP && 'id' in s && !hiddenGroupIds.has(s.id))
            .map(s => s.id) ?? [])
        const selectedUserIds: Set<string> = new Set(this.state.selected
            ?.filter((s: UserPickerSelection): s is UserPickerSelectionWithId => s.type === UserPickerSelectionType.USER)
            .map(s => s.id) ?? [])
        const selectedExternalAccounts: UserPickerSelectionWithIds[] = this.state.selected.filter((s: UserPickerSelection): s is UserPickerSelectionWithIds => s.type === UserPickerSelectionType.EXTERNAL_ACCOUNT) ?? []
        const selectedExternalAccountIds: Set<string> = new Set(selectedExternalAccounts.flatMap(s => s.ids))

        const minimalSelectedGroupIds = new Set(selectedGroupIds)
        const minimalSelectedUserIds = new Set(selectedUserIds)

        const selectedOrDescendantGroupIds: Set<string> = new Set(selectedGroupIds)
        const selectedOrDescendantUserIds: Set<string> = new Set(selectedUserIds)
        const selectedOrDescendantExternalAccountIds: Set<string> = new Set(selectedExternalAccountIds)
        
        for (let i = this.props.sortedGroupInfo.length - 1; i >= 0; --i) {  // descending
            const groupInfo = this.props.sortedGroupInfo[i]
            if (hiddenGroupIds.has(groupInfo.group.user_group_id)) {
                selectedOrDescendantGroupIds.delete(groupInfo.group.user_group_id)
            } else if (selectedAllSubteams) {
                selectedOrDescendantGroupIds.add(groupInfo.group.user_group_id)
                for (const userId of groupInfo.directUserIds) {
                    selectedOrDescendantUserIds.add(userId)
                    minimalSelectedUserIds.delete(userId)
                }
            } else if (selectedOrDescendantGroupIds.has(groupInfo.group.user_group_id)) {
                for (const subgroupIdx of groupInfo.subgroupIdxs) {
                    const subgroupId = this.props.sortedGroupInfo[subgroupIdx].group.user_group_id
                    selectedOrDescendantGroupIds.add(subgroupId)
                    minimalSelectedGroupIds.delete(subgroupId)
                }
                for (const userId of groupInfo.directUserIds) {
                    selectedOrDescendantUserIds.add(userId)
                    minimalSelectedUserIds.delete(userId)
                }
            }
        }
        if (selectedAllUsers || (selectedAllSubteams && !this.props.allSubteamsExcludesUnassigned)) {
            for (const user of this.props.visibleAccounts?.users ?? []) {
                selectedOrDescendantUserIds.add(user.user_id)
                minimalSelectedUserIds.delete(user.user_id)
            }
        }
        const partiallySelectedGroupIds: Set<string> = new Set(selectedOrDescendantGroupIds)
        for (const groupInfo of this.props.sortedGroupInfo) {  // ascending
            if (groupInfo.directUserIds.some(userId => selectedOrDescendantUserIds.has(userId))) {
                partiallySelectedGroupIds.add(groupInfo.group.user_group_id)
            }
            if (partiallySelectedGroupIds.has(groupInfo.group.user_group_id)) {
                for (const parentIdx of groupInfo.parentIdxs) {
                    partiallySelectedGroupIds.add(this.props.sortedGroupInfo[parentIdx].group.user_group_id)
                }
            }
        }
        const selectedUserNames = [...selectedUserIds].map(userId => this.props.userIdToUser.get(userId)?.user_name ?? "(other user)").sort()
        const selectedGroupNames = [...selectedGroupIds].map(userGroupId => {
          const userGroupIdx = this.props.groupIdToSortedIdx[userGroupId]
          if (userGroupIdx === undefined) { return "(other subteam)" }
          return this.props.sortedGroupInfo[userGroupIdx].group.user_group_name
        }).sort()
        const selectedExternalAccountNames = [...selectedExternalAccounts].map((v) => v.label)
        const defaultHeaderText = (
            selectedExternalAccounts.length + selectedUserIds.size + selectedGroupIds.size === 0 ? "All"
          : selectedUserIds.size + selectedGroupIds.size ? [...selectedExternalAccountNames, ...selectedGroupNames, ...selectedUserNames].join(", ")
          : selectedGroupIds.size === 0 ? `${selectedUserIds.size} users`
          : selectedUserIds.size === 0 ? `${selectedGroupIds.size} subteams`
          : `${selectedGroupIds.size} subteams + ${selectedUserIds.size} users`
        )

        // track nodes satisfying search
        const satisfiesSearch: (name: string) => boolean = (
            !this.state.textSearch ? name => true
            : this.state.textSearch.toLocaleLowerCase() === this.state.textSearch ? name => name.toLocaleLowerCase().includes(this.state.textSearch)
            : name => name.includes(this.state.textSearch)
        )
        const searchedUserIds = new Set(this.props.visibleAccounts?.users?.filter(u => satisfiesSearch(u.user_name)).map((v) => v.user_id))
        const searchedGroups = new Set(this.props.sortedGroupInfo
            .map(groupInfo => groupInfo.group)
            .filter(g => !hiddenGroupIds.has(g.user_group_id) && satisfiesSearch(g.user_group_name)))
        const searchedOrAncestorGroups = new Set(searchedGroups)
        for (const groupInfo of this.props.sortedGroupInfo) {  // ascending
            if (groupInfo.directUserIds.some(userId => {
                const user = this.props.userIdToUser.get(userId)
                return user ? searchedUserIds.has(user.user_id) : false
            })) {
                searchedOrAncestorGroups.add(groupInfo.group)
            }
            if (searchedOrAncestorGroups.has(groupInfo.group)) {
                for (const parentIdx of groupInfo.parentIdxs) {
                    searchedOrAncestorGroups.add(this.props.sortedGroupInfo[parentIdx].group)
                }
            }
        }
        const searchedExternalAccounts = this.getVisibleExternalAccounts().filter(e => satisfiesSearch(e.label))
        const searchedAgencies = this.getVisibleAgencyAccounts().filter(e => satisfiesSearch(e.label))

        const root = this

        function getParents(item: UserGroup | UserDataResult): UserGroup[] {
            return (('user_id' in item)
                    ? root.props.userIdToGroups.get(item.user_id)
                    : root.props.sortedGroupInfo[root.props.groupIdToSortedIdx[item.user_group_id]].parentIdxs.map(
                        parentIdx => root.props.sortedGroupInfo[parentIdx].group
                    )) ?? []
        }

        function getSplitChildren(group: UserGroup): {groups: UserGroup[], users: UserDataResult[]} {
            const groupInfo = root.props.sortedGroupInfo[root.props.groupIdToSortedIdx[group.user_group_id]]
            return {
                groups: groupInfo.subgroupIdxs.map(s => root.props.sortedGroupInfo[s].group).filter(g => !hiddenGroupIds.has(g.user_group_id)),
                users: groupInfo.directUserIds.map(u => root.props.userIdToUser.get(u)).filter((u): u is UserDataResult => (u ?? null) !== null),
            }
        }

        function getChildren(item: UserGroup | UserDataResult): (UserGroup | UserDataResult)[] {
            if ('user_id' in item) { return [] }
            const splitChildren = getSplitChildren(item)
            return [...splitChildren.groups, ...splitChildren.users]
        }

        function isSelectedOrDescendant(item: UserGroup | UserDataResult | ExternalAccountSelection): boolean {
            return ('user_id' in item) ? selectedOrDescendantUserIds.has(item.user_id) : ('user_group_id' in item) ? selectedOrDescendantGroupIds.has(item.user_group_id) : selectedExternalAccountIds.has(item.account_ids[0]) 
        }

        function isDirectlySelected(item: UserGroup | UserDataResult): boolean {
            return ('user_id' in item) ? selectedUserIds.has(item.user_id) : selectedGroupIds.has(item.user_group_id)
        }

        function makeUpdate(
            item: UserGroup | UserDataResult | ExternalAccountSelection | UserPickerSelectionType.ALL_SUBTEAMS | UserPickerSelectionType.ALL_USERS | UserPickerSelectionType.ALL_EXTERNAL_ACCOUNT,
            selected: boolean,
        ): UserPickerUpdate {
            if (item === UserPickerSelectionType.ALL_SUBTEAMS || item === UserPickerSelectionType.ALL_USERS || item === UserPickerSelectionType.ALL_EXTERNAL_ACCOUNT) {
                return {type: item, selected}
            } else if ('user_id' in item) {
                return {type: UserPickerSelectionType.USER, item, selected}
            } else if ('user_group_id' in item) {
                return {type: UserPickerSelectionType.USER_GROUP, item, selected}
            } else {
                return {type: UserPickerSelectionType.EXTERNAL_ACCOUNT, item, selected}
            }
        }

        function expandUpdate(update: UserPickerUpdate): UserPickerUpdate[] {
            const result: UserPickerUpdate[] = []
            if (!(update.type === UserPickerSelectionType.USER || update.type === UserPickerSelectionType.USER_GROUP)) {
                result.push(update)
                if (update.type === UserPickerSelectionType.ALL_USERS && !update.selected && selectedAllSubteams) {
                    result.push({type: UserPickerSelectionType.ALL_SUBTEAMS, selected: false}) 
                }
                if (update.type === UserPickerSelectionType.ALL_SUBTEAMS && !update.selected && selectedAllUsers) {
                    result.push({type: UserPickerSelectionType.ALL_USERS, selected: false}) 
                }
            } else if (update.selected) {
                result.push(update)
                // If all children or all-but-one were selected, then erase the redundant child selects. Do not recurse.
                if (update.type === UserPickerSelectionType.USER_GROUP) {
                    const children = getChildren(update.item)
                    const selectedChildren = children.filter(isSelectedOrDescendant)
                    if (selectedChildren.length >= children.length - 1) {
                        result.push(...children.filter(isDirectlySelected).map(c => makeUpdate(c, false)))
                    }
                }
                // A previous version would recursively replace a completed set of siblings with their parent,
                // but it turns out to be useful to distinguish selecting a whole group vs selecting all current
                // members of that group. For example, Plays might care about keeping future members separate, and
                // Analytics uses the selection roots as aggregation buckets.
            } else {
                // Recursively deselect ancestors to make the clicked item not implied (otherwise the click will seem
                // to have accomplished nothing), and reselect siblings to maintain coverage.
                const itemToUpdate: Map<UserGroup | UserDataResult, boolean> = new Map([[update.item, false]])
                getParents(update.item).filter(isSelectedOrDescendant).forEach(parent => itemToUpdate.set(parent, false))
                const startGroupIdx = ('user_id' in update.item) ? 0 : root.props.groupIdToSortedIdx[update.item.user_group_id] + 1
                for (let i = startGroupIdx; i < root.props.sortedGroupInfo.length; ++i) {
                    const ancestor = root.props.sortedGroupInfo[i].group
                    if (itemToUpdate.has(ancestor)) {
                        for (const parent of getParents(ancestor).filter(isSelectedOrDescendant)) {
                            itemToUpdate.set(parent, false)
                        }
                        for (const child of getChildren(ancestor)) {
                            if (!itemToUpdate.has(child)) {
                                itemToUpdate.set(child, true)
                            }
                        }
                    }
                }
                result.push(...[...itemToUpdate]
                    .filter(([item, selected]) => isDirectlySelected(item) !== selected)
                    .map(([item, selected]) => makeUpdate(item, selected)))
                if (selectedAllSubteams) { result.push({type: UserPickerSelectionType.ALL_SUBTEAMS, selected: false}) }
                if (selectedAllUsers) { result.push({type: UserPickerSelectionType.ALL_USERS, selected: false}) }
            }
            return result
        }

        function applyUpdate(
            item: UserGroup | UserDataResult | ExternalAccountSelection | UserPickerSelectionType.ALL_SUBTEAMS | UserPickerSelectionType.ALL_USERS | UserPickerSelectionType.ALL_EXTERNAL_ACCOUNT,
            selected: boolean,
        ): void {
            const updates: UserPickerUpdate[] = expandUpdate(makeUpdate(item, selected))
            root.setState(state => {
                return {
                    selected: updateSelected(state.selected, updates),
                    // rather than call onUpdate() directly here, queue it up for componentDidUpdate after the new
                    // state sticks, so that ref holders see fully-updated state in getFlatSelectedUsers() etc.
                    queuedUpdates: [...state.queuedUpdates, ...updates],
                    numUpdatesQueued: state.numUpdatesQueued + updates.length,
                }
            })
        }

        const renderUser = (user: UserDataResult, depth: number): JSX.Element => {
            const selected = selectedOrDescendantUserIds.has(user.user_id)
            return <div
                key={user.user_id}
                className={"user-picker-hit-target " + (depth > 0 ? "user-picker-inner-foldy" : "user-picker-outer-foldy")}
                onClick={(e) => applyUpdate(user, !selected)}
                title={user.user_name}
            >
                <div
                    className={["foldy-head user-picker-user",
                                (selected ? "user-picker-selected" : ""),
                               ].join(" ")}
                >
                    <div className="user-picker-name">{!this.props.user || user.team_id === this.props.user.team_id || !this.props.teamIdToTeamName.has(user.team_id) ? user.user_name : `${user.user_name} - ${this.props.teamIdToTeamName.get(user.team_id)}`}</div>
                </div>
            </div>
        }

        const renderExternalAccount = (account_info: ExternalAccountSelection, depth: number): JSX.Element => {
            const selected = renderAsIfSelectedAllExternalAccounts || account_info.account_ids.every((v) => selectedExternalAccountIds.has(v))
            return <div
            key={account_info.account_ids.join('_')}
            className={"user-picker-hit-target " + (depth > 0 ? "user-picker-inner-foldy" : "user-picker-outer-foldy")}
            onClick={() => applyUpdate(account_info, !selected)}
            title={account_info.label}
        >
            <div
                className={["foldy-head user-picker-user",
                            (selected ? "user-picker-selected" : ""),
                           ].join(" ")}
            >
                <div className="user-picker-name">{account_info.label}</div>
            </div>
        </div>
        }

        const renderGroup = (group: UserGroup, depth: number): JSX.Element => {
            const selected = selectedOrDescendantGroupIds.has(group.user_group_id)
            const partiallySelected = !selected && partiallySelectedGroupIds.has(group.user_group_id)
            const splitChildren = getSplitChildren(group)
            const head = <div
                title={group.user_group_name}
                className="user-picker-hit-target"
                onClick={() => applyUpdate(group, !selected)}
            >
                <div
                    className={["user-picker-group",
                                (selected ? "user-picker-selected"
                                : partiallySelected ? "user-picker-partially-selected"
                                : ""),
                            ].join(" ")}
                >
                    <div className="user-picker-name">{group.user_group_name}</div>
                </div>
            </div>
            return <Foldy
                key={group.user_group_id}
                head={head}
                startOpen={partiallySelected}
                forceOpen={root.state.textSearch.length > 0}
                onlyArrowClickToFold={true}
                className={(depth > 0 ? "user-picker-inner-foldy" : "user-picker-outer-foldy")}
            >
                {sortGroups(splitChildren.groups.filter(g => searchedOrAncestorGroups.has(g))).map(g => renderGroup(g, depth + 1))}
                {sortUsers(splitChildren.users.filter(u => searchedUserIds.has(u.user_id))).map(u => renderUser(u, depth + 1))}
            </Foldy>
        }

        const minimalSelectedItems: (UserGroup | UserDataResult | ExternalAccountSelection)[] = [
            ...sortGroups([...minimalSelectedGroupIds]
                .map(g => this.props.sortedGroupInfo[this.props.groupIdToSortedIdx[g]]?.group)
                .filter((g): g is UserGroup => !!g)),
            ...selectedExternalAccounts.map((v) => {
                return {
                    'label': v.label,
                    'account_ids': v.ids,
                }
            }),
            ...sortUsers([...minimalSelectedUserIds]
                .map(u => this.props.userIdToUser.get(u))
                .filter((u): u is UserDataResult => !!u)),
        ]

        return <div ref={this._wrapperRef} className="user-picker">
            <div
                className="user-picker-header"
                onClick={(e) => {
                    e.stopPropagation()
                    this.setState(state => {return {'open': !state.open, 'textSearch': !state.open ? state.textSearch : ''}})
                }}
            >
                {this.props.renderHeaderElementContainer ? this.props.renderHeaderElementContainer(defaultHeaderText)
                : this.props.headerElement ?? defaultHeaderText}
            </div>
            {this.state.open ? <div className="user-picker-dropdown">
                <div className="user-picker-open-header">
                    {minimalSelectedItems.map(item =>
                        <div
                            key={'user_id' in item ? item.user_id : 'user_group_id' in item ? item.user_group_id : item.label}
                            className="user-picker-selected-item user-picker-selected"
                            title={'user_id' in item ? item.user_name : 'user_group_name' in item ? "in " + item.user_group_name : item.label}
                        >
                            <div className="user-picker-name">{
                                'user_id' in item ? item.user_name :
                                'user_group_name' in item ? "in " + item.user_group_name :
                                item.label
                            }</div>
                            <div
                                className="cursor-pointer h-3 rounded-full hover:text-white hover:bg-red-500"
                                onClick={() => applyUpdate(item, false)}
                            >
                                {React.cloneElement(xIcon, {className: "w-3 h-3"})}
                            </div>
                        </div>
                    )}
                    <input
                        onKeyDown={(e) => {
                            function findSearchTarget(groups: UserGroup[], users: UserDataResult[]): UserGroup | UserDataResult | null {
                                for (const group of groups) {
                                    if (searchedGroups.has(group) && !selectedOrDescendantGroupIds.has(group.user_group_id)) { return group }
                                    const splitChildren = getSplitChildren(group)
                                    const subtarget = findSearchTarget(splitChildren.groups, splitChildren.users)
                                    if (subtarget !== null) { return subtarget }
                                }
                                for (const user of users) {
                                    if (searchedUserIds.has(user.user_id) && !selectedOrDescendantUserIds.has(user.user_id)) { return user }
                                }
                                return null
                            }
                            if (e.key === 'Enter' || e.keyCode === 13) {
                                e.preventDefault()
                                const firstSearchResult = findSearchTarget(this.props.rootGroups, this.props.unassignedUsers)
                                if (firstSearchResult !== null) {
                                    this.setState({'textSearch': ''})
                                    applyUpdate(firstSearchResult, !isSelectedOrDescendant(firstSearchResult))
                                }
                            }
                            if (this.state.textSearch.length === 0 && (e.key === "Backspace" || e.keyCode === 8 || e.keyCode === 46)) {
                                if (minimalSelectedItems.length > 0) {
                                    const lastSelected = minimalSelectedItems[minimalSelectedItems.length - 1]
                                    e.preventDefault()
                                    this.setState({'textSearch': ''})
                                    applyUpdate(lastSelected, !isSelectedOrDescendant(lastSelected))
                                }
                            }
                        }}
                        autoFocus={true}
                        value={this.state.textSearch}
                        onChange={(e) => this.setState({'textSearch': e.target.value})}
                        type="text"
                        style={{
                            'height': '18px',
                            'boxSizing': 'border-box',
                            'padding': '4px 6px',
                            'flexBasis': '25%',
                            'flexGrow': 1,
                            'minWidth': '25%',
                            'border': '0px',
                            'margin': '0px',
                            'outline': '0px',
                            fontFamily: 'Calibri,Arial,Helvetica,sans-serif',
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: 'min(12px, max(11px, 1.8vw))',
                            lineHeight: "18px",
                            letterSpacing: "0.02em",
                        }}/>
                </div>
                <div className="user-picker-list">
                    {searchedOrAncestorGroups.size === 0 ? undefined
                    : <Foldy
                        head={<div
                            title={this.props.allSubteamsLabel ?? DEFAULT_ALL_SUBTEAMS_LABEL}
                            className="user-picker-hit-target"
                            onClick={() => applyUpdate(UserPickerSelectionType.ALL_SUBTEAMS, !renderAsIfSelectedAllSubteams)}
                        >
                            <div
                                className={["user-picker-group",
                                            (renderAsIfSelectedAllSubteams ? "user-picker-selected"
                                            : this.state.selected.length ? "user-picker-partially-selected"
                                            : ""),
                                        ].join(" ")}
                            >
                                <div className="user-picker-name">&mdash; {this.props.allSubteamsLabel ?? DEFAULT_ALL_SUBTEAMS_LABEL} &mdash;</div>
                            </div>
                        </div>}
                        startOpen={true}
                        forceOpen={this.state.textSearch.length > 0}
                        onlyArrowClickToFold={true}
                        className="user-picker-outer-foldy cursor-pointer"
                    >
                        {this.props.rootGroups.filter(g => searchedOrAncestorGroups.has(g)).map(g => renderGroup(g, 1))}
                    </Foldy>}
                    {searchedUserIds.size === 0 ? undefined
                    : this.props.rootGroups.length === 0 && !this.props.showTopLevelTeamGrouping && (!this.props.visibleAccounts?.ext_accounts || this.props.visibleAccounts?.ext_accounts.length === 0) ? sortUsers([...searchedUserIds].map((v) => this.props.userIdToUser.get(v)).filter((v) => v).map((v) => v as UserDataResult)).map(u => renderUser(u, 0))
                    : <Foldy
                        head={<div
                            title={this.props.allUsersLabel ?? DEFAULT_ALL_USERS_LABEL}
                            className="user-picker-hit-target"
                            onClick={() => applyUpdate(UserPickerSelectionType.ALL_USERS, !renderAsIfSelectedAllUsers)}
                        >
                            <div
                                className={["user-picker-group",
                                            (renderAsIfSelectedAllUsers ? "user-picker-selected"
                                            : selectedOrDescendantUserIds.size ? "user-picker-partially-selected"
                                            : ""),
                                        ].join(" ")}
                            >
                                <div className="user-picker-name">&mdash; {this.props.allUsersLabel ?? DEFAULT_ALL_USERS_LABEL} &mdash;</div>
                            </div>
                        </div>}
                        startOpen={false}
                        forceOpen={this.state.textSearch.length > 0}
                        onlyArrowClickToFold={true}
                        className="user-picker-outer-foldy cursor-pointer"
                    >
                        {sortUsers([...searchedUserIds].map((v) => this.props.userIdToUser.get(v)).filter((v) => v).map((v) => v as UserDataResult)).map(u => renderUser(u, 1))}
                    </Foldy>}
                    {searchedExternalAccounts.length === 0 ? undefined : 
                    <Foldy
                        head={<div
                            title="External Accounts"
                            className="user-picker-hit-target"
                            onClick={() => applyUpdate(UserPickerSelectionType.ALL_EXTERNAL_ACCOUNT, !renderAsIfSelectedAllExternalAccounts)}
                            >
                            <div
                                className={["user-picker-group",
                                            (renderAsIfSelectedAllExternalAccounts ? "user-picker-selected"
                                            : selectedOrDescendantExternalAccountIds.size ? "user-picker-partially-selected"
                                            : ""),
                                        ].join(" ")}
                            >
                                <div className="user-picker-name">&mdash; {'By clients'} &mdash;</div>
                            </div>
                            </div>
                        }
                        startOpen={false}
                        forceOpen={this.state.textSearch.length > 0}
                        onlyArrowClickToFold={true}
                        className="user-picker-outer-foldy cursor-pointer"
                        >
                        {searchedExternalAccounts.map((v) => renderExternalAccount(v, 1))}
                    </Foldy>
                    }
                    {searchedAgencies.length === 0 ? undefined : 
                    <Foldy
                        head={<div
                            title="External Agencies"
                            className="user-picker-hit-target"
                            onClick={() => applyUpdate(UserPickerSelectionType.ALL_EXTERNAL_ACCOUNT, !renderAsIfSelectedAllExternalAccounts)}
                            >
                            <div
                                className={["user-picker-group",
                                            (renderAsIfSelectedAllExternalAccounts ? "user-picker-selected"
                                            : selectedOrDescendantExternalAccountIds.size ? "user-picker-partially-selected"
                                            : ""),
                                        ].join(" ")}
                            >
                                <div className="user-picker-name">&mdash; {'By agencies'} &mdash;</div>
                            </div>
                            </div>
                        }
                        startOpen={false}
                        forceOpen={this.state.textSearch.length > 0}
                        onlyArrowClickToFold={true}
                        className="user-picker-outer-foldy cursor-pointer"
                        >
                        {searchedAgencies.map((v) => renderExternalAccount(v, 1))}
                    </Foldy>
                    }
                </div>
            </div> : undefined}
        </div>
    }
}

export const UserPicker = connect((state: RootState, ownProps: Pick<Props, "prefilterUsers">) => {
    const includeUser = ownProps.prefilterUsers ?? DEFAULT_PREFILTER_USERS
    const users = state.visibleAccounts.value?.users.map(u => { return convertFromReduxSafeUserResult(u) }).filter(includeUser) ?? null
    const userIdToGroups = groupByKeys(
        state.userGroupInfo.memberships.filter(m => !m.member_is_group),
        m => m.member_id,
        m => state.userGroupInfo.sortedGroupInfo[state.userGroupInfo.groupIdToSortedIdx[m.user_group_id]].group,
    )
    const team_id_to_name = new Map((state.visibleAccounts.value?.teams ?? []).map(t => [t.team_id, t.team]))
    return {
        visibleAccounts: convertFromReduxSafeVisibleAccounts(state.visibleAccounts, includeUser),
        user: convertFromReduxSafeUserState(state.user),
        userIdToUser: new Map(state.visibleAccounts.value?.users?.map(u => [u.user_id, convertFromReduxSafeUserResult(u)])),
        userIdToGroups: userIdToGroups,
        sortedGroupInfo: state.userGroupInfo.sortedGroupInfo,
        groupIdToSortedIdx: state.userGroupInfo.groupIdToSortedIdx,
        rootGroups: sortGroups(state.userGroupInfo.sortedGroupInfo.filter(g => g.parentIdxs.length === 0).map(g => g.group)),
        unassignedUsers: sortUsers(users?.filter(user => !userIdToGroups.get(user.user_id)?.length) ?? []),
        memberships: state.userGroupInfo.memberships,
        groupsLoaded: state.userGroupInfo.hasLoaded,
        teamIdToTeamName: team_id_to_name,
    }
}, null, null, {forwardRef: true})(UserPickerImpl)

// smaller helpers
function selectionToString(selection: UserPickerSelection): string {
    switch (selection.type) {
        case UserPickerSelectionType.USER: return 'id' in selection ? `u=${selection['id']}` : ''
        case UserPickerSelectionType.USER_GROUP: return 'id' in selection ? `g=${selection.id}` : ''
        case UserPickerSelectionType.ALL_SUBTEAMS: return `all-u`
        case UserPickerSelectionType.ALL_USERS: return `all-g`
        case UserPickerSelectionType.ALL_EXTERNAL_ACCOUNT: return `all-e`
        case UserPickerSelectionType.EXTERNAL_ACCOUNT: return 'ids' in selection ? `e=${selection.ids.join(',')}` : ''
        case UserPickerSelectionType.ALL: return 'all'
    }
    return ''
}

function updateToSelection(update: UserPickerUpdate): UserPickerSelection {
    if (update.type === UserPickerSelectionType.USER || update.type === UserPickerSelectionType.USER_GROUP) {
        return {
            type: update.type,
            id: update.type === UserPickerSelectionType.USER_GROUP ? update.item.user_group_id : update.item.user_id 
        }
    } else if (update.type === UserPickerSelectionType.EXTERNAL_ACCOUNT) {
        return {
            type: update.type,
            ids: update.item.account_ids,
            label: update.item.label
        }
    }
    else {
        return {type: update.type}
    }
}

export function updateSelected(selected: UserPickerSelection[], updates: UserPickerUpdate[]): UserPickerSelection[] {
    const drops = updates.filter(u => !u.selected).map(updateToSelection)
    const adds = updates.filter(u => u.selected).map(updateToSelection)
    return (selected
        .filter(s => drops.every(drop => !(s.type === drop.type && ('id' in s ? s.id : null) === ('id' in drop ? drop.id : null))))
        .concat(adds))
}

export function sortUsers(users: UserDataResult[]) {
    return users.sort((u1, u2) => u1.user_name.localeCompare(u2.user_name) || u1.created_at.valueOf() - u2.created_at.valueOf())
}

function sortGroups(groups: UserGroup[]) {
    return groups.sort((g1, g2) => g1.user_group_name.localeCompare(g2.user_group_name))
}

function _nameBucket(allUserIds: string[], userIdToUser: Map<string, UserDataResult>, fallbackName: string) {
    return (allUserIds.length === 1 ? userIdToUser.get(allUserIds[0])?.user_name : undefined) ?? fallbackName
}

export function getDefaultUserBuckets(
    sortedGroupInfo: UserGroupInfo[],
    userIdToUser: Map<string, UserDataResult>,
    unassignedUsers: UserDataResult[],
): UserPickerBucket[] {
    const result: UserPickerBucket[] = []
    result.push(...sortedGroupInfo.filter(g => g.parentIdxs.length === 0 && g.allUserIds.length > 0).map(
        g => {return {'label': g.group.user_group_name, 'allUserIds': g.allUserIds, 'userGroupId': g.group.user_group_id, 'externalAccountIds': null}}
    ))
    if (result.length > 0) {  // if there were any groups, add the remaining users in one bucket
        if (unassignedUsers.filter((v) => v.team_is_active).length > 0) {
            const unassignedUserIds = unassignedUsers.map(u => u.user_id)
            result.push({
                'label': _nameBucket(unassignedUserIds, userIdToUser, 'Unassigned Team'),
                'allUserIds': unassignedUserIds,
                'userGroupId': null,
                'externalAccountIds': null,
            })
        }
    } else {
        for (const user of unassignedUsers) {
            if (!user.team_is_active) continue
            result.push({
                'label': user.user_name,
                'allUserIds': [user.user_id],
                'userGroupId': null,
                'externalAccountIds': null,
            })
        }
    }
    return result
}
