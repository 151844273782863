export const searchIcon = <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.9999 20.9999L16.6499 16.6499" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const xIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M17 7 7 17M7 7l10 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

export const vdotsIcon = <svg viewBox="0 0 16 16" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="3.1812997" r="1.2370317" />
    <circle cx="8" cy="8" r="1.2370317" />
    <circle cx="8" cy="12.818701" r="1.2370317" />
</svg>

// bullet icons all the same size
export const bulletIcon = <svg width="8" height="12" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="12" r="5" fill="currentColor" />
</svg>

export const rightArrowSmall = <svg width="8" height="12" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0676 11.5746L1.08428 0.1528C0.736499 -0.126143 0.578013 -0.0126081 0.730454 0.406311L4.44251 11.321C4.59487 11.7399 4.59344 12.4188 4.43932 12.8371L0.733645 23.5928C0.579525 24.0111 0.737884 24.1259 1.08751 23.8492L15.0644 12.5808C15.414 12.3041 15.4154 11.8536 15.0676 11.5746Z" fill="currentColor" />
</svg>

export const dashIcon = <svg width="8" height="12" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M 3 11 L 13 11 L 13 13 L 3 13 Z" fill="currentColor" />
</svg>

export const userIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M 7.9989783,1.923085 A 3.9722982,3.9722982 0 0 0 4.0270869,5.8949362 3.9722982,3.9722982 0 0 0 7.9989783,9.8667874 3.9722982,3.9722982 0 0 0 11.972913,5.8949362 3.9722982,3.9722982 0 0 0 7.9989783,1.923085 Z m -2.19096,8.316463 c -0.6848503,0.95883 -1.6444422,2.465581 -1.5759171,3.766837 l 7.3970648,0.07053 c 0,-1.301255 -0.752133,-2.397216 -1.505549,-3.698511 -1.1642454,0.890343 -2.9458179,0.683125 -4.3155987,-0.138656 z" />
</svg>

export const groupIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M 10.940608,1.5979313 C 9.6574258,1.5982118 8.4533877,2.2183841 7.7080269,3.262891 8.8314379,4.0843507 9.5620517,5.4124954 9.5614106,6.9055011 9.5613304,7.7004725 9.3257804,8.3956216 8.9318612,8.9846169 9.5399313,9.3456761 10.233357,9.5381074 10.940608,9.5416739 13.134093,9.5414335 14.912219,7.7633072 14.91246,5.5698226 14.912219,3.376298 13.134093,1.5982118 10.940608,1.5979313 Z M 5.0594315,2.9315661 A 3.9722982,3.9722982 0 0 0 1.0875401,6.9055011 3.9722982,3.9722982 0 0 0 5.0594315,10.877352 3.9722982,3.9722982 0 0 0 9.0312827,6.9055011 3.9722982,3.9722982 0 0 0 5.0594315,2.9315661 Z M 8.7475648,9.8461097 C 8.4790746,10.221996 8.1684274,10.792958 7.8944072,11.426155 c 0.2708945,0.413474 0.5368601,0.757382 0.7744939,1.138959 0.3029531,0.486488 0.5354576,1.058252 0.5466781,1.785059 l 5.3531328,0.05169 c 0,-1.301255 -0.752133,-3.117932 -1.505509,-4.419188 -1.164285,0.890345 -2.945858,0.685211 -4.3156382,-0.1366494 z M 2.8684715,11.148648 c -0.6848503,0.95883 -1.6444422,1.881635 -1.5759171,3.182891 l 7.3970245,0.07053 c 0,-1.301256 -0.7541769,-1.815355 -1.5075523,-3.11661 -1.1642856,0.890345 -2.9437743,0.685171 -4.3135551,-0.13665 z" />
</svg>

export const globeIcon = <svg width="18" height="18" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M2.05 13h5.477a17.9 17.9 0 0 0 2.925 8.88A10.005 10.005 0 0 1 2.05 13zm0-2a10.005 10.005 0 0 1 8.402-8.88A17.9 17.9 0 0 0 7.527 11H2.05zm19.9 0h-5.477a17.9 17.9 0 0 0-2.925-8.88A10.005 10.005 0 0 1 21.95 11zm0 2a10.005 10.005 0 0 1-8.402 8.88A17.9 17.9 0 0 0 16.473 13h5.478zM9.53 13h4.94A15.908 15.908 0 0 1 12 20.592 15.908 15.908 0 0 1 9.53 13zm0-2A15.908 15.908 0 0 1 12 3.408 15.908 15.908 0 0 1 14.47 11H9.53z"></path>
</svg>
