import React, { RefObject } from 'react';

import autosize from "autosize";
import { Typography } from 'interfaces/typography';


const plus = <svg style={{'padding': '2px'}} width="100%" height="100%" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_2404_7975)">
<path d="M800 367.677H432.323V0H367.677V367.677H0V432.323H367.677V800H432.323V432.323H800V367.677Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_2404_7975">
<rect width="800" height="800" fill="white"/>
</clipPath>
</defs>
</svg>



export type EditFunctionProps = {
    addEntry: () => void,
    updateEntry: (idx: number, updatedEntry: string, forceRefreshOnEnter?: boolean) => void,
    removeEntry: (idx: number) => void,
}

export type EditableListProps = {
  labels: string[]
  editFunctions: EditFunctionProps
  notEditable?: boolean,
  placeholderText?: string,
  listNumberLimit?: number,
  errorFocus?: boolean,
  onErrorFocus?: () => void,
  onlyInputs?: boolean
}

type ClassEditableAutosizedInputProps = {
  prompt: string
  editFunctions: EditFunctionProps
  idx: number
  numberLeft: number
  maxNumber?: number,
  focus?: boolean,
  maxWidth?: string,
  placeholder?: string,
  blurOnEnter?: boolean
  fontSize?: number
  fontWeight?: number
  minHeight?: string
}

export class EditableAutosizedInput extends React.Component<ClassEditableAutosizedInputProps, {}> {
  _listRef: HTMLTextAreaElement | null = null

  constructor(props: ClassEditableAutosizedInputProps) {
    super(props)
  }

  componentDidMount(): void {
    if (this._listRef)
      autosize(this._listRef)


    if (this.props.focus && this._listRef && this.props.prompt === '')
      this._listRef.focus()
  }

  componentDidUpdate(prevProps: Readonly<ClassEditableAutosizedInputProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.prompt != this.props.prompt && this._listRef) {
      autosize.update(this._listRef)
    }

    if (this.props.focus && this._listRef && prevProps.numberLeft > this.props.numberLeft) {
      this._listRef.focus()
    }
  }

  onKeyDown(x: React.KeyboardEvent<HTMLTextAreaElement>): void {
    if ((x.key === 'Enter' || x.keyCode === 13) && (this.props.blurOnEnter || !(this.props.maxNumber && this.props.numberLeft >= this.props.maxNumber)) ) {
      x.preventDefault()
      if (this.props.blurOnEnter) this._listRef?.blur()
      if (!(this.props.maxNumber && this.props.numberLeft >= this.props.maxNumber)) this.props.editFunctions.addEntry()
      else {
        this.props.editFunctions.updateEntry(this.props.idx, this.props.prompt, true)
      }
   }

   if ((x.key == "Backspace" || x.keyCode === 8 || x.keyCode === 46) && this.props.numberLeft > 1 && this.props.prompt == '') {
    x.preventDefault()
    this.props.editFunctions.removeEntry(this.props.idx)
   }
  }

  render(): JSX.Element {
  return (<div className='flex justify-center group text-left relative items-center w-full' style={{
    'width': this.props.maxWidth ? 'fit-content' : '100%',
    'justifyContent': this.props.maxWidth ? 'flex-start' : 'center'}}>
    <textarea tabIndex={0}
    style={ {
      maxHeight: "120px",
      minHeight: this.props.minHeight ?? "25px",
      resize: "none",
      paddingLeft: '2px',
      backgroundColor: "#FBFBFB",
      fontFamily: 'Avenir',
      fontStyle: "normal",
      fontWeight: this.props.fontWeight ?? "800",
      fontSize: this.props.fontSize ?? "14px",
      lineHeight: this.props.fontSize ? "20px" : "20px",
      width: this.props.maxWidth ?? "100%",
      overflowX: 'hidden',
      overflowY: 'auto',
      borderRadius: '3px',
      outline: 'none',
    }}
    ref={(c: HTMLTextAreaElement) => (this._listRef = c)}
    placeholder={this.props.placeholder ?? "Enter for new line. Delete to remove line."}
    rows={1}
    defaultValue={this.props.prompt}
    value={this.props.prompt}
    onChange={(x) => {
    this.props.editFunctions.updateEntry.bind(this)(this.props.idx, x.target.value)}}
    onKeyDown={this.onKeyDown.bind(this)}
    className="hover:border-black hover:border border-gray-400 border border-opacity-25 hover:border-opacity-25 focus:border-opacity-80 focus:border-black"
    />

    {this.props.numberLeft > 1 ? <button tabIndex={-1} onClick={()=>this.props.editFunctions.removeEntry(this.props.idx)} type="button" className="absolute hidden -top-0.5 -right-0.5 h-3.5 w-3.5 items-center justify-center rounded-full border border-gray-200 bg-gray-100 group-hover:flex dark:border-zinc-700 dark:bg-zinc-800 cursor-pointer">
          <div className="flex max-w-full grow">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="h-full w-full text-gray-500 dark:text-zinc-400"><path d="M17 7 7 17M7 7l10 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg>
        </div> 
    </button> 
    : null}
  </div>)
  }
}

export class EditableList extends React.Component<EditableListProps, {}> {

  _localRef: React.RefObject<HTMLDivElement> = React.createRef()
  constructor(props: EditableListProps) {
    super(props)
  }

  _renderEditableSuggestion(response: string, idx: number, isLast?: boolean): JSX.Element | null {
    return (
    <EditableAutosizedInput maxNumber={this.props.listNumberLimit} placeholder={this.props.placeholderText} numberLeft={this.props.labels.length} focus={isLast} idx={idx} prompt={response} editFunctions={this.props.editFunctions}/>
    )
  }

  _renderNonEditableSuggestion(response: string): JSX.Element {
    return (
      <div className='group text-left relative items-center w-full'>
        <Typography variant='h3'>
        {response}
        </Typography>
      </div>
    )
  }

  _renderEditableListBody(): JSX.Element {
    return (
      <div className='w-full flex h-full flex-col gap-3 justify-around outline-none overflow-y-auto overflow-x-hidden pt-1' style={{'alignItems': 'flex-start', 'maxHeight': '175px'}}>
        {this.props.labels.map((value: string, idx: number, arr: string[]) => {return this.props.notEditable ? this._renderNonEditableSuggestion(value) : this._renderEditableSuggestion(value, idx, idx === arr.length - 1)})}
        </div>
    )
  }

  _renderErrorFocus(): JSX.Element | null {
    return this.props.errorFocus ? 
        <div className='z-50 relative cursor-default' >
        <div onClick={(e) => {e.stopPropagation(); if (this.props.onErrorFocus) this.props.onErrorFocus()}} className='absolute h-fit w-full' style={{'backgroundColor': 'transparent', borderRadius: '10px', 'width': '300px', 'height': this._localRef.current?.offsetHeight}} />
        </div> : null
  }

  _getStyles(): React.CSSProperties {
    if (this.props.onlyInputs) return {}
    else return {
      'boxShadow': '0px 3px 3px rgb(0 0 0 / 20%)', 
      'backgroundColor': '#FFFFFF', 
      borderRadius: '10px', 
      border: '1px solid rgba(91,101,123,0.25)', 
      'width': '300px'}
  }

  render()  {
    return (
      <div>
      {this._renderErrorFocus()}
      <div ref={this._localRef} className='h-fit w-full flex items-center flex-col justify-end min-h-0 p-2' style={this._getStyles()}>
      {this._renderEditableListBody()}
      {this.props.notEditable || (this.props.listNumberLimit && this.props.labels.length >= this.props.listNumberLimit)? null : <div className='relative'>
      <button tabIndex={-1} onClick={()=>this.props.editFunctions.addEntry()} type="button" className=" absolute h-3.5 w-3.5 items-center justify-center rounded-full border border-gray-200 hover:bg-blue-500 bg-blue-300" style={{'top': '2.5px'}}>
          <div className="flex max-w-full grow items-center justify-center text-center ">
            {plus}
        </div> 
      </button>
      </div>}
      </div>

      </div>

    )
  }
  }
