import { _DEVELOPER_MODE } from "cfg/const";

/**
 * Return the last item in an array of `values`
 * @param values Array values to get the back of
 * @returns 
 */
export function back<Type>(values: Type[]): Type {
  if (values.length === 0)
    throw new Error()
  return values[values.length - 1]
}

/**
 * Return the last item in an array of `values`, or `undefined` if no such element exists
 * @param values Array values to get back of
 * @returns 
 */
export function maybeBack<Type>(values: Type[]): Type | undefined {
  try {
    return back(values)
  } catch (e) {
    return undefined
  }
}

/**
 * Helper to write a log message with a time stamp
 * @param {String} message 
 */
 export function logInfo (message: string, ...terms: any[]): void {
  if (!_DEVELOPER_MODE) return 
  const formater = new Intl.DateTimeFormat("en" , {
    hour: "2-digit", minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3,
  });
  console.log(`${formater.format(new Date())} ${message}`, ...terms)
}

/**
 * Helper to write a log message with a time stamp
 * @param {String} message 
 */
 export function logInfoColored (message: string, color: string, ...terms: any[]): void {
  const formater = new Intl.DateTimeFormat("en" , {
    hour: "2-digit", minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3,
  });
  console.log(`${formater.format(new Date())} %c${message}`, `background-color: ${color}`, ...terms)
}

/**
 * Comparison function based on native inequality comparison
 * @param a First item to compare
 * @param b Second item to compare
 */
 export function singleCompareFunc<Type>(a: Type, b: Type): number {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}

/**
 * Simple wrapper to sort without modifying the input array.
 * @param items {Array, Iterable}: Items to sort
 * @param ascending {boolean}: Return ascending (true) or descending (false) order
 * @param compareFunc {CallableFunction}: (Optional) Function to perform comparison with
 */
 export function sort<Type>(items: Type[], ascending?: boolean, compareFunc?: (arg0: Type, arg1: Type) => number) {
  // handle default parameters
  if (ascending === undefined) ascending = true
  if (compareFunc === undefined) compareFunc = singleCompareFunc

  // always copy
  const newArray = [...items]

  // sort by comparison function
  newArray.sort(compareFunc)

  // optionally reverse
  if (!ascending)
    newArray.reverse()

  return newArray
}

/**
 * Sort an array based on comparing `key` values
 * @param items {Array, Iterable}: Items to sort
 * @param key {String}: Key to compare on
 * @param ascending {boolean}: Return ascending (true) or descending (false) order
 */
 export function sortByKey<Type>(items: Record<string, Type>[], key: string, ascending?: boolean) {
  return sort(items, ascending, (a, b) => singleCompareFunc(a[key], b[key]))
}

/**
 * Resolve after a specified number of milliseconds
 * @param durationMs 
 */
export function sleep(durationMs: number): Promise<undefined> {
  return new Promise( resolve => setTimeout(resolve, durationMs));
}

/**
 * Create an Map between key <> [values]
 * @param {Array} items: Items to group
 * @param {Function} keyFunc: Means of determing keys for each item
 * @param {Function} valueFunc: Used to transform values prior to collection
 * @param {Map} target: (optional) target to group into
 */
 export function groupByKeys<T, U, V> (items: T[], keyFunc: (arg0: T, arg1: number) => U, valueFunc: (arg0: T, arg1: number) => V, target? : Map<U, V[]>) {
  if (target == null) target = new Map()
  let key, value, item
  for (let i = 0; i < items.length; i++) {
    item = items[i]
    key = keyFunc(item, i)
    value = valueFunc(item, i)

    if (!target.has(key)) {
      const result: (V)[] = []
      target.set(key, result)
    }
    
    target.get(key)!.push(value)
  }
  return target
}


/**
* Returns the index of the last element in the array where predicate is true, and -1
* otherwise.
* @param array The source array to search in
* @param predicate find calls predicate once for each element of the array, in descending
* order, until it finds one where predicate returns true. If such an element is found,
* findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
*/
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l--) {
      if (predicate(array[l], l, array))
          return l;
  }
  return -1;
}


export function compareVersions(arr1: number[], arr2: number[]) {
  for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
    const num1 = arr1[i] || 0;
    const num2 = arr2[i] || 0;

    if (num1 < num2) {
      return -1; // version1 is smaller
    } else if (num1 > num2) {
      return 1; // version1 is larger
    }
  }

  return 0; // versions are equal
}


export function newShade(hexColor: string, magnitude: number): string {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')}`;
  } else {
      return hexColor;
  }
};


interface RGBColor {
  r: number;
  g: number;
  b: number;
}

function hexToRGB(hexColor: string): RGBColor | null {
  const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const result = hexRegex.exec(hexColor);
  if (!result) return null;

  const [, r, g, b] = result;
  return {
    r: parseInt(r, 16),
    g: parseInt(g, 16),
    b: parseInt(b, 16),
  };
}

export function getGradientColor(startColor: string, endColor: string, percentage: number): string {
  const white: RGBColor = { r: 255, g: 255, b: 255 };
  const startRGB: RGBColor | null = hexToRGB(startColor);
  const endRGB: RGBColor | null = hexToRGB(endColor);

  if (!startRGB || !endRGB) {
    throw new Error('Invalid hex color format');
  }

  if (percentage <= 0) {
    return startColor; // Return the start color at 0%
  } else if (percentage >= 100) {
    return endColor; // Return the end color at 100%
  } else {
    const fraction: number = percentage / 100;

    const r: number = white.r + Math.round((endRGB.r - white.r) * fraction);
    const g: number = white.g + Math.round((endRGB.g - white.g) * fraction);
    const b: number = white.b + Math.round((endRGB.b - white.b) * fraction);

    return `rgb(${r}, ${g}, ${b})`;
  }
}

export function jsonifyParams(prev: any) {
  return Array.from(Object.entries(prev)).map(([key, value]) => JSON.stringify(value)).join('')
}