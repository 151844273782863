import React from 'react'
import styled, { keyframes } from 'styled-components'

type InfinityProps = {
  color?: string
  width?: string
}

const DEFAULT_COLOR = '#4fa94d'

const len = 242.776657104492
const time = 1.6

const anim = keyframes`
  12.5% {
    stroke-dasharray: ${len * 0.14}px, ${len}px;
    stroke-dashoffset: -${len * 0.11}px;
  }
  43.75% {
    stroke-dasharray: ${len * 0.35}px, ${len}px;
    stroke-dashoffset: -${len * 0.35}px;
  }
  100% {
    stroke-dasharray: ${len * 0.01}px, ${len}px;
    stroke-dashoffset: -${len * 0.99}px;
  }
`

const Path = styled.path`
  stroke-dasharray: ${len * 0.01}px, ${len};
  stroke-dashoffset: 0;
  animation: ${anim} ${time}s linear infinite;
`

const InfinitySpin = ({
  color = DEFAULT_COLOR,
  width = '200',
}: InfinityProps): React.ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}`}
      height={`${Number(width) * 0.5}`}
      viewBox={`0 0 ${width} ${Number(200 * 0.5)}`}
      data-testid="infinity-spin"
    >
      <Path
        data-testid="infinity-spin-path-1"
        stroke={color}
        fill="none"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
      />
      <path
        data-testid="infinity-spin-path-2"
        opacity="0.07"
        fill="none"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
      />
    </svg>
  )
}


type Props = {
  color?: string,
}

export class Loader extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
  }

  // show that you do not have the chrome extension if we did not receive an API key...

  render(): JSX.Element {
    return (
        <div style={{width: '100%', height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <InfinitySpin color={this.props.color}></InfinitySpin>
        </div>
    )
  }
}


const getDefaultStyle = (visible: boolean): Style => ({
  display: visible ? 'flex' : 'none',
})


const DEFAULT_WAI_ARIA_ATTRIBUTE = {
  'aria-busy': true,
  role: 'status',
}

export type Style = {
  [key: string]: string
}

type CircleProps = {
  wrapperStyle?: Style
  visible?: boolean
  wrapperClass?: string
  height?: string | number
  width?: string | number
  color?: string
  outerCircleColor?: string
  innerCircleColor?: string
  middleCircleColor?: string
  ariaLabel?: string
}

/**
 * @description contains three circles rotating in opposite direction
 * outer circle, middle circle and inner circle color can be set from props.
 */
export const ThreeCircles: React.FunctionComponent<CircleProps> = ({
  wrapperStyle = {},
  visible = true,
  wrapperClass = '',
  height = 100,
  width = 100,
  color = DEFAULT_COLOR,
  ariaLabel = 'three-circles-loading',
  outerCircleColor,
  innerCircleColor,
  middleCircleColor,
}): React.ReactElement => {
  return (
    <div
      style={{ ...getDefaultStyle(visible), ...wrapperStyle }}
      className={wrapperClass}
      data-testid="three-circles-wrapper"
      aria-label={ariaLabel}
      {...DEFAULT_WAI_ARIA_ATTRIBUTE}
    >
      <svg
        version="1.1"
        height={`${height}`}
        width={`${width}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 100 100"
        xmlSpace="preserve"
        data-testid="three-circles-svg"
      >
        <path
          fill={outerCircleColor || color} /** outer circle */
          d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
  c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="2s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
        <path
          fill={middleCircleColor || color} /** Middle circle */
          d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
  c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="-360 50 50"
            repeatCount="indefinite"
          />
        </path>
        <path
          fill={innerCircleColor || color} /** Inner circle */
          d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
  L82,35.7z"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="2s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  )
}
