import React, { RefObject } from 'react';

import autosize from "autosize";
import { Typography } from 'interfaces/typography';
import { SEC_TO_MS } from 'cfg/const';
import { CommentDataResult } from 'interfaces/services';


const plus = <svg style={{'padding': '2px'}} width="100%" height="100%" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_2404_7975)">
<path d="M800 367.677H432.323V0H367.677V367.677H0V432.323H367.677V800H432.323V432.323H800V367.677Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_2404_7975">
<rect width="800" height="800" fill="white"/>
</clipPath>
</defs>
</svg>


export type CommentFunctionProps = {
    updateEntry: (identifier: string, updatedEntry: string) => void,
    removeEntry: (identifier: string) => void,
    addEntry: (transcriptIdx?: number) => void
}

export type EditableListProps = {
  labels: string[]
  editFunctions: CommentFunctionProps
  notEditable?: boolean,
  placeholderText?: string,
  listNumberLimit?: number,
  errorFocus?: boolean,
  onErrorFocus?: () => void,
}

type CommentProps = {
  viewerUserId?: string
  comment: CommentDataResult,
  editFunctions: CommentFunctionProps
  focus?: boolean,
  placeholder?: string,
  updateSelectedTranscript?: (selectedIdx: number | undefined, callback?: () => void) => void
}

type CommentState = {
  'autosized': boolean
}

export class Comment extends React.Component<CommentProps, CommentState> {
  _listRef: HTMLTextAreaElement | null = null

  constructor(props: CommentProps) {
    super(props)
    this.state = {
      'autosized': false
    }
  }

  componentDidMount(): void {
    if (this._listRef) {
      autosize(this._listRef)
      setTimeout(() => {
        if (this._listRef) { 
          autosize.update(this._listRef)
          this.setState({'autosized': true})
        }
      }, 0.65*SEC_TO_MS)
    }

    if (this.props.focus && this._listRef)
      this._listRef.focus()
  }

componentDidUpdate(prevProps: Readonly<CommentProps>, prevState: Readonly<CommentState>, snapshot?: any): void {
    if (prevProps.comment.comment != this.props.comment.comment && this._listRef) {
      autosize.update(this._listRef)
    }

    if (this.props.focus && this._listRef) {
      this._listRef.focus()
    }
  }

  onKeyDown(x: React.KeyboardEvent<HTMLTextAreaElement>): void {
    if ((x.key === 'Enter' || x.keyCode === 13) && !x.shiftKey) {
      x.preventDefault()
      if (this.props.updateSelectedTranscript) this.props.updateSelectedTranscript(undefined)
      this._listRef?.blur()
   }
  }

  renderEditableComment(): JSX.Element {
    return (<div className='group text-left relative items-center w-full'>
    <textarea tabIndex={0}
    style={ {
      padding: '4px',
      maxHeight: "120px",
      minHeight: "25px",
      resize: "none",
      paddingLeft: '2px',
      backgroundColor: "inherit",
      fontFamily: 'Calibri,Arial,Helvetica,sans-serif',
      fontStyle: "normal",
      fontWeight: "00",
      fontSize: "12px",
      lineHeight: "14px",
      width: "100%",
      overflowX: 'hidden',
      overflowY: this.state.autosized ? 'auto' : 'hidden',
      borderRadius: '5px',
      outline: 'none'
    }}
    ref={(c: HTMLTextAreaElement) => (this._listRef = c)}
    placeholder={"Add a comment"}
    rows={1}
    defaultValue={this.props.comment.comment}
    value={this.props.comment.comment}
    onClick={(event: React.MouseEvent<HTMLTextAreaElement>) => {
      event.stopPropagation();
      if (this.props.updateSelectedTranscript) this.props.updateSelectedTranscript(this.props.comment.transcriptIdx)
    }}
    onChange={(x) => {
    this.props.editFunctions.updateEntry.bind(this)(this.props.comment.commentId, x.target.value)}}
    onKeyDown={this.onKeyDown.bind(this)}
    className="hover:border-black hover:border border-gray-400 border border-opacity-25 hover:border-opacity-25 focus:border-opacity-80 focus:border-black"
    />

    {<button tabIndex={-1} onClick={()=>{this.props.editFunctions.removeEntry(this.props.comment.commentId)}} type="button" className="absolute hidden -top-0.5 -right-0.5 h-3.5 w-3.5 items-center justify-center rounded-full border border-gray-200 bg-gray-100 group-hover:flex dark:border-zinc-700 dark:bg-zinc-800 cursor-pointer">
          <div className="flex max-w-full grow">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="h-full w-full text-gray-500 dark:text-zinc-400"><path d="M17 7 7 17M7 7l10 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg>
        </div> 
    </button> 
    }
  </div>)
  }

  renderNotEditableComment(): JSX.Element {
    return <div>
      <Typography variant='smallParagraph' style={{
        'border': '0.5px solid lightgrey',
        'borderRadius': '5px',
        'padding': '4px',
        'backgroundColor': '#fcf4d375',
      }}>
        {this.props.comment.comment}
      </Typography>
    </div>
  }

  render(): JSX.Element {
  return (  
  <div className='flex flex-row gap-1 items-start w-full'>
    <div>
      <Typography style={{
        maxWidth: '40px',
      }} variant='smallParagraph'>{this.props.viewerUserId === this.props.comment.userId ? 'You:' : this.props.comment.userName}</Typography>
    </div>
    {this.props.viewerUserId == this.props.comment.userId ? this.renderEditableComment() : this.renderNotEditableComment()}
  </div>
  )
  }
}