/**
 * Find the first parent node from `node` for which `callable` is true
 * note: this does not look through iframes (and nor will parentNode)
 */
 export function findParentByCallable(
  node: HTMLElement, 
  callable: (target: HTMLElement) => boolean, 
  stopAt?: HTMLElement
): HTMLElement | null {
  while (node !== stopAt) {
    if (callable(node)) return node
    const parent = node.parentElement // 
    if (parent == null) break
    node = parent
  }
  return null
}


/**
 * gets page width - compatible with different browser types
 * from https://stackoverflow.com/questions/1038727/how-to-get-browser-width-using-javascript-code
 * @returns page width
 */
export function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

export function sendMessageToTop(message: any) {
  if (window.top && document.location.ancestorOrigins 
      && document.location.ancestorOrigins.length > 0) {
        const ancestorOrigin = document.location.ancestorOrigins[0]
      try {
          window.top.postMessage(message, ancestorOrigin)
      } catch (e) {
      }
  }
}
