// trellus related constants
export type ContactType = 'USER_ID' | 'SALESFORCE_CONTACT_ID' | 'SALESLOFT_PERSON_ID' | 'PHONE' | 'EMAIL'
export type Goal = 'TEST' | 'DISCOVERY'
export type AudioSource = 'RECALL' | 'EXTENSION'
export type TranscriptSource = 'ASSEMBLY_RT' | 'ASSEMBLY_BATCH' | 'RECALL'
export type ScopeDomain = 'USER' | 'TEAM' | 'GLOBAL'
export enum PromptType {
    VOICEMAIL = 'VOICEMAIL',
    MENU = 'MENU',
    NOT_IN_SERVICE = 'NOT_IN_SERVICE',
    SUMMARY_V2 = 'SUMMARY_V2',
    MONOLOG = 'MONOLOG',
    NEXT_STEPS = 'NEXT_STEPS',
    CADENCE_FAST = 'CADENCE_FAST',
    SEQUENCE = 'SEQUENCE',
    OBJECTION_RESPONSE = 'OBJECTION_RESPONSE',
    TRIGGER = 'TRIGGER',
    BUYING_INTENT = 'BUYING_INTENT',
    TEXT_SUMMARY_PENDING = 'TEXT_SUMMARY_PENDING',
    TEXT_SUMMARY_ERROR = 'TEXT_SUMMARY_ERROR',
    TEXT_SUMMARY = 'TEXT_SUMMARY',
    AI_EMAIL_PENDING = 'AI_EMAIL_PENDING',
    AI_EMAIL_ERROR = 'AI_EMAIL_ERROR',
    AI_EMAIL = 'AI_EMAIL',
    PLATFORM_AUTOMATION = 'PLATFORM_AUTOMATION',
    GEN_COACHING_CAN_REQUEST = 'GEN_COACHING_CAN_REQUEST',
    TEMPO_STATS = 'TEMPO_STATS',
    COACHING_END = 'COACHING_END',
    PLAY = 'PLAY',
    IDENTITY = 'IDENTITY',
    SOUND = 'SOUND'
}

export const COACHING_PROMPT_TYPES = [
  PromptType.SUMMARY_V2,
  PromptType.MONOLOG,
  PromptType.CADENCE_FAST,
  PromptType.TRIGGER,
  PromptType.BUYING_INTENT,
  PromptType.TEXT_SUMMARY_PENDING,
  PromptType.TEXT_SUMMARY_ERROR,
  PromptType.TEXT_SUMMARY,
  PromptType.AI_EMAIL_PENDING,
  PromptType.AI_EMAIL_ERROR,
  PromptType.AI_EMAIL,
  PromptType.GEN_COACHING_CAN_REQUEST,
  PromptType.TEMPO_STATS,
  PromptType.COACHING_END,
  PromptType.PLAY,
] 

export const PromptTypeToHumanReadable: Map<PromptType, string> = new Map<PromptType, string>().set(PromptType.TRIGGER, 'Suggestion').set(PromptType.MONOLOG, 'Monolog').set(PromptType.CADENCE_FAST, 'Speed')

export enum ScopeType {
  SESSIONS_READ = 'SESSIONS_READ',                 // read session (and related) data
  SESSIONS_WRITE = 'SESSIONS_WRITE',              // write session (and related) data
  ADMIN_WRITE = 'ADMIN_WRITE',                     // administer configuration
  INFRASTRUCTURE_READ = 'INFRASTRUCTURE_READ',     // read data about servers and such
  INFRASTRUCTURE_WRITE = 'INFRASTRUCTURE_WRITE',
}
