import { SEC_TO_MS, MIN_TO_MS, HOUR_TO_MS } from 'cfg/const'

/**
 * Return a string representation of a duration
 * @param duration Elapsed time in ms
 */
export function durationToString(duration: number): string {
  // define a helper to format a number as a two digit string
  const toTwoDigit = (x: number) => {
    const string = x.toFixed(0)
    return string.length === 2 ? string : ('0' + string)
  }
  
  // collect string representation
  let result = ''
  
  // include hours if any
  const numHours = Math.floor(duration / HOUR_TO_MS)
  if (numHours > 0) {
    result = numHours.toFixed(0) + ':'
    duration -= numHours * HOUR_TO_MS
  }

  // include minutes
  const minutes = Math.floor(duration / MIN_TO_MS)
  result += (numHours > 0 ? toTwoDigit(minutes) : minutes.toFixed(0)) + ':'
  duration -= minutes * MIN_TO_MS

  // include seconds
  const seconds = Math.floor(duration / SEC_TO_MS)
  result += toTwoDigit(seconds)
  
  return result
}

/**
 * Return a string representing a date
 * @param date Date to convert
 */
export function dateToString(date: Date): string {
  const formater = new Intl.DateTimeFormat("en" , {
    month: 'short', day: 'numeric', hour: "numeric", minute: '2-digit', hour12: true,
  });
  return formater.format(date)
}

export function minimalDateToString(date: Date): string {
  // e.g., Tues, 3/12
  const formater = new Intl.DateTimeFormat("en" , {
    weekday: "short", month: 'numeric', day: 'numeric'
  });
  return formater.format(date)
}

export function shortenedDateToString(date: Date): string {
  const formater = new Intl.DateTimeFormat("en" , {
    weekday: "long",
    month: 'long', day: 'numeric'
  });
  return formater.format(date)
}

export function getClosestDayOfWeek(date: Date, dayOfWeek: number /* 0 = Sunday to 6 = Saturday */): Date {
  date = new Date(date)  // copy before we mutate stuff in place
  const offset = (dayOfWeek - date.getDay() + 10) % 7 - 3  // difference but wrapped to [-3, 3]
  date.setDate(date.getDate() + offset)  // setDate overflows intelligently
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

export function getClosestMonday(date: Date): Date {
  return getClosestDayOfWeek(date, 1)
}

export function getClosestSunday(date: Date): Date {
  return getClosestDayOfWeek(date, 0)
}