import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { Typography } from "interfaces/typography";
import { WidgetType } from "../Widget";


const pulse = keyframes`
  0%,10% {
    opacity: 0;
    transform: scaleX(1.1) scaleY(1.1);
  }
  80% {
    opacity: 0.7;
    transform: scaleX(1.15,1.5) scaleY(1.05, 1.25);
  }
  81%, 100% {
    opacity: 0;
    transform: scaleX(1) scaleY(1);
  }
`;

const Scale = keyframes`
  0% {
    transform: scaleY(1) scaleY(1);
  }
  35%, 80% {
    transform: scaleX(1.1,1.4) scaleY(1.05, 1.25);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
`;


type PulsatingProps = {
    visible: boolean,
    widgetType: WidgetType,
    onClick?: () => void,
    noPulsate?: boolean
}

export const Pulsating = (props: PulsatingProps) => {

  const MAX_LAYERS = props.noPulsate ? 1 : 2;


  // @ts-ignore
  const Pulse = styled.div`
    animation: ${({ id = "0"}) => (parseFloat(id) ? pulse : Scale)} 2s infinite;
    background: rgb(60, 71, 88);
    border: 1px solid ${({ color = '' }) => color};
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 10px;
    z-index: ${({id = "0" }) => MAX_LAYERS - parseFloat(id)};
  `;

  const Container = styled.div`
  z-index: ${MAX_LAYERS + 1};
  cursor: pointer;
  border-radius: 10px;
  ;`;

  const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: ${({ id = "0" }) => parseFloat(id) ? '100' :'110'}%;
  height: 100%;
  border-radius: 10px;
  `;

  return (
    <Wrapper>
      <Container onClick={props.onClick?.bind(this)}>
        <div className="flex flex-col gap-1 justify-center items-center text-center" style={{'zIndex': 9999, 'padding': '5px', 'borderRadius': '10px'}}>
        <Typography variant="largeParagraph" color={'white'}>
            {props.widgetType === WidgetType.AI_AUTOMATION_EMAIL ? 'Request email' : 
            props.widgetType === WidgetType.AI_AUTOMATION_SUMMARY ? 'Request summary' : 
            props.widgetType === WidgetType.AI_AUTOMATION_COACHING ? 'Request coaching' :
            props.widgetType === WidgetType.CUSTOM_METRIC ? 'Request custom metric' : 'Request'}
        </Typography>
        </div>
        </Container>
      {props.visible &&
        Array.from(Array(MAX_LAYERS).keys()).map((key) => (
          <Pulse key={key} id={key.toString()}/>
        ))}
    </Wrapper>
  );
};

Pulsating.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  visible: PropTypes.bool,
  width: PropTypes.number
};

Pulsating.defaultProps = {
  color: "#FFE896",
  height: "100%",
  visible: false,
  width: "100%",
  backgroundColor: "#FFE896" 
}
