import React from "react";
import { rightArrowSmall, bulletIcon } from "icons";

import './foldy.css';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    startOpen?: boolean,
    forceOpen?: boolean,
    head?: React.ReactNode,
    onlyArrowClickToFold?: boolean,
}

type State = {
    open: boolean,
}

export class Foldy extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            open: props.startOpen ?? true,
        }
    }

    _headRef: React.RefObject<HTMLDivElement> = React.createRef()
    _iconRef: React.RefObject<HTMLDivElement> = React.createRef()

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (typeof prevProps.startOpen === 'undefined' && typeof this.props.startOpen !== 'undefined') {
            this.setState({open: this.props.startOpen})
        }
    }

    onClick(e: React.MouseEvent) {
        if (!this.props.onlyArrowClickToFold || e.target === this._headRef.current || this._iconRef.current?.contains(e.target as Node)) {
            this.setState(state => { return {'open': !state.open} })
        }
    }

    render(): React.ReactElement {
        const empty = typeof this.props.children === 'undefined' || this.props.children === null
            || (this.props.children instanceof Array && this.props.children.length === 0)
        return <div
            className={[
                'foldy',
                empty ? 'foldy-empty': '',
                (this.state.open || this.props.forceOpen) && !empty ? 'foldy-open' : '',
                this.props.className ?? ''].join(" ")}
        >
            <div ref={this._headRef} className="foldy-head" onClick={(e) => { if (!empty) { this.onClick(e) } }}>
                <div ref={this._iconRef} className="foldy-icon">
                    {empty ? bulletIcon : rightArrowSmall}
                </div>
                {this.props.head}
            </div>
            <div className="foldy-contents">
                {this.props.children}
            </div>
        </div>
    }
}
