import { RequestState } from "components/Widgets/Automation"
import { AGENT_TYPE } from "interfaces/db"
import { PreviousCallSummary, SummarizerResult } from "interfaces/services"

export type AGENT_DATA = {
    status: RequestState,
    url: string | null,
    has_data: boolean
    info?: null | SummarizerResult
    scraped_data?: string | null
    error_reason?: string | null
}

export const jsonSafeToAgentData = (data: any): AGENT_DATA => { return {status: data.status, url: data.url, has_data: data.has_data, info: data.info, scraped_data: data.scraped_data } }

export type NUMBER_INFO = {
    numbers: string[]
    summaries: null | PreviousCallSummary[]
    
    transcripts_found: null | number
}

export const jsonSafeToNumberInfo = (data: any): NUMBER_INFO => { 
    return {numbers: data.numbers, summaries: data.summaries, transcripts_found: data.transcripts_found} }

export type EMAIL_INPUT_DATA = {
    prospect_name: string | null
    prospect_company: string | null
    prospect_title: string | null
    agent_data: {[k in AGENT_TYPE]: AGENT_DATA}
    number_info: NUMBER_INFO
}
