import React from 'react'
import { Outlet } from "react-router-dom";
import { SideBar } from 'components/SideBar'
import { Loader } from 'components/Loader';

export class App extends React.Component {
  
  render(): JSX.Element {
    return (
      <div className='flex select-none w-screen h-screen overflow-hidden' style={{'backgroundColor': "#FBFBFB"}}>
        <SideBar/>
        <React.Suspense fallback={<><Loader /></>}>
        <div className='overflow-auto h-full flex-1 w-full'>
          <div className='h-full w-full'>
          <Outlet />
          </div>
        </div>
        </React.Suspense>
      </div>
    )
  }
}
