import React from "react"
import { EMAIL_INPUT_DATA, NUMBER_INFO } from "./interfaces"
import { Typography } from "interfaces/typography"
import { SummarizedCallHistory } from "components/SummarizedCallHistory"

export type TranscriptInputBodyProps = {
    current_data: EMAIL_INPUT_DATA
    onTranscriptSelect: (transcript_id: string) => void
}

export class TranscriptEmailInputBody extends React.Component<TranscriptInputBodyProps, {}> {
    constructor(props: TranscriptInputBodyProps) { super(props) }
    renderDefaultTranscriptSection() {
        return <div className="w-full h-full flex-grow flex gap-3 justify-content items-center text-center">
            <Typography className="w-full" variant="largeParagraph">
                {!this.props.current_data.number_info.summaries && this.props.current_data.number_info.numbers.length > 0 ? 'Detecting transcripts..' : 'No calls detected'}
            </Typography>
        </div>
    }

    renderTranscriptData(data: NUMBER_INFO) {
        return <SummarizedCallHistory noDecorations={true} updatedSelectedSessionId={this.props.onTranscriptSelect.bind(this)} previousCallSummaries={data.summaries} pageClosed={false} />
    }
    
    render() {
        if (!this.props.current_data.number_info || !this.props.current_data.number_info.summaries || this.props.current_data.number_info.summaries.length === 0) return this.renderDefaultTranscriptSection()
        return this.renderTranscriptData(this.props.current_data.number_info)
    }
}