import { MICRO_TO_MS, SEC_TO_MS } from "cfg/const"
import { TextBlock } from "components/TextBlock"
import { HistoricalData, PreviousCallData, WeatherLocationInfo } from "interfaces/services"
import { Typography } from "interfaces/typography"
import React from "react"
import { durationToString } from "time"

export type CallInfoProps = {
  timeElapsed: number
  sessionActive: boolean
  weatherLocationInfo?: WeatherLocationInfo 
  historicalData?: HistoricalData
  previousCallData?: PreviousCallData
  minimizedFormat?: boolean
  showLeaderboard?: boolean
  callActive?: boolean
  showPastTranscript?: () => void,
}

const nonActivePhoneGraphic =
<svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.8513 16.1224C21.5385 15.8479 21.1274 15.7015 20.7049 15.7141C20.2824 15.7267 19.8814 15.8973 19.5868 16.19L15.691 20.058C14.7533 19.8851 12.8681 19.3177 10.9275 17.4489C8.98695 15.5739 8.39924 13.7491 8.22505 12.85L12.2283 9.0873C12.5317 8.80301 12.7087 8.41588 12.7218 8.00784C12.7349 7.59979 12.583 7.20285 12.2983 6.90101L6.28286 0.515046C5.99803 0.212261 5.60217 0.0285999 5.17933 0.0030677C4.7565 -0.0224645 4.34002 0.112144 4.01833 0.378305L0.485599 3.30331C0.204138 3.57603 0.0361438 3.939 0.0134829 4.32336C-0.0109369 4.7163 -0.476541 14.0241 6.99917 21.2447C13.5209 27.5395 21.6901 28 23.94 28C24.2689 28 24.4707 27.9906 24.5245 27.9874C24.9225 27.9659 25.2983 27.803 25.5794 27.5301L28.6074 24.1178C28.8833 23.8074 29.023 23.4055 28.9969 22.9972C28.9708 22.589 28.7808 22.2067 28.4674 21.9315L21.8513 16.1224Z" fill="#3C4758"/>
</svg>

const activePhoneGraphic = <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.6704 14H32C32 6.01911 25.5522 0 17 0V3.11146C23.7625 3.11146 28.6704 7.68996 28.6704 14Z" fill="#B7DEC0"/>
<path d="M16.2803 9.56743C19.704 9.56743 21.1643 10.9773 21.1643 14.2826H24.4203C24.4203 9.21379 21.5306 6.42395 16.2803 6.42395V9.56743ZM21.8513 18.1224C21.5385 17.8479 21.1274 17.7015 20.7049 17.7141C20.2824 17.7267 19.8814 17.8973 19.5868 18.19L15.691 22.058C14.7533 21.8851 12.8681 21.3177 10.9275 19.4489C8.98695 17.5739 8.39924 15.7491 8.22505 14.85L12.2283 11.0873C12.5317 10.803 12.7087 10.4159 12.7218 10.0078C12.7349 9.59979 12.583 9.20285 12.2983 8.90101L6.28286 2.51505C5.99803 2.21226 5.60217 2.0286 5.17933 2.00307C4.7565 1.97754 4.34002 2.11214 4.01833 2.3783L0.485599 5.30331C0.204138 5.57603 0.0361438 5.939 0.0134829 6.32336C-0.0109369 6.7163 -0.476541 16.0241 6.99917 23.2447C13.5209 29.5395 21.6901 30 23.94 30C24.2689 30 24.4707 29.9906 24.5245 29.9874C24.9225 29.9659 25.2983 29.803 25.5794 29.5301L28.6074 26.1178C28.8833 25.8074 29.023 25.4055 28.9969 24.9972C28.9708 24.589 28.7808 24.2067 28.4674 23.9315L21.8513 18.1224Z" fill="#3C4758"/>
</svg>

type CallInfoState = {
  isViewingTranscript: boolean
}

function timeSincePlualization(val: number, time_str: string, shortened?: boolean) {
  const floor_val = Math.floor(val)
  return floor_val === 1 ? `${floor_val} ${time_str}${shortened ? '' : ' ago'}` : `${floor_val} ${time_str}s${shortened ? '' : ' ago'}`
}

export function timeSince(dateInMicroSeconds: number, onlyUpToMinute?: boolean, shortened?: boolean): string {
  const seconds = Math.floor((new Date().getTime() - dateInMicroSeconds*MICRO_TO_MS) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) {
    return timeSincePlualization(interval, shortened ? "yr" : "year", shortened)
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return timeSincePlualization(interval, shortened? "mo" : "month", shortened)
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return timeSincePlualization(interval, shortened ? "d" : "day", shortened)
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return timeSincePlualization(interval, shortened ? "hr" : "hour", shortened)
  }
  interval = seconds / 60;
  if (interval > 1) {
    return timeSincePlualization(interval, shortened ? "min" : "minute", shortened)
  } else if (onlyUpToMinute) {
    return '<1 min'
  }
  return timeSincePlualization(seconds, "second", shortened)
}

  
export class CallInfo extends React.Component<CallInfoProps, CallInfoState> {

constructor(props: CallInfoProps) {
    super(props)
    this.state = {
      'isViewingTranscript': false
    }
}

_renderLineSplitHeaderRecallSection(label: string): JSX.Element {
    return (
    <div className='items-center flex flex-row justify-between'>
        <hr className='w-1/4' style={{'height': '2.2px', backgroundColor: '#131414'}} />
        <span style={{'width': '100%', fontWeight: 'bold'}}>
              <Typography variant='h5' color="#131414">
                {label}
              </Typography>
        </span>
        <hr className='w-1/4' style={{'height': '2px', backgroundColor: '#131414'}} />
  </div>)
  }

  /**
   * Render a short time string of current time with specified UTC offset
   * @param {number} offsetSeconds seconds east of UTC
   */
  _renderLocalTime (offsetSeconds: number) {
    const now = new Date()
    // Date.getTimezoneOffset returns minutes west of UTC
    // but I didn't read the docs carefully so this might go very badly near DST changes
    const offsetFromHere = offsetSeconds + now.getTimezoneOffset() * 60
    const offsetTime = new Date(now.getTime() + offsetFromHere * 1000)
    return offsetTime.toLocaleTimeString([], {timeStyle: "short"})
  }

  _renderWeatherLocationInfo(): JSX.Element {
  return (
    <div className='flex flex-col'>
    <div className='flex flex-row gap-1'>
      <div>
      <Typography variant='smallParagraph' color='#3C4758' style={{'fontSize': '9px'}}>
      {this.props.weatherLocationInfo?.location}
      </Typography>
      </div>
      <div style={{'borderRight' : '1px #3C4758 solid'}}>
      </div>
      <div>
        <Typography variant='smallParagraph' color='#3C4758' style={{'fontSize': '9px'}}>
        {this.props.weatherLocationInfo?.timeOffsetUtc ? this._renderLocalTime(this.props.weatherLocationInfo?.timeOffsetUtc) : null}
      </Typography>
      </div>
    </div>
    <div>
     <div className='flex flex-row gap-1'>
      <Typography variant='smallParagraph' color='#3C4758' style={{'fontSize': '9px'}}>
      {this.props.weatherLocationInfo?.weatherTemperature ? Math.trunc(this.props.weatherLocationInfo.weatherTemperature) + String.fromCharCode(176) + 'F' : null}
      </Typography>
      { this.props.weatherLocationInfo?.weatherImgUrl ?
     <img src={this.props.weatherLocationInfo.weatherImgUrl} style={{'width': '15px', 'height': '15px', 'backgroundColor': 'darkgray', 'borderRadius': '15px'}} /> : null}
     </div>
    </div>
    </div>)
  }

  _renderCoachingCallInfo(): JSX.Element {
    return (
    <div className='flex flex-col'>
    <div>
    <Typography variant='h1' color='#3C4758'>
        {this.props.weatherLocationInfo?.prospectName ? this.props.weatherLocationInfo?.prospectName : this.props.sessionActive ? 'Prospect' : 'Ready'}
      </Typography>
    </div>
    {this.props.sessionActive && this.props.weatherLocationInfo?.location && this.props.weatherLocationInfo.timeOffsetUtc && this.props.weatherLocationInfo.weatherImgUrl && this.props.weatherLocationInfo.weatherTemperature ? 
    this._renderWeatherLocationInfo() : null}
  </div>)
  }

  _renderCallTime(): JSX.Element {
    return (
      <div className='flex-grow flex flex-col'>
        <div className='flex flex-row justify-end items-center gap-1'>
          <div style={{'borderRadius': '50%', width: '12px', height: '12px', 'backgroundColor': this.props.sessionActive ? '#AA5855' : '#495164'}}>
          </div>
          <Typography variant='largeParagraph'>
                {durationToString(this.props.timeElapsed)}
          </Typography>
        </div>
        <div className='flex justify-end'>
          <Typography variant='caption'>
            {this.props.sessionActive ? 'Active' : 'Idle' } 
          </Typography>
        </div>

      </div>
    )
  }
  
  _renderCoachingCallHeader(): JSX.Element {
    return (
    <div className='w-full flex flex-row items-center pt-2'>
        <div className='gap-3 flex flex-row items-center'>
          {this.props.sessionActive ? activePhoneGraphic : nonActivePhoneGraphic}
          {this._renderCoachingCallInfo()}
        </div>
        <div className='flex-grow'>
          {this._renderCallTime()}
        </div>
    </div>
    )
  }
  
  _renderHistoryInLineState(label: string, value: string | JSX.Element) {
    return (
      <div className='flex flex-row items-center'>
          <div className='mr-1' style={{fontWeight: 'bold'}}>
          <Typography variant='h5' color='#131414'>
            {label}:
            </Typography>
          </div>
          <div>
            <Typography variant='mediumParagraph' color='#131414'>
            {value}
            </Typography>

          </div>
      </div>
    )
  }

  _renderDailyHistory(): JSX.Element | null{
    if (!this.props.historicalData) return null
    const inactive = this.props.sessionActive ? ' inactive' : ''
    const topClassLevel = 'flex flex-col' + ' collapsible' + inactive
    return <div className={topClassLevel}>
      {this._renderHistoryInLineState('Total Dials Today', this.props.historicalData.numberDialsToday.toString())}
    </div>
  }

  _formatLastCallDurationAndTime(lastCallTime: number, lastCallDuration: number | null): string {
    if (lastCallDuration) {
      return durationToString(lastCallDuration*SEC_TO_MS) + " - " + timeSince(lastCallTime)
    } else {
      return timeSince(lastCallTime)
    }
  }

  _renderSummaryInfo(): JSX.Element | null {
    if (!this.props.previousCallData || !this.props.previousCallData.previousCallSummaries || this.props.previousCallData.previousCallSummaries?.length == 0) return null
    const lastCall = this.props.previousCallData.previousCallSummaries[0]
    const notes = (lastCall.notes && lastCall.notes.length > 0 && lastCall.notes.map((v) => v.note).join('').trim() !== '' ? lastCall.notes.map((v) => v.note).join(' | ') : lastCall.sessionMetric ? lastCall.sessionMetric.genSummaryText : '') ?? ''
    if (notes.trim() !== '') {
      return <div className="flex flex-col">
        {this._renderLineSplitHeaderRecallSection('Previous call note')}
        <TextBlock maxLines={this.props.minimizedFormat ? 1 : 3} variant={'mediumParagraph'} color={'#131414'} text={(lastCall.notes && lastCall.notes.length > 0 ? lastCall.notes.map((v) => v.note).join(' | ') : lastCall.sessionMetric ? lastCall.sessionMetric.genSummaryText : '') ?? ''} />
      </div>
    } else {
      return null
    }
  }

  _formatLastCallTranscript(): JSX.Element {
    return (
      <Typography onClick={(e) => {e.stopPropagation(); this.props.showPastTranscript?.bind(this)()}} className='cursor-pointer' variant='mediumParagraph' color='#0000EE'>
      Click Here
      </Typography>
    )
  }

  _renderPreviousProspectCallInfo(): JSX.Element | null{
    if (!this.props.previousCallData || !this.props.previousCallData.previousCallSummaries || this.props.previousCallData.previousCallSummaries?.length == 0) return null
    const lastCall = this.props.previousCallData.previousCallSummaries[0]
    const dispositions = (lastCall.dispositions ?? []).filter((v) => v.label.toLowerCase().includes('disposition') || v.label.toLowerCase().includes('sentiment'))
    return <div className='flex flex-col'>
     {lastCall.scheduledStart ? this._renderHistoryInLineState(`${this.props.callActive ? 'Last' : 'Prior'} Call`, this._formatLastCallDurationAndTime(lastCall.scheduledStart, lastCall.duration)) : null}
     {lastCall.sessionId ? this._renderHistoryInLineState(`${this.props.callActive ? 'Last': 'Prior'} Call\'s Transcript`, this._formatLastCallTranscript()) : null}
     {(this.props.callActive || !this.props.showLeaderboard) && dispositions.length > 0 ?
        this._renderHistoryInLineState('Disposition', dispositions.map((v) => v.disposition).join(', ')) : null}
    </div>
  }

  render():JSX.Element {
    return (
      <div className='w-full' style={{'transition': 'background-color border-bottom-color 1s', 'height': 'fit-content', 'backgroundColor': this.props.sessionActive ? '#f6f9f7' : "#e9ebf1", 'borderBottom': '6px solid', 'borderBottomColor': this.props.sessionActive ? '#5DD077' : "#3C4758", 'paddingLeft': '4%', 'paddingRight': '4%', 'paddingBottom': '5px'}}>
          {this._renderCoachingCallHeader()}
          {this._renderPreviousProspectCallInfo()}
          {this.props.showLeaderboard ? null : this._renderDailyHistory()}
          {this._renderSummaryInfo()}
      </div>)
  }
}