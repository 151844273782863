import { Typography } from 'interfaces/typography';

import React from 'react'
import './image_background.css'

export enum WidgetType {
    SUGGESTIONS = 'Suggestions',
    BEHAVIORAL = 'Behavioral',
    CADENCE = 'Latest Pace',
    SIGNAL = 'Signal',
    TALK_TIME = 'Talk Time',
    TRANSCRIPT = 'Transcript',
    CUSTOM_METRIC = 'Custom Metric',
    AI_AUTOMATION_COACHING = 'AI Automation - Coaching',
    AI_AUTOMATION_SUMMARY = 'AI Automation - Summary',
    AI_AUTOMATION_EMAIL = 'AI Automation - Email',
    OPENER = 'OPENER',
    POPOUT = 'POPOUT',
    LEADERBOARD = 'Leaderboard',
    FLOW_STATE_PRE_CALL = 'Flow State - Pre call'
  }
  
  
export enum BackgroundTypes {
  CROWN = 'CROWN',
  DIAMOND = 'DIAMOND',
  MONEY = 'MONEY',
  SWORD = 'SWORD'
}

const BackgroundTypesToBackgroundCSS: {[k in BackgroundTypes]: string} = {
  [BackgroundTypes.SWORD]: 'sword',
  [BackgroundTypes.CROWN]: 'crown',
  [BackgroundTypes.DIAMOND]: 'diamond',
  [BackgroundTypes.MONEY]: 'money'
}

export interface WithWidgetProps {
    maxHeight?: string,
    lastWidget?: boolean, 
    heightFull?: boolean,
    minHeight?: string
    additionalBoxShadow?: boolean,
    onStarClick?: () => void, 
    isStarred?: boolean,
    isNotCoachingWidget?: boolean
    customLink?: string,
    noHeader?: boolean,
    customPadding?: boolean
    newWidget?: boolean
    backgroundColor?: string
    backgroundImage?: BackgroundTypes
    zIndex?: number
  }

const dashboardGraphic = 
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 9V12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H11M5 17H15M7 13V17M13 13V17M15 1H19M19 1V5M19 1L14 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>


const unselectedStarGraphic = 
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0002 0.833374L12.8327 6.57171L19.1668 7.49754L14.5835 11.9617L15.6652 18.2684L10.0002 15.2892L4.33516 18.2684L5.41683 11.9617L0.833496 7.49754L7.16766 6.57171L10.0002 0.833374Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const selectedStarGraphic = 
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.99967 0.833984L12.8322 6.57232L19.1663 7.49815L14.583 11.9623L15.6647 18.269L9.99967 15.2898L4.33467 18.269L5.41634 11.9623L0.833008 7.49815L7.16717 6.57232L9.99967 0.833984Z" fill="#006116" stroke="#006116" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const withWidget = <P extends object>(Component: React.ComponentType<P>, widgetType: WidgetType) =>
  class withWidget extends React.Component<P & WithWidgetProps> {
      _renderWidgetFooter(): JSX.Element {
          return  (
              <div className='justify-between align-center flex flex-row items-center w-full' style={{'backgroundColor': '#3C4758', 'borderRadius': '0 0 10px 10px', 'bottom': 0, left: 0, paddingLeft: '6px', paddingRight: '6px'}}>
                <div className='justify-between align-center flex flex-row items-center w-full'>
                  <div style={{'color': 'white'}}>
                    <Typography variant='mediumParagraph'>
                    {widgetType}
                    </Typography>
                  </div >
                  <div className='justify-end flex flex-row gap-3'>
                    {widgetType === WidgetType.TRANSCRIPT && this.props.isStarred && this.props.onStarClick ? 
                      <div className='cursor-pointer'onClick={() => {if (this.props.onStarClick) this.props.onStarClick()}}>
                          {this.props.isStarred ? selectedStarGraphic : unselectedStarGraphic}
                    </div> : null}
                  </div>
                </div>
              </div>)
      }

      render() {
          const { maxHeight, heightFull,lastWidget, additionalBoxShadow, onStarClick, noHeader, customPadding, minHeight, ...props } = this.props;
          const updateFlexGrow: React.CSSProperties = lastWidget ? {'flex': '1 1 0%'} : {}
          const fullHeight: React.CSSProperties = heightFull ? {'height': '100%'} : {}

          return (
            <div className={('w-full flex flex-col justify-end min-h-0 gap-2') + (props.backgroundImage ? ` background ${BackgroundTypesToBackgroundCSS[props.backgroundImage]}` : '')} 
            style={{
              ...updateFlexGrow, 
              ...fullHeight, 
              'padding': customPadding ? 'initial' : '8px',
              'boxShadow': "rgb(0 0 0 / 50%) 0.25px 3px 10px",
              'transitionProperty': 'box-shadow, background-color, color, min-height, height, max-height, width, max-width, z-index, opacity',
              'transitionDuration': '1.5s',
              'transitionTimingFunction': 'linear',
              'backgroundColor': props.backgroundColor ?? '#FFFFFF', 
              'minHeight': minHeight ?? '0px',
              'zIndex': this.props.zIndex ?? 'inherit',
              borderRadius: '10px', 
              border: '1px solid rgba(91,101,123,0.25)'}}>
                {props.newWidget ? <div className='relative w-0 h-0'>
                <div className='absolute p-1 text-center'
                style={{
                  'backgroundColor': '#45103E',
                  'borderRadius': '10px',
                  'color': 'white',
                  'bottom': '-5px',
                  'right': '-20px',
                  'boxShadow': "rgb(0 0 0 / 50%) 0.25px 3px 10px",
                }}
                >
                <Typography variant='largeParagraph'>
                  New!
                </Typography>
                </div>
              </div>: null}
                {noHeader ? null : <Typography variant="largeParagraph" fontWeight={800}>
                    {widgetType}
                </Typography>}

                <Component {...props as P}/>
            </div>)
        }
  };




