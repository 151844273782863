import { AGENT_TYPE, EmailValueProp } from "interfaces/db"
import { Typography } from "interfaces/typography"
import React from "react"
import { AgentInputBody } from "./AgentInputBody"
import { TranscriptEmailInputBody } from "./TranscriptEmailInputBody"
import { EMAIL_INPUT_DATA } from "./interfaces"
import { EMAIL_INPUT } from "interfaces/services"
import { connect } from "react-redux"
import { RootState } from "store"

export type EmailInputProps = { 
    data: EMAIL_INPUT_DATA,
    selected_section: EMAIL_INPUT,
    onSectionUpdate: (section: EMAIL_INPUT) => void,
    onTranscriptSelect: (transcript_id: string) => void
    onEmailRequest: () => void,
    email_value_props: EmailValueProp[] | null
}

export type EmailInputState = { selected_section: EMAIL_INPUT }

const EMAIL_INPUTS_TO_TEXT: {[k in EMAIL_INPUT]: string} = {
    [EMAIL_INPUT.PROSPECT_NAME]: 'Prospect Name',
    [EMAIL_INPUT.PROSPECT_COMPANY]: 'Prospect Company',
    [EMAIL_INPUT.PROSPECT_TITLE]: 'Prospect Title',
    [EMAIL_INPUT.LINKEDIN]: 'LinkedIn',
    [EMAIL_INPUT.WEBSITE]: 'Company',
    [EMAIL_INPUT.TRANSCRIPTS]: 'Calls',
}

class ProspectOverviewPageImpl extends React.Component<EmailInputProps> {
    constructor(props: EmailInputProps) {
        super(props)
    }
    
    renderProfilePic() {
        const prospect_name = this.props.data.prospect_name
        return <div className="w-7 h-7 p-1 flex items-center text-center justify-center" 
        style={{
            'borderRadius': '50%', 
            'backgroundColor': '#ACE7BA',
            'boxShadow': 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'    
        }}>
            <Typography variant="largeParagraph" fontWeight={650}>
                {prospect_name ? prospect_name[0] : 'P'}
            </Typography>
        </div>
    }

    renderProfileName() {
        return <Typography fontSize={14} fontWeight={550} variant="largeParagraph">
            {this.props.data.prospect_name ?? 'Unknown Prospect'}
        </Typography>
    }

    renderCompanyJobTitle() {
        const company = this.props.data.prospect_company
        const title = this.props.data.prospect_title
        if (!company && !title) return null
        const text = [title, company].filter((v) => v).join(' at ')
        return <Typography variant="largeParagraph">
            {text}
        </Typography>
    }

    renderProfileInformation() {
        return <div className="w-full flex flex-grow flex-col gap-0.5">
            {this.renderProfileName()}
            {this.renderCompanyJobTitle()}
        </div>
    }

    renderProfileDataSection() {
        return <div className="w-full flex flex-row items-center gap-2.5">
            {this.renderProfilePic()}
            {this.renderProfileInformation()}
        </div>
    }


    renderProfileSection() {
        if (!this.props.data.prospect_name && !this.props.data.prospect_company && !this.props.data.prospect_title) return null
        return <div className="pl-3.5 pr-3.5 w-full flex flex-col gap-1 items-center">
            {this.renderProfileDataSection()}
            {this.props.email_value_props && this.props.email_value_props.length > 0 ? this.renderPersonalizeButton() : null}
        </div>
    }

    _canApplyPersonlization() {
        if (!this.props.email_value_props || this.props.email_value_props.length === 0) return false
        if (!this.props.data.agent_data[AGENT_TYPE.LINKEDIN].info?.summary && !this.props.data.agent_data[AGENT_TYPE.COMPANY_WEBSITE].info?.summary) return false
        if (!this.props.data.prospect_name  && !this.props.data.prospect_company&& !this.props.data.prospect_title) return false
        return true
    }

    renderPersonalizeButton() {
        return <button onClick={() => {
            if (!this._canApplyPersonlization()) return
            this.props.onEmailRequest.bind(this)()
        }}
        className="p-2 w-full opacity-85 hover:opacity-100" style={{
            cursor: !this._canApplyPersonlization() ? 'not-allowed' : 'pointer',
            borderRadius: '8px',
            background: !this._canApplyPersonlization() ? 'lightgrey' : 'linear-gradient(45deg, rgb(106, 17, 203), rgb(37, 117, 252)) right center'
        }}>
            <Typography variant="largeParagraph" color="white" fontWeight={650}>
                Create personalized email
            </Typography>
        </button>
    }

    renderBreaker() { return <div className="w-full bg-gray-300" style={{height: '1px'}}></div> }
    
    renderSectionOption(input: EMAIL_INPUT) {
        const selected = this.props.selected_section === input
        return <div 
        onClick={() => this.props.onSectionUpdate(input)}
        className="w-fit pt-0.5 pb-0.5 pl-1 pr-1 text-center items-center flex flex-row justify-center cursor-pointer" 
        style={{
            'userSelect': 'none',
            'borderRadius': '8px', 
            'border': '1px solid grey',
            'backgroundColor': selected ? 'rgb(218 196 241)' : 'white',
            }}>
            <Typography variant="mediumParagraph">
                {EMAIL_INPUTS_TO_TEXT[input]}
            </Typography>
        </div>
    }

    renderSectionOptions() {
        return <div className="w-full pl-3.5 pr-3.5 flex flex-row flex-wrap items-center gap-2">
            {this.renderSectionOption(EMAIL_INPUT.TRANSCRIPTS)}
            {this.renderSectionOption(EMAIL_INPUT.WEBSITE)}
            {this.renderSectionOption(EMAIL_INPUT.LINKEDIN)}
        </div>
    }



    renderSectionContent() {
        switch (this.props.selected_section) {
            case EMAIL_INPUT.TRANSCRIPTS: return <TranscriptEmailInputBody onTranscriptSelect={this.props.onTranscriptSelect.bind(this)} current_data={this.props.data} /> 
            case EMAIL_INPUT.WEBSITE: return <AgentInputBody agent_type={AGENT_TYPE.COMPANY_WEBSITE} current_data={this.props.data} />
            case EMAIL_INPUT.LINKEDIN: return <AgentInputBody agent_type={AGENT_TYPE.LINKEDIN} current_data={this.props.data} />
        }
    }

    renderSectionInfo() {
        return <div className="h-full w-full flex flex-grow overflow-y-auto" style={{
            paddingLeft: this.props.selected_section === EMAIL_INPUT.TRANSCRIPTS ? '6px' : '14px',
            paddingRight: this.props.selected_section === EMAIL_INPUT.TRANSCRIPTS ? '6px' : '14px',
        }}>
            {this.renderSectionContent()}
        </div>
    }
    
    render() {
        const profile_section = this.renderProfileSection()
        return (
        <div className="pt-3.5 w-full h-screen flex flex-col gap-3">
            {profile_section}
            {profile_section ? this.renderBreaker() : null}
            {this.renderSectionOptions()}
            {this.renderSectionInfo()}
        </div>
        );
    }
}


const mapStateToProps = (state: RootState) => {
        return {
            email_value_props: state.emailValueProps.value
        };
};
    
const ReduxWrapped = connect(mapStateToProps)(ProspectOverviewPageImpl)
  
  export { ReduxWrapped as ProspectOverviewPage}