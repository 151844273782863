import { logInfoColored } from "core";

const _LOG_SCOPE: string = `[Trellus][Network]`
const _LOG_COLOR: string = '#F0FFFF'


export const HTTP_STATUS_NAME_OK = "OK"
export const HTTP_STATUS_NAMES = new Map<number, string>([
  [200, HTTP_STATUS_NAME_OK],
  [401, "Unauthorized"],
  [403, "Forbidden"],
  [500, "Internal Server Error"],
  [501, "Not Implemented"],
  [502, "Bad Gateway"],
  [503, "Service Unavailable"],
])


export async function responseToJson(response: Response) {
  if (response.status === 200)
    return await response.json();
  else
    throw new Error(response.status.toString());
}

function logRequestStart(method: string, url: string, parameters: Record<string, any>): string {
  // get an id for the request
  const requestId: string = crypto.randomUUID()

  // log it
  const parametersSafe: Record<string, any> = {...parameters}
  if (parametersSafe['api_key'] != null) 
    parametersSafe['api_key'] = `${parametersSafe['api_key'].slice(0, 2)}...`
  logInfoColored(`${_LOG_SCOPE} ${requestId} ${method} ${url}`, _LOG_COLOR, parametersSafe)

  return requestId
}

function logRequestEnd(requestId: string, response: Response): void {
  logInfoColored(`${_LOG_SCOPE} ${requestId} ${response.status}`, _LOG_COLOR)
}

export async function simpleGet(url: string, parameters: Record<string, string>) {
  // formulate the request
  const recordId = logRequestStart('GET', url, parameters)
  const init: RequestInit = {'method': 'GET', 'headers': { 'Content-Type': 'text/plain' }}
  url = url + '?' + new URLSearchParams(parameters).toString()

  // make it
  const response = await fetch(url, init);
  
  // log and resolve
  logRequestEnd(recordId, response)
  return responseToJson(response)
}


export async function corsGet(url: string, headers: Record<string, any>, no_jsonification?: boolean) {
  const headersJsonified = Object.fromEntries(Array.from(Object.entries(headers)).map(([key, value]: [string, any]) => {
    return [key, JSON.stringify(value)?.replaceAll(/[\u007f-\uffff]/g, c => '\\u' + ('00' + c.charCodeAt(0).toString(16)).slice(-4))]
  }))
  // formulate the request
  const recordId = logRequestStart('GET', url, headersJsonified)
  const init: RequestInit = {'method': 'GET', 'headers': headersJsonified}

  // make it
  const response = await fetch(url, init);
  
  // log and resolve
  logRequestEnd(recordId, response)
  if (no_jsonification) return response
  return responseToJson(response)
}


export async function corsPut(url: string, parameters: Record<string, any>) {
  // formulate the request
  const recordId = logRequestStart('PUT', url, parameters)
  const body = JSON.stringify(parameters)
  const init: RequestInit = {'method': 'PUT', 'headers': { 'Content-Type': 'text/plain' }, 'body': body}

  // make it
  const response = await fetch(url, init);

  // log and resolve
  logRequestEnd(recordId, response)
  return response
}

export async function corsDelete(url: string, headers: Record<string, any>) {
  const headersJsonified = Object.fromEntries(Array.from(Object.entries(headers)).map(([key, value]: [string, any]) => {
    return [key, JSON.stringify(value)]
  }))
  // formulate the request
  const recordId = logRequestStart('DELETE', url, headersJsonified)
  const init: RequestInit = {'method': 'DELETE', 'headers': headersJsonified}

  // make it
  const response = await fetch(url, init);

 // log and resolve
 logRequestEnd(recordId, response)
 return response
}


export async function simplePost(url: string, parameters: object | FormData, textResponse?: boolean, noJsonifciation?: boolean) {
  // formulate the request
  const recordId = logRequestStart('POST', url, parameters)
  const headers: HeadersInit = {}
  const init: RequestInit = {'method': 'POST', 'headers': headers}
  if (parameters instanceof FormData) {
    init.body = parameters
  } else {
    init.body = JSON.stringify(parameters)
    headers['Content-Type'] = 'text/plain'
  }

  // make it
  const response = await fetch(url, init);
  if (response.status === 401) return null
  
  // log and resolve
  logRequestEnd(recordId, response)
  if (noJsonifciation) return response
  if (textResponse) return response.text()
  return responseToJson(response)
}


/**
 * Make a simple CORS request to the specified url by text encoding the body object.
 * Raise exceptions if the status is not OK
 * @param {string} url
 * @param {object} parameters
 * @param {boolean} isPost
 * @returns {Promise.<object>} Json decoded result
 */
export async function simpleFetchAndCheck(url: string, parameters: Record<string, any>, isPost=false) {
  const init: { [key: string]: any} = {
      'method': isPost ? 'POST' : 'GET',
      'headers': { 'Content-Type': 'text/plain' },
  }

  // encode parameters in either body (for post) or query string (for get)
  if (isPost)
      init['body'] = JSON.stringify(parameters)
  else
      url = url + '?' + new URLSearchParams(parameters).toString()

  // get an id for the request
  const requestId = crypto.randomUUID()

  // log it
  const parametersSafe = {...parameters}
  if (parametersSafe['api_key'] != null) 
  parametersSafe['api_key'] = `<length=${parametersSafe['api_key'].length}>`
  logInfoColored(`${_LOG_SCOPE} ${requestId} ${init['method']} ${url}`, _LOG_COLOR, parametersSafe)

  // make the request
  const response = await fetch(url, init);

  // log the status
  logInfoColored(`${_LOG_SCOPE} ${requestId} ${response.status}`, _LOG_COLOR)

  // store the response
  if (response.status === 200)
    return await response.json();
  else
    throw new Error(await response.text());
}