// time constants
export const SEC_TO_MS = 1000;
export const MIN_TO_MS = 60 * SEC_TO_MS;
export const HOUR_TO_MS = 60 * MIN_TO_MS;
export const DAY_TO_MS = 24 * HOUR_TO_MS;
export const MS_TO_SEC = 1 / SEC_TO_MS;
export const MS_TO_MIN = 1 / MIN_TO_MS;
export const MS_TO_HOUR = 1 / HOUR_TO_MS;
export const MS_TO_DAY = 1 / DAY_TO_MS;
export const MIN_TO_SEC = MIN_TO_MS * MS_TO_SEC;
export const SEC_TO_MIN = SEC_TO_MS * MS_TO_MIN;
export const HOUR_TO_SEC = HOUR_TO_MS * MS_TO_SEC;
export const SEC_TO_HOUR = SEC_TO_MS * MS_TO_HOUR;
export const DAY_TO_SEC = DAY_TO_MS * MS_TO_SEC;
export const SEC_TO_DAY = SEC_TO_MS * MS_TO_DAY;
export const MS_TO_MICRO = 1000;
export const MICRO_TO_MS = 1 / MS_TO_MICRO;

export const NEXHEALTH_TEAM_ID = '84ef3d0d3dd811eeb6995170fc8764c2'
export const TRELLUS_TEAM_ID = '82e867113da011ed913095cb8eb5a87e'
export const ATTYX_TEAM_ID = 'bb5111005cc046a9bf7af9bb7bb65c5e'
export const ELEVATEK12_TEAM_ID = '66ab676d9fb349e1956e388783afe7bc'
export const GRAINCLEANER_TEAM_ID = '6dab8d7ba54d431f9f8511cfe67c59d3'
export const GOODKIND_TEAM_ID = '86195901da664f698d81b0e465dc3562'
export const CAPITAL_GENERATIONS_TEAM_ID = '6114fc2ed774448fa0d392f38b603391'
export const VENACORP_TEAM_ID = '974aec16a0c611ed913095cb8eb5a87e'
export const TRENDHUNTER_TEAM_ID = 'd47d45f0f41a11edb6995170fc8764c2'
export const GAINSIGHT_TEAM_ID = 'ae8788c1e2334fae840397697bf069fb'
export const KILO_TEAM_ID = 'a646e13170ca46d1bf2a1f908edad7b0'
export const PIGMENT_TEAM_ID = 'aa207330f0ee43189d6462632c3f601d'
export const NINJAONE_TEAM_ID = 'abd03e132f5045f59bd58d6f3213c6bf'
export const PROPEL_TEAM_ID = '75c7c70ad119458fa7bca57b2cc4eb30'
export const ATOB_TEAM_ID = '3993daa7acf340d5b4325f7a75f6ac4a'
export const MAGIC_TEAM_ID = '6b3bc4cb316211eeb6995170fc8764c2'
export const MONEX_TEAM_ID = 'bfa03937fbf411edb6995170fc8764c2'
export const BEMO_TEAM_ID = '0a566ae6ab0e400fa00b4fb9e1845677'
export const FLOATCARD_TEAM_ID = 'a2854ba0692045c2893e6825bf05f410'
export const CLARION_TEAM_ID = '159546bf61074d308b35e6a31c02c388'
export const FOX_ORDERING_TEAM_ID = 'd8f7504f562c11eeb6995170fc8764c2'
export const SEESAW_TEAM_ID = '8973f3cf2f954ee9bc050d13bb48ac75'

export const ELIGIBLE_FOR_SPANISH = [
    FOX_ORDERING_TEAM_ID,
    MONEX_TEAM_ID,
    TRELLUS_TEAM_ID
]

export const _DEVELOPER_MODE = false