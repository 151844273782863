import styled from '@emotion/styled';
import React from 'react';

export type TypographyVariant = 'h1'
| 'h2'
| 'h3'
| 'h4'
| 'h5'
| 'h6'
| 'largeParagraph'
| 'mediumParagraph'
| 'smallParagraph'
| 'caption'
| 'smallCaption'

const largeFont = 'min(14px, max(13px, 2.1vw))'
export const mediumFont = 'min(13px, max(12px, 2.0vw))'
const smallFont = 'min(12px, max(11px, 1.8vw))'
const smallerFont = 'min(11px, max(10px, 1.6vw))'
const smallestFont = 'min(10px, max(8px, 1.5vw))'

export const fontFamily = 'Calibri,Arial,Helvetica,sans-serif';

export interface TypographyProps extends React.HTMLAttributes<HTMLParagraphElement> {
  children?: React.ReactNode;
  fontWeight?: number | string;
  color?: string;
  fontSize?: number | string;
  variant?: TypographyVariant
}

const StyledTypography = styled.p<TypographyProps>`
  margin: 0;
  ${({ variant }) => {
    switch (variant) {
      case 'h1':
        return `
        font-size: ${largeFont}; 
        line-height: 23px;
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: 800;

        `;
      case 'h2':
        return `
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: 400;
        font-size: ${largeFont};
        line-height: 23px;
        `;
      case 'h3':
        return `
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: 800;
        font-size: ${mediumFont};
        line-height: 20px;
        `;
      case 'h4':
        return `
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: 800;
        font-size: ${mediumFont};
        line-height: 20px;
        `;
      case 'h5':
        return `
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: 700;
        font-size: ${mediumFont};
        line-height: 18px;
        /* identical to box height, or 150% */
        
        text-align: center;
        letter-spacing: 0.02em;
        `;
      case 'smallParagraph':
        return `
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: 400;
        font-size: ${smallestFont};
        line-height: 15px;
        letter-spacing: 0.02em;
        `;      
      case 'mediumParagraph':
        return `
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: 400;
        font-size: ${smallFont};
        line-height: 18px;
        letter-spacing: 0.02em;
        `;      
      case 'largeParagraph':
        return `
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: 400;
        font-size: ${smallFont};
        line-height: 18px;
        letter-spacing: 0.02em;
        `;
      case 'caption':
        return `
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: 500;
        font-size: ${smallerFont};
        line-height: 18px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        `;
      case 'smallCaption':
        return `
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: 500;
        font-size: ${smallestFont};
        line-height: 14px;
        text-transform: uppercase;
        `
      default:
        return '';
    }
  }}
  ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight};` : '')}
  ${({ color }) => (color ? `color: ${color};` : `color: ${"black"};`)}
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize};` : '')}
`;

export const Typography: React.FC<TypographyProps> = ({ variant = 'smallParagraph', children, ...rest }) => {
  return (
    <StyledTypography variant={variant} {...rest}>
      {children}
    </StyledTypography>
  );
};