import { Filter, FilterProps } from "components/Filter";
import { Typography } from "interfaces/typography";
import { findParentByCallable } from "lib/user-agent";
import React, { RefObject } from "react";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";

const threeHorizontalIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 95.95" width="20" height="20">
  <path d="M8.94,0h105c4.92,0,8.94,4.02,8.94,8.94l0,0c0,4.92-4.02,8.94-8.94,8.94h-105C4.02,17.88,0,13.86,0,8.94l0,0 C0,4.02,4.02,0,8.94,0L8.94,0z M8.94,78.07h105c4.92,0,8.94,4.02,8.94,8.94l0,0c0,4.92-4.02,8.94-8.94,8.94h-105 C4.02,95.95,0,91.93,0,87.01l0,0C0,82.09,4.02,78.07,8.94,78.07L8.94,78.07z M8.94,39.03h105c4.92,0,8.94,4.02,8.94,8.94l0,0 c0,4.92-4.02,8.94-8.94,8.94h-105C4.02,56.91,0,52.89,0,47.97l0,0C0,43.06,4.02,39.03,8.94,39.03L8.94,39.03z"/>
</svg>

export function makeCell(
  contents: React.ReactNode, 
  extraClasses: string = "",
  noPadding?: boolean,
  forcedWidth?: string,
  additionalStyles?: React.CSSProperties,
  expandOptions?: {expanded: boolean, onStatusChange: () => void},
  numElements?: number,
): JSX.Element {
  const className = (noPadding ? [extraClasses, "text-center"] : [extraClasses, "text-center", "px-1", "py-3"]).join(" ").trim()
  return <td 
  className={className} 
  style={{width: forcedWidth ?? 'fit-content', maxWidth: '100px', ...(additionalStyles ?? {})}}>
      {expandOptions ? <div className={"text-center w-full justify-center items-center " + extraClasses + " flex flex-row gap-2.5"}>
        {renderArrow(expandOptions.expanded, expandOptions.onStatusChange, numElements === 0)}
        {contents}
      </div>: contents}
  </td>
}

function renderArrow(expanded: boolean, onClick: () => void, hide?: boolean) {
  return (
  <div onClick={(e) => {
    if (hide) return
    e.stopPropagation()
    onClick()
  }} className="cursor-pointer w-fit p-2.5 bg-white hover:bg-blue-300" style={{
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: '10px',
    opacity: hide ? '0%' : '100%' 
  }}>
  <span color='black' className="flex flex-col justify-center items-center"><span style={{
    'direction': 'ltr',
    listStyleType: 'none',
    'fontSize': '13px',
    'fontWeight': '400',
    'cursor': hide ? 'inherit' : 'pointer',
    color: 'black',
    'display': 'inline-block',
    'transition': 'transform 0.3s ease 0s',
    'borderRight': '2px solid currentcolor',
    'borderBottom': '2px solid currentcolor',
    'width': '5px',
    'height': '5px',
    'transform': expanded ? 'rotate(45deg)' : 'rotate(-45deg)'
  }}>
  </span></span>
  </div>)
}


export type TableState = {
  showFilterModal?: boolean[]
  permutation: number[]  // position in display -> position in props.items
  lastSortColumn: number | null
  lastSortAscending: boolean
}

export type TableProps<ItemType> = {
  headers?: ({label: string | JSX.Element, filterProps?: FilterProps})[],
  keyFunc: (item: ItemType) => string,
  columnFuncs: ((item: ItemType, onHover?: boolean) => string | JSX.Element)[],
  items: ItemType[],
  
  itemToSubItems?: {[k: string]: ItemType[]}
  showSubItem?: {[k: string]: boolean}
  updateShowSubItem?: (k: string) => void

  onRowClick?: (item: ItemType, i: number, col_idx?: number, classList?: DOMTokenList) => void,
  highlightedItemIdx: number
  tableWidthFull?: boolean
  tdClassAdditionalStyle?: React.CSSProperties
  trClassStyleNonHighlighted?: React.CSSProperties
  trClassStyleHighlighted?: React.CSSProperties
  trClassStyleRowFn?: (item: ItemType) => React.CSSProperties
  trClassAdditionalStyleRowFn?: (item: ItemType) => React.CSSProperties
  
  group_by?: (item: ItemType) => string
  group_by_value?: (item: ItemType) => JSX.Element
  putSeparatedRows?: boolean
  noPadding?: boolean
  equalWidth?: boolean
  noEqualForIdxes?: number[]
  growWidthForIdxes?: number[]
  tdSpecialStyles?: React.CSSProperties

  removeAllPadding?: boolean
  sortable?: boolean
}

export class Table<ItemType> extends React.Component<TableProps<ItemType>, TableState> {
  _keyToItemIdx: Map<string, number>
  _handleClickBound: (event: React.MouseEvent<HTMLElement>) => void
  _observer: RefObject<HTMLTableRowElement>

  constructor(props: TableProps<ItemType>) {
    super(props)
    this._keyToItemIdx = new Map(this.props.items.map((x, i) => [this.props.keyFunc(x), i]))
    this._handleClickBound = this._handleClick.bind(this)
    this._observer = React.createRef();
    this.state = {
      'showFilterModal': this.props.headers ? new Array(this.props.headers.length).fill(false) : undefined,
      'permutation': this.props.items.map((x, i) => i),
      'lastSortColumn': null,
      'lastSortAscending': true,
    }
  }

  componentDidUpdate() {
    this._keyToItemIdx = new Map(this.props.items.map((x, i) => [this.props.keyFunc(x), i]))
    if (this.state.permutation.length !== this.props.items.length) {
      this.setState({'permutation': this.props.items.map((x, i) => i)})
    }
  }

  _handleClick (event: React.MouseEvent<HTMLElement>) {
    // exit early if there is no row to click on
    const onRowClick = this.props.onRowClick
    if (onRowClick == null) return

    // find the containing table row
    const target = event.target as HTMLElement
    const targetRow = findParentByCallable(target, (target) => target.tagName === 'TR')

    // get the associated item by key
    if (targetRow == null) return
    const targetKey = targetRow.getAttribute('data-key')
    if (targetKey == null) return
    const itemIdx = this._keyToItemIdx.get(targetKey)
    if (itemIdx == null) return
    const colIdx = Array.prototype.indexOf.call(targetRow.children, findParentByCallable(target, (target) => target.tagName === 'TD'))

    // fire the callback
    onRowClick(this.props.items[itemIdx], itemIdx, colIdx === -1 ? undefined : colIdx, target.classList)
    event.stopPropagation()
  }

  _updateFilterState(idx: number) {
    if (!this.state.showFilterModal) return
    const showFilterModal = [...this.state.showFilterModal];
    showFilterModal[idx] = !showFilterModal[idx];
    this.setState({ showFilterModal }); 
  }

  _toggleSort(idx: number) {
    if (!this.props.sortable) return
    if (idx === this.state.lastSortColumn) {
      this.setState(state => { return {
        'permutation': [...state.permutation].reverse(),
        'lastSortAscending': !state.lastSortAscending,
      } })
    } else {
      // render column to string
      const columnStringValues: string[] = this.props.items.map(item => {
        const rawValue = this.props.columnFuncs[idx](item);
        if (typeof rawValue === 'string') {
          return rawValue
        } else {
          const div = document.createElement('div')
          const root = createRoot(div)
          flushSync(() => root.render(rawValue))
          return div.textContent ?? ""
        }
      })
      // sniff floats and dates
      const columnValues: number[] | null = (columnStringValues.every(v => isFinite((new Date(v)).valueOf()))
                                             ? columnStringValues.map(v => (new Date(v)).valueOf())
                                             : columnStringValues.every(v => isFinite(Number(v)))
                                               ? columnStringValues.map(v => Number(v))
                                               : null)
      // sort
      const currentPosition: number[] = new Array(this.state.permutation.length)
      this.state.permutation.forEach((x, i) => currentPosition[x] = i)
      const compareFunc = (columnValues === null
                           ? ((a: number, b: number) => columnStringValues[a].localeCompare(columnStringValues[b]) || (currentPosition[a] - currentPosition[b]))
                           : ((a: number, b: number) => (columnValues[a] - columnValues[b]) || (currentPosition[a] - currentPosition[b])))
      this.setState(state => {
        return {
          'lastSortColumn': idx,
          'lastSortAscending': true,
          'permutation': [...state.permutation].sort(compareFunc),
        }
      })
    }
  }

  _renderLineSplitHeaderRecallSection(label: string | JSX.Element): JSX.Element {
    return (
    <div className='w-full items-center flex flex-row justify-between'>
        <hr className='w-1/3' style={{'height': '2px', backgroundColor: '#131414'}} />
        {typeof label === 'string' ? <span style={{'width': '100%', color: '#131414', fontWeight: 'bold'}}>
              <Typography variant='h5'>
                {label}
              </Typography>
        </span> : label}
        <hr className='w-1/3' style={{'height': '2px', backgroundColor: '#131414'}} />
  </div>)
  }

  render(): JSX.Element {
    // make rows
    const trClassNameClickableNonHighlighted = this.props.onRowClick == null ? "" : " cursor-pointer hover:bg-slate-200"
    const trClassNameClickableHighlighted = this.props.onRowClick == null ? "" : " cursor-pointer"
    const trClassNameNonHighlighted = this.props.trClassStyleNonHighlighted || this.props.trClassStyleRowFn ? trClassNameClickableNonHighlighted : "even:bg-white odd:bg-gray-100 rounded bg-black" + trClassNameClickableNonHighlighted
    const trClassNameHighlighted = this.props.trClassStyleNonHighlighted || this.props.trClassStyleRowFn ? trClassNameClickableHighlighted : "bg-green-100" + trClassNameClickableHighlighted

    const rowElements: JSX.Element[] = []

    // Keep permutation in-range when this.props.items changes---triggering both render() and componentDidUpdate(),
    // but the this.state.permutation update in componentDidUpdate() doesn't take immediately.
    const renderPermutation = this.state.permutation.length === this.props.items.length ? this.state.permutation : this.props.items.map((x, i) => i)

    renderPermutation.forEach((i, position) => {
      const x = this.props.items[i]
      const isHighlighted = this.props.highlightedItemIdx === i
      const key = this.props.keyFunc(x)
      let trClassName = isHighlighted ? trClassNameHighlighted : trClassNameNonHighlighted
      trClassName += ' parent'
      const isLastElement = this.props.items.length === position + 1;
      let trStyle: React.CSSProperties = (isHighlighted ? this.props.trClassStyleHighlighted : this.props.trClassStyleRowFn ? this.props.trClassStyleRowFn(x) : this.props.trClassStyleNonHighlighted) ?? {}
      if (this.props.trClassAdditionalStyleRowFn) trStyle = {...trStyle, ...this.props.trClassAdditionalStyleRowFn(x)}
      const rowElement = <tr key={key} style={{...trStyle}} className={trClassName} data-key={key} ref={isLastElement ? this._observer : undefined}>
          {this.props.columnFuncs.map((y, j) => {
            let contents = y(x)
            if (!(React.isValidElement(contents) && contents.type === "td")) {
              contents = makeCell(contents, "", this.props.noPadding, 
              this.props.growWidthForIdxes?.includes(j) ? '100%' : this.props.equalWidth && (!this.props.noEqualForIdxes || !this.props.noEqualForIdxes.includes(j)) ? `${Math.floor(1/this.props.columnFuncs.length*100).toString()}%` : this.props.equalWidth ? '2%' : undefined, 
              this.props.tdClassAdditionalStyle,
              j === 0 && this.props.updateShowSubItem && this.props.itemToSubItems && this.props.itemToSubItems[key] ? {'expanded': this.props.showSubItem?.[key] ?? false, 'onStatusChange': () => this.props.updateShowSubItem ? this.props.updateShowSubItem(key) : {}} : undefined,
              this.props.itemToSubItems && this.props.itemToSubItems[key] ? this.props.itemToSubItems[key].length : undefined
              )
            }
            return <React.Fragment key={j}>{contents}</React.Fragment>
          })}
        </tr>
        rowElements.push(rowElement)
        if (this.props.itemToSubItems && 
          this.props.showSubItem && 
          this.props.itemToSubItems[key] && 
          this.props.showSubItem[key]) {
          const subItems = this.props.itemToSubItems[key]
          for (const z of subItems) {
            const subKey = this.props.keyFunc(z)
            const subRowElement = <tr key={subKey} style={{...trStyle, 'backgroundColor': 'rgb(240 240 240 / 51%)'}} className={[trClassName].join(" ")} data-key={subKey}>
            {this.props.columnFuncs.map((y, j) => {
              let contents = y(z)
              if (!(React.isValidElement(contents) && contents.type === "td")) {
                contents = makeCell(contents, "", this.props.noPadding, 
                this.props.equalWidth && (!this.props.noEqualForIdxes || !this.props.noEqualForIdxes.includes(j)) ? `${Math.floor(1/this.props.columnFuncs.length*100).toString()}%` : this.props.equalWidth ? '2%' : undefined,
                this.props.tdClassAdditionalStyle)
              }
              return <React.Fragment key={j}>{contents}</React.Fragment>
            })}
          </tr>
          rowElements.push(subRowElement)
          }
        }
    })

    if (this.props.group_by !== undefined && this.props.items.length > 0) {
      const uniquegroupByColValues = renderPermutation.map((i) => {
        return  this.props.group_by!(this.props.items[i])
      }).filter((v,i,a)=>a.indexOf(v)===i)

      for (let i = 0; i < uniquegroupByColValues.length; i++) {
        const idx = this.props.items.findIndex((x: ItemType) => this.props.group_by!(x) === uniquegroupByColValues[i])
        if (!this.props.group_by_value) rowElements.splice(idx + i, 0, <td key={`${uniquegroupByColValues[i]}@${idx}`} className="cursor-default" colSpan={this.props.columnFuncs.length}>{this._renderLineSplitHeaderRecallSection(uniquegroupByColValues[i])}</td>)
        else rowElements.splice(idx + i, 0, <td key={`${uniquegroupByColValues[i]}@${idx}`} className="cursor-default" colSpan={this.props.columnFuncs.length}>{this._renderLineSplitHeaderRecallSection(this.props.group_by_value(this.props.items[idx]))}</td>)
      }
    }

    
    const bodyClass = this.props.onRowClick == null ? "" : 'cursor-pointer'
    const bodyOnClick = this.props.onRowClick == null ? undefined : this._handleClickBound

    const headers = this.props.headers ? this.props.headers.map((x, i) => {
      return (
        <th className={"text-center align-bottom w-fit" + this.props.noPadding ? '': ' py-3 px-2'} key={i}>
          <div className={(x.filterProps || this.props.sortable ? "cursor-pointer " : "") + "flex flex-row items-center justify-center"}>
            {x.filterProps && <div onClick={() => this._updateFilterState(i)} className="px-2">{threeHorizontalIcon}</div>}
            <div onClick={() => this.props.sortable ? this._toggleSort(i) : x.filterProps ? this._updateFilterState(i) : null}>{x.label}</div>
            {this.props.sortable &&
              <div onClick={() => this._toggleSort(i)} className="px-2 text-left w-4">
                {this.state.lastSortColumn === i
                  ? (this.state.lastSortAscending ? '↓' : '↑')
                  : <span className="opacity-25">⇅</span>}
              </div>}
          </div>

          {x.filterProps && this.state.showFilterModal && this.state.showFilterModal[i] ?
              <div className="absolute">
              <Filter {...x.filterProps}></Filter>
              </div> : null}
        </th>
      )
    }) : undefined

    const tableWidthTailwind = this.props.tableWidthFull ? 'w-full' : 'min-w-max'

    return (
      <div className='h-full w-full' style={{borderTopRightRadius: '5px', borderBottomRightRadius: '5px'}}>
      <table style={{'borderCollapse': this.props.removeAllPadding ? 'collapse' : 'separate',
                     'borderSpacing': this.props.removeAllPadding ? '0px' : '0px 2.5px',
                     'isolation': 'isolate'}}
             className={tableWidthTailwind}>
        {headers ? <thead className="top-0 sticky z-10" style={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          color: 'black'}}>
          <tr style={{
            'backgroundColor': '#dae6f0'
          }}>
            {headers}
          </tr>
        </thead> : null}
        
        <tbody className={bodyClass} onClick={bodyOnClick}>
          {rowElements}
        </tbody>
      </table>
      </div>
    )
  }
}
