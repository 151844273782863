import { AGENT_TYPE } from "interfaces/db";
import React from "react";
import { EMAIL_INPUT_DATA } from "./interfaces";
import { Typography } from "interfaces/typography";
import { RequestState } from "components/Widgets/Automation";
import { Loader } from "components/Loader";
import { sendMessageToTop } from "lib/user-agent";
import { MESSAGE_TYPES } from "cfg/endpoints";
import { CustomJsonLink, CustomJsonLinks } from "interfaces/services";

export type AgentInputProps = {
    agent_type: AGENT_TYPE,
    current_data: EMAIL_INPUT_DATA
    requestData?: () => void,
}

export const ERROR_CODES = {
    CHANGED_URL: 'CHANGED_URL',
    BAD_URL: 'BAD_URL',
    SLOW_TO_LOAD: 'SLOW_TO_LOAD',
  }

export class AgentInputBody extends React.Component<AgentInputProps, {}> {
    _getAgentData() { return this.props.current_data.agent_data[this.props.agent_type] }
    _getLeadingText() { 
        switch(this.props.agent_type) {
            case AGENT_TYPE.LINKEDIN: 
                const name = this.props.current_data.prospect_name
                return name ? `${name}` : 'LinkedIn URL'            
            case AGENT_TYPE.COMPANY_WEBSITE: 
                const company = this.props.current_data.prospect_company
                return company ? `${company} Website` : 'Company Website'
        }
    }
    
    renderSection(content: JSX.Element | null) {
        return <div className="flex flex-col p-2" style={{
            border: '1px solid #E2E8F0',
            borderRadius: '6px',
        }}>
            {content}
        </div>
    }

    getErrorMessage() {
        switch(this._getAgentData().error_reason) {
            case ERROR_CODES.CHANGED_URL: return "Incorrect URL"
            case ERROR_CODES.BAD_URL: return "Bad URL"
            case ERROR_CODES.SLOW_TO_LOAD: return "Slow to load"
            default: return "Could not fetch data"
        }
    }

    renderRequestButton() {
        return <button
        onClick={() => sendMessageToTop({'type': MESSAGE_TYPES.EXTERNAL_TO_PRE_CALL_INITIATE_LINKEDIN_SCRAPE, 'aggresive': true})}
        className="p-2 w-full cursor-pointer" style={{
        borderRadius: '8px',
        background: 'linear-gradient(45deg, rgb(0 153 255), rgb(0 0 0)) right center'
        }}>
            <Typography variant="largeParagraph" color="white" fontWeight={650}>
                Request data
            </Typography>
        </button>
    }

    renderStatus() {
        const status = this._getAgentData().status
        switch(status) {
            case RequestState.REQUESTED_BY_USER:
            case RequestState.PENDING: return <Loader />
            case RequestState.ERROR:
                const error_reason = this._getAgentData().error_reason
                return ( 
                <div className="w-full h-full flex flex-col gap-2 text-center items-center" style={{
                    'justifyContent': error_reason === ERROR_CODES.SLOW_TO_LOAD ? 'start' : 'center',
                }}>
                <Typography variant="h1">{this.getErrorMessage()}</Typography>
                {error_reason === ERROR_CODES.SLOW_TO_LOAD ? this.renderRequestButton() : null}  
            </div>)
        case RequestState.TO_BE_REQUESTED_BY_USER: return this.renderRequestButton()
        }
        return null
    }

    renderAgentInfo(header: string, content: string) {
        return <div className="w-full flex flex-col gap-1">
            <Typography variant="largeParagraph" fontWeight={650}>{header.replaceAll('*', '')}</Typography>
            {content.split('\n').map((v) => <Typography variant="largeParagraph">{v.replaceAll('*', '')}</Typography>)}
        </div>
    }

    _renderUrl(v: CustomJsonLink) {
        return (
        <div className="w-full flex flex-row gap-1">
        <div className=""><Typography variant="largeParagraph" fontWeight={800}>{">"}</Typography></div>
        <a className="text-blue-600 hover:text-blue-800" style={{ overflow: 'hidden' }} href={v.url} target={'_blank'} rel="noreferrer" >
        <div style={{}}>
            <Typography variant={'largeParagraph'} className={"text-blue-600 hover:text-blue-800"} style={{
                'fontSize': '10.5px',
                'display': '-webkit-box',
                'WebkitLineClamp': 2,
                'WebkitBoxOrient': 'vertical',
                'overflow': 'hidden'}}>
            {`${v.text.includes('.pdf') ? "(PDF)" :""}${v.text.trim() !== '' ? (v.text.trim() + ": ") : ""}${v.url}`}
            </Typography>
        </div>
        </a>
        </div>)
    }

    _renderJsonItem(links: CustomJsonLinks) {
        return <div className="w-full overflow-x-hidden">
        <Typography variant="h3" style={{'fontSize': '12.5px', 'textDecoration': 'underline'}}>
            {links.header}
        </Typography>
        {links.data ? 
        <div>
        <div className="w-full flex flex-row gap-0.5">
            <Typography variant="largeParagraph">
            <span style={{'fontWeight': '650'}}>{"Summary: "}</span>{links.data}
            </Typography>
            </div>
            {links.urls.length > 0 ? <Typography variant="largeParagraph" fontWeight={600}>
                Potential Links:
            </Typography> : null}
            </div>
             : null}
            {links.urls.length === 0 ? <Typography variant="largeParagraph" style={{'fontSize': '11.5px'}}>{'No urls found'}</Typography> : links.urls.map((value: CustomJsonLink) => this._renderUrl(value))}
            </div>
    }

    _renderCustomJson() {
        const data = this._getAgentData()
        if (!data.info?.custom_json) return null
        return <div className="flex flex-col gap2">
        </div>
    }

    _fetchHeaderContent(x: string) {
        const sections = x.split(':')
        const header = sections.length > 1 ? sections[0] : `Summary`
        const content = sections.length > 1 ? sections.slice(1).join(' ') : sections[0]
        return {'header': header, 'content': content}
    }

    _renderSummarizedInfo(): JSX.Element[] | null {
        const data = this._getAgentData()
        if (!data.info?.summary && !data.info?.header_and_content && !data.info?.custom_json) return null
        let summaries = []
        if (data.info && data.info.summary) {
            summaries = data.info.summary.split(/\s+(?=\d\.\s)/).map((v) => {
                const content = this._fetchHeaderContent(v)
                return this.renderSection(this.renderAgentInfo(content['header'], content['content']))
            })
        } else {
            summaries = (data.info.header_and_content ?? []).map((v, idx) => this.renderSection(this.renderAgentInfo(v.header, v.content)))
        }
        let json = (data.info.custom_json ?? []).map((v) => this.renderSection(this._renderJsonItem(v)))
        return [...json, ...summaries]
    }

    renderEmpty() {
        return <div className="w-full flex flex-row items-center justify-center">
            <Typography variant="largeParagraph">No data available</Typography>
        </div>
    }
    
    render() {
        const data = this._getAgentData()
        const url = data.url
        return (
            <div className="w-full h-full flex flex-grow flex-col gap-1.5">
                {!url ? this.renderSection(this.renderEmpty()) : 
                data && (data.info?.summary || data.info?.custom_json) ? this._renderSummarizedInfo() : 
                this.renderStatus()}
            </div>
        );
    }
}