import { AvatarStyle } from "components/Widgets/Leaderboard"
import { PromptType, ScopeDomain } from "./consts"
import { Counterpart, Display, jsonSafeToDisplay, jsonSafeToNote, jsonSafeToNoteDisposition, jsonSafeToParty, jsonSafeToPerson, jsonSafeToSession, 
  jsonSafeToTranscript, Note, NoteDisposition, Party, PartyRole, Person, Remark, 
  Platform,
  Session, STAGE, Transcript, SubteamView, jsonSafeToSubteamView, jsonSafeToSubteam, Subteam, jsonSafeToSubteamMemeber as jsonSafeToSubteamMember, SubteamMember,
  UserGroup, UserGroupMembership, jsonSafeToUserGroup, jsonSafeToUserGroupMembership, CustomMetric, jsonSafeToCustomMetric, EmailValueProp, EmailToneType, SESSION_METRICS_COLUMNS, ChatbotPromptVariant, ChatbotPromptTemplate, jsonSafeToChatbotPromptVariant, jsonSafeToChatbotPromptTemplate, MultiDialerSubscription, jsonSafeToMultiDialerSubscription,
} from "./db"
import { jsonSafeValueToDate, jsonSafeValueToMaybeDate } from "./typing"

/////////////////////////////////////////////
// SummaryDataResult is the return type of `get-summary-data`
////////////////////////////////////////////
export type SummaryDataResult = {
  scope_domain: ScopeDomain,
  weekly_activity: SummaryDataWeeklyActivityResult[],
  user_activity: null | SummaryDataUserActivityResult[],
  cohort_activity: null | SummaryDataCohortActivityResult[],
}

export type SummaryDataWeeklyActivityResult = {
  week_end: Date,
  num_sessions: number,
  duration: number,
}

export type SummaryDataUserActivityResult = {
  user_id: string,
  user_name: string,
  num_sessions: number,
  duration: number,
}

export type SummaryDataCohortActivityResult = {
  cohort_week_end: Date,
  week_end: Date,
  num_sessions: number,
  duration: number,
}

export function jsonSafeToSummaryDataResult(value: Record<string, any>): SummaryDataResult {
  return {
    scope_domain: value.scope_domain,
    weekly_activity: value.weekly_activity.map((x: Record<string, any>) => jsonSafeToSummaryDataWeeklyActivityResult(x)),
    user_activity: value.user_activity == null ? null : value.user_activity.map((x: Record<string, any>) => jsonSafeToSummaryDataUserActivityResult(x)),
    cohort_activity: value.cohort_activity == null ? null : value.cohort_activity.map((x: Record<string, any>) => jsonSafeToSummaryDataCohortActivityResult(x)),
  }
}

function jsonSafeToSummaryDataWeeklyActivityResult(value: Record<string, any>): SummaryDataWeeklyActivityResult {
  return {
    week_end: jsonSafeValueToDate(value.week_end), 
    num_sessions: value.num_sessions,
    duration: value.duration,
  }
}

function jsonSafeToSummaryDataUserActivityResult(value: Record<string, any>): SummaryDataUserActivityResult {
  return {
    user_id: value.user_id, 
    user_name: value.user_name,
    num_sessions: value.num_sessions,
    duration: value.duration,
  }
}

function jsonSafeToSummaryDataCohortActivityResult(value: Record<string, any>): SummaryDataCohortActivityResult {
  return {
    cohort_week_end: jsonSafeValueToDate(value.cohort_week_end), 
    week_end: jsonSafeValueToDate(value.week_end), 
    num_sessions: value.num_sessions,
    duration: value.duration,
  }
}


////////////////////////////////////////////
// LastWeekDataResult is the return type of `last-week-data-result`
////////////////////////////////////////////

export enum METRIC_TYPES {
  CALL_COUNT = 'CALL_COUNT',
  CALL_DURATION = 'CALL_DURATION',
  UNIQUE_CONTACTS = 'UNIQUE_CONTACTS',
  COUNTERPART_DURATION = 'COUNTERPART_DURATION',
  REMARK_COUNT = 'REMARK_COUNT',
  PROMPT_COUNT = 'PROMPT_COUNT',
  SPEECH_DURATION='SPEECH_DURATION'
}

export type MetricSummaryDataResult = {
  metric: METRIC_TYPES,
  value: number, 
  user_id: string | null,
  start: Date | null,
  end: Date | null,
  prompt_type: string | null,
  counterpart: string| null,
  remark: string | null,
  party_role: string | null,
  stage: STAGE | null
  uniqueLabel?: string
  uniqueLabelValue?: string
  uniqueLabelType?: string,
}

export type MetricsSummaryDataResult = MetricSummaryDataResult[]

export function jsonSafeToMetricsSummaryDataResult(value:  Record<string, any>[]): MetricsSummaryDataResult {
  return value.map((item) => ({
    metric: item.metric,
    value: item.value, 
    user_id: item.user_id,
    start: jsonSafeValueToMaybeDate(item.start),
    end: jsonSafeValueToMaybeDate(item.end),
    prompt_type: item.prompt_type,
    counterpart: item.counterpart,
    remark: item.remark,
    party_role: item.party_role,
    stage: item.stage
  }))
}

export type MetricDetailParams = {
    metric: METRIC_TYPES
    start: Date,
    end: Date,
    step: number | null,
    counterparts: string[] | null,
    remarks: string[] | null,
    prompt_types: string[] | null,
    user_ids: string[] | null,
    party_role: string| null
    group_by_user_id: boolean,
    group_by_time: boolean,
    group_by_counterpart: boolean,
    group_by_remark: boolean,
    group_by_prompt_type: boolean,
    group_by_party_role: boolean
}

export enum Metric {
  CALL_COUNT = 'CALL_COUNT',
  CALL_DURATION = 'CALL_DURATION',
  SPEECH_DURATION = 'SPEECH_DURATION',
  INITIAL_RINGING_DURATION = 'INITIAL_RINGING_DURATION',
  TEMPO = 'TEMPO',
  UNIQUE_CONTACTS = 'UNIQUE_CONTACTS',
  COUNTERPART_DURATION = 'COUNTERPART_DURATION',
  HAVING_COUNTERPART_COUNT = 'HAVING_COUNTERPART_COUNT',
  HAVING_REMARK_COUNT = 'HAVING_REMARK_COUNT',
  END_STAGE = 'END_STAGE',

  CUSTOM_METRIC = 'CUSTOM_METRIC',
  CUSTOM_METRIC_NOT_NULL = 'CUSTOM_METRIC_NOT_NULL'
}

export type MetricSelect = {
  metric: Metric
  party_role?: PartyRole | null
  remark?: Remark | null
  counterpart?: Counterpart
  stage?: STAGE
  custom_metric_version?: string | null
  custom_metric_field?: string | null
}

export type StageFilter = {
  stage: STAGE,
  require_ending: boolean
}

export type CounterpartFilter = {
  counterpart: Counterpart
}

export type MetadataFilter = {
  session_id?: string
  disposition?: string
  sentiment?: string
  purpose?: string
  has_star?: boolean
  review_is_open?: boolean
  min_duration?: number
  max_duration?: number
  prospect_transcribed?: string
  prospect_recorded?: string
  prospect_transcript_persisted?: string
  prospect_phone_value?: string
}

export type CustomMetricFilterTerm = {
    version: string
    field: string
    min_value?: number | null,
    max_value?: number | null
}

export type SessionMetricFilterTerm = {
  field: SESSION_METRICS_COLUMNS
  min_value?: string | number | null,
  max_value?: string | number | null,
  in_values?: string[]
}

export type StaticFilterDisjunction = {
  filter_type: CnfFilterType,
  user_filters?: UserFilterTerm[],
  user_account_filters?: UserAccountFilterTerm[],
  external_account_filters?: ExternalAccountFilterTerm[],
  prospect_filters?: ProspectFilterTerm[],
  phrase_filters?: PhraseFilterTerm[]
  remark_filters?: RemarkFilterTerm[],
  stage_filters?: StageFilter[],
  counterpart_filters?: CounterpartFilter[]
  metadata_filters?: MetadataFilter[]
  custom_metric_filters?: CustomMetricFilterTerm[]
  session_metric_filters?: SessionMetricFilterTerm[]
  negated: boolean
  metadata_type_info?: string
}

export type MetricDetailParamsV3 = {
  'selects': MetricSelect[]
  'cnf': StaticFilterDisjunction[]
  'start': Date,
  'end': Date,
  'step'?: number | null
  'step_modulus'?: number | null,
  'group_by_time'?: boolean
  'group_by_user'?: boolean
  'group_by_account'?: boolean
}

export type MetricsDetailsResult = {
  'values': number[],
  'user_id': string | null,
  'start': Date | null,
  'end': Date | null,
  'account_id': string | null,
}

export function jsonSafeToMetricsDetailsResult(v: Record<string, any>): MetricsDetailsResult {
  return {
    'values': v.values,
    'user_id': v.user_id,
    'start': v.start ? jsonSafeValueToDate(v.start) : null,
    'end': v.end ? jsonSafeValueToDate(v.end) : null,
    'account_id': v.account_id,
  }
}

export function jsonSafeToMetricsDetailResultQuery(v: Record<string, any>): MetricsDetailsResult[] {
  return v.map((x: Record<string, any>) => jsonSafeToMetricsDetailsResult(x))
}

export type MetricsDetailsResultV4 = {
  user_id: string | null,
  account_id: string | null,
  start: Date | null,
  end: Date | null,
  values: number[]
}

export function jsonSafeToMetricsDetailsResultV4(v: Record<string, any>): MetricsDetailsResultV4 {
  return {
    'user_id': v.user_id,
    'account_id': v.account_id,
    'start': v.start ? jsonSafeValueToDate(v.start) : null,
    'end': v.end ? jsonSafeValueToDate(v.end) : null,
    'values': v.values
  }
}

export function jsonSafeToMetricsDetailsResultQueryV4(v: Record<string, any>): MetricsDetailsResultV4[] {
  return v.map((x: Record<string, any>) => jsonSafeToMetricsDetailsResultV4(x))
}

export type MetricSelectV4 = {
  session_metrics_col?: SESSION_METRICS_COLUMNS
  custom_metrics_col?: string
  cnf?: StaticFilterDisjunction[]
}

export type MetricDetailParamsV4 = {
  selects: MetricSelectV4[]
  cnf: StaticFilterDisjunction[]
  start: Date
  end: Date
  step?: number
  step_modulus?: number
  group_by_user_account?: boolean
  group_by_time?: boolean
}


////////////////////////////////////////////
// NumberHealthResult is the return type of `number-health`
////////////////////////////////////////////

export type NumberHealthResult = {
  'rep_phone_value': string | null,
  'call_count': number,
  'connect_rate': number,
  'is_local': boolean,
  'last_used': Date,
  'user_counts': Record<string, number>,
}

export function jsonSafeToNumberHealthResult(v: Record<string, any>): NumberHealthResult {
  return {
    'rep_phone_value': v.rep_phone_value,
    'call_count': v.call_count,
    'connect_rate': v.connect_rate,
    'is_local': v.is_local,
    'last_used': jsonSafeValueToDate(v.last_used),
    'user_counts': v.user_counts,
  }
}

export function jsonSafeToNumberHealthResultQuery(v: Record<string, any>): NumberHealthResult[] {
  return v.map((x: Record<string, any>) => jsonSafeToNumberHealthResult(x))
}


////////////////////////////////////////////
// AreaCodeConnectRateResult is the return type of `area-code-connect-rate`
////////////////////////////////////////////

export type AreaCodeConnectRateResult = {
  'area_code': string | null,
  'call_count': number,
  'connect_rate': number,
}

export function jsonSafeToAreaCodeConnectRateResult(v: Record<string, any>): AreaCodeConnectRateResult {
  return {
    'area_code': v.area_code,
    'call_count': v.call_count,
    'connect_rate': v.connect_rate,
  }
}

export function jsonSafeToAreaCodeConnectRateResultQuery(v: Record<string, any>): AreaCodeConnectRateResult[] {
  return v.map((x: Record<string, any>) => jsonSafeToAreaCodeConnectRateResult(x))
}


////////////////////////////////////////////
// UserSessionResult is the return type of `list-user-sessions`
////////////////////////////////////////////

export enum CoachingTypes {
  MONOLOG='MONOLOG',
  OBJECTION='TRIGGER',
  PAUSE='CADENCE_FAST'
}


export const CoachingTypesToLabels: {[key in CoachingTypes] : string} = {
  [CoachingTypes.MONOLOG]: 'Monologue',
  [CoachingTypes.OBJECTION]: 'Objection',
  [CoachingTypes.PAUSE]: 'Pause'
}


export type UserSessionsResult = UserSessionsResultItem[]

export type UserSessionsResultItem = {
  session_id: string,
  scheduled_start: Date,
  prospect_phone_value: string | null,
  prospect_name: string | null,
  duration: number | null,
  user_id: number
  dispositions: string | null,
  has_star: boolean,
  review_status: string
  has_gatekeeper: boolean,
  has_not_gatekeeper: boolean,
  review_is_open: boolean | null,
  review_opened_by_user: boolean
}


export function jsonSafeToUserSessionResult(value: Record<string, any>[]): UserSessionsResult {
  return value.map((x: Record<string, any>) => ({
    session_id: x.session_id,
    scheduled_start: jsonSafeValueToDate(x.scheduled_start),
    prospect_phone_value: x.prospect_phone_value,
    prospect_name: x.prospect_name,
    duration: x.duration,
    user_id: x.user_id,
    dispositions: x.dispositions ?? null,
    has_star: x.has_star,
    review_status: x.review_status,
    has_gatekeeper: x.has_gatekeeper,
    has_not_gatekeeper: x.has_not_gatekeeper,
    review_is_open: x.review_is_open,
    review_opened_by_user: x.review_opened_by_user
  }))
}


////////////////////////////////////////////
// SessionDataResult is the return type of `get-session-data`
////////////////////////////////////////////

export type SessionMetric = {
    sessionId: string
    callDuration: number

    // counterparts
    targetDuration: number
    gatekeeperDuration: number
    voicemailDuration: number

    // speech by counterpart
    targetSpeech: number
    gatekeeperSpeech: number
    repSpeechWithGatekeeper: number
    repSpeechWithTarget: number
    repSpeechDuringVoicemailDuration: number

    // monologue calculations
    longestMonologueRepTarget: number
    // # pace - rep overall
    numberWordsRepAllEligible: number
    durationRepAllEligibleSpeech: number
    wordsPerSecondRepAllEligible: number

    // # pace - prospect
    numberWordsTargetEligible: number
    durationTargetEligibleSpeech: number
    wordsPerSecondTargetEligible: number
    // # pace - number rep w/ prospect
    numberWordsRepTargetEligible: number
    durationRepTargetEligibleSpeech: number
    wordsPerSecondRepTargetEligible: number

    // # common objections mapped to remarks
    rDenyCount: number
    rTitleDenyCount: number
    rRespNotDecisionMakerCount: number
    rTransferDenyCount: number
    rProductNoNeedCount: number
    rProductNoBudgetCount: number
    rCompetitorsUsingCount: number
    rEmailApproveCount: number

    // # generative text
    genSummaryText: string | null
    genEmailText: string | null
    genCoachingText: string | null

    // # external account association
    externalAccountId: string | null
}

export function jsonSafeToSessionMetric(value: Record<string, any>): SessionMetric {
  return {
    sessionId: value.session_id,
    callDuration: value.call_duration,

    // # counterparts
    targetDuration: value.target_duration,
    gatekeeperDuration: value.gatekeeper_duration,
    voicemailDuration: value.voicemail_duration,

    // # speech by counterpart
    targetSpeech: value.target_speech,
    gatekeeperSpeech: value.gatekeeper_speech,
    repSpeechWithGatekeeper: value.rep_speech_with_gatekeeper,
    repSpeechWithTarget: value.rep_speech_with_target,
    repSpeechDuringVoicemailDuration: value.rep_speech_during_voicemail_duration,

    // # monologue calculations
    longestMonologueRepTarget: value.longest_monologue_rep_target,
    // # pace - rep overall
    numberWordsRepAllEligible: value.number_words_rep_all_eligible,
    durationRepAllEligibleSpeech: value.duration_rep_all_eligible_speech,
    wordsPerSecondRepAllEligible: value.words_per_second_rep_all_eligible,

    // # pace - prospect
    numberWordsTargetEligible: value.number_words_target_eligible,
    durationTargetEligibleSpeech: value.duration_target_eligible_speech,
    wordsPerSecondTargetEligible: value.words_per_second_target_eligible,
    // # pace - number rep w/ prospect
    numberWordsRepTargetEligible: value.number_words_rep_target_eligible,
    durationRepTargetEligibleSpeech: value.duration_rep_target_eligible_speech,
    wordsPerSecondRepTargetEligible: value.words_per_second_rep_target_eligible,

    // # common objections mapped to remarks
    rDenyCount: value.r_deny_count,
    rTitleDenyCount: value.r_title_deny_count,
    rRespNotDecisionMakerCount: value.r_resp_not_decision_maker_count,
    rTransferDenyCount: value.r_transfer_deny_count,
    rProductNoNeedCount: value.r_product_no_need_count,
    rProductNoBudgetCount: value.r_product_no_budget_count,
    rCompetitorsUsingCount: value.r_competitors_using_count,
    rEmailApproveCount: value.r_email_approve_count,

    // # generative text
    genSummaryText: value.gen_summary_text,
    genEmailText: value.gen_email_text,
    genCoachingText: value.gen_coaching_text,

    // # external account association
    externalAccountId: value.external_account_id,
  }
}

export type CommentDataResult = {
  commentId: string
  sessionId: string
  userId: string
  comment: string
  createdAt: Date
  userName: string
  start: number | null
  end: number | null
  transcriptIdx?: number 
}

export enum UpdateType {
  MODIFY = 'MODIFY',
  REMOVE = 'REMOVE',
  ADD = 'ADD'
}

export type CommentUpdate = {
  commentId: string,
  comment: string,
  start?: number,
  end?: number
}

export type CommentParams = {
  comment: CommentUpdate
  update: UpdateType
}

export function jsonSafeToComment(value: Record<string, any>): CommentDataResult {
  return {
    'commentId': value.comment_id,
    'sessionId': value.session_id,
    'userId': value.user_id,
    'comment': value.comment,
    'createdAt': jsonSafeValueToDate(value.created_at),
    'start': value.start,
    'end': value.end,
    'userName': value.user_name
  }
}

export type RemarkResult = {
  resultId: string,
  startRel: number,
  endRel: number, 
  remark: Remark
}

export function jsonSafeToRemark(value: Record<string, any>): RemarkResult {
  return {
    'resultId': value.result_id,
    'startRel': value.start_rel,
    'endRel': value.end_rel,
    'remark': value.remark
  }
}

export type ReviewStatus = {
  sessionId: string
  openingUserId: string
  isOpen: boolean
  openedAt: Date
}

function jsonSafeToReviewStatus(value: Record<string, any>): ReviewStatus {
  return {
    'sessionId': value.session_id,
    'openingUserId': value.opening_user_id,
    'isOpen': value.is_open,
    'openedAt': jsonSafeValueToDate(value.opened_at)
  }
}

export type SpeechTurn = {
  start: number
  end: number
  text: string
  role: PartyRole,
}

function jsonSafeToSpeechTurn(x: Record<string, any>): SpeechTurn {
  return {
    'start': x.start,
    'end': x.end,
    'text': x.text,
    'role': x.role
  }
}

export type RemarkTurn = {
  start: number
  end: number
  remark: Remark
  role: PartyRole
}

function jsonSafeToRemarkTurn(x: Record<string, any>): RemarkTurn {
  return {
    'start': x.start,
    'end': x.end,
    'remark': x.remark as Remark,
    'role': x.role
  }
}

export type CounterpartTurn = {
  start: number
  end: number
  counterpart: Counterpart
}

function jsonSafeToCounterpartTurn(x: Record<string, any>): CounterpartTurn {
  return {
    'start': x.start,
    'end': x.end,
    'counterpart': x.counterpart
  }
}

export type TemporalCnfMatch = {
  start: number,
  end: number
}

function jsonSafeToTemporalCnfMatch(x: Record<string, any>): TemporalCnfMatch {
  return {
    start: x.start,
    end: x.end
  }
}

export type AudioDataResult = {
  session: Session,
  audio: AudioResult[],
  audio_links: string[]
}

export function jsonSafeToAudioDataResult(value: Record<string, any>): AudioDataResult { 
  return {
    session: jsonSafeToSession(value.session),
    audio: value.audio.map((x: Record<string, any>) => jsonSafeToAudio(x)),
    audio_links: value.audio_links
  }
}

export type SessionDataResult = {
  turns: SpeechTurn[],
  remarks: RemarkTurn[],
  counterparts: CounterpartTurn[],
  user: UserDataResult,
  session: Session,
  prospect_info: ProspectInfo | null,
  sessionMetric: SessionMetric | null,
  customMetric: CustomMetric | null,
  audio: AudioResult[],
  audio_links: string[],
  displays: Display[],
  notes: Note[],
  dispositions: NoteDisposition[],
  reviewStatus?: ReviewStatus,
  comments: CommentDataResult[],
  has_star: boolean,
  prospectSessionCount: number,
  temporal_cnf_sat?: TemporalCnfMatch[],
}

export function jsonSafeToSessionDataResult(value: Record<string, any>): SessionDataResult {
  return {
    turns: value.turns.map((v: Record<string, any>) => jsonSafeToSpeechTurn(v)),
    remarks: value.remarks.map((v: Record<string, any>) => jsonSafeToRemarkTurn(v)),
    counterparts: value.counterparts.map((v: Record<string, any>) => jsonSafeToCounterpartTurn(v)),
    user: jsonSafeToAuthorizedUserDataResult(value.user),
    session: jsonSafeToSession(value.session),
    prospect_info: value.prospect_info ? jsonSafeToProspectInfo(value.prospect_info) : null,
    sessionMetric: value.session_metric ? jsonSafeToSessionMetric(value.session_metric) : null,
    customMetric: value.custom_metric ? jsonSafeToCustomMetric(value.custom_metric) : null,
    audio: value.audio ? value.audio.map((x: Record<string, any>) => jsonSafeToAudio(x)) : [],
    audio_links: value.audio_links ? value.audio_links.map((x: string) => x) : [],
    displays: value.displays ? value.displays.map((x: Record<string, any>) => jsonSafeToDisplay(x)) : [],
    notes: value.notes ? value.notes.map((x: Record<string, any>) => jsonSafeToNote(x)) : [],
    dispositions: value.dispositions ? value.dispositions.map((x: Record<string, any>) => jsonSafeToNoteDisposition(x)) : [],
    reviewStatus: value.review_status ? jsonSafeToReviewStatus(value.review_status) : undefined,
    comments: value.comments.map((x: Record<string, any>) => jsonSafeToComment(x)),
    has_star: value.has_star, 
    prospectSessionCount: value.prospect_session_count,
    temporal_cnf_sat: value.temporal_cnf_sat ? value.temporal_cnf_sat.map((x: Record<string, any>) => jsonSafeToTemporalCnfMatch(x)) : undefined
  }
}


function jsonSafeToAudio(value: Record<string, any>): AudioResult {
  return {
    audio_id: value.audio_id,
    conversation_id: value.conversation_id,
    party_code: value.party_code,
    start: jsonSafeValueToDate(value.start),
    end: jsonSafeValueToDate(value.end),
    bucket_key: value.bucket_key,
    audio_status: value.audio_status
  }
}

export type AudioResult = {
    audio_id: string,
    conversation_id: string
    party_code: number
    start: Date
    end: Date
    bucket_key: string
    audio_status: string
}

// Other data types used by services
export type Word = {
  start_time: number,
  end_time: number,
  text: string,
  confidence: number | null
}

export function jsonSafeToWord(value: Record<string, any>): Word {
  return {
    start_time: value.start_time,  // in seconds
    end_time: value.end_time,
    text: value.text,
    confidence: value.confidence,
  }
}

export function jsonSafeToWordArray(values: Record<string, any>[]): Word[] {
  return values.map((x) => jsonSafeToWord(x))
}

////////////////////////////////////////////
// TriggerListDataResult is the return type of `get-triggers`
////////////////////////////////////////////
export type TriggerDataResult = {
  trigger_id: string
  trigger_name: string
  triggers_on_user: boolean
  trigger_prompt: string
  user_id?: string,
  team_id?: string
}

export type TriggerFormulaDataResult = {
  trigger_id: string
  trigger_formula: string
}


export type TriggerListDataResult = {
  triggers: TriggerDataResult[]
  default_triggers: TriggerDataResult[]
  trigger_formula: TriggerFormulaDataResult[]
}

export type TriggerPostParams = {
  triggers: TriggerDataResult[]
  trigger_formula: TriggerFormulaDataResult[]
  is_team: boolean
}

export function jsonSafeToTriggerDataResult(value: Record<string, any>): TriggerDataResult {
  return {
    trigger_id: value["trigger_id"],
    trigger_name: value["trigger_name"],
    triggers_on_user: value["triggers_on_user"],
    trigger_prompt: value["trigger_prompt"],
    user_id: value["user_id"],
    team_id: value["team_id"]
  }
}

export function jsonSafeToTriggerFormulaDataResult(value: Record<string, any>): TriggerFormulaDataResult {
  return {
    trigger_id: value["trigger_id"],
    trigger_formula: value["trigger_formula"]
  }
}

/**
 * Load triggers from database and render
 */
 export function jsonSafeToTriggerListDataResult(value: Record<string, any>): TriggerListDataResult {
  // group by trigger id
  return {
    triggers: value['triggers'].map((trigger: any) => jsonSafeToTriggerDataResult(trigger)),
    trigger_formula: value["trigger_formulas"].map((trigger_formula: any) => jsonSafeToTriggerFormulaDataResult(trigger_formula)),
    default_triggers: value['default_triggers'].map((trigger: any) => jsonSafeToTriggerDataResult(trigger)),
  }
}

/////////////////////////////////////////////
// HealthTableResult is the return type of `get-health-table`
////////////////////////////////////////////
export type RealtimeHealthRecord = {
  // fields we got from the server
  hostname: string,
  period_end: Date,
  update_as_of: Date,
  latency: number,
  response_code: number,
  reports_this_period: number,
  num_active_audio_connections: number | null,
  num_active_client_connections: number | null,
  num_active_sessions: number | null,
  num_coaching_sessions: number | null,
  num_async_tasks: number | null,
  num_db_readonly_connections: number | null,
  num_db_readwrite_connections: number | null,
  cpu_utilization: number | null,
  memory_utilization: number | null,
  disk_utilization: number | null,
  num_global_processes: number | null,
  num_global_net_connections: number | null,
  num_global_open_files: number | null,
  max_descendant_open_files: number | null,
  is_aggregate: boolean,
}
export type HealthTableResult = RealtimeHealthRecord[]

export function jsonSafeToHealthTableResult(value: Record<string, any>[]): HealthTableResult {
  return value.map((item) => ({
    hostname: item.hostname,
    period_end: jsonSafeValueToDate(item.period_end),
    update_as_of: jsonSafeValueToDate(item.update_as_of),
    latency: item.latency,
    response_code: item.response_code,
    reports_this_period: item.reports_this_period,
    num_active_audio_connections: item.num_active_audio_connections,
    num_active_client_connections: item.num_active_client_connections,
    num_active_sessions: item.num_active_sessions,
    num_coaching_sessions: item.num_coaching_sessions,
    num_async_tasks: item.num_async_tasks,
    num_db_readonly_connections: item.num_db_readonly_connections,
    num_db_readwrite_connections: item.num_db_readwrite_connections,
    cpu_utilization: item.cpu_utilization,
    memory_utilization: item.memory_utilization,
    disk_utilization: item.disk_utilization,
    num_global_processes: item.num_global_processes,
    num_global_net_connections: item.num_global_net_connections,
    num_global_open_files: item.num_global_open_files,
    max_descendant_open_files: item.max_descendant_open_files,
    is_aggregate: item.is_aggregate,
  }))
}

/////////////////////////////////////////////
// HealthSeriesResult is the return type of `get-health-series`
////////////////////////////////////////////
export type RealtimeHealthMeasurement = {
  // fields we got from the server
  hostname: string,
  update_as_of: Date,
  value: number,
}
export type HealthSeriesResult = Map<string, RealtimeHealthMeasurement[]>

export function jsonSafeToHealthSeriesResult(value: Record<string, any>[]): HealthSeriesResult {
  return new Map(Object.entries(value).map(([hostname, measurements]) =>
    [hostname, measurements.map((item: Record<string, any>) => ({
      hostname: item.hostname,
      update_as_of: jsonSafeValueToDate(item.update_as_of),
      value: item.value,
    }))]
  ))
}


////////////////////////////////////////////
// DispositionsDataResult is the return type of `get-user-dispositions`
////////////////////////////////////////////
export type DispositionsDataResult = {
  dispositions: string[]
}

export function jsonSafeToDispositionsDataResult(value: string[]): DispositionsDataResult {
  return {
    dispositions: value
  }
}


////////////////////////////////////////////
// AuthorizedUsersDataResult is the return type of `get-authorized-users`
////////////////////////////////////////////
export type UserDataResult = {
  user_id: string
  user_name: string
  team_id: string
  created_at: Date
  team_is_active: boolean
  can_dial: boolean
  leaderboard_avatar_name: string | null
  leaderboard_avatar_style: AvatarStyle | null
  default_language: null | DEFAULT_LANGUAGE_SETTING
  additional_language: null | DEFAULT_LANGUAGE_SETTING
  autodialer_paid: null | boolean
  autodialer_weekly_limit: null | number 
  callblitz_enabled: boolean | null
  parallel_enabled: boolean | null
  defaults_to_native_engine: boolean | null
}

export function jsonSafeToAuthorizedUserDataResult(value: Record<string, any>): UserDataResult {
  return {
    user_id: value.user_id,
    user_name: value.user_name,
    team_id: value.team_id,
    created_at: jsonSafeValueToDate(value.created_at),
    team_is_active: value.team_is_active,
    can_dial: value.can_dial,
    leaderboard_avatar_name: value.leaderboard_avatar_name,
    leaderboard_avatar_style: value.leaderboard_avatar_style,
    default_language: value.default_language,
    additional_language: value.additional_language,
    autodialer_paid: value.autodialer_paid,
    autodialer_weekly_limit: value.autodialer_weekly_limit,
    callblitz_enabled: value.callblitz_enabled,
    parallel_enabled: value.parallel_enabled,
    defaults_to_native_engine: value.defaults_to_native_engine
  }
}

export function jsonSafeToAuthorizedUsersDataResult(value:  Record<string, any>[]): UserDataResult[] {
  return value.map((item) => jsonSafeToAuthorizedUserDataResult(item))
}

export type UserAuthDataResult = {
  user_id: string
  api_key: string
  email: string
}

export function jsonSafeToUserAuthDataResult(value: Record<string, any>): UserAuthDataResult {
  return {
    user_id: value['user_id'],
    api_key: value['api_key'],
    email: value['email']
  }
}

export type UserAndUserAuthResult = {
  'user': UserDataResult
  'user_auth': UserAuthDataResult
}

export function jsonSafeToUserAndUserAuthResult(value: Record<string, any>): UserAndUserAuthResult {
  return {
    user: jsonSafeToAuthorizedUserDataResult(value[0]),
    user_auth: jsonSafeToUserAuthDataResult(value[1]),
  }
}

export function jsonSafeToUserAndUserAuthResults(value:  Record<string, any>[]): UserAndUserAuthResult[] {
  return value.map((item) => jsonSafeToUserAndUserAuthResult(item))
}

export enum SubscriptionType {
  FREE = 'FREE',
  BETA = 'BETA',
  TEAM = 'TEAM'
}

export type TeamResult = {
  team_id: string
  team: string
  subscription_type: SubscriptionType
  exclusive_domain: string | null
}

export function jsonSafeToTeamResult(value: Record<string, any>): TeamResult {
  return {
    team_id: value['team_id'],
    team: value['team'],
    subscription_type: value['subscription_type'],
    exclusive_domain: value['exclusive_domain']
  }
}

export function jsonSafeToTeamResults(value:  Record<string, any>[]): TeamResult[] {
  return value.map((item) => jsonSafeToTeamResult(item))
}

export type ExternalAccountResult = {
  external_account_id: string
  external_account_name: string
  team_id: string
  is_active: boolean
  client_team_id: string | null
  agency_name: string | null
}

export function jsonSafeToExternalAccountResult(value: Record<string, any>): ExternalAccountResult {
  return {
    external_account_id: value['external_account_id'],
    external_account_name: value['external_account_name'],
    team_id: value['team_id'],
    is_active: value['is_active'],
    client_team_id: value['client_team_id'],
    agency_name: value['agency_name']
  }
}

export function jsonSafeToExternalAccountResults(value:  Record<string, any>[]): ExternalAccountResult[] {
  return value.map((item) => jsonSafeToExternalAccountResult(item))
}

export type ExternalAccountMemberResult = {
  external_account_id: string
  user_id: string
  team_id: string
  is_active: boolean
  client_team_id: string | null
}

export function jsonSafeToExternalAccountMemberResult(value: Record<string, any>): ExternalAccountMemberResult {
  return {
    external_account_id: value['external_account_id'],
    user_id: value['user_id'],
    team_id: value['team_id'],
    is_active: value['is_active'],
    client_team_id: value['client_team_id']
  }
}

export function jsonSafeToExternalAccountMemberResults(value:  Record<string, any>[]): ExternalAccountMemberResult[] {
  return value.map((item) => jsonSafeToExternalAccountMemberResult(item))
}

export type VisibleAccountsResult = {
  teams: TeamResult[],
  ext_accounts: ExternalAccountResult[],
  ext_account_members: ExternalAccountMemberResult[]
  users: UserDataResult[]
}

export function jsonSafeToVisibleAccountsResult(value: Record<string, any>): VisibleAccountsResult {
  return {
    teams: jsonSafeToTeamResults(value.teams),
    ext_accounts: jsonSafeToExternalAccountResults(value.ext_accounts),
    ext_account_members: jsonSafeToExternalAccountMemberResults(value.ext_account_members),
    users: jsonSafeToAuthorizedUsersDataResult(value.users)
  }
}

////////////////////////////////////////////
// DisplayParams is the params type of `update-display`
////////////////////////////////////////////

export type DisplayParams = {
  client_id: string, 
  prompt_id: number, 
  prompt_type: string,
  prompt_text: string | null,
  start: number,
  end: number | null
}


////////////////////////////////////////////
// TextSummaryResult is the reslt type of `request-text-summary`
////////////////////////////////////////////

export type TextSummaryResult = {
  summary?: string
}

export function jsonSafeToTextSummaryResult(value:  Record<string, any>): TextSummaryResult {
  return {
    summary: value.summary,
  }
}


////////////////////////////////////////////
// EmailResult is the reslt type of `request-email`
////////////////////////////////////////////

export type EmailResult = {
  email?: string
}

export function jsonSafeToEmailResult(value:  Record<string, any>): EmailResult {
  return {
    email: value.email,
  }
}



////////////////////////////////////////////
// CoachingPromptInfo is the result type of coaching websocket
////////////////////////////////////////////

export type CoachingPromptInfo = {
  promptId: number | null
  promptType: PromptType
  promptText: string | null
  promptValue: any | null
  asOf: number | null 
  playId: string | null
  playName: string | null
  active: boolean | null
}

export function jsonSafeToCoachingPrompt(value:  Record<string, any>): CoachingPromptInfo {
  return {
    promptId: value['prompt_id'],
    promptType: value['prompt_type'].toUpperCase(),
    promptText: value['prompt_text'],
    promptValue: value['value'],
    asOf: value['as_of'],
    playId: value['play_id'],
    playName: value['play_name'],
    active: value['active']
  }
}

////////////////////////////////////////////
// AuthResult is the parameter type for submitting an auth request
////////////////////////////////////////////

export type AuthResult = {
  apiKey: string,
}

export function jsonSafeToAuthResult(value:  Record<string, any>): AuthResult {
  return {
    apiKey: value['api_key'],
  }
}

////////////////////////////////////////////
// TrackerPayload is the parameter type for submitting a tracker 
////////////////////////////////////////////


export type TrackerPayload = {
  payload_key: string,
  payload_value: string,
}

export type TrackerPayloadParam = TrackerPayload[]

////////////////////////////////////////////
// NamedPersonResult is the result of get-named-person
////////////////////////////////////////////

export type NamedPersonResult = {
  person_name: string,
  prospect_phone_value?: string
}

export type NamedPersonTable = NamedPersonResult[]

export function jsonSafeToNamedPersonResult(value:  Record<string, any>[]): NamedPersonTable {
  return value.map((item) => ({
    person_name: item.person_name,
    prospect_phone_value: item.prospect_phone_value
  }))
}


////////////////////////////////////////////
// SettingsResult is the result of get settings
////////////////////////////////////////////

export enum PROSPECT_RECORDING_SETTING {
  ALL='ALL',
  ONE_PARTY_ONLY='ONE_PARTY_ONLY',
  DIALER = 'DIALER',
  NONE='NONE'
}

export enum EMAIL_FREQUENCY_SETTING {
  DAILY = 'DAILY',
  TWICE_A_WEEK = 'TWICE_A_WEEK',
  WEEKLY = 'WEEKLY',
  NONE = 'NONE'
}

export enum DEFAULT_LANGUAGE_SETTING {
  ENGLISH = 'EN_US',
  SPANISH = 'ES_LATIN',
  FRENCH = 'FRENCH',
}

export const LANGUAGE_SETTING_TO_HUMAN_READABLE: {[k in DEFAULT_LANGUAGE_SETTING]: string} = {
  [DEFAULT_LANGUAGE_SETTING.ENGLISH]: 'English',
  [DEFAULT_LANGUAGE_SETTING.SPANISH]: 'Spanish',
  [DEFAULT_LANGUAGE_SETTING.FRENCH]: 'French',
}

export type SettingsUpdate ={
  is_team: boolean,
  default_prospect_recording?: PROSPECT_RECORDING_SETTING,
  default_prospect_streaming_transcription?: PROSPECT_RECORDING_SETTING,
  default_prospect_transcript_persistence?: PROSPECT_RECORDING_SETTING,
  email_frequency?: EMAIL_FREQUENCY_SETTING
  default_language?: DEFAULT_LANGUAGE_SETTING,
  additional_language?: DEFAULT_LANGUAGE_SETTING 
  reporting_min_healthy_connect_rate?: number
  autodialer_disabled?: boolean
  callblitz_enabled?: boolean,
  linkedin_finder_disabled?: boolean
  notify_on_review_status_change?: boolean
}

export enum SUBSCRIPTION_TYPE {
  FREE = 'FREE',
  BETA = 'BETA',
  TEAM = 'TEAM'
}

export type SettingsResult = {
  subscription_type: SUBSCRIPTION_TYPE,
  default_prospect_recording: PROSPECT_RECORDING_SETTING,
  default_prospect_streaming_transcription: PROSPECT_RECORDING_SETTING,
  default_prospect_transcript_persistence: PROSPECT_RECORDING_SETTING,
  email_frequency: EMAIL_FREQUENCY_SETTING,
  default_language: DEFAULT_LANGUAGE_SETTING,
  additional_language: DEFAULT_LANGUAGE_SETTING | null,
  reporting_min_healthy_connect_rate: number | null,
  autodialer_disabled: boolean | null,
  callblitz_enabled: boolean | null,
  linkedin_finder_disabled: boolean | null,
  notify_on_review_status_change: boolean | null
}

export function jsonSafeToSettingsResult(value: Record<string, any>): SettingsResult {
  return {
    'subscription_type': value.subscription_type,
    'default_prospect_recording': value.default_prospect_recording,
    'default_prospect_streaming_transcription': value.default_prospect_streaming_transcription,
    'default_prospect_transcript_persistence': value.default_prospect_transcript_persistence,
    'email_frequency': value.email_frequency,
    'default_language': value.default_language,
    'additional_language': value.additional_language,
    'reporting_min_healthy_connect_rate': value.reporting_min_healthy_connect_rate,
    'autodialer_disabled': value.autodialer_disabled,
    'callblitz_enabled': value.callblitz_enabled,
    'linkedin_finder_disabled': value.linkedin_finder_disabled,
    'notify_on_review_status_change': value.notify_on_review_status_change
  }
}

////////////////////////////////////////////
// ScopeReuslt is the result of get scope
////////////////////////////////////////////

export const USER_COVERAGE_ID_ALL = 'ALL'
export const ACCOUNT_COVERAGE_ID_ALL = 'ALL'
export const ACCOUNT_COVERAGE_ID_NA = 'NA'

export enum ScopeType {
  SESSIONS_READ = 'SESSIONS_READ',                 // read session (and related) data
  SESSIONS_WRITE = 'SESSIONS_WRITE',              // write session (and related) data
  ADMIN_WRITE = 'ADMIN_WRITE',                     // administer configuration
  INFRASTRUCTURE_READ = 'INFRASTRUCTURE_READ',     // read data about servers and such
  INFRASTRUCTURE_WRITE = 'INFRASTRUCTURE_WRITE',
}


export type Scope = {
  user_id: string,
  scope_type: ScopeType,
  scope_domain: ScopeDomain
  team_id: string | null,
}

export type ScopeResult = Scope[]

export function jsonSafeToScopeResult(value: Record<string, any>[]): ScopeResult {
  return value.map((v) => {
    return {
      'scope_domain': v.scope_domain,
      'scope_type': v.scope_type,
      'user_id': v.user_id,
      'team_id': v.team_id
    }
  })
}


export enum ScopeHolderType {
  USER = 'USER',
  USER_GROUP = 'USER_GROUP',
  TEAM = 'TEAM',
}

export enum ScopeUserCoverageType {
  USER = 'USER',
  TEAM = 'TEAM',
  USER_GROUP = 'USER_GROUP',
  ALL = 'ALL'  // everything covered by any matching ExternalScope rows
}


export enum ScopeAccountCoverageType {
    TEAM = 'TEAM',
    EXTERNAL_ACCOUNT = 'EXTERNAL_ACCOUNT',
    TEAM_AND_AGENCIES = 'TEAM_AND_AGENCIES',  // team + dials done by agencies for which team is the client
    ALL = 'ALL',  // TEAM plus everything granted to this team in the ExternalScope table
    NA = 'NA'  // not applicable, e.g. for admin write
}

export type ScopeV2 = {
    scope_type: ScopeType
    holder_type: ScopeHolderType
    holder_id: string
    holder_team_id: string
    user_coverage_type: ScopeUserCoverageType
    user_coverage_id: string  // takes special value USER_COVERAGE_ID_ALL for corresponding type
    account_coverage_type: ScopeAccountCoverageType  // # will be NA for scopes other than session_read
    account_coverage_id: string  // takes special values ACCOUNT_COVERAGE_ID_ALL, ACCOUNT_COVERAGE_ID_NA for corresponding types
}

export type ExternalScope = {
  scope_type: ScopeType,
  holder_team_id : string,
  account_coverage_type: ScopeAccountCoverageType, // only TEAM, EXT_ACCOUNT
  account_coverage_id: string, // only TEAM, EXT_ACCOUNT
}

export type ScopeV2Result = {
  scopes: ScopeV2[],
  external_scopes: ExternalScope[]
}

export function jsonSafeToScopeV2(value: Record<string, any>): ScopeV2 {
  return {
    scope_type: value.scope_type,
    holder_type: value.holder_type,
    holder_id: value.holder_id,
    holder_team_id: value.holder_team_id,
    user_coverage_type: value.user_coverage_type,
    user_coverage_id: value.user_coverage_id,
    account_coverage_type: value.account_coverage_type,
    account_coverage_id: value.account_coverage_id
  }
}

export function jsonSafeToScopesV2(value: Record<string, any>[]): ScopeV2[] {
  return value.map((v) => jsonSafeToScopeV2(v))
}

export function jsonSafeToExternalScope(value: Record<string, any>): ExternalScope {
  return {
    scope_type: value.scope_type,
    holder_team_id: value.holder_team_id,
    account_coverage_type: value.account_coverage_type,
    account_coverage_id: value.account_coverage_id
  }
}
export function jsonSafeToScopeV2Result(value: Record<string, any>): ScopeV2Result {
  return {
    scopes: value.scopes.map((v: Record<string, any>) => jsonSafeToScopeV2(v)),
    external_scopes: value.ext_scopes.map((v: Record<string, any>) => jsonSafeToExternalScope(v))
  }
}

export type AdminWriteTargetsResult = {
  team_ids: string[]
  user_group_ids: string[]
  user_ids: string[]
}

export function jsonSafeToAdminWriteTargetsResult(value: Record<string, any>): AdminWriteTargetsResult 
{
  return {
    team_ids: value.team_ids,
    user_group_ids: value.user_group_ids,
    user_ids: value.user_ids
  }
}


export type HistoricalData = {
  'numberDialsToday': number
}

export type WeatherLocationInfo = {
  'prospectName': string | null,
  'location': string | null,
  'timeOffsetUtc': number | null
  'weatherImgUrl': string | null,
  'weatherTemperature': number | null,
}

export type PreviousCallSummary = {
'sessionId': string
'userId': string,
'sessionMetric': SessionMetric | null
'duration': number
'scheduledStart': number,
'repSummary': string | null
'prospectSummary': string | null 
'notes': Note[] | null
'dispositions': NoteDisposition[] | null 
}


export function jsonSafeToPrevousCallSummary(value: Record<string, any>): PreviousCallSummary {
  return {
    'sessionId': value['session_id'], 
    'userId': value['user_id'],
    'sessionMetric': value['session_metric'] ? jsonSafeToSessionMetric(value['session_metric']) : null,
    'scheduledStart': value['scheduled_start'], 
    'duration': value['audio_duration'],
    'repSummary': value['rep_summary'], 
    'prospectSummary': value['prospect_summary'],
    'notes': value['notes'] ? value['notes'].map((v: Record<string, any>) => jsonSafeToNote(v)) : null,
    'dispositions': value['dispositions'] ? value['dispositions'].map((v: Record<string, any>) => jsonSafeToNoteDisposition(v)) : null 
  }
}

export type PreviousCallSummaryResult = PreviousCallSummary[]

export function jsonSafeToPreviousCallSummaryResult(value: Record<string, any>): PreviousCallSummaryResult {
  return value.map((x: Record<string, any>) => jsonSafeToPrevousCallSummary(x))
}


export type PreviousCallData = {
  'currentSessionId': string
  'numberPreviousDials': number
  'prospectPhoneNumber': number
  'previousCallSummaries': PreviousCallSummary[] | null
}

export function jsonSafeToPreviousCallData(value:  Record<string, any>, sessionId: string): PreviousCallData {
  return { 
  'currentSessionId': sessionId,
  'numberPreviousDials': value['session_count'], 
  'prospectPhoneNumber': value['prospect_phone'],
  'previousCallSummaries':  value['previous_call_summaries'] ? value['previous_call_summaries'].map((v: Record<string, any>) => jsonSafeToPrevousCallSummary(v)) : null 
  }
}

export enum RoomMetricType {
  TOTAL_CALLS='TOTAL_CALLS',
  LIVE_DURATION='LIVE_DURATION',
}

export type RoomMetadata = {
  room_id: string | null,
  room_name: string,
  metric: RoomMetricType
  room_code: string | null,
  team_id: string | null,
  email_domain: string | null
}

export function jsonSafeToRoomMetadata(record: Record<string, any>): RoomMetadata {
  return {
    room_id: record.room_id,
    room_name: record.room_name,
    metric: record.room_metric,
    room_code: record.room_code,
    team_id: record.team_id,
    email_domain: record.email_domain
  }
}

export type GetRoomMembershipsResult  = RoomMetadata[]

export function jsonSafeToGetRoomMembershipsResult(record:  Record<string, any>): GetRoomMembershipsResult {
  return record.map((value: Record<string, any>) => jsonSafeToRoomMetadata(value))
}

export type UserLeaderboardResult = {
  user_id: string | null
  avatar_name: string | null,
  avatar_style: AvatarStyle | null,
  value: number
  rank?: number
}

export function jsonSafeToUserLeaderboardResult(record: Record<string, any>): UserLeaderboardResult {
  return {
    'user_id': record.user_id,
    'avatar_name': record.avatar_name,
    'avatar_style': record.avatar_style,
    'value': record.value,
    'rank': undefined
  }
}

export type LeaderboardDataResult = UserLeaderboardResult[]

export function jsonSafeToLeaderboardDataResult(record:  Record<string, any>): LeaderboardDataResult {
  return record.map((value: Record<string, any>) => jsonSafeToUserLeaderboardResult(value))
}

export const GLOBAL_ROOM_ID = '__GLOBAL_ROOM_ID'
export const GLOBAL_ROOM_NAME = 'GLOBAL ROOM'

export type CustomJsonLink = {
  'url': string,
  'text': string
}

export function jsonSafeToCustomJsonLink(record: Record<string, any>): CustomJsonLink {
  return {
    'url': record.url,
    'text': record.text
  }
}

export type CustomJsonLinks = {
  'header': string,
  'urls': CustomJsonLink[]
  'data'?: string,
}

export function jsonSafeToCustomJsonLinks(record: Record<string, any>): CustomJsonLinks {
  return {
    'header': record.header,
    'urls': record.urls.map((v: Record<string, any>) => jsonSafeToCustomJsonLink(v)),
    'data': record.data ? record.data : undefined
  }
}
 
export type SummarizerResult = {
  'summary': string | null,
  'header_and_content'?: {'header': string, 'content': string}[]
  'custom_json': CustomJsonLinks[] | null
}

export function jsonSafeToSummarizerResult(record: Record<string, any>): SummarizerResult {
  return {
    'summary': record.summary,
    'custom_json': record.custom_json ? record.custom_json.map((v: Record<string, any>) => jsonSafeToCustomJsonLinks(v)) : null
  }
}

export type RemarkTerm = {
  remark: Remark
  negated: boolean
}

export type OrRemarkTerm = RemarkTerm[]

export type TextFilterTerm = {
  text: string
  party_role: PartyRole | null
}

export type OrTextFilterTerm = TextFilterTerm[]

export type ListUserSessionParamsV2 = {
  start: Date | null
  end: Date | null,
  has_star: boolean | null,
  user_ids: string[] | null,
  min_duration: number | null,
  dispositions: string[] | null,
  phone_numbers: string[] | null,
  remarks: string[] | null
  review_is_open: boolean | null,
  prompt_types: string[] | null,
  counterparts: string[] | null,
  cadence_steps: string[] | null,
  cadences: string[] | null,
  industries: string[] | null,
  titles: string[] | null,
  seniorities: string[] | null,
  remark_cnf: OrRemarkTerm[] | null,
  text_cnf: OrTextFilterTerm[] | null,
  stages: STAGE[] | null,
}

export type ListUserSessionParamsV3 = {
  start: Date | null
  end: Date | null,
  cnf: StaticFilterDisjunction[]
}


export enum ProspectInfoTypes {
  CADENCE_STEPS = 'cadence_steps',
  CADENCES = 'cadence',
  TITLES = 'title',
  SENIORITIES = 'seniority',
  INDUSTRIES = 'industry'
}

export type ProspectInfoChoice = {
    field_name: string
    choices: string[]
}

export type ProspectInfoChoices = ProspectInfoChoice[]

export function jsonSafeToProspectInfoChoice(record: Record<string, any>): ProspectInfoChoice {
  return {
    'field_name': record.field_name,
    'choices': record.choices,
  }
}

export function jsonSafeToProspectInfoChoices(record: Record<string, any>[]): ProspectInfoChoices {
  return record.map((v: Record<string, any>) => jsonSafeToProspectInfoChoice(v)) 
}

export type ProspectInfo = {
    platform: Platform
    platform_person_id: string | null
    platform_company_id: string | null
    prospect_phone_value: string | null
    platform_person_url: string | null
    xref_platform: Platform
    xref_person_id: string | null
    xref_company_id: string | null
    xref_person_url: string | null
    cadence: string | null
    cadence_2: string | null
    cadence_3: string | null
    cadence_step: string | null,
    cadence_step_2: string | null,
    cadence_step_3: string | null,

    prospect_name: string | null
    prospect_title: string | null
    prospect_occupation: string | null
    prospect_seniority: string | null
    prospect_linkedin: string | null

    company_name: string | null
    company_linkedin: string | null
    company_url: string | null
    company_industry: string | null
    company_industry_2: string | null
    company_industry_3: string | null
}

export function jsonSafeToProspectInfo(record: Record<string, any>): ProspectInfo {
  return {
    platform: record.platform,
    platform_person_id: record.platform_person_id,
    platform_company_id: record.platform_company_id,
    prospect_phone_value: record.prospect_phone_value,
    platform_person_url: record.platform_person_url,
    xref_platform: record.xref_platform,
    xref_person_id: record.xref_person_id,
    xref_company_id: record.xref_company_id,
    xref_person_url: record.xref_person_url,
    cadence: record.cadence,
    cadence_2: record.cadence_2,
    cadence_3: record.cadence_3,
    cadence_step: record.cadence_step,
    cadence_step_2: record.cadence_step_2,
    cadence_step_3: record.cadence_step_3,

    prospect_name: record.prospect_name,
    prospect_title: record.prospect_title,
    prospect_occupation: record.prospect_occupation,
    prospect_seniority: record.prospect_seniority,
    prospect_linkedin: record.prospect_linkedin,

    company_name: record.company_name,
    company_linkedin: record.company_linkedin,
    company_url: record.company_url,
    company_industry: record.company_industry,
    company_industry_2: record.company_industry_2,
    company_industry_3: record.company_industry_3,
  }
}


export type GetSessionStatusResult = {
  session: Session
  prospect_info: ProspectInfo | null,
  live_rep_audio: boolean
  live_prospect_audio: boolean
  active: boolean
  status_as_of: Date,
}

export function jsonSafeToGetSessionStatusResult(v: Record<string, any>): GetSessionStatusResult {
  return {
    'session': jsonSafeToSession(v.session),
    'prospect_info': v.prospect_info ? jsonSafeToProspectInfo(v.prospect_info) : null,
    'live_rep_audio': v.live_rep_audio,
    'live_prospect_audio': v.live_prospect_audio,
    'active': v.active,
    'status_as_of': jsonSafeValueToDate(v.status_as_of)
  }
}

export function jsonSafeToGetSubteamViewResult(v: Record<string, any>): SubteamView[] {
  return v.map((x: Record<string, any>) => jsonSafeToSubteamView(x))
}


export function jsonSafeToSubteamResult(v: Record<string, any>): Subteam[] {
  return v.map((x: Record<string, any>) => jsonSafeToSubteam(x))
}


export function jsonSafeToSubteamMemberResult(v: Record<string, any>): SubteamMember[] {
  return v.map((x: Record<string, any>) => jsonSafeToSubteamMember(x))
}

export type GetUserGroupResult = {
  groups: UserGroup[]
  memberships: UserGroupMembership[]
}

export function jsonSafeToGetUserGroupResult(v: Record<string, any>): GetUserGroupResult {
  return {
    groups: v.groups.map(jsonSafeToUserGroup),
    memberships: v.memberships.map(jsonSafeToUserGroupMembership),
  }
}

export type Keyword = {
  keyword_id: string,
  temp_keyword_id?: string,
  keyword_name: string,
  party_role: PartyRole | null
}

export function jsonSafeToKeyword(v: Record<string, any>): Keyword {
  return {
    keyword_id: v.keyword_id,
    keyword_name: v.keyword_name,
    party_role: v.party_role ? v.party_role : null
  }
}

export function jsonSafeToKeywordResult(v: Record<string, any>): Keyword[] { 
  return v.map((x: Record<string, any>) => jsonSafeToKeyword(x))
}

export type KeywordPhrase = {
  keyword_id: string,
  temp_keyword_id?: string,
  keyword_phrase: string,
  keyword_phrase_id: string
  temp_keyword_phrase_id?: string,
  language: DEFAULT_LANGUAGE_SETTING
  boost: boolean
}

export function jsonSafeToKeywordPhrase(v: Record<string, any>): KeywordPhrase {
  return {
    keyword_id: v.keyword_id,
    keyword_phrase: v.keyword_phrase,
    keyword_phrase_id: v.keyword_phrase_id,
    language: v.language,
    boost: v.boost
  }
}

export function jsonSafeToKeywordPhraseResult(v: Record<string, any>): KeywordPhrase[] { 
  return v.map((x: Record<string, any>) => jsonSafeToKeywordPhrase(x))
}

export type KeywordGroupMembership = {
  keyword_id: string,
  keyword_group_id: string
  temp_keyword_id?: string
  temp_group_id?: string
}

export function jsonSafeToKeywordGroupMembership(v: Record<string, any>): KeywordGroupMembership {
  return {
    keyword_id: v.keyword_id,
    keyword_group_id: v.keyword_group_id
  }
}

export function jsonSafeToKeywordGroupMembershipResult(v: Record<string, any>): KeywordGroupMembership[] { 
  return v.map((x: Record<string, any>) => jsonSafeToKeywordGroupMembership(x))
}

export type KeywordGroup = {
  keyword_group_id: string
  keyword_group_name: string 
  temp_group_id?: string
}

export function jsonSafeToKeywordGroup(v: Record<string, any>): KeywordGroup {
  return {
    keyword_group_id: v.keyword_group_id,
    keyword_group_name: v.keyword_group_name
  }
}

export function jsonSafeToKeywordGroupResult(v: Record<string, any>): KeywordGroup[] { 
  return v.map((x: Record<string, any>) => jsonSafeToKeywordGroup(x))
}

export type Condition = {
  condition_id: string
  temp_condition_id?: string,
  team_id: string
  filter_cnf: TemporalFilterDisjunction[]
  created_at: Date
  updated_at: Date
  active: boolean
  condition_name: string | null
  condition_group: string | null
  deployRequested?: boolean
}

export function jsonSafeToCondition(v: Record<string, any>): Condition {
  return {
    condition_id: v.condition_id,
    team_id: v.team_id,
    filter_cnf: jsonSafeToTemporalFilterDisjunctions(JSON.parse(v.filter_cnf_str)),
    created_at: jsonSafeValueToDate(v.created_at),
    updated_at: jsonSafeValueToDate(v.updated_at),
    active: v.active,
    condition_name: v.condition_name ? v.condition_name : null,
    condition_group: v.condition_group ? v.condition_group : null
  }
}

export function jsonSafeToConditionResult(v: Record<string, any>): Condition[] {
  return v.map((x: Record<string, any>) => jsonSafeToCondition(x))
}

export type Play = {
  play_id: string,
  temp_play_id?: string 
  team_id: string,
  condition_id: string,
  temp_condition_id?: string,
  prompt_text: string,
  created_at: Date,
  updated_at: Date,
  active: boolean,
  weight: number,
  play_name: string,
  min_duration: number | null
  deployRequested?: boolean
}

export function jsonSafeToPlay(v: Record<string, any>): Play {
  return {
    play_id: v.play_id,
    team_id: v.team_id,
    condition_id: v.condition_id,
    prompt_text: v.prompt_text,
    created_at: jsonSafeValueToDate(v.created_at),
    updated_at: jsonSafeValueToDate(v.updated_at),
    active: v.active,
    weight: v.weight,
    play_name: v.play_name,
    min_duration: v.min_duration !== null ? v.min_duration : null
  }
}

export function jsonSafeToPlayResult(v: Record<string, any>): Play[] {
  return v.map((x: Record<string, any>) => jsonSafeToPlay(x))
}


export enum CnfFilterType {
  USER = 'USER',
  USER_ACCOUNT = 'USER_ACCOUNT',
  EXTERNAL_ACCOUNT = 'EXTERNAL_ACCOUNT',
  PROSPECT = 'PROSPECT',
  TIME = 'TIME',
  DISPLAY = 'DISPLAY',
  PHRASE = 'PHRASE',
  REMARK = 'REMARK',
  STAGE = 'STAGE',
  COUNTERPARTS = 'COUNTERPARTS',
  METADATA = 'METADATA',
  CUSTOM_METRIC = "CUSTOM_METRIC",
  SESSION_METRIC = "SESSION_METRIC"
}

export type UserFilterTerm = {
  user_id?: string 
  user_group_id?: string
}

export function jsonSafeToUserFilterTerm(v: Record<string, any>): UserFilterTerm {
  return {
    user_id: v.user_id ? v.user_id : undefined,
    user_group_id: v.user_group_id ?? v.subteam_id,  // filter_cnf_str from May 2024 and before might use subteam_id
  }
}

export function jsonSafeToUserFilterTerms(v: Record<string, any>): UserFilterTerm[] {
  return v.map((x: Record<string, any>) => jsonSafeToUserFilterTerm(x))
}

export type UserAccountFilterTerm = {
  user_ids?: string[]
  user_group_ids?: string[]
  account_ids?: string[]
}

export function jsonSafeToUserAccountFilterTerm(v: Record<string, any>): UserAccountFilterTerm {
  return {
    user_ids: v.user_ids ? v.user_ids : undefined,
    user_group_ids: v.user_group_ids ? v.user_group_ids : undefined,
    account_ids: v.account_ids ? v.account_ids : undefined
  }
}

export function jsonSafeToUserAccountFilterTerms(v: Record<string, any>): UserAccountFilterTerm[] {
  return v.map((x: Record<string, any>) => jsonSafeToUserAccountFilterTerm(x))
}

export type ExternalAccountFilterTerm = {
  external_account_id?: string
}

export function jsonSafeToExternalAccountFilterTerm(v: Record<string, any>): ExternalAccountFilterTerm {
  return {
    external_account_id: v.external_account_id ?? undefined,
  }
}

export function jsonSafeToExternalAccountFilterTerms(v: Record<string, any>): ExternalAccountFilterTerm[] {
  return v.map((x: Record<string, any>) => jsonSafeToExternalAccountFilterTerm(x))
}

export type ProspectFilterTerm = {
  cadence_step?: string
  cadence?: string 
  industry?: string 
  title?: string 
  seniority?: string
  phone_number?: string,

  exact: boolean
  case_sensitive: boolean
}

export function jsonSafeToProspectFilterTerm(v: Record<string, any>): ProspectFilterTerm {
  return {
    cadence_step: v.cadence_step ? v.cadence_step : undefined,
    cadence: v.cadence ? v.cadence : undefined,
    industry: v.industry ? v.industry : undefined,
    title: v.title ? v.title : undefined,
    seniority: v.seniority ? v.seniority : undefined,
    phone_number: v.phone_number ? v.phone_number : undefined,
    exact: v.exact,
    case_sensitive: v.case_sensititive,
  }
}

export function jsonSafeToProspectFilterTerms(v: Record<string, any>): ProspectFilterTerm[] {
  return v.map((x: Record<string, any>) => jsonSafeToProspectFilterTerm(x))
}

export type TimeFilterTerm = {
  start?: number
  end?: number
}

export enum TimeFilterType {
  BEFORE = 'Before',
  BETWEEN = 'Between',
  AFTER = 'After'
}

export function jsonSafeToTimeFilterTerm(v: Record<string, any>): TimeFilterTerm {
  return {
    start: v.start != null ? v.start : undefined,
    end: v.end != null ? v.end : undefined
  }
}

export function jsonSafeToTimeFilterTerms(v: Record<string, any>): TimeFilterTerm[] {
  return v.map((x: Record<string, any>) => jsonSafeToTimeFilterTerm(x))
}


export type PhraseFilterTerm = {
  text: string,
  party_role?: PartyRole
  stem: boolean
  language: DEFAULT_LANGUAGE_SETTING
}

export function jsonSafeToPhraseFilterTerm(v: Record<string, any>): PhraseFilterTerm {
  return {
    text: v.text,
    party_role: v.party_role ? v.party_role : undefined, 
    stem: v.stem,
    language: v.language,
  }
}

export function jsonSafeToPhraseFilterTerms(v: Record<string, any>): PhraseFilterTerm[] {
  return v.map((x: Record<string, any>) => jsonSafeToPhraseFilterTerm(x))
}

export type RemarkFilterTerm = {
  remark: Remark
  party_role?: PartyRole
}

export function jsonSafeToRemarkFilterTerm(v: Record<string, any>): RemarkFilterTerm {
  return {
    remark: v.remark,
    party_role: v.party_role ? v.party_role : undefined
  }
}

export function jsonSafeToRemarkFilterTerms(v: Record<string, any>): RemarkFilterTerm[] {
  return v.map((x: Record<string, any>) => jsonSafeToRemarkFilterTerm(x))
}

export enum LookbackRule {
  PRECEDED_BY = 'PRECEDED_BY',
  NOT_PRECEDED_BY = 'NOT_PRECEDED_BY',
}

export type TemporalFilterDisjunction = {
  filter_type: CnfFilterType
  user_filters?: UserFilterTerm[] 
  prospect_filters?: ProspectFilterTerm[]
  time_filters?: TimeFilterTerm[] 
  phrase_filters?: PhraseFilterTerm[]
  remark_filters?: RemarkFilterTerm[]
  lookback_rule?: LookbackRule
  lookback_horizon?: number

  prospectFilterPrimarilyFor?: 'CADENCE_STEP' | 'CADENCE' | 'INDUSTRY' | 'TITLE' | 'SENIORITY' | 'PHONE_NUMBER'
  userFilterPrimarilyFor?: 'USERS' | 'SUBTEAMS' | 'USERS_AND_SUBTEAMS',
  phraseFilterPrimarilyFor?: 'PROSPECT' | 'REP' | 'ANYONE',
  remarkFilterPrimarilyFor?: 'PROSPECT' | 'REP' | 'ANYONE',
  timeFilterPrimarilyFor?: TimeFilterType
}

export function jsonSafeToTemporalFilterDisjunction(v: Record<string, any>): TemporalFilterDisjunction {
  return {
    filter_type: v.filter_type,
    user_filters: v.user_filters ? jsonSafeToUserFilterTerms(v.user_filters): undefined,
    prospect_filters: v.prospect_filters ? jsonSafeToProspectFilterTerms(v.prospect_filters) : undefined,
    time_filters: v.time_filters ? jsonSafeToTimeFilterTerms(v.time_filters) : undefined,
    phrase_filters: v.phrase_filters ? jsonSafeToPhraseFilterTerms(v.phrase_filters) : undefined,
    remark_filters: v.remark_filters ? jsonSafeToRemarkFilterTerms(v.remark_filters): undefined,
    lookback_rule: v.lookback_rule ? v.lookback_rule : undefined,
    lookback_horizon: v.lookback_horizon != null ? v.lookback_horizon: undefined,
  }
}

export function TemporalFilterDisjunctionToStaticFilterDisjunction(temporalFilters: TemporalFilterDisjunction): StaticFilterDisjunction {
  return {
    'negated': false,
    'user_filters': temporalFilters.user_filters,
    'prospect_filters': temporalFilters.prospect_filters,
    'phrase_filters': temporalFilters.phrase_filters,
    'remark_filters': temporalFilters.remark_filters,
    'filter_type': temporalFilters.filter_type
  }
}

export function TemporalFilterDisjunctionToCleanTemporalFilterDisjunction(temporalFilters: TemporalFilterDisjunction): TemporalFilterDisjunction {
    return {
      filter_type: temporalFilters.filter_type,
      user_filters: temporalFilters.user_filters, 
      prospect_filters: temporalFilters.prospect_filters,
      time_filters: temporalFilters.time_filters, 
      phrase_filters: temporalFilters.phrase_filters,
      remark_filters: temporalFilters.remark_filters,
      lookback_rule: temporalFilters.lookback_rule,
      lookback_horizon: temporalFilters.lookback_horizon
    }
  }

export function cleanTemporalFilterDisjunctions(temporalFilters: TemporalFilterDisjunction[]): TemporalFilterDisjunction[] {
  return temporalFilters.map((v) => TemporalFilterDisjunctionToCleanTemporalFilterDisjunction(v))
}

export function jsonSafeToTemporalFilterDisjunctions(v: Record<string, any>): TemporalFilterDisjunction[] {
  return v.map((x: Record<string, any>) => jsonSafeToTemporalFilterDisjunction(x))
}

export type FrontEndLogs = {
  log_message: string
  notify_developer: boolean
}

export type SubscriptionInfo = {
  subscription_id: string,
  client_secret: string
}

export enum PaymentSubscriptionOption {
  ANNUAL= 'ANNUAL',
  MONTHLY='MONTHLY'
}

export const PaymentSubscriptionOptionToPaymentLink: {[k in PaymentSubscriptionOption]: string} = {
  [PaymentSubscriptionOption.ANNUAL]: 'https://buy.stripe.com/14k3ctgkWcSD2R25lV',
  [PaymentSubscriptionOption.MONTHLY]: 'https://buy.stripe.com/00g3ct6KmcSD0IU9C9',
}

export const SubscriptionTypeToHumanReadable: {[k in PaymentSubscriptionOption]: string} = {
  [PaymentSubscriptionOption.MONTHLY]: 'Monthly',
  [PaymentSubscriptionOption.ANNUAL]: 'Annual'
}

export const SubscriptionTypeToPriceInfo: {[k in PaymentSubscriptionOption]: string} = {
  [PaymentSubscriptionOption.MONTHLY]: '39.99',
  [PaymentSubscriptionOption.ANNUAL]: '34.99'
}


export function jsonSafeToSubscriptionInfo(v: Record<string, any>): SubscriptionInfo {
  return {
    subscription_id: v['subscription_id'],
    client_secret: v['client_secret']
  }
}


export type UpgradeStatus = {
  can_upgrade: boolean
}

export function jsonSafeToUpgradeStatus(v: Record<string, any>): UpgradeStatus {
  return {'can_upgrade': v.can_upgrade}
}


export type AdminUserSettingsUpdate = {
  autodialer_paid?: boolean
  team_is_active?: boolean
  can_dial?: boolean
  autodialer_weekly_limit?: number | null
  parallel_enabled?: boolean
  defaults_to_native_engine?: boolean
}

export type AdminTeamSettingUpdate = {
  subscription_type?: SubscriptionType
}

export type AdminScopeUpdate = {
  scope: ScopeV2,
  is_remove: boolean
}

export type ExclusiveDomainUpdate = {
  exclusive_domain: string
}

export enum EMAIL_INPUT  {
  PROSPECT_NAME = 'prospect_name',
  PROSPECT_COMPANY = 'prospect_company',
  PROSPECT_TITLE = 'prospect_title',
  LINKEDIN = 'linkedin',
  WEBSITE = 'website',
  TRANSCRIPTS = 'transcripts',
}

export enum Diagnostic {
  AVERAGE_SCORE = 'Call Score',
  // ACTIVITY DIAGNOSTICS
  DIAL_COUNT = '# Dials/rep',
  DIALS_PER_DAY = '# Dials/rep/day',
  DIALS_PER_HOUR = '# Dials/rep/hour',
  UNIQUE_COUNT = '# Unique contacts/rep',
  AVERAGE_ATTEMPTS_PER_CONTACT = 'Average attempt per contact', 
  TIME_SPENT_PER_DAY = 'Call time/rep/day (hrs.)',

  // DIAL TO CONNECT DIAGNOSTICS
  DIAL_TO_CONNECT = 'Dial to connect',
  CALLING_TIMES = 'Dials during primetime',
  RINGING_DURATION = 'Ringing duration w/o contact',

  // CONNECT TO PITCHED DIAGNOSTIC
  CONNECT_TO_PITCHED = 'Connect to pitched',
  BRUSH_OFF_PITCHED = 'No pitch: Brush off',
  PERMISSION_ASK_PITCHED = 'No pitch: Did not ask for permission',
  WRONG_PERSON_PITCHED = 'No pitch: Got wrong person',

  // PITCHED TO CONVERSATIO METRIC
  PITCHED_TO_CONVERSATION = 'Pitched to conversation',
  WRONG_ROLE_CONVERSATION = 'No convo: Prospect was wrong role',
  NO_AUTHORITY_CONVERSATION = 'No convo: Prospect had no authority',
  NO_NEED_CONVERSATION = 'No convo: Prospect claimed to have no need',
  BRUSH_OFF_CONVERSATION = 'No convo: Prospect not interested', 
  
  // CONVERSATION TO MEETING BOOKED METRIC
  CONVERSATION_TO_MEETING_BOOKED = 'Conversation to meeting booked',
  REP_NO_ASK_FOR_MEETING = 'No meeting: Rep did not ask for meeting',
  REP_NO_ASK_ABOUT_BUSINESS = 'No meeting: Rep did not ask about business',
  REP_NO_ASK_ABOUT_RESPONSIBILITIES = 'No meeting: Rep did not ask about responsibilities',

  SUB_CUSTOM_METRIC_ONE = 'Custom Metric One',
  SUB_CUSTOM_METRIC_TWO = 'Custom Metric Two',
  SUB_CUSTOM_METRIC_THREE = 'Custom Metric Three',
  SUB_CUSTOM_METRIC_FOUR = 'Custom Metric Four',
  SUB_CUSTOM_METRIC_FIVE = 'Custom Metric Five',
  SUB_CUSTOM_METRIC_SIX = 'Custom Metric Six',
  SUB_CUSTOM_METRIC_SEVEN = 'Custom Metric Seven',
}

export const SUB_SCORE_DIAGNOSTICS: Diagnostic[] = [Diagnostic.SUB_CUSTOM_METRIC_ONE, Diagnostic.SUB_CUSTOM_METRIC_TWO, Diagnostic.SUB_CUSTOM_METRIC_THREE, Diagnostic.SUB_CUSTOM_METRIC_FOUR, Diagnostic.SUB_CUSTOM_METRIC_FIVE, Diagnostic.SUB_CUSTOM_METRIC_SIX, Diagnostic.SUB_CUSTOM_METRIC_SEVEN]
export const SCORE_DIAGNOSTICS: Diagnostic[] = [Diagnostic.AVERAGE_SCORE, ...SUB_SCORE_DIAGNOSTICS]

export type EmailValuePropUpdate = {
  email_value_prop_id: string | null
  is_remove: boolean | null
  is_team: boolean | null
  pain_point: string | null
  prospect_benefits: string | null
  solution: string | null
}

export type ProspectEmailInput = {
  prospect_name: string | null
  prospect_company: string | null
  prospect_title: string | null
  linkedin_summary: string | null
  website_summary: string | null
}

export type EmailGenRequest = {
  prospect_id: string
  value_prop: EmailValueProp,
  previous_session_ids: string[]
  selected_session_ids: string[]
  prospect_info: ProspectEmailInput
}

export type LinkedInGenRequest = {
  platform_person_url: string
  prospect_name: string
  value_prop: EmailValueProp,
  message_history: string | null,
  research: string | null,
}

export type CustomEditInfo = {
    uuid: string
    custom_tone: EmailToneType | null
    custom_instructions: string | null
}

export type AdminSynthResult  = {
  count: number
  team: string
  team_id: string
}

export function jsonSafeToAdminSynthResult(v: Record<string, any>): AdminSynthResult {
  return {
    count: v.count,
    team: v.team,
    team_id: v.team_id
  }
}

export function jsonSafeToAdminSynthResults(v: Record<string, any>): AdminSynthResult[] {
  return v.map((x: Record<string, any>) => jsonSafeToAdminSynthResult(x))
}

export type AdminSynthTeamResult = {
  variants: ChatbotPromptVariant[]
  templates: ChatbotPromptTemplate[]
}

export function jsonSafeToAdminSynthTeamResult(v: Record<string, any>): AdminSynthTeamResult {
  return {
    variants: v.variants.map((x: Record<string, any>) => jsonSafeToChatbotPromptVariant(x)),
    templates: v.templates.map((x: Record<string, any>) => jsonSafeToChatbotPromptTemplate(x))
  }
}

export type MultiDialerQuotaInfo = {
  subscription: MultiDialerSubscription,
  dials_remaining: number
  period_end: Date
  international_credits_remaining: number
  minutes_remaining: number
}

export function jsonSafeToQuotaInfo(v: Record<string, any>): MultiDialerQuotaInfo {
  return {
    subscription: jsonSafeToMultiDialerSubscription(v.subscription),
    dials_remaining: v.dials_remaining,
    period_end: jsonSafeValueToDate(v.period_end),
    international_credits_remaining: v.international_credits_remaining,
    minutes_remaining: v.minutes_remaining
  }
}