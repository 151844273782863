import { Typography } from "interfaces/typography"
import React from "react"

type DebouncerProps = {
    elementToShow: JSX.Element
    stringToShow?: string
    customElementToShow?: JSX.Element
    customAfter?: boolean  
    customTimer?: number
  }
  
  type DebouncerState = {
    showDebouncer: boolean
    showDebouncerPostTimer: boolean
  }
  
export class Debouncer extends React.Component<DebouncerProps, DebouncerState> {
    constructor(props: DebouncerProps) {
      super(props)
      this.state = {
        showDebouncer: false,
        showDebouncerPostTimer: false
      }
    }

    componentDidUpdate(prevProps: Readonly<DebouncerProps>, prevState: Readonly<DebouncerState>, snapshot?: any): void {
      if (this.props.customTimer && this.state.showDebouncer && !prevState.showDebouncer) {
        setTimeout(() => {
            this.setState((state) => {
              if (!state.showDebouncer || state.showDebouncerPostTimer) return null
              return {
                'showDebouncerPostTimer': true
              }
            })
          }, this.props.customTimer)
      }
    }

    _showDebouncer() { 
      return (!this.props.customTimer && this.state.showDebouncer) || (this.props.customTimer && this.state.showDebouncer && this.state.showDebouncerPostTimer)
    }
  
    render() {
      const showDebouncer = this._showDebouncer()
      return (
      <div className='relative'>
        {showDebouncer && this.props.customElementToShow && !this.props.customAfter ? this.props.customElementToShow : null}
        <div 
        onMouseEnter={() => {
          if(!this.state.showDebouncer) 
          this.setState({'showDebouncer': true})}} 
        onMouseLeave={() => {
          if (this.state.showDebouncer) 
          this.setState({'showDebouncer': false, 'showDebouncerPostTimer': false})}}>
        {this.props.elementToShow}
        </div>
        {showDebouncer ? this.props.customAfter && this.props.customElementToShow ? this.props.customElementToShow : this.props.stringToShow ?
        <div className='absolute' style={{'marginTop': '4px', 'zIndex': '999999999'}}>
          <div className='bg-white p-1' style={{'backgroundColor': 'white', 'width': '100px', 'textAlign': 'center', 'color': 'black', 'borderRadius': '2px', 'boxShadow': '0px 0px 4px rgba(0, 0, 0, 0.35)'}}>
            <Typography variant='smallParagraph'>
            {this.props.stringToShow}
            </Typography>
        </div>
      </div> : null : null}
      </div>)
    }
  }
  